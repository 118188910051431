import React from 'react'
import styled, { css } from 'styled-components'

type IconProps = {
	color?: string
}

export const ChemistryIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<StyledIcon xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" color={color}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
			/>
		</StyledIcon>
	)
}

export const PhysicsIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M11.78,3.79a1.38,1.38,0,0,0,2.57.69h0a1.34,1.34,0,0,0,.18-.69,1.23,1.23,0,0,0,0-.27h0a1.38,1.38,0,0,0-2.73.27Z" />
			<path d="M8.39,6.57A1.43,1.43,0,1,0,9.83,8,1.43,1.43,0,0,0,8.39,6.57Z" />
			<path d="M15.47,10.08a5.69,5.69,0,0,0-1.13-1.64c-.13-.15-.28-.29-.43-.44.15-.15.3-.29.43-.44a5.69,5.69,0,0,0,1.13-1.64,1.86,1.86,0,0,0,0-1.6l-.06-.1a2.37,2.37,0,0,1-.88,1.42A6.21,6.21,0,0,1,13.2,7.37a18.1,18.1,0,0,0-1.72-1.24c0-.29-.06-.57-.1-.85a14.45,14.45,0,0,0-.44-2.16c-.06-.21-.13-.41-.2-.6A5.45,5.45,0,0,0,9.8.76,1.84,1.84,0,0,0,8.39,0,1.82,1.82,0,0,0,7,.76a5.45,5.45,0,0,0-.94,1.76Q5.87,3,5.72,3.6q-.57-.14-1.11-.21a5.64,5.64,0,0,0-2,0,1.83,1.83,0,0,0-1.33.9,1.86,1.86,0,0,0,0,1.6A5.69,5.69,0,0,0,2.45,7.56c.13.14.26.28.41.42a8.26,8.26,0,0,0-1,1.17,2.35,2.35,0,0,1,1,.27,8.08,8.08,0,0,1,.77-.81A17.33,17.33,0,0,0,5.31,9.87a15.64,15.64,0,0,0,.22,1.61A8.92,8.92,0,0,1,4,11.73a2.3,2.3,0,0,1-.34,1,9.43,9.43,0,0,0,2-.29q.15.58.33,1.08A5.45,5.45,0,0,0,7,15.24a1.82,1.82,0,0,0,1.4.76h.15a2.31,2.31,0,0,1-.89-1.43,6.66,6.66,0,0,1-1-2.41A16.76,16.76,0,0,0,8.4,11.5c.33.14.65.27,1,.38a15.62,15.62,0,0,0,2.21.63l.6.1a7.39,7.39,0,0,0,1,.07,4.07,4.07,0,0,0,1-.1,1.85,1.85,0,0,0,1.33-.9,1.86,1.86,0,0,0,0-1.6ZM12.48,8c-.29.23-.6.46-.93.7,0-.23,0-.47,0-.7s0-.47,0-.7C11.88,7.54,12.19,7.77,12.48,8ZM8.4,10.47c-.34-.15-.68-.32-1-.5s-.79-.43-1.15-.65c0-.42,0-.86,0-1.32s0-.9,0-1.33C6.59,6.45,7,6.24,7.37,6s.68-.34,1-.5c.34.15.68.32,1,.5s.79.43,1.15.65c0,.42,0,.86,0,1.32s0,.9,0,1.32c-.36.22-.74.44-1.15.65s-.69.35-1,.5Zm-2.07-5c0-.26.07-.5.11-.74l.78.27-.28.14-.61.33Zm3.52-.33L9.56,5l.79-.28c0,.24.08.5.11.76l-.61-.33ZM8.39.94C9,.94,9.69,2,10.15,3.83,9.58,4,9,4.24,8.39,4.5,7.79,4.25,7.2,4,6.63,3.85,7.09,2,7.81.94,8.39.94ZM2.13,4.75c.16-.31.68-.49,1.46-.49a8.6,8.6,0,0,1,1.94.27,15.24,15.24,0,0,0-.22,1.59A17.25,17.25,0,0,0,3.57,7.36C2.37,6.22,1.89,5.22,2.13,4.75ZM4.29,8c.3-.24.62-.47,1-.7,0,.24,0,.47,0,.71s0,.47,0,.7c-.34-.24-.66-.48-1-.71Zm2.15,3.23c0-.23-.08-.48-.11-.74l.61.33.28.14Zm3.41-.41.62-.33c0,.25-.07.5-.11.74L9.57,11l.28-.14Zm4.81.44c-.27.51-1.53.67-3.39.23.09-.51.16-1.05.21-1.61A18.61,18.61,0,0,0,13.2,8.63c1.21,1.14,1.7,2.14,1.46,2.62Z" />
			<path d="M3.09,11.47a1.38,1.38,0,1,0-1.38,1.37A1.38,1.38,0,0,0,3.09,11.47Z" />
			<path d="M11.3,14.13a1.37,1.37,0,0,0-.44-1h0a1.35,1.35,0,0,0-.87-.37H9.93a1.38,1.38,0,1,0,1.37,1.37Z" />
		</FilledIcon>
	)
}

export const BiologyIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M15.53,7.5H14.28a.47.47,0,0,0,0,.94h1.25a.47.47,0,0,0,0-.94Z" />
			<path d="M14.61,10a.47.47,0,0,0-.66.66l.94.94a.47.47,0,0,0,.66-.66Z" />
			<path d="M14,6a.47.47,0,0,0,.66,0L15.55,5a.47.47,0,0,0,0-.66.45.45,0,0,0-.66,0L14,5.29A.48.48,0,0,0,14,6Z" />
			<path d="M1.72,8.44a.47.47,0,1,0,0-.94H.47a.47.47,0,0,0,0,.94Z" />
			<path d="M2.05,10a.47.47,0,0,0-.66,0l-.94.94a.47.47,0,0,0,.66.66l.94-.94A.47.47,0,0,0,2.05,10Z" />
			<path d="M1.11,4.36a.45.45,0,0,0-.66,0A.47.47,0,0,0,.45,5L1.39,6a.47.47,0,0,0,.66,0,.48.48,0,0,0,0-.67Z" />
			<path d="M8.48,5c1.68-1,3.58-2.15,3.58-4.55A.47.47,0,0,0,11.59,0h-.93a.47.47,0,0,0-.47.47,2.42,2.42,0,0,1,0,.47H5.86A2.42,2.42,0,0,1,5.81.47.47.47,0,0,0,5.34,0H4.41a.47.47,0,0,0-.47.47A4,4,0,0,0,5.47,3.62C6,3.24,6.53,2.91,7,2.61l.1-.06a4.34,4.34,0,0,1-.77-.68H9.63A8.93,8.93,0,0,1,7.52,3.41C5.84,4.42,3.94,5.56,3.94,8a4,4,0,0,0,1.53,3.15c.52-.38,1.06-.71,1.57-1l.1-.06a4.34,4.34,0,0,1-.77-.68H9.63a8.93,8.93,0,0,1-2.11,1.54c-1.68,1-3.58,2.15-3.58,4.56a.47.47,0,0,0,.47.47h.93a.47.47,0,0,0,.47-.47A2.34,2.34,0,0,1,5.86,15h4.28a2.34,2.34,0,0,1,0,.47.47.47,0,0,0,.47.47h.93a.47.47,0,0,0,.47-.47,4,4,0,0,0-1.53-3.15c-.52.38-1.06.7-1.57,1l-.1.06a4.75,4.75,0,0,1,.77.67H6.37a9.22,9.22,0,0,1,2.11-1.54c1.68-1,3.58-2.15,3.58-4.55a4,4,0,0,0-1.53-3.15c-.52.38-1.06.7-1.57,1l-.1.06a4.36,4.36,0,0,1,.77.67H6.37A9.22,9.22,0,0,1,8.48,5ZM10.14,7.5a2.34,2.34,0,0,1,0,.47,2.42,2.42,0,0,1,0,.47H5.86A2.42,2.42,0,0,1,5.81,8a2.34,2.34,0,0,1,.05-.47Z" />
		</FilledIcon>
	)
}

export const MathIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon
			enable-background="new 0 0 512 512"
			height="512"
			viewBox="0 0 512 512"
			width="512"
			xmlns="http://www.w3.org/2000/svg"
			color={color}
		>
			<g>
				<path d="m241 271h-241v196c0 24.814 20.186 45 45 45h196zm-77.578 162.422c-5.859 5.859-15.351 5.86-21.211 0l-21.211-21.211-21.211 21.211c-5.859 5.859-15.351 5.86-21.211 0-5.859-5.859-5.859-15.352 0-21.211l21.211-21.211-21.211-21.211c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0l21.211 21.211 21.211-21.211c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211l-21.211 21.211 21.211 21.211c5.859 5.859 5.859 15.351 0 21.211z" />
				<path d="m271 271v241h196c24.814 0 45-20.186 45-45v-196zm165 165h-90c-8.291 0-15-6.709-15-15s6.709-15 15-15h90c8.291 0 15 6.709 15 15s-6.709 15-15 15zm0-60h-90c-8.291 0-15-6.709-15-15s6.709-15 15-15h90c8.291 0 15 6.709 15 15s-6.709 15-15 15z" />
				<path d="m241 241v-241h-196c-24.814 0-45 20.186-45 45v196zm-165-135h30v-30c0-8.291 6.709-15 15-15s15 6.709 15 15v30h30c8.291 0 15 6.709 15 15s-6.709 15-15 15h-30v30c0 8.291-6.709 15-15 15s-15-6.709-15-15v-30h-30c-8.291 0-15-6.709-15-15s6.709-15 15-15z" />
				<path d="m271 241h241v-196c0-24.814-20.186-45-45-45h-196zm75-135h90c8.291 0 15 6.709 15 15s-6.709 15-15 15h-90c-8.291 0-15-6.709-15-15s6.709-15 15-15z" />
			</g>
		</FilledIcon>
	)
}

export const EnglishIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M8,5.6H7.2V7.2H8A.8.8,0,0,0,8,5.6Z" />
			<path d="M8,3.47H7.2v1.6H8a.8.8,0,0,0,0-1.6Z" />
			<path d="M4.22,3.47l-.3,1.6h.69l-.3-1.6Z" />
			<path d="M15.73,10.93h-.8V.27H1.07V10.93H.27a.27.27,0,0,0,0,.54H5.22l-1.47,3.9a.28.28,0,0,0,.16.35.27.27,0,0,0,.34-.16l.73-2h6l.73,2a.27.27,0,0,0,.25.17h.09a.28.28,0,0,0,.16-.35l-1.47-3.9h5a.27.27,0,0,0,0-.54ZM6.67,3.2a.27.27,0,0,1,.26-.27H8a1.33,1.33,0,0,1,.79,2.4,1.34,1.34,0,0,1,.48,1.49A1.31,1.31,0,0,1,8,7.73H6.93a.26.26,0,0,1-.26-.26ZM5.07,7.52,4.71,5.6H3.82L3.46,7.52a.28.28,0,0,1-.17.21A.29.29,0,0,1,3,7.68a.29.29,0,0,1-.08-.26l.8-4.27A.26.26,0,0,1,4,2.93h.53a.28.28,0,0,1,.27.22l.8,4.27a.27.27,0,0,1-.09.26.28.28,0,0,1-.27.05A.3.3,0,0,1,5.07,7.52Zm5.75,5.55H5.18l.6-1.6h4.44ZM10.4,6.4V4.27a1.34,1.34,0,1,1,2.67,0,.27.27,0,0,1-.54,0,.8.8,0,1,0-1.6,0V6.4a.8.8,0,1,0,1.6,0,.27.27,0,0,1,.54,0,1.34,1.34,0,0,1-2.67,0Zm4,4.53h-.53V10.4h-1.6v.53h-.54v-.8A.27.27,0,0,1,12,9.87h2.13a.27.27,0,0,1,.27.26Z" />
		</FilledIcon>
	)
}

export const FrenchIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M11.68,15.19l-1.32-2.34v-.6a.53.53,0,0,0-.41-.51L9.41,10l.14-.17a.54.54,0,0,0,.06-.56A.53.53,0,0,0,9.27,9,29.38,29.38,0,0,1,9,5.66c-.09-2-.13-3.11-.14-3.46a.67.67,0,0,0,.11-.37V1.41a.71.71,0,0,0-.4-.64V.52a.52.52,0,1,0-1,0V.77a.71.71,0,0,0-.4.64v.42a.67.67,0,0,0,.11.37c0,.35-.05,1.46-.14,3.46A29.38,29.38,0,0,1,6.73,9a.51.51,0,0,0-.34.28.52.52,0,0,0,.06.56l.14.17-.54,1.78a.52.52,0,0,0-.41.51v.6L4.3,15.22a.53.53,0,0,0,.46.78H6.07a.52.52,0,0,0,.53-.52,1.4,1.4,0,1,1,2.8,0,.52.52,0,0,0,.52.52h1.32a.52.52,0,0,0,.52-.52A.55.55,0,0,0,11.68,15.19ZM7.71,10h.58l.38,2H7.33Z" />
		</FilledIcon>
	)
}

export const GermanIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M13.41,6.23,11.89,4H3.11L1.58,6.23a.48.48,0,0,0,0,.48A.46.46,0,0,0,2,7h.64a5.17,5.17,0,0,0-.11,1,5,5,0,0,0,2.74,4.45,6.29,6.29,0,0,0-1,.5A6.48,6.48,0,0,0,1.87,15.3a.47.47,0,0,0,.41.7H12.72a.47.47,0,0,0,.4-.23.44.44,0,0,0,0-.47A6.4,6.4,0,0,0,10.79,13a5.85,5.85,0,0,0-1-.5A5,5,0,0,0,12.49,8a5.17,5.17,0,0,0-.11-1H13a.45.45,0,0,0,.41-.25A.44.44,0,0,0,13.41,6.23ZM5.52,15.06H3.17a5.61,5.61,0,0,1,2.35-1.71Zm3,0H6.46v-2a5.46,5.46,0,0,1,2.08,0Zm3.29,0H9.47V13.35A5.58,5.58,0,0,1,11.83,15.06Zm-4.33-3A4.07,4.07,0,0,1,3.58,7h7.83A4,4,0,0,1,7.5,12.05Z" />
			<path d="M14.36.14a.47.47,0,0,0-.67,0L11.48,2.35V.47A.47.47,0,0,0,11,0H4a.47.47,0,0,0-.47.47V3h8.63L14.36.8A.47.47,0,0,0,14.36.14Z" />
		</FilledIcon>
	)
}

export const ItalianIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M10.17,8.46a.62.62,0,0,0-.62.62V16h1.23V9.08A.61.61,0,0,0,10.17,8.46Z" />
			<path d="M5.83,8.46a.61.61,0,0,0-.61.62V16H6.45V9.08A.62.62,0,0,0,5.83,8.46Z" />
			<path d="M3.05,16H4.28V9.08a1.56,1.56,0,1,1,3.11,0V16H8.61V9.08a1.56,1.56,0,1,1,3.11,0V16H13V6.1H3.05Z" />
			<path d="M13.42,0H2.58a2.59,2.59,0,0,0,0,5.17h.23A1.74,1.74,0,0,0,4.55,3.42a1.3,1.3,0,0,0-1.3-1.3,1,1,0,0,0-.94.95H1.37A1.88,1.88,0,0,1,3.25,1.19,2.23,2.23,0,0,1,5.49,3.42a2.64,2.64,0,0,1-.65,1.75h6.32a2.64,2.64,0,0,1-.65-1.75,2.23,2.23,0,0,1,2.24-2.23,1.88,1.88,0,0,1,1.88,1.88h-.94a1,1,0,0,0-.94-.95,1.3,1.3,0,0,0-1.3,1.3,1.74,1.74,0,0,0,1.74,1.75h.23a2.59,2.59,0,0,0,0-5.17Z" />
		</FilledIcon>
	)
}

export const ArtIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M16,7.92A8.08,8.08,0,0,0,8.31,0,7.88,7.88,0,0,0,2.45,2.24,8,8,0,0,0,7.58,16h.3a1.67,1.67,0,0,0,1.45-1.66,1.61,1.61,0,0,0-.31-1H9c-.16-.24-.3-.44-.43-.61A2.19,2.19,0,0,1,8,11.44a.37.37,0,0,1,0-.11.67.67,0,0,1,.67-.66,7.6,7.6,0,0,1,1.6.28c1.34.34,3,.76,4.59-.34A3,3,0,0,0,16,7.92ZM3.67,11.33A1.67,1.67,0,1,1,5.33,9.67,1.66,1.66,0,0,1,3.67,11.33Zm.66-4.66A1.67,1.67,0,1,1,6,5,1.66,1.66,0,0,1,4.33,6.67ZM9,5.33a1.67,1.67,0,1,1,1.67-1.66A1.66,1.66,0,0,1,9,5.33Zm3.33,3.34A1.67,1.67,0,1,1,14,7,1.66,1.66,0,0,1,12.33,8.67Z" />
		</FilledIcon>
	)
}

export const HistoryIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M1.8,0A1.86,1.86,0,0,0,0,1.25a.47.47,0,0,0,.06.43.47.47,0,0,0,.38.2h3.2A1.88,1.88,0,0,0,1.8,0Z" />
			<path d="M8.7,14.12a3.25,3.25,0,0,1-1,1.88h6.17a2.36,2.36,0,0,0,2-1.17.49.49,0,0,0,0-.47.47.47,0,0,0-.41-.24Z" />
			<path d="M12,0H3.88c.31.35,1.23,1.14.43,5.21-.52,2.8-1.59,5.7-1.57,8.54a2.34,2.34,0,0,0,4.68-.09.47.47,0,0,1,.47-.47h4.62a25,25,0,0,1,.92-5,24.49,24.49,0,0,0,1-5.81A2.35,2.35,0,0,0,12,0Zm-.94,10.31H5.16a.47.47,0,0,1,0-.94h5.93a.47.47,0,0,1,0,.94Zm.45-1.87H5.62a.47.47,0,0,1,0-.94h5.92a.47.47,0,0,1,0,.94ZM12,6.56H6.05a.47.47,0,0,1-.47-.47.46.46,0,0,1,.47-.46H12a.46.46,0,0,1,.47.46A.47.47,0,0,1,12,6.56Zm.36-1.87h-6a.47.47,0,1,1,0-.94h6a.47.47,0,1,1,0,.94Z" />
		</FilledIcon>
	)
}

export const GeographyIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M10.35,8.23,9,9.05a.73.73,0,0,0-.33.42l-.32,1.07A5,5,0,0,0,11,9.16a5.1,5.1,0,0,0,.56-.67l-.35-.24a.78.78,0,0,0-.82,0Z" />
			<path d="M9.53,2.9a.42.42,0,0,0-.13.33v.18A1.37,1.37,0,0,1,8.2,4.83l-.36,0H7.66A1.36,1.36,0,0,1,6.3,3.3a.45.45,0,0,0-.43-.51l-1.17,0a1.23,1.23,0,0,1-.91-.46A4.83,4.83,0,0,0,2.55,5l.92-.25a1.38,1.38,0,0,1,1,.14L6,5.75a1.34,1.34,0,0,1,.66,1l.07.91A1.34,1.34,0,0,1,6,9a2.11,2.11,0,0,0-.86.84L5.06,10a5,5,0,0,0,2.28.61L7.77,9.2a1.69,1.69,0,0,1,.75-.95l1.35-.82a1.71,1.71,0,0,1,1.83.05l.27.19a4.91,4.91,0,0,0,.42-2,4.9,4.9,0,0,0-1.12-3.16l.61-.61a5.81,5.81,0,0,1-.32,7.89,5.84,5.84,0,0,1-7.89.32l.61-.61h0l0-.09A2.94,2.94,0,0,1,5.59,8.18a.4.4,0,0,0,.21-.39l-.07-.92a.42.42,0,0,0-.19-.32L4,5.66a.42.42,0,0,0-.31,0l-1.2.33A4.86,4.86,0,0,0,3.62,8.81L3,9.44,2.46,8.9l-.67.66.87.87A6.72,6.72,0,0,0,7,12.4v.86H4.29L3.34,16h8.21l-1-2.74H7.91V12.4A6.76,6.76,0,0,0,12.23.87L11.36,0l-.67.66.54.54Z" />
			<path d="M4.73,1.82l1.17,0a1.37,1.37,0,0,1,1,.49,1.39,1.39,0,0,1,.3,1.09.47.47,0,0,0,.12.38A.45.45,0,0,0,7.72,4l.36-.05a.44.44,0,0,0,.39-.45V3.27a1.37,1.37,0,0,1,.4-1l1-1a5,5,0,0,0-5.32.37.27.27,0,0,0,.24.16Z" />
		</FilledIcon>
	)
}

export const GeologyIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M11.79,8A3.78,3.78,0,0,0,8.48,4.26V5.45a2.59,2.59,0,0,1,0,5.1v1.19A3.78,3.78,0,0,0,11.79,8Z" />
			<path d="M12.73,8a4.73,4.73,0,0,1-4.25,4.69v1.18a5.89,5.89,0,0,0,0-11.74V3.31A4.73,4.73,0,0,1,12.73,8Z" />
			<path d="M16,8A8,8,0,0,0,8.48,0V1.18a6.84,6.84,0,0,1,0,13.64V16A8,8,0,0,0,16,8Z" />
			<path d="M9.67,8A1.64,1.64,0,0,0,8.48,6.42V9.58A1.64,1.64,0,0,0,9.67,8Z" />
			<path d="M7.22,8.47A1.24,1.24,0,0,0,6,9.6a1.23,1.23,0,0,0,1.44,1.31h.1V8.47Z" />
			<path d="M4.74,1.1a2.86,2.86,0,0,0,2.8,2.24V0A7.94,7.94,0,0,0,4.65.72Z" />
			<path d="M2.45,12.42a1.49,1.49,0,0,0,.73-1,1.44,1.44,0,0,0-.24-1.19,1.91,1.91,0,0,1,.41-2.63A.93.93,0,0,0,2.79,6H.26A8.23,8.23,0,0,0,0,8a8,8,0,0,0,1.64,4.86Z" />
			<path d="M7.22,11.87A2.16,2.16,0,0,1,5.06,9.52a2.16,2.16,0,0,1,2.16-2h.32V4.28a3.77,3.77,0,0,1-2.39-.85A3.83,3.83,0,0,1,3.82,1.31l0-.12A7.85,7.85,0,0,0,2.35,2.33,8,8,0,0,0,.57,5H2.79A1.87,1.87,0,0,1,4.66,6.88a1.89,1.89,0,0,1-.75,1.5,1,1,0,0,0-.2,1.32,2.46,2.46,0,0,1,.39,2,2.43,2.43,0,0,1-1.2,1.59l-.63.35.08.08A7.94,7.94,0,0,0,7.54,16V11.85A1.67,1.67,0,0,1,7.22,11.87Z" />
		</FilledIcon>
	)
}

export const ArabicIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M15.86,4.12l-4-4a.45.45,0,0,0-.66,0L9.11,2.25a.47.47,0,0,0,0,.67l4,4a.46.46,0,0,0,.66,0l2.11-2.11A.45.45,0,0,0,15.86,4.12Z" />
			<path d="M12.42,7.56l-4-4v0L3.94,5.8a.45.45,0,0,0-.22.25L0,15.34l5.7-5.7a1.41,1.41,0,1,1,.66.66L.66,16,10,12.28a.45.45,0,0,0,.25-.22l2.24-4.49Z" />
			<path d="M7,8.57A.47.47,0,1,0,7.43,9,.47.47,0,0,0,7,8.57Z" />
		</FilledIcon>
	)
}

export const PsychologyIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M8.07,2.47a.41.41,0,0,1,.6,0,.41.41,0,0,1,.12.3.42.42,0,0,1-.12.3h0l-.15.12a1.09,1.09,0,0,0,0,1.53.34.34,0,0,0,.46,0,.32.32,0,0,0,0-.46.43.43,0,0,1,0-.6.42.42,0,0,1,.6,0,.34.34,0,0,0,.46,0,.33.33,0,0,0,0-.47,1,1,0,0,0-.6-.3.49.49,0,0,0,0-.12A1,1,0,0,0,9.13,2,1.06,1.06,0,0,0,7.61,2a.32.32,0,0,0,0,.46A.34.34,0,0,0,8.07,2.47Z" />
			<path d="M8.71,0a5.9,5.9,0,0,0-6,5.81,4.41,4.41,0,0,0,0,.52L1.37,9.46a.65.65,0,0,0,0,.59.64.64,0,0,0,.52.28H2.7V11a2.7,2.7,0,0,0,2.69,2.72H6V16h6.49V10.34a5.74,5.74,0,0,0,2.23-4.53A5.89,5.89,0,0,0,8.71,0ZM4.21,7.64a.54.54,0,0,1-.54-.53.54.54,0,1,1,.54.53Zm6.7,7H9.57V14h1.34Zm0-1.3H9.57v-.66h1.34Zm0-1.31H9.57v-.65h1.34Zm0-1.3H9.57V10.1h1.34Zm0-1.31H9.57V8.8h1.34Zm1.84-3a1.09,1.09,0,0,1-1.07.9h-.15a1.08,1.08,0,0,1-.91.5h-.06A1.08,1.08,0,0,1,9,7.68l-.24,0A1.75,1.75,0,0,1,7.2,6.81a1.23,1.23,0,0,1-.39.06A1.27,1.27,0,0,1,5.56,5.74a1.12,1.12,0,0,1-.31,0,1.3,1.3,0,0,1-.5-2.48v-.1A1.15,1.15,0,0,1,5.88,2a1.16,1.16,0,0,1,.42.08,1.26,1.26,0,0,1,1.19-.86,1.25,1.25,0,0,1,.58.15A1.5,1.5,0,0,1,9.13,1a1.48,1.48,0,0,1,1.23.68,1.23,1.23,0,0,1,.54-.13A1.26,1.26,0,0,1,12,2.2l.21,0a1.14,1.14,0,0,1,1.12,1.15,1.22,1.22,0,0,1-.07.38A1.47,1.47,0,0,1,14,5,1.44,1.44,0,0,1,12.75,6.43Z" />
			<path d="M12.53,3.43a.32.32,0,0,0,.32-.33.32.32,0,0,0-.32-.32,1.07,1.07,0,0,0-1.08,1.07.76.76,0,0,0,0,.15.83.83,0,0,0-.16-.21,1.11,1.11,0,0,0-.76-.32.33.33,0,0,0,0,.66.42.42,0,0,1,.3.72.43.43,0,0,1-.3.13.32.32,0,0,0-.33.32.34.34,0,0,0,0,.17h0a1.06,1.06,0,0,0-.8.36,1.16,1.16,0,0,0-.3-.54A1.07,1.07,0,0,0,8.36,5h0a1.07,1.07,0,0,0-.76.31,1.09,1.09,0,0,0-.32.76.33.33,0,0,0,.66,0,.41.41,0,0,1,.42-.42h0a.41.41,0,0,1,.3.12.43.43,0,0,1,.13.3.32.32,0,0,0,.32.33h.05a.86.86,0,0,0,0,.16,1.07,1.07,0,0,0,1.07,1.08.33.33,0,0,0,.33-.33A.32.32,0,0,0,10.22,7a.43.43,0,0,1,0-.85.33.33,0,0,0,.33-.33.3.3,0,0,0-.05-.16h0a1.14,1.14,0,0,0,.45-.1.32.32,0,0,0,.23.09.44.44,0,0,1,.43.43.43.43,0,0,1-.13.3.41.41,0,0,1-.3.12.32.32,0,0,0-.32.33.32.32,0,0,0,.32.32A1.07,1.07,0,0,0,11.51,5a1,1,0,0,0,.1-.46.66.66,0,0,0,0-.14,1.57,1.57,0,0,0,.17.21,1.07,1.07,0,0,0,.76.31.33.33,0,0,0,0-.65.44.44,0,0,1-.43-.43A.42.42,0,0,1,12.53,3.43Z" />
			<path d="M7.87,4.45a.32.32,0,0,0-.32-.32.32.32,0,0,0-.33.32.43.43,0,1,1-.85,0h0a1.23,1.23,0,0,0,0-.19A1.08,1.08,0,0,0,5.31,3.18.32.32,0,0,0,5,3.51a.32.32,0,0,0,.32.32.43.43,0,1,1,0,.85A.32.32,0,0,0,5,5a.32.32,0,0,0,.32.32A1,1,0,0,0,6,5.12a1.08,1.08,0,0,0,1.92-.67Z" />
		</FilledIcon>
	)
}

export const PhilosophyIcon: React.FC<IconProps> = ({ color }) => {
	return (
		<FilledIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
			<path d="M8,16a1.72,1.72,0,0,0,1.56-1H6.44A1.72,1.72,0,0,0,8,16Z" />
			<path d="M7.53,11.87V8.08a2.12,2.12,0,0,1-1.87-2,.47.47,0,1,1,.93,0,1.16,1.16,0,0,0,.94,1V.32A2,2,0,0,0,6.44,0a2,2,0,0,0-2,1.67A2.65,2.65,0,0,0,3.17,5.94,2.64,2.64,0,0,0,4.44,10.2a2,2,0,0,0,2,1.67Z" />
			<path d="M5.66,13.28a.78.78,0,0,0,.78.78H9.56a.78.78,0,0,0,.78-.78v-.47H5.66Z" />
			<path d="M11.56,1.67A2,2,0,0,0,9.56,0,2,2,0,0,0,8.47.32V3.79a2.13,2.13,0,0,1,1.87,2,.47.47,0,1,1-.93,0,1.17,1.17,0,0,0-.94-1v7.12H9.56a2,2,0,0,0,2-1.67,2.64,2.64,0,0,0,1.27-4.26,2.65,2.65,0,0,0-1.27-4.27Z" />
			<path d="M14.59,2.66a.48.48,0,0,1-.39-.21.48.48,0,0,1,.13-.65l.94-.63a.48.48,0,0,1,.65.13.47.47,0,0,1-.13.65l-.94.63A.5.5,0,0,1,14.59,2.66Z" />
			<path d="M15.53,10.47a.5.5,0,0,1-.26-.08l-.94-.63A.47.47,0,1,1,14.85,9l.94.63a.47.47,0,0,1,.13.65A.46.46,0,0,1,15.53,10.47Z" />
			<path d="M1.41,2.66a.5.5,0,0,1-.26-.08L.21,2A.47.47,0,0,1,.08,1.3a.48.48,0,0,1,.65-.13l.94.63a.48.48,0,0,1,.13.65A.48.48,0,0,1,1.41,2.66Z" />
			<path d="M.47,10.47a.46.46,0,0,1-.39-.21.47.47,0,0,1,.13-.65L1.15,9a.47.47,0,1,1,.52.78l-.94.63A.5.5,0,0,1,.47,10.47Z" />
			<path d="M1.41,6.25H.47a.47.47,0,0,1,0-.94h.94a.47.47,0,0,1,0,.94Z" />
			<path d="M15.53,6.25h-.94a.47.47,0,0,1,0-.94h.94a.47.47,0,1,1,0,.94Z" />
		</FilledIcon>
	)
}

const IconCss = css`
	width: 26px;
	height: 26px;
	position: relative;
	top: 4px;
	margin: 0 8px 26px 0;
`

const StyledIcon = styled.svg`
	${IconCss};
	color: ${props => props?.color ?? props.theme.colors.purple};
`
const FilledIcon = styled.svg`
	${IconCss};
	fill: ${props => props?.color ?? props.theme.colors.purple};
`
