import api from '@Api/Api'
import { Invoice, TimelineEvent, WalletHistory } from '../DashboardSlice/Dashboard.slice.types'

class DashboardAPI {
	private static instance: DashboardAPI
	private static endpoint: string = 'student/profile/'
	public static getInstance() {
		if (!DashboardAPI.instance) {
			return new DashboardAPI()
		}

		return DashboardAPI.instance
	}

	getWalletHistory(requestPayload?: FiltersPayload) {
		return api.post<ApiResponse<WalletHistory[]>>(`${DashboardAPI.endpoint}wallet/history`, requestPayload)
	}

	getTimelineEvents(requestPayload?: FiltersPayload) {
		return api.post<ApiResponse<TimelineEvent[]>>(`${DashboardAPI.endpoint}timeline`, requestPayload)
	}

	getUserClassrooms() {
		return api.get<ApiResponse<Classroom[]>>(`${DashboardAPI.endpoint}classrooms`)
	}

	getUserInvoices() {
		return api.post<ApiResponse<Invoice[]>>(`${DashboardAPI.endpoint}invoices`)
	}
}

export default DashboardAPI
