import routes from '@Constants/Routes'
import { Icon, Icons, RaisedButton, TextButton } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

type Props = {
	classroom: Classroom
}

const ClassroomCard: React.FC<Props> = ({ classroom }) => {
	const navigate = useNavigate()
	const [t] = useTranslation(['classroomsOverview', 'dropdowns'])
	const renderStatus = (status: string | undefined) => {
		switch (status) {
			case 'on track':
				return '#5AC0FC'
			case 'late':
				return '#FF8532'
			case 'new':
				return '#6C63FF'
			case 'inconsistent':
				return '#FFD037'
			default:
				return '#000'
		}
	}
	const label = (label: string) => {
		switch (label) {
			case 'online':
				return <ClassroomType>{t('online')}</ClassroomType>
			default:
				return <ClassroomType>{t('center')}</ClassroomType>
		}
	}

	const routeClick = () => {
		const path = `${routes.dashboard}/classrooms/${classroom?.id}`
		navigate(path)
	}

	return (
		<Card>
			<ClassroomInfo>
				<StyledClassroomImage src={classroom?.thumbnail ?? ''} />
				<ClassroomLabel>{classroom.title}</ClassroomLabel>
			</ClassroomInfo>
			{classroom?.enrolled.map(_ => {
				const status = _.pivot.pivot_status

				return (
					<>
						{status ? (
							<ClassroomStatusLabel background={renderStatus(status)}>
								{t(`classroom_status.${status}`, { ns: 'dropdowns' })}
							</ClassroomStatusLabel>
						) : (
							<ClassroomStatusLabel background={renderStatus(undefined)}>-</ClassroomStatusLabel>
						)}
					</>
				)
			})}
			{label(classroom.type)}
			<Spacer />
			<ViewButton outlined onClick={routeClick}>
				{t('view')}
			</ViewButton>
			<GoButton
				onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
					e.stopPropagation()
					navigate(`/classroom/${classroom.label}`)
				}}
			>
				<FlexLayout flexDirection="column" alignItems="start">
					<GoToClassroom>
						<span>{t('goTo')}</span>
						<ArrowIcon color="purple">
							<Icons.RightArrow />
						</ArrowIcon>
					</GoToClassroom>
					<span>{t('classroom')}</span>
				</FlexLayout>
			</GoButton>
		</Card>
	)
}

const Card = styled.div`
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	height: 4.85rem;
	width: 100%;
	border-radius: 5px;
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: repeat(3, 1fr);
	align-content: center;
	width: 100%;
	align-items: center;
	flex-wrap: wrap;
	padding-inline-start: 0.675rem;
	padding-block-end: 1rem;
	align-content: space-around;
	justify-content: space-between;
	${({ theme }) => `${theme.mediaQueries.large}{
    height: 4.063rem;
    padding-inline-start: 1.563rem;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: column;
    padding-block-end: unset;
	}`}
`
const ClassroomInfo = styled(FlexLayout)`
	gap: 0.288rem;
	order: 4;
	grid-row-start: 2;
	align-items: center;
	overflow: hidden;
	${({ theme }) => `${theme.mediaQueries.large}{
    gap: 0.738rem;
    order: 1;
    grid-row-start: 1;
	}`}
`
const Spacer = styled.div`
	order: 5;
	grid-row-start: 2;
	${({ theme }) => `${theme.mediaQueries.large}{
    display: none;
	}`}
`
const StyledClassroomImage = styled.img`
	width: 1.4rem;
	height: 1.394rem;
	object-fit: fill;
	${({ theme }) => `${theme.mediaQueries.large}{
    width:2.763rem;
    height:2.75rem;
	}`}
`
const ClassroomLabel = styled.p`
	font-weight: 600;
	color: #000;
	font-size: 0.625rem;
	white-space: pre-wrap;
	text-overflow: ellipsis;
	max-width: 100px;
	overflow: hidden;
	white-space:nowrap;
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: 1.125rem;
	max-width: 20ch;
	}`}
`
const ClassroomStatusLabel = styled.span.attrs(() => ({
	dir: document.documentElement.dir,
}))<{ background: string | undefined }>`
	background: ${({ background }) => background};
	border-radius: 9px 9px 0 9px;
	color: #f9fafc;
	width: fit-content;
	padding: 0.213rem 0.43rem 0.213rem;
	min-height: 0.938rem;
	font-weight: 600;
	text-align: center;
	font-size: 0.625rem;
	order: 1;
	grid-row-start: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	&&[dir='rtl'] {
		border-radius: 9px 9px 0 9px;
	}
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: 1rem;
    height: 1.813rem;
    order: 2;
    grid-row-start: 1;
    margin: auto;
    padding: 0.313rem 0.938rem 0.313rem 1rem;
	}`}
`
const ClassroomType = styled.p`
	font-weight: 600;
	color: #251b33;
	font-size: 0.625rem;
	order: 2;
	grid-row-start: 1;
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: 1rem;
    order: 3;
    grid-row-start: 1;
    margin: auto;
	}`}
`
const ViewButton = styled(RaisedButton)`
	min-width: 4.2rem;
	width: 4.2rem;
	min-height: 1.2rem;
	height: 1.2rem;
	font-size: 0.625rem;
	font-weight: bold;
	padding: 0;
	grid-row-start: 2;
	order: 6;
	margin: auto;
	${({ theme }) => `${theme.mediaQueries.large}{
    min-width: 8.5rem;
    width: 8.5rem;
    min-height: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
    order: 4;
    grid-row-start: 1;
	}`}
`
const GoButton = styled(TextButton)`
	text-decoration: unset;
	order: 3;
	font-size: 0.5rem;
	font-weight: 600;
	color: #6c63ff;
	grid-row-start: 1;
	${({ theme }) => `${theme.mediaQueries.large}{
    order: 5;
    font-size: 0.75rem;
    grid-row-start: 1;
	}`}
`
const GoToClassroom = styled(FlexLayout)`
	gap: 0.5rem;
	margin-bottom: -2px;
	${({ theme }) => `${theme.mediaQueries.large}{
    gap: 0.75rem;
    margin-bottom: -6px;
	}`}
`
const ArrowIcon = styled(Icon)`
	font-size: 0.625rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: 1.25rem;
	}`}
`
export default ClassroomCard
