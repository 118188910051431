import { setAuthData } from '@Features/Authentication/AuthenticationSlice/Authentication.slice'
import { BaseApi } from '@Features/Base/BaseApi/BaseApi'
import { dashboardAPI } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { rtkPrepareHeadersCallback } from './../../../Api/Api'

const classroomApi = BaseApi.enhanceEndpoints({
	addTagTypes: ['CLASSROOM', 'COURSE', 'Profile'],
}).injectEndpoints({
	endpoints: builder => ({
		getClassroomByLabel: builder.query<ApiResponse<Classroom>, string>({
			query: (label: string) => ({ url: `/classroom/get/${label}` }),
			providesTags: (result, error, args) => {
				let Tags: Array<{ type: 'COURSE'; id: number }> = []
				if (result) {
					result.data.tabs.forEach(tab => {
						tab.sections.forEach(section => {
							section.courses.forEach(course => {
								Tags.push({ type: 'COURSE', id: course.id })
							})
						})
					})
					return [...Tags, 'CLASSROOM']
				}
				return ['CLASSROOM']
			},
		}),
		getClassroomAdmission: builder.query<ApiResponse<AdmissionForm>, number>({
			query: (id: number) => ({
				url: `/admission/form/${id}`,
			}),
			// keepUnusedDataFor: 0,
		}),
		goToCourse: builder.query<ApiResponse<{ goto_url: string }>, number>({
			query: id => ({
				url: `/classroom/course/goto?course_id=${id}`,
			}),
		}),
		checkoutSingleCourse: builder.mutation<ApiResponse<CheckoutResponse>, CheckoutCoursePayload>({
			query: payload => ({
				url: '/checkout/course',
				method: 'POST',
				body: payload,
			}),
			async onQueryStarted(arg, api) {
				try {
					const {
						data: { data },
					} = await api.queryFulfilled
					if (data.redirect) {
						api.dispatch(setAuthData({ redirect: data.redirect }))
					} else {
						api.dispatch(dashboardAPI.util.invalidateTags(['Profile']))
						api.dispatch(classroomApi.util.invalidateTags([{ type: 'COURSE', id: arg.course_id }]))
					}
				} catch (error) {}
			},
		}),
		submitAdmission: builder.mutation<any, AdmissionPayload>({
			query: payload => ({ url: 'admission/form/submit', method: 'POST', body: payload }),
			invalidatesTags: ['CLASSROOM'],
		}),
		assignGroup: builder.mutation<ApiResponse<any>, { classroom_id: number; group_id: number }>({
			query: paylod => ({ url: 'classroom/group/assign', method: 'POST', body: paylod }),
			invalidatesTags: ['CLASSROOM'],
		}),
	}),
})

export const {
	useGetClassroomByLabelQuery,
	useLazyGetClassroomByLabelQuery,
	useGetClassroomAdmissionQuery,
	useLazyGoToCourseQuery,
	useCheckoutSingleCourseMutation,
	useSubmitAdmissionMutation,
	useAssignGroupMutation,
} = classroomApi

export default classroomApi

type CheckoutCoursePayload = {
	payment_method: 'wallet' | 'scratchCard'
	promocode?: string
	course_id: number
	code?: string
}
