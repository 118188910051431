import React, { createContext, useContext } from 'react'
import { QuestionPayload } from '../Schema/Test.schema'
import { Updater } from 'use-immer'
type TestContextArgs = {
	mode: TestViewMode
	questions: Array<TestQuestion>
	drawerOpened: boolean
	openDrawer: React.Dispatch<boolean>
	bottomSheetOpened: boolean
	openBottomSheet: React.Dispatch<boolean>
	overallProgress?: number
	test: Test
	formQuestions: Array<FormQuestion>
	setFormQuestions: Updater<Array<FormQuestion>>
	attempt: TestAttempt
}
type TestProviderArgs = {
	mode: TestViewMode
	questions: Array<TestQuestion>
	drawerOpened: boolean
	openDrawer: React.Dispatch<boolean>
	overallProgress?: number
	test: Test
	formQuestions: Array<FormQuestion>
	setFormQuestions: Updater<Array<FormQuestion>>
	attempt: TestAttempt
	bottomSheetOpened: boolean
	openBottomSheet: React.Dispatch<boolean>
}
export type FormQuestion = TestQuestion & { touched: boolean; submitted: boolean }
const TestContext = createContext<TestContextArgs | null>(null)

export const TestProvider: React.FC<TestProviderArgs> = ({ children, ...props }) => {
	return <TestContext.Provider value={props}>{children}</TestContext.Provider>
}

export const useTest = () => {
	const context = useContext(TestContext)
	if (!context) throw new Error('No TestProvider found !!!')
	return context
}
