const Cities = [
	{
		id: '1',
		governorate_id: '1',
		city_name_ar: '15 مايو',
		name: '15 May',
	},
	{
		id: '2',
		governorate_id: '1',
		city_name_ar: 'الازبكية',
		name: 'Al Azbakeyah',
	},
	{
		id: '3',
		governorate_id: '1',
		city_name_ar: 'البساتين',
		name: 'Al Basatin',
	},
	{
		id: '4',
		governorate_id: '1',
		city_name_ar: 'التبين',
		name: 'Tebin',
	},
	{
		id: '5',
		governorate_id: '1',
		city_name_ar: 'الخليفة',
		name: 'El-Khalifa',
	},
	{
		id: '6',
		governorate_id: '1',
		city_name_ar: 'الدراسة',
		name: 'El darrasa',
	},
	{
		id: '7',
		governorate_id: '1',
		city_name_ar: 'الدرب الاحمر',
		name: 'Aldarb Alahmar',
	},
	{
		id: '8',
		governorate_id: '1',
		city_name_ar: 'الزاوية الحمراء',
		name: 'Zawya al-Hamra',
	},
	{
		id: '9',
		governorate_id: '1',
		city_name_ar: 'الزيتون',
		name: 'El-Zaytoun',
	},
	{
		id: '10',
		governorate_id: '1',
		city_name_ar: 'الساحل',
		name: 'Sahel',
	},
	{
		id: '11',
		governorate_id: '1',
		city_name_ar: 'السلام',
		name: 'El Salam',
	},
	{
		id: '12',
		governorate_id: '1',
		city_name_ar: 'السيدة زينب',
		name: 'Sayeda Zeinab',
	},
	{
		id: '13',
		governorate_id: '1',
		city_name_ar: 'الشرابية',
		name: 'El Sharabeya',
	},
	{
		id: '14',
		governorate_id: '1',
		city_name_ar: 'مدينة الشروق',
		name: 'Shorouk',
	},
	{
		id: '15',
		governorate_id: '1',
		city_name_ar: 'الظاهر',
		name: 'El Daher',
	},
	{
		id: '16',
		governorate_id: '1',
		city_name_ar: 'العتبة',
		name: 'Ataba',
	},
	{
		id: '17',
		governorate_id: '1',
		city_name_ar: 'القاهرة الجديدة',
		name: 'New Cairo',
	},
	{
		id: '18',
		governorate_id: '1',
		city_name_ar: 'المرج',
		name: 'El Marg',
	},
	{
		id: '19',
		governorate_id: '1',
		city_name_ar: 'عزبة النخل',
		name: 'Ezbet el Nakhl',
	},
	{
		id: '20',
		governorate_id: '1',
		city_name_ar: 'المطرية',
		name: 'Matareya',
	},
	{
		id: '21',
		governorate_id: '1',
		city_name_ar: 'المعادى',
		name: 'Maadi',
	},
	{
		id: '22',
		governorate_id: '1',
		city_name_ar: 'المعصرة',
		name: 'Maasara',
	},
	{
		id: '23',
		governorate_id: '1',
		city_name_ar: 'المقطم',
		name: 'Mokattam',
	},
	{
		id: '24',
		governorate_id: '1',
		city_name_ar: 'المنيل',
		name: 'Manyal',
	},
	{
		id: '25',
		governorate_id: '1',
		city_name_ar: 'الموسكى',
		name: 'Mosky',
	},
	{
		id: '26',
		governorate_id: '1',
		city_name_ar: 'النزهة',
		name: 'Nozha',
	},
	{
		id: '27',
		governorate_id: '1',
		city_name_ar: 'الوايلى',
		name: 'Waily',
	},
	{
		id: '28',
		governorate_id: '1',
		city_name_ar: 'باب الشعرية',
		name: 'Bab al-Shereia',
	},
	{
		id: '29',
		governorate_id: '1',
		city_name_ar: 'بولاق',
		name: 'Bolaq',
	},
	{
		id: '30',
		governorate_id: '1',
		city_name_ar: 'جاردن سيتى',
		name: 'Garden City',
	},
	{
		id: '31',
		governorate_id: '1',
		city_name_ar: 'حدائق القبة',
		name: 'Hadayek El-Kobba',
	},
	{
		id: '32',
		governorate_id: '1',
		city_name_ar: 'حلوان',
		name: 'Helwan',
	},
	{
		id: '33',
		governorate_id: '1',
		city_name_ar: 'دار السلام',
		name: 'Dar Al Salam',
	},
	{
		id: '34',
		governorate_id: '1',
		city_name_ar: 'شبرا',
		name: 'Shubra',
	},
	{
		id: '35',
		governorate_id: '1',
		city_name_ar: 'طره',
		name: 'Tura',
	},
	{
		id: '36',
		governorate_id: '1',
		city_name_ar: 'عابدين',
		name: 'Abdeen',
	},
	{
		id: '37',
		governorate_id: '1',
		city_name_ar: 'عباسية',
		name: 'Abaseya',
	},
	{
		id: '38',
		governorate_id: '1',
		city_name_ar: 'عين شمس',
		name: 'Ain Shams',
	},
	{
		id: '39',
		governorate_id: '1',
		city_name_ar: 'مدينة نصر',
		name: 'Nasr City',
	},
	{
		id: '40',
		governorate_id: '1',
		city_name_ar: 'مصر الجديدة',
		name: 'New Heliopolis',
	},
	{
		id: '41',
		governorate_id: '1',
		city_name_ar: 'مصر القديمة',
		name: 'Masr Al Qadima',
	},
	{
		id: '42',
		governorate_id: '1',
		city_name_ar: 'منشية ناصر',
		name: 'Mansheya Nasir',
	},
	{
		id: '43',
		governorate_id: '1',
		city_name_ar: 'مدينة بدر',
		name: 'Badr City',
	},
	{
		id: '44',
		governorate_id: '1',
		city_name_ar: 'مدينة العبور',
		name: 'Obour City',
	},
	{
		id: '45',
		governorate_id: '1',
		city_name_ar: 'وسط البلد',
		name: 'Cairo Downtown',
	},
	{
		id: '46',
		governorate_id: '1',
		city_name_ar: 'الزمالك',
		name: 'Zamalek',
	},
	{
		id: '47',
		governorate_id: '1',
		city_name_ar: 'قصر النيل',
		name: 'Kasr El Nile',
	},
	{
		id: '48',
		governorate_id: '1',
		city_name_ar: 'الرحاب',
		name: 'Rehab',
	},
	{
		id: '49',
		governorate_id: '1',
		city_name_ar: 'القطامية',
		name: 'Katameya',
	},
	{
		id: '50',
		governorate_id: '1',
		city_name_ar: 'مدينتي',
		name: 'Madinty',
	},
	{
		id: '51',
		governorate_id: '1',
		city_name_ar: 'روض الفرج',
		name: 'Rod Alfarag',
	},
	{
		id: '52',
		governorate_id: '1',
		city_name_ar: 'شيراتون',
		name: 'Sheraton',
	},
	{
		id: '53',
		governorate_id: '1',
		city_name_ar: 'الجمالية',
		name: 'El-Gamaleya',
	},
	{
		id: '54',
		governorate_id: '1',
		city_name_ar: 'العاشر من رمضان',
		name: '10th of Ramadan City',
	},
	{
		id: '55',
		governorate_id: '1',
		city_name_ar: 'الحلمية',
		name: 'Helmeyat Alzaytoun',
	},
	{
		id: '56',
		governorate_id: '1',
		city_name_ar: 'النزهة الجديدة',
		name: 'New Nozha',
	},
	{
		id: '57',
		governorate_id: '1',
		city_name_ar: 'العاصمة الإدارية',
		name: 'Capital New',
	},
	{
		id: '58',
		governorate_id: '2',
		city_name_ar: 'الجيزة',
		name: 'Giza',
	},
	{
		id: '59',
		governorate_id: '2',
		city_name_ar: 'السادس من أكتوبر',
		name: 'Sixth of October',
	},
	{
		id: '60',
		governorate_id: '2',
		city_name_ar: 'الشيخ زايد',
		name: 'Cheikh Zayed',
	},
	{
		id: '61',
		governorate_id: '2',
		city_name_ar: 'الحوامدية',
		name: 'Hawamdiyah',
	},
	{
		id: '62',
		governorate_id: '2',
		city_name_ar: 'البدرشين',
		name: 'Al Badrasheen',
	},
	{
		id: '63',
		governorate_id: '2',
		city_name_ar: 'الصف',
		name: 'Saf',
	},
	{
		id: '64',
		governorate_id: '2',
		city_name_ar: 'أطفيح',
		name: 'Atfih',
	},
	{
		id: '65',
		governorate_id: '2',
		city_name_ar: 'العياط',
		name: 'Al Ayat',
	},
	{
		id: '66',
		governorate_id: '2',
		city_name_ar: 'الباويطي',
		name: 'Al-Bawaiti',
	},
	{
		id: '67',
		governorate_id: '2',
		city_name_ar: 'منشأة القناطر',
		name: 'ManshiyetAl Qanater',
	},
	{
		id: '68',
		governorate_id: '2',
		city_name_ar: 'أوسيم',
		name: 'Oaseem',
	},
	{
		id: '69',
		governorate_id: '2',
		city_name_ar: 'كرداسة',
		name: 'Kerdasa',
	},
	{
		id: '70',
		governorate_id: '2',
		city_name_ar: 'أبو النمرس',
		name: 'Abu Nomros',
	},
	{
		id: '71',
		governorate_id: '2',
		city_name_ar: 'كفر غطاطي',
		name: 'Kafr Ghati',
	},
	{
		id: '72',
		governorate_id: '2',
		city_name_ar: 'منشأة البكاري',
		name: 'Manshiyet Al Bakari',
	},
	{
		id: '73',
		governorate_id: '2',
		city_name_ar: 'الدقى',
		name: 'Dokki',
	},
	{
		id: '74',
		governorate_id: '2',
		city_name_ar: 'العجوزة',
		name: 'Agouza',
	},
	{
		id: '75',
		governorate_id: '2',
		city_name_ar: 'الهرم',
		name: 'Haram',
	},
	{
		id: '76',
		governorate_id: '2',
		city_name_ar: 'الوراق',
		name: 'Warraq',
	},
	{
		id: '77',
		governorate_id: '2',
		city_name_ar: 'امبابة',
		name: 'Imbaba',
	},
	{
		id: '78',
		governorate_id: '2',
		city_name_ar: 'بولاق الدكرور',
		name: 'Boulaq Dakrour',
	},
	{
		id: '79',
		governorate_id: '2',
		city_name_ar: 'الواحات البحرية',
		name: 'Al Wahat Al Baharia',
	},
	{
		id: '80',
		governorate_id: '2',
		city_name_ar: 'العمرانية',
		name: 'Omraneya',
	},
	{
		id: '81',
		governorate_id: '2',
		city_name_ar: 'المنيب',
		name: 'Moneeb',
	},
	{
		id: '82',
		governorate_id: '2',
		city_name_ar: 'بين السرايات',
		name: 'Bin Alsarayat',
	},
	{
		id: '83',
		governorate_id: '2',
		city_name_ar: 'الكيت كات',
		name: 'Kit Kat',
	},
	{
		id: '84',
		governorate_id: '2',
		city_name_ar: 'المهندسين',
		name: 'Mohandessin',
	},
	{
		id: '85',
		governorate_id: '2',
		city_name_ar: 'فيصل',
		name: 'Faisal',
	},
	{
		id: '86',
		governorate_id: '2',
		city_name_ar: 'أبو رواش',
		name: 'Abu Rawash',
	},
	{
		id: '87',
		governorate_id: '2',
		city_name_ar: 'حدائق الأهرام',
		name: 'Hadayek Alahram',
	},
	{
		id: '88',
		governorate_id: '2',
		city_name_ar: 'الحرانية',
		name: 'Haraneya',
	},
	{
		id: '89',
		governorate_id: '2',
		city_name_ar: 'حدائق اكتوبر',
		name: 'Hadayek October',
	},
	{
		id: '90',
		governorate_id: '2',
		city_name_ar: 'صفط اللبن',
		name: 'Saft Allaban',
	},
	{
		id: '91',
		governorate_id: '2',
		city_name_ar: 'القرية الذكية',
		name: 'Smart Village',
	},
	{
		id: '92',
		governorate_id: '2',
		city_name_ar: 'ارض اللواء',
		name: 'Ard Ellwaa',
	},
	{
		id: '93',
		governorate_id: '3',
		city_name_ar: 'ابو قير',
		name: 'Abu Qir',
	},
	{
		id: '94',
		governorate_id: '3',
		city_name_ar: 'الابراهيمية',
		name: 'Al Ibrahimeyah',
	},
	{
		id: '95',
		governorate_id: '3',
		city_name_ar: 'الأزاريطة',
		name: 'Azarita',
	},
	{
		id: '96',
		governorate_id: '3',
		city_name_ar: 'الانفوشى',
		name: 'Anfoushi',
	},
	{
		id: '97',
		governorate_id: '3',
		city_name_ar: 'الدخيلة',
		name: 'Dekheila',
	},
	{
		id: '98',
		governorate_id: '3',
		city_name_ar: 'السيوف',
		name: 'El Soyof',
	},
	{
		id: '99',
		governorate_id: '3',
		city_name_ar: 'العامرية',
		name: 'Ameria',
	},
	{
		id: '100',
		governorate_id: '3',
		city_name_ar: 'اللبان',
		name: 'El Labban',
	},
	{
		id: '101',
		governorate_id: '3',
		city_name_ar: 'المفروزة',
		name: 'Al Mafrouza',
	},
	{
		id: '102',
		governorate_id: '3',
		city_name_ar: 'المنتزه',
		name: 'El Montaza',
	},
	{
		id: '103',
		governorate_id: '3',
		city_name_ar: 'المنشية',
		name: 'Mansheya',
	},
	{
		id: '104',
		governorate_id: '3',
		city_name_ar: 'الناصرية',
		name: 'Naseria',
	},
	{
		id: '105',
		governorate_id: '3',
		city_name_ar: 'امبروزو',
		name: 'Ambrozo',
	},
	{
		id: '106',
		governorate_id: '3',
		city_name_ar: 'باب شرق',
		name: 'Bab Sharq',
	},
	{
		id: '107',
		governorate_id: '3',
		city_name_ar: 'برج العرب',
		name: 'Bourj Alarab',
	},
	{
		id: '108',
		governorate_id: '3',
		city_name_ar: 'ستانلى',
		name: 'Stanley',
	},
	{
		id: '109',
		governorate_id: '3',
		city_name_ar: 'سموحة',
		name: 'Smouha',
	},
	{
		id: '110',
		governorate_id: '3',
		city_name_ar: 'سيدى بشر',
		name: 'Sidi Bishr',
	},
	{
		id: '111',
		governorate_id: '3',
		city_name_ar: 'شدس',
		name: 'Shads',
	},
	{
		id: '112',
		governorate_id: '3',
		city_name_ar: 'غيط العنب',
		name: 'Gheet Alenab',
	},
	{
		id: '113',
		governorate_id: '3',
		city_name_ar: 'فلمينج',
		name: 'Fleming',
	},
	{
		id: '114',
		governorate_id: '3',
		city_name_ar: 'فيكتوريا',
		name: 'Victoria',
	},
	{
		id: '115',
		governorate_id: '3',
		city_name_ar: 'كامب شيزار',
		name: 'Camp Shizar',
	},
	{
		id: '116',
		governorate_id: '3',
		city_name_ar: 'كرموز',
		name: 'Karmooz',
	},
	{
		id: '117',
		governorate_id: '3',
		city_name_ar: 'محطة الرمل',
		name: 'Mahta Alraml',
	},
	{
		id: '118',
		governorate_id: '3',
		city_name_ar: 'مينا البصل',
		name: 'Mina El-Basal',
	},
	{
		id: '119',
		governorate_id: '3',
		city_name_ar: 'العصافرة',
		name: 'Asafra',
	},
	{
		id: '120',
		governorate_id: '3',
		city_name_ar: 'العجمي',
		name: 'Agamy',
	},
	{
		id: '121',
		governorate_id: '3',
		city_name_ar: 'بكوس',
		name: 'Bakos',
	},
	{
		id: '122',
		governorate_id: '3',
		city_name_ar: 'بولكلي',
		name: 'Boulkly',
	},
	{
		id: '123',
		governorate_id: '3',
		city_name_ar: 'كليوباترا',
		name: 'Cleopatra',
	},
	{
		id: '124',
		governorate_id: '3',
		city_name_ar: 'جليم',
		name: 'Glim',
	},
	{
		id: '125',
		governorate_id: '3',
		city_name_ar: 'المعمورة',
		name: 'Al Mamurah',
	},
	{
		id: '126',
		governorate_id: '3',
		city_name_ar: 'المندرة',
		name: 'Al Mandara',
	},
	{
		id: '127',
		governorate_id: '3',
		city_name_ar: 'محرم بك',
		name: 'Moharam Bek',
	},
	{
		id: '128',
		governorate_id: '3',
		city_name_ar: 'الشاطبي',
		name: 'Elshatby',
	},
	{
		id: '129',
		governorate_id: '3',
		city_name_ar: 'سيدي جابر',
		name: 'Sidi Gaber',
	},
	{
		id: '130',
		governorate_id: '3',
		city_name_ar: 'الساحل الشمالي',
		name: 'North Coast/sahel',
	},
	{
		id: '131',
		governorate_id: '3',
		city_name_ar: 'الحضرة',
		name: 'Alhadra',
	},
	{
		id: '132',
		governorate_id: '3',
		city_name_ar: 'العطارين',
		name: 'Alattarin',
	},
	{
		id: '133',
		governorate_id: '3',
		city_name_ar: 'سيدي كرير',
		name: 'Sidi Kerir',
	},
	{
		id: '134',
		governorate_id: '3',
		city_name_ar: 'الجمرك',
		name: 'Elgomrok',
	},
	{
		id: '135',
		governorate_id: '3',
		city_name_ar: 'المكس',
		name: 'Al Max',
	},
	{
		id: '136',
		governorate_id: '3',
		city_name_ar: 'مارينا',
		name: 'Marina',
	},
	{
		id: '137',
		governorate_id: '4',
		city_name_ar: 'المنصورة',
		name: 'Mansoura',
	},
	{
		id: '138',
		governorate_id: '4',
		city_name_ar: 'طلخا',
		name: 'Talkha',
	},
	{
		id: '139',
		governorate_id: '4',
		city_name_ar: 'ميت غمر',
		name: 'Mitt Ghamr',
	},
	{
		id: '140',
		governorate_id: '4',
		city_name_ar: 'دكرنس',
		name: 'Dekernes',
	},
	{
		id: '141',
		governorate_id: '4',
		city_name_ar: 'أجا',
		name: 'Aga',
	},
	{
		id: '142',
		governorate_id: '4',
		city_name_ar: 'منية النصر',
		name: 'Menia El Nasr',
	},
	{
		id: '143',
		governorate_id: '4',
		city_name_ar: 'السنبلاوين',
		name: 'Sinbillawin',
	},
	{
		id: '144',
		governorate_id: '4',
		city_name_ar: 'الكردي',
		name: 'El Kurdi',
	},
	{
		id: '145',
		governorate_id: '4',
		city_name_ar: 'بني عبيد',
		name: 'Bani Ubaid',
	},
	{
		id: '146',
		governorate_id: '4',
		city_name_ar: 'المنزلة',
		name: 'Al Manzala',
	},
	{
		id: '147',
		governorate_id: '4',
		city_name_ar: 'تمي الأمديد',
		name: "tami al'amdid",
	},
	{
		id: '148',
		governorate_id: '4',
		city_name_ar: 'الجمالية',
		name: 'aljamalia',
	},
	{
		id: '149',
		governorate_id: '4',
		city_name_ar: 'شربين',
		name: 'Sherbin',
	},
	{
		id: '150',
		governorate_id: '4',
		city_name_ar: 'المطرية',
		name: 'Mataria',
	},
	{
		id: '151',
		governorate_id: '4',
		city_name_ar: 'بلقاس',
		name: 'Belqas',
	},
	{
		id: '152',
		governorate_id: '4',
		city_name_ar: 'ميت سلسيل',
		name: 'Meet Salsil',
	},
	{
		id: '153',
		governorate_id: '4',
		city_name_ar: 'جمصة',
		name: 'Gamasa',
	},
	{
		id: '154',
		governorate_id: '4',
		city_name_ar: 'محلة دمنة',
		name: 'Mahalat Damana',
	},
	{
		id: '155',
		governorate_id: '4',
		city_name_ar: 'نبروه',
		name: 'Nabroh',
	},
	{
		id: '156',
		governorate_id: '5',
		city_name_ar: 'الغردقة',
		name: 'Hurghada',
	},
	{
		id: '157',
		governorate_id: '5',
		city_name_ar: 'رأس غارب',
		name: 'Ras Ghareb',
	},
	{
		id: '158',
		governorate_id: '5',
		city_name_ar: 'سفاجا',
		name: 'Safaga',
	},
	{
		id: '159',
		governorate_id: '5',
		city_name_ar: 'القصير',
		name: 'El Qusiar',
	},
	{
		id: '160',
		governorate_id: '5',
		city_name_ar: 'مرسى علم',
		name: 'Marsa Alam',
	},
	{
		id: '161',
		governorate_id: '5',
		city_name_ar: 'الشلاتين',
		name: 'Shalatin',
	},
	{
		id: '162',
		governorate_id: '5',
		city_name_ar: 'حلايب',
		name: 'Halaib',
	},
	{
		id: '163',
		governorate_id: '5',
		city_name_ar: 'الدهار',
		name: 'Aldahar',
	},
	{
		id: '164',
		governorate_id: '6',
		city_name_ar: 'دمنهور',
		name: 'Damanhour',
	},
	{
		id: '165',
		governorate_id: '6',
		city_name_ar: 'كفر الدوار',
		name: 'Kafr El Dawar',
	},
	{
		id: '166',
		governorate_id: '6',
		city_name_ar: 'رشيد',
		name: 'Rashid',
	},
	{
		id: '167',
		governorate_id: '6',
		city_name_ar: 'إدكو',
		name: 'Edco',
	},
	{
		id: '168',
		governorate_id: '6',
		city_name_ar: 'أبو المطامير',
		name: 'Abu al-Matamir',
	},
	{
		id: '169',
		governorate_id: '6',
		city_name_ar: 'أبو حمص',
		name: 'Abu Homs',
	},
	{
		id: '170',
		governorate_id: '6',
		city_name_ar: 'الدلنجات',
		name: 'Delengat',
	},
	{
		id: '171',
		governorate_id: '6',
		city_name_ar: 'المحمودية',
		name: 'Mahmoudiyah',
	},
	{
		id: '172',
		governorate_id: '6',
		city_name_ar: 'الرحمانية',
		name: 'Rahmaniyah',
	},
	{
		id: '173',
		governorate_id: '6',
		city_name_ar: 'إيتاي البارود',
		name: 'Itai Baroud',
	},
	{
		id: '174',
		governorate_id: '6',
		city_name_ar: 'حوش عيسى',
		name: 'Housh Eissa',
	},
	{
		id: '175',
		governorate_id: '6',
		city_name_ar: 'شبراخيت',
		name: 'Shubrakhit',
	},
	{
		id: '176',
		governorate_id: '6',
		city_name_ar: 'كوم حمادة',
		name: 'Kom Hamada',
	},
	{
		id: '177',
		governorate_id: '6',
		city_name_ar: 'بدر',
		name: 'Badr',
	},
	{
		id: '178',
		governorate_id: '6',
		city_name_ar: 'وادي النطرون',
		name: 'Wadi Natrun',
	},
	{
		id: '179',
		governorate_id: '6',
		city_name_ar: 'النوبارية الجديدة',
		name: 'New Nubaria',
	},
	{
		id: '180',
		governorate_id: '6',
		city_name_ar: 'النوبارية',
		name: 'Alnoubareya',
	},
	{
		id: '181',
		governorate_id: '7',
		city_name_ar: 'الفيوم',
		name: 'Fayoum',
	},
	{
		id: '182',
		governorate_id: '7',
		city_name_ar: 'الفيوم الجديدة',
		name: 'Fayoum El Gedida',
	},
	{
		id: '183',
		governorate_id: '7',
		city_name_ar: 'طامية',
		name: 'Tamiya',
	},
	{
		id: '184',
		governorate_id: '7',
		city_name_ar: 'سنورس',
		name: 'Snores',
	},
	{
		id: '185',
		governorate_id: '7',
		city_name_ar: 'إطسا',
		name: 'Etsa',
	},
	{
		id: '186',
		governorate_id: '7',
		city_name_ar: 'إبشواي',
		name: 'Epschway',
	},
	{
		id: '187',
		governorate_id: '7',
		city_name_ar: 'يوسف الصديق',
		name: 'Yusuf El Sediaq',
	},
	{
		id: '188',
		governorate_id: '7',
		city_name_ar: 'الحادقة',
		name: 'Hadqa',
	},
	{
		id: '189',
		governorate_id: '7',
		city_name_ar: 'اطسا',
		name: 'Atsa',
	},
	{
		id: '190',
		governorate_id: '7',
		city_name_ar: 'الجامعة',
		name: 'Algamaa',
	},
	{
		id: '191',
		governorate_id: '7',
		city_name_ar: 'السيالة',
		name: 'Sayala',
	},
	{
		id: '192',
		governorate_id: '8',
		city_name_ar: 'طنطا',
		name: 'Tanta',
	},
	{
		id: '193',
		governorate_id: '8',
		city_name_ar: 'المحلة الكبرى',
		name: 'Al Mahalla Al Kobra',
	},
	{
		id: '194',
		governorate_id: '8',
		city_name_ar: 'كفر الزيات',
		name: 'Kafr El Zayat',
	},
	{
		id: '195',
		governorate_id: '8',
		city_name_ar: 'زفتى',
		name: 'Zefta',
	},
	{
		id: '196',
		governorate_id: '8',
		city_name_ar: 'السنطة',
		name: 'El Santa',
	},
	{
		id: '197',
		governorate_id: '8',
		city_name_ar: 'قطور',
		name: 'Qutour',
	},
	{
		id: '198',
		governorate_id: '8',
		city_name_ar: 'بسيون',
		name: 'Basion',
	},
	{
		id: '199',
		governorate_id: '8',
		city_name_ar: 'سمنود',
		name: 'Samannoud',
	},
	{
		id: '200',
		governorate_id: '9',
		city_name_ar: 'الإسماعيلية',
		name: 'Ismailia',
	},
	{
		id: '201',
		governorate_id: '9',
		city_name_ar: 'فايد',
		name: 'Fayed',
	},
	{
		id: '202',
		governorate_id: '9',
		city_name_ar: 'القنطرة شرق',
		name: 'Qantara Sharq',
	},
	{
		id: '203',
		governorate_id: '9',
		city_name_ar: 'القنطرة غرب',
		name: 'Qantara Gharb',
	},
	{
		id: '204',
		governorate_id: '9',
		city_name_ar: 'التل الكبير',
		name: 'El Tal El Kabier',
	},
	{
		id: '205',
		governorate_id: '9',
		city_name_ar: 'أبو صوير',
		name: 'Abu Sawir',
	},
	{
		id: '206',
		governorate_id: '9',
		city_name_ar: 'القصاصين الجديدة',
		name: 'Kasasien El Gedida',
	},
	{
		id: '207',
		governorate_id: '9',
		city_name_ar: 'نفيشة',
		name: 'Nefesha',
	},
	{
		id: '208',
		governorate_id: '9',
		city_name_ar: 'الشيخ زايد',
		name: 'Sheikh Zayed',
	},
	{
		id: '209',
		governorate_id: '10',
		city_name_ar: 'شبين الكوم',
		name: 'Shbeen El Koom',
	},
	{
		id: '210',
		governorate_id: '10',
		city_name_ar: 'مدينة السادات',
		name: 'Sadat City',
	},
	{
		id: '211',
		governorate_id: '10',
		city_name_ar: 'منوف',
		name: 'Menouf',
	},
	{
		id: '212',
		governorate_id: '10',
		city_name_ar: 'سرس الليان',
		name: 'Sars El-Layan',
	},
	{
		id: '213',
		governorate_id: '10',
		city_name_ar: 'أشمون',
		name: 'Ashmon',
	},
	{
		id: '214',
		governorate_id: '10',
		city_name_ar: 'الباجور',
		name: 'Al Bagor',
	},
	{
		id: '215',
		governorate_id: '10',
		city_name_ar: 'قويسنا',
		name: 'Quesna',
	},
	{
		id: '216',
		governorate_id: '10',
		city_name_ar: 'بركة السبع',
		name: 'Berkat El Saba',
	},
	{
		id: '217',
		governorate_id: '10',
		city_name_ar: 'تلا',
		name: 'Tala',
	},
	{
		id: '218',
		governorate_id: '10',
		city_name_ar: 'الشهداء',
		name: 'Al Shohada',
	},
	{
		id: '219',
		governorate_id: '11',
		city_name_ar: 'المنيا',
		name: 'Minya',
	},
	{
		id: '220',
		governorate_id: '11',
		city_name_ar: 'المنيا الجديدة',
		name: 'Minya El Gedida',
	},
	{
		id: '221',
		governorate_id: '11',
		city_name_ar: 'العدوة',
		name: 'El Adwa',
	},
	{
		id: '222',
		governorate_id: '11',
		city_name_ar: 'مغاغة',
		name: 'Magagha',
	},
	{
		id: '223',
		governorate_id: '11',
		city_name_ar: 'بني مزار',
		name: 'Bani Mazar',
	},
	{
		id: '224',
		governorate_id: '11',
		city_name_ar: 'مطاي',
		name: 'Mattay',
	},
	{
		id: '225',
		governorate_id: '11',
		city_name_ar: 'سمالوط',
		name: 'Samalut',
	},
	{
		id: '226',
		governorate_id: '11',
		city_name_ar: 'المدينة الفكرية',
		name: 'Madinat El Fekria',
	},
	{
		id: '227',
		governorate_id: '11',
		city_name_ar: 'ملوي',
		name: 'Meloy',
	},
	{
		id: '228',
		governorate_id: '11',
		city_name_ar: 'دير مواس',
		name: 'Deir Mawas',
	},
	{
		id: '229',
		governorate_id: '11',
		city_name_ar: 'ابو قرقاص',
		name: 'Abu Qurqas',
	},
	{
		id: '230',
		governorate_id: '11',
		city_name_ar: 'ارض سلطان',
		name: 'Ard Sultan',
	},
	{
		id: '231',
		governorate_id: '12',
		city_name_ar: 'بنها',
		name: 'Banha',
	},
	{
		id: '232',
		governorate_id: '12',
		city_name_ar: 'قليوب',
		name: 'Qalyub',
	},
	{
		id: '233',
		governorate_id: '12',
		city_name_ar: 'شبرا الخيمة',
		name: 'Shubra Al Khaimah',
	},
	{
		id: '234',
		governorate_id: '12',
		city_name_ar: 'القناطر الخيرية',
		name: 'Al Qanater Charity',
	},
	{
		id: '235',
		governorate_id: '12',
		city_name_ar: 'الخانكة',
		name: 'Khanka',
	},
	{
		id: '236',
		governorate_id: '12',
		city_name_ar: 'كفر شكر',
		name: 'Kafr Shukr',
	},
	{
		id: '237',
		governorate_id: '12',
		city_name_ar: 'طوخ',
		name: 'Tukh',
	},
	{
		id: '238',
		governorate_id: '12',
		city_name_ar: 'قها',
		name: 'Qaha',
	},
	{
		id: '239',
		governorate_id: '12',
		city_name_ar: 'العبور',
		name: 'Obour',
	},
	{
		id: '240',
		governorate_id: '12',
		city_name_ar: 'الخصوص',
		name: 'Khosous',
	},
	{
		id: '241',
		governorate_id: '12',
		city_name_ar: 'شبين القناطر',
		name: 'Shibin Al Qanater',
	},
	{
		id: '242',
		governorate_id: '12',
		city_name_ar: 'مسطرد',
		name: 'Mostorod',
	},
	{
		id: '243',
		governorate_id: '13',
		city_name_ar: 'الخارجة',
		name: 'El Kharga',
	},
	{
		id: '244',
		governorate_id: '13',
		city_name_ar: 'باريس',
		name: 'Paris',
	},
	{
		id: '245',
		governorate_id: '13',
		city_name_ar: 'موط',
		name: 'Mout',
	},
	{
		id: '246',
		governorate_id: '13',
		city_name_ar: 'الفرافرة',
		name: 'Farafra',
	},
	{
		id: '247',
		governorate_id: '13',
		city_name_ar: 'بلاط',
		name: 'Balat',
	},
	{
		id: '248',
		governorate_id: '13',
		city_name_ar: 'الداخلة',
		name: 'Dakhla',
	},
	{
		id: '249',
		governorate_id: '14',
		city_name_ar: 'السويس',
		name: 'Suez',
	},
	{
		id: '250',
		governorate_id: '14',
		city_name_ar: 'الجناين',
		name: 'Alganayen',
	},
	{
		id: '251',
		governorate_id: '14',
		city_name_ar: 'عتاقة',
		name: 'Ataqah',
	},
	{
		id: '252',
		governorate_id: '14',
		city_name_ar: 'العين السخنة',
		name: 'Ain Sokhna',
	},
	{
		id: '253',
		governorate_id: '14',
		city_name_ar: 'فيصل',
		name: 'Faysal',
	},
	{
		id: '254',
		governorate_id: '15',
		city_name_ar: 'أسوان',
		name: 'Aswan',
	},
	{
		id: '255',
		governorate_id: '15',
		city_name_ar: 'أسوان الجديدة',
		name: 'Aswan El Gedida',
	},
	{
		id: '256',
		governorate_id: '15',
		city_name_ar: 'دراو',
		name: 'Drau',
	},
	{
		id: '257',
		governorate_id: '15',
		city_name_ar: 'كوم أمبو',
		name: 'Kom Ombo',
	},
	{
		id: '258',
		governorate_id: '15',
		city_name_ar: 'نصر النوبة',
		name: 'Nasr Al Nuba',
	},
	{
		id: '259',
		governorate_id: '15',
		city_name_ar: 'كلابشة',
		name: 'Kalabsha',
	},
	{
		id: '260',
		governorate_id: '15',
		city_name_ar: 'إدفو',
		name: 'Edfu',
	},
	{
		id: '261',
		governorate_id: '15',
		city_name_ar: 'الرديسية',
		name: 'Al-Radisiyah',
	},
	{
		id: '262',
		governorate_id: '15',
		city_name_ar: 'البصيلية',
		name: 'Al Basilia',
	},
	{
		id: '263',
		governorate_id: '15',
		city_name_ar: 'السباعية',
		name: 'Al Sibaeia',
	},
	{
		id: '264',
		governorate_id: '15',
		city_name_ar: 'ابوسمبل السياحية',
		name: 'Abo Simbl Al Siyahia',
	},
	{
		id: '265',
		governorate_id: '15',
		city_name_ar: 'مرسى علم',
		name: 'Marsa Alam',
	},
	{
		id: '266',
		governorate_id: '16',
		city_name_ar: 'أسيوط',
		name: 'Assiut',
	},
	{
		id: '267',
		governorate_id: '16',
		city_name_ar: 'أسيوط الجديدة',
		name: 'Assiut El Gedida',
	},
	{
		id: '268',
		governorate_id: '16',
		city_name_ar: 'ديروط',
		name: 'Dayrout',
	},
	{
		id: '269',
		governorate_id: '16',
		city_name_ar: 'منفلوط',
		name: 'Manfalut',
	},
	{
		id: '270',
		governorate_id: '16',
		city_name_ar: 'القوصية',
		name: 'Qusiya',
	},
	{
		id: '271',
		governorate_id: '16',
		city_name_ar: 'أبنوب',
		name: 'Abnoub',
	},
	{
		id: '272',
		governorate_id: '16',
		city_name_ar: 'أبو تيج',
		name: 'Abu Tig',
	},
	{
		id: '273',
		governorate_id: '16',
		city_name_ar: 'الغنايم',
		name: 'El Ghanaim',
	},
	{
		id: '274',
		governorate_id: '16',
		city_name_ar: 'ساحل سليم',
		name: 'Sahel Selim',
	},
	{
		id: '275',
		governorate_id: '16',
		city_name_ar: 'البداري',
		name: 'El Badari',
	},
	{
		id: '276',
		governorate_id: '16',
		city_name_ar: 'صدفا',
		name: 'Sidfa',
	},
	{
		id: '277',
		governorate_id: '17',
		city_name_ar: 'بني سويف',
		name: 'Bani Sweif',
	},
	{
		id: '278',
		governorate_id: '17',
		city_name_ar: 'بني سويف الجديدة',
		name: 'Beni Suef El Gedida',
	},
	{
		id: '279',
		governorate_id: '17',
		city_name_ar: 'الواسطى',
		name: 'Al Wasta',
	},
	{
		id: '280',
		governorate_id: '17',
		city_name_ar: 'ناصر',
		name: 'Naser',
	},
	{
		id: '281',
		governorate_id: '17',
		city_name_ar: 'إهناسيا',
		name: 'Ehnasia',
	},
	{
		id: '282',
		governorate_id: '17',
		city_name_ar: 'ببا',
		name: 'beba',
	},
	{
		id: '283',
		governorate_id: '17',
		city_name_ar: 'الفشن',
		name: 'Fashn',
	},
	{
		id: '284',
		governorate_id: '17',
		city_name_ar: 'سمسطا',
		name: 'Somasta',
	},
	{
		id: '285',
		governorate_id: '17',
		city_name_ar: 'الاباصيرى',
		name: 'Alabbaseri',
	},
	{
		id: '286',
		governorate_id: '17',
		city_name_ar: 'مقبل',
		name: 'Mokbel',
	},
	{
		id: '287',
		governorate_id: '18',
		city_name_ar: 'بورسعيد',
		name: 'PorSaid',
	},
	{
		id: '288',
		governorate_id: '18',
		city_name_ar: 'بورفؤاد',
		name: 'Port Fouad',
	},
	{
		id: '289',
		governorate_id: '18',
		city_name_ar: 'العرب',
		name: 'Alarab',
	},
	{
		id: '290',
		governorate_id: '18',
		city_name_ar: 'حى الزهور',
		name: 'Zohour',
	},
	{
		id: '291',
		governorate_id: '18',
		city_name_ar: 'حى الشرق',
		name: 'Alsharq',
	},
	{
		id: '292',
		governorate_id: '18',
		city_name_ar: 'حى الضواحى',
		name: 'Aldawahi',
	},
	{
		id: '293',
		governorate_id: '18',
		city_name_ar: 'حى المناخ',
		name: 'Almanakh',
	},
	{
		id: '294',
		governorate_id: '18',
		city_name_ar: 'حى مبارك',
		name: 'Mubarak',
	},
	{
		id: '295',
		governorate_id: '19',
		city_name_ar: 'دمياط',
		name: 'Damietta',
	},
	{
		id: '296',
		governorate_id: '19',
		city_name_ar: 'دمياط الجديدة',
		name: 'New Damietta',
	},
	{
		id: '297',
		governorate_id: '19',
		city_name_ar: 'رأس البر',
		name: 'Ras El Bar',
	},
	{
		id: '298',
		governorate_id: '19',
		city_name_ar: 'فارسكور',
		name: 'Faraskour',
	},
	{
		id: '299',
		governorate_id: '19',
		city_name_ar: 'الزرقا',
		name: 'Zarqa',
	},
	{
		id: '300',
		governorate_id: '19',
		city_name_ar: 'السرو',
		name: 'alsaru',
	},
	{
		id: '301',
		governorate_id: '19',
		city_name_ar: 'الروضة',
		name: 'alruwda',
	},
	{
		id: '302',
		governorate_id: '19',
		city_name_ar: 'كفر البطيخ',
		name: 'Kafr El-Batikh',
	},
	{
		id: '303',
		governorate_id: '19',
		city_name_ar: 'عزبة البرج',
		name: 'Azbet Al Burg',
	},
	{
		id: '304',
		governorate_id: '19',
		city_name_ar: 'ميت أبو غالب',
		name: 'Meet Abou Ghalib',
	},
	{
		id: '305',
		governorate_id: '19',
		city_name_ar: 'كفر سعد',
		name: 'Kafr Saad',
	},
	{
		id: '306',
		governorate_id: '20',
		city_name_ar: 'الزقازيق',
		name: 'Zagazig',
	},
	{
		id: '307',
		governorate_id: '20',
		city_name_ar: 'العاشر من رمضان',
		name: 'Al Ashr Men Ramadan',
	},
	{
		id: '308',
		governorate_id: '20',
		city_name_ar: 'منيا القمح',
		name: 'Minya Al Qamh',
	},
	{
		id: '309',
		governorate_id: '20',
		city_name_ar: 'بلبيس',
		name: 'Belbeis',
	},
	{
		id: '310',
		governorate_id: '20',
		city_name_ar: 'مشتول السوق',
		name: 'Mashtoul El Souq',
	},
	{
		id: '311',
		governorate_id: '20',
		city_name_ar: 'القنايات',
		name: 'Qenaiat',
	},
	{
		id: '312',
		governorate_id: '20',
		city_name_ar: 'أبو حماد',
		name: 'Abu Hammad',
	},
	{
		id: '313',
		governorate_id: '20',
		city_name_ar: 'القرين',
		name: 'El Qurain',
	},
	{
		id: '314',
		governorate_id: '20',
		city_name_ar: 'ههيا',
		name: 'Hehia',
	},
	{
		id: '315',
		governorate_id: '20',
		city_name_ar: 'أبو كبير',
		name: 'Abu Kabir',
	},
	{
		id: '316',
		governorate_id: '20',
		city_name_ar: 'فاقوس',
		name: 'Faccus',
	},
	{
		id: '317',
		governorate_id: '20',
		city_name_ar: 'الصالحية الجديدة',
		name: 'El Salihia El Gedida',
	},
	{
		id: '318',
		governorate_id: '20',
		city_name_ar: 'الإبراهيمية',
		name: 'Al Ibrahimiyah',
	},
	{
		id: '319',
		governorate_id: '20',
		city_name_ar: 'ديرب نجم',
		name: 'Deirb Negm',
	},
	{
		id: '320',
		governorate_id: '20',
		city_name_ar: 'كفر صقر',
		name: 'Kafr Saqr',
	},
	{
		id: '321',
		governorate_id: '20',
		city_name_ar: 'أولاد صقر',
		name: 'Awlad Saqr',
	},
	{
		id: '322',
		governorate_id: '20',
		city_name_ar: 'الحسينية',
		name: 'Husseiniya',
	},
	{
		id: '323',
		governorate_id: '20',
		city_name_ar: 'صان الحجر القبلية',
		name: 'san alhajar alqablia',
	},
	{
		id: '324',
		governorate_id: '20',
		city_name_ar: 'منشأة أبو عمر',
		name: 'Manshayat Abu Omar',
	},
	{
		id: '325',
		governorate_id: '21',
		city_name_ar: 'الطور',
		name: 'Al Toor',
	},
	{
		id: '326',
		governorate_id: '21',
		city_name_ar: 'شرم الشيخ',
		name: 'Sharm El-Shaikh',
	},
	{
		id: '327',
		governorate_id: '21',
		city_name_ar: 'دهب',
		name: 'Dahab',
	},
	{
		id: '328',
		governorate_id: '21',
		city_name_ar: 'نويبع',
		name: 'Nuweiba',
	},
	{
		id: '329',
		governorate_id: '21',
		city_name_ar: 'طابا',
		name: 'Taba',
	},
	{
		id: '330',
		governorate_id: '21',
		city_name_ar: 'سانت كاترين',
		name: 'Saint Catherine',
	},
	{
		id: '331',
		governorate_id: '21',
		city_name_ar: 'أبو رديس',
		name: 'Abu Redis',
	},
	{
		id: '332',
		governorate_id: '21',
		city_name_ar: 'أبو زنيمة',
		name: 'Abu Zenaima',
	},
	{
		id: '333',
		governorate_id: '21',
		city_name_ar: 'رأس سدر',
		name: 'Ras Sidr',
	},
	{
		id: '334',
		governorate_id: '22',
		city_name_ar: 'كفر الشيخ',
		name: 'Kafr El Sheikh',
	},
	{
		id: '335',
		governorate_id: '22',
		city_name_ar: 'وسط البلد كفر الشيخ',
		name: 'Kafr El Sheikh Downtown',
	},
	{
		id: '336',
		governorate_id: '22',
		city_name_ar: 'دسوق',
		name: 'Desouq',
	},
	{
		id: '337',
		governorate_id: '22',
		city_name_ar: 'فوه',
		name: 'Fooh',
	},
	{
		id: '338',
		governorate_id: '22',
		city_name_ar: 'مطوبس',
		name: 'Metobas',
	},
	{
		id: '339',
		governorate_id: '22',
		city_name_ar: 'برج البرلس',
		name: 'Burg Al Burullus',
	},
	{
		id: '340',
		governorate_id: '22',
		city_name_ar: 'بلطيم',
		name: 'Baltim',
	},
	{
		id: '341',
		governorate_id: '22',
		city_name_ar: 'مصيف بلطيم',
		name: 'Masief Baltim',
	},
	{
		id: '342',
		governorate_id: '22',
		city_name_ar: 'الحامول',
		name: 'Hamol',
	},
	{
		id: '343',
		governorate_id: '22',
		city_name_ar: 'بيلا',
		name: 'Bella',
	},
	{
		id: '344',
		governorate_id: '22',
		city_name_ar: 'الرياض',
		name: 'Riyadh',
	},
	{
		id: '345',
		governorate_id: '22',
		city_name_ar: 'سيدي سالم',
		name: 'Sidi Salm',
	},
	{
		id: '346',
		governorate_id: '22',
		city_name_ar: 'قلين',
		name: 'Qellen',
	},
	{
		id: '347',
		governorate_id: '22',
		city_name_ar: 'سيدي غازي',
		name: 'Sidi Ghazi',
	},
	{
		id: '348',
		governorate_id: '23',
		city_name_ar: 'مرسى مطروح',
		name: 'Marsa Matrouh',
	},
	{
		id: '349',
		governorate_id: '23',
		city_name_ar: 'الحمام',
		name: 'El Hamam',
	},
	{
		id: '350',
		governorate_id: '23',
		city_name_ar: 'العلمين',
		name: 'Alamein',
	},
	{
		id: '351',
		governorate_id: '23',
		city_name_ar: 'الضبعة',
		name: 'Dabaa',
	},
	{
		id: '352',
		governorate_id: '23',
		city_name_ar: 'النجيلة',
		name: 'Al-Nagila',
	},
	{
		id: '353',
		governorate_id: '23',
		city_name_ar: 'سيدي براني',
		name: 'Sidi Brani',
	},
	{
		id: '354',
		governorate_id: '23',
		city_name_ar: 'السلوم',
		name: 'Salloum',
	},
	{
		id: '355',
		governorate_id: '23',
		city_name_ar: 'سيوة',
		name: 'Siwa',
	},
	{
		id: '356',
		governorate_id: '23',
		city_name_ar: 'مارينا',
		name: 'Marina',
	},
	{
		id: '357',
		governorate_id: '23',
		city_name_ar: 'الساحل الشمالى',
		name: 'North Coast',
	},
	{
		id: '358',
		governorate_id: '24',
		city_name_ar: 'الأقصر',
		name: 'Luxor',
	},
	{
		id: '359',
		governorate_id: '24',
		city_name_ar: 'الأقصر الجديدة',
		name: 'New Luxor',
	},
	{
		id: '360',
		governorate_id: '24',
		city_name_ar: 'إسنا',
		name: 'Esna',
	},
	{
		id: '361',
		governorate_id: '24',
		city_name_ar: 'طيبة الجديدة',
		name: 'New Tiba',
	},
	{
		id: '362',
		governorate_id: '24',
		city_name_ar: 'الزينية',
		name: 'Al ziynia',
	},
	{
		id: '363',
		governorate_id: '24',
		city_name_ar: 'البياضية',
		name: 'Al Bayadieh',
	},
	{
		id: '364',
		governorate_id: '24',
		city_name_ar: 'القرنة',
		name: 'Al Qarna',
	},
	{
		id: '365',
		governorate_id: '24',
		city_name_ar: 'أرمنت',
		name: 'Armant',
	},
	{
		id: '366',
		governorate_id: '24',
		city_name_ar: 'الطود',
		name: 'Al Tud',
	},
	{
		id: '367',
		governorate_id: '25',
		city_name_ar: 'قنا',
		name: 'Qena',
	},
	{
		id: '368',
		governorate_id: '25',
		city_name_ar: 'قنا الجديدة',
		name: 'New Qena',
	},
	{
		id: '369',
		governorate_id: '25',
		city_name_ar: 'ابو طشت',
		name: 'Abu Tesht',
	},
	{
		id: '370',
		governorate_id: '25',
		city_name_ar: 'نجع حمادي',
		name: 'Nag Hammadi',
	},
	{
		id: '371',
		governorate_id: '25',
		city_name_ar: 'دشنا',
		name: 'Deshna',
	},
	{
		id: '372',
		governorate_id: '25',
		city_name_ar: 'الوقف',
		name: 'Alwaqf',
	},
	{
		id: '373',
		governorate_id: '25',
		city_name_ar: 'قفط',
		name: 'Qaft',
	},
	{
		id: '374',
		governorate_id: '25',
		city_name_ar: 'نقادة',
		name: 'Naqada',
	},
	{
		id: '375',
		governorate_id: '25',
		city_name_ar: 'فرشوط',
		name: 'Farshout',
	},
	{
		id: '376',
		governorate_id: '25',
		city_name_ar: 'قوص',
		name: 'Quos',
	},
	{
		id: '377',
		governorate_id: '26',
		city_name_ar: 'العريش',
		name: 'Arish',
	},
	{
		id: '378',
		governorate_id: '26',
		city_name_ar: 'الشيخ زويد',
		name: 'Sheikh Zowaid',
	},
	{
		id: '379',
		governorate_id: '26',
		city_name_ar: 'نخل',
		name: 'Nakhl',
	},
	{
		id: '380',
		governorate_id: '26',
		city_name_ar: 'رفح',
		name: 'Rafah',
	},
	{
		id: '381',
		governorate_id: '26',
		city_name_ar: 'بئر العبد',
		name: 'Bir al-Abed',
	},
	{
		id: '382',
		governorate_id: '26',
		city_name_ar: 'الحسنة',
		name: 'Al Hasana',
	},
	{
		id: '383',
		governorate_id: '27',
		city_name_ar: 'سوهاج',
		name: 'Sohag',
	},
	{
		id: '384',
		governorate_id: '27',
		city_name_ar: 'سوهاج الجديدة',
		name: 'Sohag El Gedida',
	},
	{
		id: '385',
		governorate_id: '27',
		city_name_ar: 'أخميم',
		name: 'Akhmeem',
	},
	{
		id: '386',
		governorate_id: '27',
		city_name_ar: 'أخميم الجديدة',
		name: 'Akhmim El Gedida',
	},
	{
		id: '387',
		governorate_id: '27',
		city_name_ar: 'البلينا',
		name: 'Albalina',
	},
	{
		id: '388',
		governorate_id: '27',
		city_name_ar: 'المراغة',
		name: 'El Maragha',
	},
	{
		id: '389',
		governorate_id: '27',
		city_name_ar: 'المنشأة',
		name: "almunsha'a",
	},
	{
		id: '390',
		governorate_id: '27',
		city_name_ar: 'دار السلام',
		name: 'Dar AISalaam',
	},
	{
		id: '391',
		governorate_id: '27',
		city_name_ar: 'جرجا',
		name: 'Gerga',
	},
	{
		id: '392',
		governorate_id: '27',
		city_name_ar: 'جهينة الغربية',
		name: 'Jahina Al Gharbia',
	},
	{
		id: '393',
		governorate_id: '27',
		city_name_ar: 'ساقلته',
		name: 'Saqilatuh',
	},
	{
		id: '394',
		governorate_id: '27',
		city_name_ar: 'طما',
		name: 'Tama',
	},
	{
		id: '395',
		governorate_id: '27',
		city_name_ar: 'طهطا',
		name: 'Tahta',
	},
	{
		id: '396',
		governorate_id: '27',
		city_name_ar: 'الكوثر',
		name: 'Alkawthar',
	},
]

export default Cities
