import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import AuthenticationReducer from '@Features/Authentication/AuthenticationSlice/Authentication.slice'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import classroomApi from '@Features/Classroom/ClassroomSlice/ClassroomSlice'
import { ProgressAPI } from '@Features/Progress/ProgressSlice/ProgressSlice'
import settingsSlice from '@Features/Settings/SettingsSlice/Settings.slice'
import { BaseApi } from '@Features/Base/BaseApi/BaseApi'
import SnackbarSlice from './SnackbarSlice/SnackbarSlice'
import RunningTestSlice from './RunningTestSlice/RunningTestSlice'
import UtilsSlice from './UtilsSlice/UtilsSlice'
export const store = configureStore({
	reducer: {
		auth: AuthenticationReducer,
		[classroomApi.reducerPath]: classroomApi.reducer,
		[SnackbarSlice.name]: SnackbarSlice.reducer,
		[RunningTestSlice.name]: RunningTestSlice.reducer,
		[ProgressAPI.reducerPath]: ProgressAPI.reducer,
		[settingsSlice.name]: settingsSlice.reducer,
		[BaseApi.reducerPath]: BaseApi.reducer,
		[UtilsSlice.name]  : UtilsSlice.reducer
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(BaseApi.middleware),
	devTools: true,
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
