import { BaseWidget, Mosaic } from '@eduact/ed-components'
import React from 'react'
import AnalyticsWidget from './Components/AnalyticsWidget/AnalyticsWidget'
import ClassroomsWidget from './Components/ClassroomsWidget/ClassroomsWidget'

const ClassroomsOverview = ({ ...args }) => {
	return (
		<Mosaic height={'100%'}>
			<Mosaic.Row flex={1}>
				<Mosaic.Col flex={2}>
					<BaseWidget widgetProps={undefined} onClick={() => { } } widget={ClassroomsWidget} {...args} bg="light" height={'auto'} />
				</Mosaic.Col>
				<Mosaic.Col flex={1}>
					<BaseWidget widgetProps={undefined} onClick={() => { } } widget={AnalyticsWidget} {...args} bg="light" height={'auto'} />
				</Mosaic.Col>
			</Mosaic.Row>
			<Mosaic.Row flex={1} />
		</Mosaic>
	)
}

export default ClassroomsOverview
