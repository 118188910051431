import qs from 'qs'

export default function addQueryString(currentSearchString: string, key: string, value: string | number): string {
	const queries = qs.parse(currentSearchString.replace('?', ''))
	if (String(value).trim() === '') {
		delete queries[key]
	} else {
		queries[key] = String(value)
	}
	return qs.stringify(queries)
}
