import { BaseApi } from '@Features/Base/BaseApi/BaseApi'

const SingleClassroomApi = BaseApi.enhanceEndpoints({
	addTagTypes: ['SINGLE_CLASSROOM', 'LEARNING_TIME'],
}).injectEndpoints({
	endpoints: build => ({
		getClassroomBasicInfo: build.query<ApiResponse<SigleClassroomInfo>, string>({
			query: id => `/classroom/basic-info/${id}`,
			providesTags: (result, error, arg) => {
				const tags: Array<{ type: 'SINGLE_CLASSROOM'; id: number }> = []
				return [...tags, { type: 'SINGLE_CLASSROOM', id: arg }]
			},
		}),
		getClassroomAttendanceRate: build.query<ApiResponse<AttendaceRate>, ClassroomId>({
			query: payload => ({ url: `/student/analytics/attendance-rate`, method: 'POST', body: payload }),
		}),
		getClassroomAverageScore: build.query<ApiResponse<AverageScore>, ClassroomId>({
			query: payload => ({ url: `/student/analytics/tests/average-score`, method: 'POST', body: payload }),
		}),
		getTimeline: build.query<ApiResponse<TimelineEvent[]>, FiltersPayload | null>({
			query: payload => ({
				url: 'student/profile/timeline',
				body: {
					...payload,
					related_to: 'classroom',
				},
				method: 'POST',
			}),
		}),
		getLearningTime: build.query<ApiResponse<LearningTime>, ClassroomId | UserId>({
			query: payload => ({
				url: 'student/analytics/learning-time',
				body: payload,
				method: 'POST',
			}),
			providesTags: ['LEARNING_TIME'],
		}),
		// getClassroomsAnalytics: build.query<ApiResponse<ClassroomsAnalytics>, ClassroomId>({
		// 	query: payload => ({
		// 		url: 'student/analytics/count',
		// 		method: 'POST',
		// 		body: {
		// 			classroom_id: payload.classroom_id,
		// 		},
		// 	}),
		// }),
		getCompletedUnitsGraph: build.query<ApiResponse<CompletedUnitGraphResponse>, GraphPayload>({
			query: payload => ({
				url: 'student/analytics/units/graph',
				method: 'POST',
				body: payload,
			}),
		}),
	}),
})

export const {
	useGetClassroomBasicInfoQuery,
	useGetClassroomAttendanceRateQuery,
	useGetClassroomAverageScoreQuery,
	useLazyGetTimelineQuery,
	useGetClassroomsAnalyticsQuery,
	useGetCompletedUnitsGraphQuery,
	useLazyGetCompletedUnitsGraphQuery,
	useGetLearningTimeQuery,
} = SingleClassroomApi
export default SingleClassroomApi
