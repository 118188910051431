import { FlexLayout } from '@eduact/ed-system'
import moment from 'moment'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { EddieImg, Title, Message } from './StartTest'
import Passed from '../Assets/passed.svg'
import Failed from '../Assets/failed.svg'
import ProgressBar from './ProgressBar'
import edEn from '../Assets/eden.svg'
import i18next from 'i18next'
import { useCourseContext } from '@Features/Course/Providers/CourseProvider/CourseProvider'
import { generatePath, useNavigate } from 'react-router'
import routes from '@Constants/Routes'
import { useAppDispatch } from '@Hooks/ReduxHooks'
import { setRunningTest } from '@Redux/RunningTestSlice/RunningTestSlice'
import { RaisedButton } from '@eduact/ed-components'
import TestSummary from './TestSummary'
import { useGetTestAttemptsQuery } from '../TestMcqApi/TestMcqApi'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import Dropdown, { DropdownItem } from '@Components/InputFields/Dropdown'

type Props = {
	data: Test | undefined
	opened: Opened | undefined
	testInfo: StartTestData | undefined
	completed: Completed[] | undefined
	attempt_id: number | undefined
}

const EndPage: FC<Props> = ({ data, completed, attempt_id }) => {
	const [t] = useTranslation('test')
	const { dir } = i18next
	const { course, classroomLabel } = useCourseContext()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { refetch, data: attemptsData } = useGetTestAttemptsQuery(Number(data?.id) ?? skipToken)
	const [attemptId, setAttemptId] = useState<number | undefined>(attempt_id)
	const [testSummmaryData, setTestSummmaryData] = useState<AttemptBase | undefined>()

	useEffect(() => {
		setAttemptId(attempt_id)
	}, [attempt_id])

	useEffect(() => {
		refetch()
	}, [attempt_id])

	const onRetakeTest = async () => {
		if (data && course && classroomLabel && data.unit_id) {
			dispatch(
				setRunningTest({
					testData: {
						classroomLabel,
						course_id: course.id,
						unit_id: data.unit_id,
					},
				})
			)
			if (data.id) navigate(generatePath(routes.test, { id: data.id.toString() }))
		}
	}

	const passedMsg = testSummmaryData?.status === 'passed' && data?.message_if_passed
	const failedMsg = testSummmaryData?.status === 'failed' && data?.message_if_failed
	const passedImg = data?.show_status && testSummmaryData?.status === 'passed'
	const failedImg = data?.show_status && testSummmaryData?.status === 'failed'

	const studentScore = testSummmaryData?.score
	const studentDate = testSummmaryData?.created_at

	const isSuccess = useMemo(() => {
		return testSummmaryData?.status === 'passed'
	}, [testSummmaryData?.status])

	const canRetakeTrials = useMemo(() => {
		if (testSummmaryData?.test?.allowed_trials) {
			return completed && testSummmaryData && testSummmaryData?.test?.allowed_trials > completed?.length
		}
		return false
	}, [completed, testSummmaryData])

	const canRetake = useMemo(() => {
		return testSummmaryData?.test?.allow_repetition_when === 'always' && canRetakeTrials
	}, [testSummmaryData?.test?.allow_repetition_when, canRetakeTrials])

	const canRetakeFailed = useMemo(() => {
		return testSummmaryData?.test?.allow_repetition_when === 'failed' && canRetakeTrials
	}, [testSummmaryData?.test?.allow_repetition_when, canRetakeTrials])

	const showProgressBar = useMemo(() => {
		return testSummmaryData?.test?.show_score_value && testSummmaryData?.test?.show_score_percentage
	}, [testSummmaryData?.test?.show_score_value, testSummmaryData?.test?.show_score_percentage])

	const currentAttemptIndex = useMemo(() => {
		if (attemptsData?.data) {
			const index = attemptsData?.data.findIndex(_ => _.id === attemptId)
			return index + 1
		}
	}, [attemptId, attemptsData?.data])

	const currentAttempt = useMemo(() => {
		if (attemptsData?.data) {
			return attemptsData?.data.find(_ => _.id === attemptId)
		}
	}, [attemptId, attemptsData?.data])

	return (
		<>
			<CardWrapper>
				<FlexLayout alignItems="center" justifyContent="space-between" width="100%">
					<Title>{data?.unit?.name ?? ''}</Title>
					<DropdownStyle>
						<AttemptDropdown
							placeholder="Attempts"
							selectedItemLabel={
								`Attempt # ${currentAttemptIndex} -${moment(currentAttempt?.created_at).format('DD/MM/yyyy, hh:mm A')}` as any
							}
							setSelectedItem={setAttemptId}
						>
							{attemptsData?.data?.map((attempt, index) => (
								<DropdownItem
									key={attempt.id}
									value={attempt.id}
									onClick={() => {
										setAttemptId(attempt.id)
									}}
								>
									Attempt #{index + 1} -{moment(attempt?.created_at).format('DD/MM/yyyy, hh:mm A')}
								</DropdownItem>
							))}
						</AttemptDropdown>
					</DropdownStyle>
				</FlexLayout>
				<InfoContainer>
					<DateWrapper>
						<Text>{t('takenDate')}:</Text>
						<Date>{moment(studentDate).format('DD/MM/YY hh:mm A')}</Date>
					</DateWrapper>
					{showProgressBar && <TextScore>{t('testScore')}:</TextScore>}
					<ProgressWrapper flexDirection="column">
						<FlexLayoutStyle margin="auto">
							{showProgressBar && (
								<ProgressBar overallScore={testSummmaryData?.overall_score} progressData={testSummmaryData?.test} score={studentScore} />
							)}
							{passedImg && <StatusImg src={Passed} alt="passed" />}
							{failedImg && <StatusImg src={Failed} alt="failed" />}
						</FlexLayoutStyle>
					</ProgressWrapper>
					<GradeWrapper>
						<StudentGrade flexDirection="column" gridGap="0.4rem">
							{data?.show_grade && testSummmaryData?.grade && (
								<GradeText>
									{t('grade')}: {testSummmaryData?.grade}
								</GradeText>
							)}
							{data?.allowed_trials && (
								<AttemptText>
									{t('attemptNum')}: {completed?.length}/ {data?.allowed_trials}
								</AttemptText>
							)}
						</StudentGrade>
						{passedMsg || failedMsg ? (
							<MessageWrapper>
								{dir() === 'rtl' && <EddieImg src={edEn} alt="eddie" />}
								<Message maxWidth="12.719rem" maxHeight="8.094rem" minWidth="unset">
									{passedMsg ? passedMsg : failedMsg}
								</Message>
								{dir() === 'ltr' && <EddieImg src={edEn} alt="eddie" />}
							</MessageWrapper>
						) : null}
					</GradeWrapper>
					<BtnsWrapper>
						{/* <BackBtn
					variant="purple"
					outlined={true}
					onClick={() => {
						// history("/classroom/CSEN");
					}}
				>
					{t('backToLec')}
				</BackBtn> */}
						{((canRetakeFailed && !isSuccess) || canRetake) && (
							<RetakeBtn
								variant="primary"
								onClick={() => {
									onRetakeTest()
								}}
							>
								{t('retake')}
							</RetakeBtn>
						)}
					</BtnsWrapper>
				</InfoContainer>
			</CardWrapper>
			<TestSummary id={attemptId} setTestSummmaryData={setTestSummmaryData} />
		</>
	)
}
const DropdownStyle = styled.div`
	width: 19rem;
`
const CardWrapper = styled(FlexLayout)`
	scroll-behavior: smooth;
	min-height: 8.563rem;
	border-radius: 15px;
	padding: 46.6px 98.7px 45px 54.8px;
	background-color: #fff;
	margin-bottom: 0.75rem;
	flex-direction: column;
	align-items: start;
	justify-items: flex-start;
	position: relative;
	gap: 1rem;
	overflow: hidden;

	@media (min-width: 610px) and (max-width: 1440px) {
		padding: 15px 50px 15px 15px;
	}
	@media (max-width: 610px) {
		gap: 0.5rem;
		padding: 11.8px 25.4px 17.4px 18.7px;
		min-height: 9rem;
		border-radius: 10px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		margin-bottom: 0.75rem;
		justify-items: flex-start;
		justify-content: space-between;
		overflow: unset;
	}
`
const InfoContainer = styled(FlexLayout)`
	width: 100%;
	flex-direction: column;
	align-items: start;
	justify-items: flex-start;
	z-index: 1;
	@media (max-width: 610px) {
		align-items: center;
		padding: 0;
		z-index: 0;
	}
`
const ProgressWrapper = styled(FlexLayout)`
	width: 80%;
	align-items: start;
	margin: auto;
	margin-top: 0.8rem;
`
const FlexLayoutStyle = styled(FlexLayout)`
	justify-content: space-around;
	margin-bottom: 1rem;
	width: 100%;
	padding: 0 5rem;
	@media (max-width: 768px) {
		padding: 0;
		align-items: center;
	}
`
const GradeWrapper = styled(FlexLayout)`
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-top: 1.5rem;
	padding: 0 5rem;
	@media (max-width: 610px) {
		flex-direction: column;
		justify-content: center;
		margin-top: unset;
		padding: 0;
	}
`
const StudentGrade = styled(FlexLayout)`
	justify-content: start;
`
const MessageWrapper = styled(FlexLayout)`
	@media (max-width: 610px) {
		margin: auto;
	}
`
const DateWrapper = styled(FlexLayout)`
	gap: 1.5rem;
	justify-content: center;
	width: 100%;
	@media (max-width: 610px) {
		flex-direction: column;
		align-items: center;
		justify-content: start;
		margin-bottom: 0;
		gap: 0;
	}
`
const BtnsWrapper = styled(FlexLayout)`
	flex-direction: row-reverse;
	align-items: center;
	margin-top: 1rem;
	width: 100%;
	gap: 1rem;
	@media (max-width: 610px) {
		flex-direction: column;
		justify-content: center;
		gap: 0.75rem;
		margin-top: 1.125rem;
	}
`
const Text = styled.p`
	font-size: 1.75rem;
	font-weight: 600;
	@media (max-width: 610px) {
		font-size: 1rem;
		line-height: 1.38;
	}
`
const Date = styled.p`
	font-size: 1.75rem;
	font-weight: 500;
	@media (max-width: 610px) {
		font-size: 1rem;
		line-height: 1.38;
	}
`
const TextScore = styled.p`
	font-size: 1.75rem;
	font-weight: 600;
	margin-top: 2rem;
	padding: 0 5rem;
	@media (min-width: 610px) and (max-width: 1440px) {
		padding: 0 2rem;
	}
	@media (max-width: 610px) {
		font-size: 0.75rem;
		margin-top: 0.4rem;
		padding: 0 1rem;
	}
`
const GradeText = styled.p`
	font-size: 1.75rem;
	font-weight: 600;
	@media (max-width: 610px) {
		font-size: 0.75rem;
		line-height: 1.22;
	}
`
const AttemptDropdown = styled(Dropdown)`
	border: 2px solid #5ac0fc;
	padding-inline-start: 0.4rem;
	padding-inline-end: 0.4rem;
	border-radius: 5px;
	width: 19rem;
	height: 2.875rem;
	box-shadow: inset 11px 10px 50px -33px rgba(0, 0, 0, 0.13);
	width: max-content;
	div {
		border: unset;
		min-height: 2.5rem;
		width: max-content;
		color: #2f2e41;
		font-size: 1rem;
		font-weight: 600;
		width: 19rem;
	}
	svg {
		margin-top: 2px;
		font-size: 1.2rem;
	}
`
// const BackBtn = styled(RaisedButton)`
// 	width: 14.625rem;
// 	height: 3.25rem;
// 	font-weight: 600;
// 	font-size: 1.125rem;
// 	border-radius: 26px;
// 	@media (max-width: 610px) {
// 		width: 8.375rem;
// 		height: 2.288rem;
// 		font-weight: 600;
// 		font-size: 0.75rem;
// 		border-radius: 26px;
// 	}
// `
const RetakeBtn = styled(RaisedButton)`
	width: 9.875rem;
	height: 3.25rem;
	font-weight: 600;
	font-size: 1.125rem;
	border-radius: 26px;
	@media (max-width: 610px) {
		width: 8.375rem;
		height: 2.288rem;
		font-weight: 600;
		font-size: 0.75rem;
	}
`
const StatusImg = styled.img`
	width: 174px !important;
	object-fit: contain;
	@media (max-width: 768px) {
		width: 140.6px !important;
	}
	@media (max-width: 500px) {
		width: 115px !important;
	}
`
const AttemptText = styled.p`
	font-size: 1.75rem;
	font-weight: 600;
	@media (max-width: 610px) {
		font-size: 0.75rem;
		margin-bottom: 0.75rem;
	}
`
export default EndPage
