import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

type CategoryProps = {
	icon: React.ReactNode
	name: string
	displayName?: string
}

const Category: React.FC<CategoryProps> = ({ icon, name, displayName }) => {
	if (!displayName) displayName = name

	const history = useNavigate()

	// TODO: set category page route
	const onClickHandler = () => {
		history(`/results?query=${name}`)
	}

	return (
		<StyledCategory onClick={onClickHandler}>
			{icon}
			<CategoryName>{displayName}</CategoryName>
		</StyledCategory>
	)
}

const StyledCategory = styled.div`
	display: flex;
	align-items: baseline;
	margin: 1rem 0;
	svg {
		margin: 0 1rem;
		${({ theme }) => `${theme.mediaQueries.small}{
			margin: 0 0.5rem;
			width : 20px;
			height : 20px;
		}`}
	}
	&:hover {
		cursor: pointer;
	}
`

const CategoryName = styled.p`
	font-weight: 500;
	color: black;
	font-size: 1rem;
	@media (min-width: 768px) {
		font-size: 22px;
	}
`

export default Category
