import React, { useRef } from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled, { css } from 'styled-components'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io'
import { FlexItem, FlexLayout, GridLayout } from '@eduact/ed-system'
import { NavIcon } from '../Swiper/Swiper.styled'
type ItemProps = {
	label: string
}

const SwiperItemWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
const SwiperItem: React.FC<ItemProps> = ({ children }) => <SwiperItemWrapper className="SliderItem">{children}</SwiperItemWrapper>

interface SwiperComposition {
	Item: React.FC<ItemProps>
}
type Props = {
	perSlide?: number
	children: React.ReactElement<ItemProps>[]
}
const AutoPlaySwiper: React.FC<Props> & SwiperComposition = ({ children, perSlide = 4 }) => {
	const swiperRef = useRef<Slider | null>(null)
	const settings: Settings = {
		cssEase: 'linear',
		// autoplay: true,
		autoplaySpeed: 4000,
		slidesToShow: 7,
		slidesToScroll: 7,
		arrows: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
		],
	}
	const handleNextSlide = () => {
		document.documentElement.dir === 'rtl' ? swiperRef.current?.slickPrev() : swiperRef.current?.slickNext()
	}
	const handlePrevSlide = () => {
		document.documentElement.dir !== 'rtl' ? swiperRef.current?.slickPrev() : swiperRef.current?.slickNext()
	}
	return (
		<StyledGrid gridTemplateColumns={{ sm: '5% 80% 5%', lg: '5% 90% 5%' }} alignItems="center">
			<NavIcon onClick={handlePrevSlide} color="purple" show top={'unset'}>
				<IoIosArrowDropleftCircle />
			</NavIcon>
			<Slider ref={swiperRef} {...settings}>
				{children}
			</Slider>
			<NavIcon onClick={handleNextSlide} color="purple" show top={'unset'}>
				<IoIosArrowDroprightCircle />
			</NavIcon>
		</StyledGrid>
	)
}

AutoPlaySwiper.Item = SwiperItem

export default AutoPlaySwiper

const StyledGrid = styled(GridLayout).attrs(prop => ({ dir: document.documentElement.dir }))`
	&&[dir='rtl'] {
		svg {
			transform: scale(-1, 1);
		}
	}
`
