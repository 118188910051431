import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {
	ARMainLocale,
	AR_Dashboard,
	AR_Dropdowns,
	AR_EduactionalInfo,
	AR_FAQs,
	AR_Inputs,
	AR_LandingPage,
	AR_Layouts,
	AR_Login,
	AR_Search,
	AR_Instructor,
	AR_Webinar,
	AR_PaymentFees,
	AR_Generic,
	AR_Signup,
	AR_Classroom,
	AR_Wallet,
	AR_CourseOverview,
	AR_Payment,
	AR_Test,
	AR_Note,
	AR_ClassroomsOverview,
	AR_ClassroomTests,
	AR_About,
	AR_StudentDashboard,
	AR_Settings,
	AR_Assignment,
} from './ar'
import {
	ENMainLocale,
	EN_Dashboard,
	EN_Dropdowns,
	EN_EduactionalInfo,
	EN_FAQs,
	EN_Inputs,
	EN_LandingPage,
	EN_Layouts,
	EN_Login,
	EN_Search,
	EN_Instructor,
	EN_webinar,
	EN_PaymentFees,
	EN_Generic,
	EN_Signup,
	EN_Classroom,
	EN_Wallet,
	EN_CourseOverview,
	EN_Payment,
	EN_Test,
	EN_Note,
	EN_ClassroomsOverview,
	EN_ClassroomTests,
	EN_About,
	EN_StudentDashboard,
	EN_Settings,
	EN_Assignment,
} from './en'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
import 'moment/locale/ar'
import 'moment/locale/en-gb'
const resources = {
	en: {
		translation: ENMainLocale,
		educational_info: EN_EduactionalInfo,
		landingPage: EN_LandingPage,
		layouts: EN_Layouts,
		login: EN_Login,
		inputs: EN_Inputs,
		dashboard: EN_Dashboard,
		dropdowns: EN_Dropdowns,
		FAQs: EN_FAQs,
		search: EN_Search,
		instructor: EN_Instructor,
		webinar: EN_webinar,
		fees: EN_PaymentFees,
		signup: EN_Signup,
		generic: EN_Generic,
		classroom: EN_Classroom,
		wallet: EN_Wallet,
		course: EN_CourseOverview,
		payment: EN_Payment,
		test: EN_Test,
		note: EN_Note,
		classroomsOverview: EN_ClassroomsOverview,
		classroomTests: EN_ClassroomTests,
		about: EN_About,
		studentDashboard: EN_StudentDashboard,
		settings: EN_Settings,
		assignment: EN_Assignment,
	},
	ar: {
		translation: ARMainLocale,
		educational_info: AR_EduactionalInfo,
		landingPage: AR_LandingPage,
		layouts: AR_Layouts,
		login: AR_Login,
		inputs: AR_Inputs,
		dashboard: AR_Dashboard,
		dropdowns: AR_Dropdowns,
		FAQs: AR_FAQs,
		search: AR_Search,
		instructor: AR_Instructor,
		webinar: AR_Webinar,
		fees: AR_PaymentFees,
		signup: AR_Signup,
		generic: AR_Generic,
		classroom: AR_Classroom,
		wallet: AR_Wallet,
		course: AR_CourseOverview,
		payment: AR_Payment,
		test: AR_Test,
		note: AR_Note,
		classroomsOverview: AR_ClassroomsOverview,
		classroomTests: AR_ClassroomTests,
		about: AR_About,
		studentDashboard: AR_StudentDashboard,
		settings: AR_Settings,
		assignment: AR_Assignment,
	},
}

i18n.on('languageChanged', lng => {
	localStorage.setItem('lang', lng)
	if (lng === 'ar-EG') {
		document.body.dir = 'rtl'
		document.documentElement.lang = 'ar-EG'
		moment.locale('ar')
		document.dir = 'rtl'
	} else {
		document.documentElement.lang = 'en-GB'
		moment.locale('en')
		document.body.dir = ''
		document.dir = ''
	}
})
i18n.on('initialized', function (options) {
	if (localStorage.getItem('lang') === 'ar-EG') {
		moment.locale('ar')
		document.documentElement.lang = 'ar-EG'
		document.body.dir = 'rtl'
	} else {
		moment.locale('en')
		document.documentElement.lang = 'en-GB'
	}
})
i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: {
			lookupLocalStorage: 'lang',
		},
		resources: resources,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: true,
		},
		editor: {
			autoOpen: true,
			enabled: true,
			mode: 'iframe',
		},
	})

export default i18n
