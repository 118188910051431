import { FlexLayout } from '@eduact/ed-system'
import { useStudentScreenSize } from '@eduact/student-theme'
import { useGetClassroomsAnalyticsQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Title, Wrapper } from '../ClassroomsWidget/ClassroomsWidget'
import enrolled from './assets/enroll.png'
import courses from './assets/courses.png'
import tests from './assets/tests.png'
import videos from './assets/videos.png'
import documents from './assets/documents.png'

const AnalyticsWidget = () => {
	const { isDesktop } = useStudentScreenSize()
	const [t] = useTranslation('classroomsOverview')
	const { currentData } = useGetClassroomsAnalyticsQuery()

	return (
		<Wrapper>
			<Title>{t('analytics')}</Title>
			<WrapperAnalytics flexDirection="column" justifyContent="space-between">
				<Enrolled alignItems="center" flexDirection="column">
					<Icon src={enrolled} alt="enrolled classrooms" size={isDesktop ? 5.188 : 3.019} />
					<Count fontSize={isDesktop ? 2.125 : 1.375} color="#5ac0fc">
						{currentData?.data?.classrooms}
					</Count>
					<Text fontSize={isDesktop ? 1.125 : 0.8}>{t('enrolled')}</Text>
				</Enrolled>
				<CompletedCourse alignItems="center" flexDirection="column">
					<Icon src={courses} alt="completed courses" size={isDesktop ? 4.438 : 2.581} />
					<Count fontSize={isDesktop ? 2 : 1.25} color="#6C63FF">
						{currentData?.data?.courses}
					</Count>
					<Text fontSize={isDesktop ? 1 : 0.75}>{t('completedCourse')}</Text>
				</CompletedCourse>
				<FlexLayout margin="auto" justifyContent="space-between" width="100%">
					<WatchedUnits alignItems="center" flexDirection="column">
						<Icon src={tests} alt="tests taken" size={isDesktop ? 3.588 : 2.081} />
						<Count fontSize={isDesktop ? 1.5 : 1} color="#FF8532">
							{currentData?.data?.tests}
						</Count>
						<Text fontSize={isDesktop ? 0.875 : 0.625}>{t('testsTaken')}</Text>
					</WatchedUnits>
					<WatchedUnits alignItems="center" flexDirection="column">
						<Icon src={videos} alt="videos watched" size={isDesktop ? 3.588 : 2.081} />
						<Count fontSize={isDesktop ? 1.5 : 1} color="#FF8532">
							{currentData?.data?.videos}
						</Count>
						<Text fontSize={isDesktop ? 0.875 : 0.625}>{t('videosWatched')}</Text>
					</WatchedUnits>
					<WatchedUnits alignItems="center" flexDirection="column">
						<Icon src={documents} alt="documents read" size={isDesktop ? 3.588 : 2.081} />
						<Count fontSize={isDesktop ? 1.5 : 1} color="#FF8532">
							{currentData?.data?.documents}
						</Count>
						<Text fontSize={isDesktop ? 0.875 : 0.625}>{t('documentsRead')}</Text>
					</WatchedUnits>
				</FlexLayout>
			</WrapperAnalytics>
		</Wrapper>
	)
}

const WrapperAnalytics = styled(FlexLayout)`
	margin-top: 0.613rem;
	gap: 2.875rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    gap: 5.438rem;
    margin-top: 1.25rem;
	}`}
`
const Enrolled = styled(FlexLayout)`
	gap: 0.5rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    gap: 0.75rem;
	}`}
`
const CompletedCourse = styled(FlexLayout)`
	gap: 0.5rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    gap: 0.75rem;
	}`}
`
const WatchedUnits = styled(FlexLayout)`
	gap: 0.5rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    gap: 0.75rem;
	}`}
`
const Icon = styled.img<{ size: number }>`
	width: ${({ size }) => `${size}rem`};
	height: ${({ size }) => `${size}rem`};
	object-fit: contain;
	${({ theme, size }) => `${theme.mediaQueries.large}{
    width: ${(size: number) => `${size}rem`};
	  height: ${(size: number) => `${size}rem`};
	}`}
`
const Count = styled.p<{ color: string; fontSize: number }>`
	color: ${({ color }) => color};
	font-weight: 600;
	font-size: ${({ fontSize }) => `${fontSize}rem`};
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: ${(fontSize: number) => `${fontSize}rem`};
	}`}
`
const Text = styled.p<{ fontSize: number }>`
	color: #2f2e41;
	font-size: ${({ fontSize }) => `${fontSize}rem`};
	font-weight: 600;
	margin: auto;
	text-align: center;
	line-height: 1.6;
	max-width: 80px;
	white-space: pre-wrap;
	text-overflow: ellipsis;
	${({ theme, fontSize }) => `${theme.mediaQueries.large}{
    font-size: ${(fontSize: number) => `${fontSize}rem`};
    line-height: 1.3;
    max-width: fit-content;
	}`}
`

export default AnalyticsWidget
