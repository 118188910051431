import routes from '@Constants/Routes'
import { Icon, Icons } from '@eduact/ed-components'
import { FlexLayout, Typography } from '@eduact/ed-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'

type Props = {} & SpaceProps
const ViewAllButton: React.VoidFunctionComponent<Props> = props => {
	const navigate = useNavigate()
	const [t] = useTranslation(['landingPage'])
	return (
		<StyledViewAll
			{...props}
			onClick={() => navigate({ pathname: routes.results, search: 'query=' })}
			alignItems={'center'}
			justifyContent={'center'}
			gridGap={'0.2rem'}
		>
			<Typography fontSize={{ lg: '1.125rem' }} fontWeight={'600'} textDecoration={'underline'}>
				{t('view_all')}
			</Typography>
			<Icon color="dark" size="1.5rem">
				<Icons.RightArrow />
			</Icon>
		</StyledViewAll>
	)
}

export default ViewAllButton

const StyledViewAll = styled(FlexLayout).attrs(p => ({ dir: document.documentElement.dir }))`
	cursor: pointer;
	&[dir='rtl'] {
		svg {
			transform: scale(-1, 1);
		}
	}
	${({ theme }) => `${theme.mediaQueries.large}{
		// padding : 0 3.375rem;
	}`}
`
