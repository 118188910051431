import CustomTextInput from '@Components/InputFields/CustomTextInput/CustomTextInput'
import { Card, Icon, Icons, RaisedButton } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout, Typography } from '@eduact/ed-system'
import { useLazyChangePasswordQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { useProfileInfoPanel } from '@Features/Dashboard/Profile/ProfileInfoPanelContext'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { object, ref, string } from 'yup'

const Schema = object({
	old_password: string().required('_field_required'),
	new_password: string()
		.matches(/(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{8,})/, { message: 'invalid_pattern' })
		.required('_field_required'),
	confirm_new_password: string()
		.oneOf([ref('new_password'), null], 'invalid_password')
		.required('_field_required'),
})

type ResetPasswordFormProps = {
	onSave?: (data: ChangePasswordPayload) => boolean
}
const ResetPasswordForm: React.VoidFunctionComponent<ResetPasswordFormProps> = () => {
	const [changePassword] = useLazyChangePasswordQuery()
	const { setCurrentInfo, setPasswordModal } = useProfileInfoPanel()
	const {
		control,
		formState: { isDirty, isValid, isSubmitting },
		handleSubmit,
	} = useForm<ChangePasswordPayload>({
		mode: 'all',
		resolver: yupResolver(Schema),
	})

	const onSave = async (formData: ChangePasswordPayload) => {
		const { isSuccess } = await changePassword(formData)
		if (isSuccess) {
			setCurrentInfo('card')
			setPasswordModal(false)
		}
	}

	const handleCancelResetPassword = () => {
		setCurrentInfo('card')
	}
	return (
		<div>

			<FlexLayout flexDirection={'column'}>
				<Typography fontFamily={'Roboto'} color="#757575" textAlign={'center'} fontWeight={500} fontSize={{ sm: 14, md: 20 }}>
					Change Password
				</Typography>
				<Spacer mb={{ sm: '1.875rem' }} />
				<CustomTextInput placeholder="Old password" type="password" control={control} name="old_password" />
				<CustomTextInput placeholder="New password" type="password" control={control} name="new_password" />
				<CustomTextInput placeholder="Confirm new password" type="password" control={control} name="confirm_new_password" />
			</FlexLayout>
			<Spacer mb={{ sm: '3.313rem' }} />
			<RaisedButton
				onClick={handleSubmit(onSave, e => console.log(e))}
				mb={{ lg: '1.563rem' }}
				disabled={!isDirty || !isValid || isSubmitting}
				width={'100%'}
				btnSize={{ sm: 'medium' }}
			>
				Save
			</RaisedButton>
		</div>
	)
}

export default ResetPasswordForm
