import api from '@Api/Api'
import {
	LoginRequestPayload,
	LoginResponsePayload,
	SignupRequestPayload,
	SignupResponsePayload,
	ResendEmailRequestPayload,
	ResendEmailResponsePayload,
	VerifyEmailRequestPayload,
	VerifyEmailResponsePayload,
	EducationalInfoResponsePayload,
	CompleteProfileRequestPayload,
	VerifyPhoneRequestPayload,
} from '@Features/Authentication/AuthenticationApi/Authentication.api.types'

export default class AuthenticationApi {
	private static endpoint: string = '/auth'

	public static login(requestPayload: LoginRequestPayload) {
		return api.post<LoginResponsePayload>(`${this.endpoint}/login`, requestPayload)
	}

	public static signup(requestPayload: SignupRequestPayload) {
		return api.post<SignupResponsePayload>(`${this.endpoint}/signup`, requestPayload)
	}

	public static resendEmail(requestPayload: ResendEmailRequestPayload) {
		return api.post<ResendEmailResponsePayload>(`${this.endpoint}/email/verification/resend`, requestPayload)
	}

	public static verifyEmail(requestPayload: VerifyEmailRequestPayload) {
		return api.post<VerifyEmailResponsePayload>(`${this.endpoint}/email/confirm`, requestPayload)
	}
	public static verifyPhone(requestPayload: VerifyPhoneRequestPayload) {
		return api.post<VerifyEmailResponsePayload>(`${this.endpoint}/phone/confirm`, requestPayload)
	}

	public static getEducationalInfo() {
		return api.get<EducationalInfoResponsePayload>('/education/info')
	}

	public static completeProfile(requestPayload: CompleteProfileRequestPayload) {
		return api.put<ApiResponse<null>>('/student/profile/complete', requestPayload)
	}
}
