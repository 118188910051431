import React, { FC } from 'react'
import Pie from './Pie'

type Props = {
	overallScore?: number
	color: string
}

const Brogressbar: FC<Props> = ({ overallScore, color }) => {
	return <Pie color={color} percentage={overallScore as number} />
}

export default Brogressbar
