import InvoiceCard from '@Components/Cards/InvoiceCard'
import routes from '@Constants/Routes'
import { BaseWidget, useWidget } from '@eduact/ed-components'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useGetInvoicesQuery } from '../../ActivityApi/ActivityApi'

export const InvoicesWidget = () => {
	const { data } = useGetInvoicesQuery({
		page: 1,
		perPage: 8,
	})
	const { setAction, setTitle } = useWidget()
	useEffect(() => {
		setTitle('Invoices')
		setAction(() => <Anchor to={`${routes.dashboard}/activity/invoices`}>View All</Anchor>)
	}, [])
	return (
		<InvoicesGrid>
			{data?.data.map((iv, index) => {
				return (
					<InvoiceCard
						key={`${iv.id}-${index}`}
						amount={iv.transaction.amount.toString()}
						date={iv.created_at.toString()}
						invoiceReferenceNumber={iv.invoice_ref}
						provider={iv.transaction.provider}
						providerReference={iv.transaction.provider_ref}
						status={iv.status}
						type={iv.type}
					/>
				)
			})}
		</InvoicesGrid>
	)
}

const InvoicesGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 2.875rem;
	row-gap: 2rem;
	border-radius: inherit;
	justify-content: center;
	padding: 1rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		justify-content: initial;
	}`}
`

const Anchor = styled(Link)`
	font-size: 0.563rem;
	font-weight: 500;
	color: #2f2e41;
	@media screen and (min-width: 769px) {
		font-size: 0.875rem;
	}
`
