import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { store } from '@Redux/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import GlobalStyles from '@Styles/GlobalStyles'
import { BrowserRouter, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
// import { createBrowserHistory } from 'history'
import i18n from '@Locale/index'
import { I18nextProvider } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryFallback from '@Components/ErrorBoundaryFallback/ErrorBoundaryFallback'
import { Reset } from 'styled-reset'
// const history = createBrowserHistory()
ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<BrowserRouter>
				<Provider store={store}>
					<App />
				</Provider>
			</BrowserRouter>
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register({
	onSuccess: ({ showNotification }) => {
		showNotification('Test', { body: '' })
	},
})
