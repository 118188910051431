import React from 'react'
import styled from 'styled-components'
import Icon from '@Features/LandingPage/GuestLanding/Assets/FindClassroomImage.svg'
import { useTranslation } from 'react-i18next'
import { FlexLayout } from '@eduact/ed-system'

const FindClassroomSection: React.FC = () => {
	const [t] = useTranslation('landingPage', { keyPrefix: 'courses' })
	return (
		<StyledFndClassroomSection>
			<StyledIcon src={Icon} alt="find your classroom icon" />
			<FlexLayout flexDirection={'column'} alignItems={{ lg: 'flex-start' }}>
				<StyledInfoHeader>{t('find_classroom')}</StyledInfoHeader>
				<StyledInfo flexDirection={'column'} alignItems={{ lg: 'flex-start' }}>
					<StyledInfoSpan>{t('what_classroom')}</StyledInfoSpan>
					<div>{t('classroom_def1')}</div>
					<div>{t('classroom_def2')}</div>
					<div>{t('classroom_def3')}</div>
				</StyledInfo>
			</FlexLayout>
		</StyledFndClassroomSection>
	)
}

const StyledFndClassroomSection = styled.div`
	display: flex;
	justify-content: center;
	gap: 70px;
	align-items: center;
	flex-direction: column;
	align-items: center;
	margin: 2rem 0;

	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 2.75rem 0;
		flex-direction: row;
		text-align: center;

	}`};
`

const StyledIcon = styled.img.attrs(props => ({ dir: document.documentElement.dir }))`
	max-width: 210px;
	display: none;
	&[dir='rtl'] {
		transform: scale(-1, 1);
	}
	${({ theme }) => `${theme.mediaQueries.large}{
		display: block;
	}`}
`

const StyledInfoHeader = styled.h3`
	line-height: 29px;
	font-size: 1.375rem;
	font-size: 1rem;
	margin-bottom: 26px;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size :1.5rem;
	}`}
`

const StyledInfo = styled(FlexLayout)`
	max-width: 540px;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.14px;
	color: #0c0c0c;
`

const StyledInfoSpan = styled.span`
	display: block;
	color: #b5b1ff;
	font-size: 14px;
	font-weight: bolder;
	margin-bottom: 4px;
`

export default FindClassroomSection
