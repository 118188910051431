import Wallet from '@Components/Wallet/Wallet'
import routes from '@Constants/Routes'
import { Avatar, Drawer, IconButton } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout } from '@eduact/ed-system'
import { useAppSelector } from '@Hooks/ReduxHooks'
import CustomLink from '@Styles/CustomLink'
import React, { useEffect, useState } from 'react'
import { MdOutlineMenu } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

type Props = {
	drawerOpen: boolean
	setDrawerOpen: React.Dispatch<boolean>
	handleLogout: () => void
}
const EdDrawer: React.FC<Props> = ({ drawerOpen, setDrawerOpen, handleLogout }) => {
	const { user, isAuth } = useAppSelector(state => state.auth)

	const handleNavigate = () => {
		setDrawerOpen(false)
	}
	const { app_wallet } = useAppSelector(s => s.settings)
	return (
		<Drawer overflow={'auto'} onClose={() => setDrawerOpen(false)} variant="dark" open={drawerOpen} drawerPosition="right" width={'70vw'}>
			<FlexLayout flexDirection={'column'} p={'2rem'}>
				<FlexLayout width={'100%'} justifyContent={'flex-end'}>
					<IconButton onClick={() => setDrawerOpen(false)} padding={'0'} variant="light" icon={<MdOutlineMenu size={'1.5rem'} />} />
				</FlexLayout>
				<FlexLayout flexDirection={'column'}>
					{isAuth && (
						<>
							<DrawerContainer flexDirection={'column'}>
								<DrawerLink color="light" to={'/dashboard'}>
									<FlexLayout alignItems={'center'}>
										<Avatar img={user?.profile_photo} avatarSize="medium" />
										<Spacer mx={6} />
										<span>{user?.username}</span>
									</FlexLayout>
								</DrawerLink>
								<Spacer mb={'1rem'} />

								<DrawerItem onClick={handleLogout}>Logout</DrawerItem>
								<Spacer mb={'1.25rem'} />
							</DrawerContainer>
							{app_wallet && (
								<div>
									<Wallet onFinish={() => setDrawerOpen(false)} withCloseAction={false} />
								</div>
							)}
						</>
					)}
					{!isAuth && (
						<DrawerContainer flexDirection={'column'}>
							<DrawerLink onClick={handleNavigate} to={routes.login}>
								Login
							</DrawerLink>
							<Spacer mb={'1.25rem'} />
							<DrawerLink onClick={handleNavigate} to={routes.signup}>
								Sign up
							</DrawerLink>
							<Spacer mb={'1.25rem'} />
						</DrawerContainer>
					)}
				</FlexLayout>
			</FlexLayout>
		</Drawer>
	)
}

export default EdDrawer

const DrawerContainer = styled(FlexLayout)`
	position: relative;
	color: #fff;
`

const DrawerLink = styled(CustomLink)`
	font-size: 0.875rem;
	font-family: 'Montserrat';
	text-decoration: none;
	font-weight: 500;
`
const DrawerItem = styled.a`
	font-size: 0.875rem;
	cursor: pointer;
	font-family: 'Montserrat';
	text-decoration: none;
	font-weight: 500;
`
