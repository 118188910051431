import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import Spacer from '@eduact/ed-components/Spacer'
import { Box, FlexLayout, Typography } from '@eduact/ed-system'
import { DateFormatter } from '@eduact/utils'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
	assignment: AssignmentUnit
	onEditClick: () => void
	isEdit?: boolean
	submission: AssignmentSubmission
	canSubmit: boolean
}
const AssignmentContent: React.VoidFunctionComponent<Props> = ({ assignment, onEditClick, isEdit = true, submission, canSubmit }) => {
	const hasStatus = !!submission?.status
	const { t } = useTranslation('assignment')
	return (
		<>
			<FlexLayout alignItems={'baseline'} mb={{ lg: '1.438rem' }}>
				<Typography fontSize={{ lg: 26 }} fontWeight={'bold'}>
					{assignment.unit.name}
				</Typography>
				<Spacer mx={{ lg: '1rem' }} />
				<EdTypography color={!!submission?.score && canSubmit ? 'dark' : 'princetonOrange'} fontWeight={600}>
					{t('deadline')}: {moment(assignment.created_at).add('d', assignment.duration).format('DD/MM/yyyy')}
				</EdTypography>
			</FlexLayout>
			<AssignmentContainer>
				<FlexLayout flex={'1'} px="3.563rem" pt={'2.938rem'}>
					{assignment.question_type === 'text' && <div dangerouslySetInnerHTML={{ __html: assignment.question }}></div>}
					{assignment.question_type === 'attachment' && (
						<AssignmentIframe title="title" width={'100%'} height="100%" src={assignment.question}></AssignmentIframe>
					)}
				</FlexLayout>
				{!hasStatus && submission && !isEdit && canSubmit && (
					<SubmittedHeader justifyContent={'space-between'} alignItems="center">
						<Typography fontWeight={500}>{t('for_grade')}</Typography>
						<Typography>
							{t('submitted')}: {moment(submission?.updated_at).format('DD/MM/yyyy, hh:mm A')}
						</Typography>
						{canSubmit && (
							<EditButton onClick={onEditClick} fontSize={{ lg: '1.25rem' }} fontWeight={600} color="purple">
								{t('edit')}
							</EditButton>
						)}
						{!canSubmit && <Typography fontWeight={500}>{t('no_submit')}</Typography>}
					</SubmittedHeader>
				)}
				{hasStatus && (
					<SubmittedHeader>
						<Typography fontSize={'1.125rem'} fontWeight={600}>
							{t('score')}: {submission.score}/{assignment.overall_score}
						</Typography>
						<Typography>
							{t('submitted')}: {moment(submission?.updated_at).format('DD/MM/yyyy, hh:mm A')}
						</Typography>
						<Typography fontSize={'1.125rem'} fontWeight="600">
							{t('status')}:{' '}
							<Typography
								fontSize={'1.5rem'}
								fontWeight="bold"
								textTransform={'capitalize'}
								color={submission.status === 'passed' ? '#2fdd86' : 'red'}
							>
								{submission.status && t(submission.status)}
							</Typography>
						</Typography>
					</SubmittedHeader>
				)}
			</AssignmentContainer>
			<Spacer mb={{ lg: '1rem' }} />
		</>
	)
}

export default AssignmentContent

const AssignmentIframe = styled.iframe`
	min-height: 500px;
`

const AssignmentContainer = styled.div`
	max-height: 35.563rem;
	min-height: 35.563rem;
	border-radius: 15px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: ${props => props.theme.colors.light};
	overflow: auto;
	display: flex;
	flex-direction: column;
`

const SubmittedHeader = styled(FlexLayout)`
	position: sticky;
	bottom: 0;
	background: #e4e4e4;
	padding: 1.563rem;
	justify-content: space-between;
	align-items: center;
`
const EditButton = styled(EdTypography)`
	cursor: pointer;
`
