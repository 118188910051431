import React, { FC } from 'react'
import Pie from './Pie'

type Props = {
	score?: number | undefined
	progressData?: Test | undefined
	overallScore?: number
}

const ProgressBar: FC<Props> = ({ progressData, score, overallScore }) => {
	const progressScore = score && overallScore ? (score / overallScore) * 100 : 0

	return (
		<Pie
			overallScore={overallScore}
			percentage={progressScore}
			colour={progressScore === 0 ? '' : '#6C63FF'}
			score={score}
			data={progressData}
		/>
	)
}

export default ProgressBar
