import { useStudentScreenSize } from '@eduact/student-theme'
import React, { FC } from 'react'
import styled from 'styled-components'

type TextProps = {
	overallScore: number
	colour: string
}

const Text: FC<TextProps> = ({ overallScore, colour }) => {
	const { isDesktop } = useStudentScreenSize()
	return (
		<Grade color={colour} x={isDesktop ? '55%' : '58%'} y={isDesktop ? '55%' : '25%'} dominantBaseline="central" textAnchor="middle">
			{isNaN(overallScore) ? '0' : overallScore.toFixed(0)}%
		</Grade>
	)
}

const Grade = styled.text<{ color: string }>`
	fill: ${({ color }) => color};
	font-size: 1.563rem;
	font-weight: 600;
	letter-spacing: 2px;
	margin: auto;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 2.2rem;
	}`}
`

export default Text
