import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import Spacer from '@eduact/ed-components/Spacer'
import React from 'react'
import styled from 'styled-components'
import AttendanceCount from './Components/AttendanceCount/AttendanceCount'
import AttendanceTable from './Components/AttendanceTable/AttendanceTable'

const Attendance = () => {
	return (
		<PageWrapper>
			<EdTypography as="h1" fontWeight={600} fontSize={{ sm: '0.75rem', lg: '1.125rem' }}>
				Attendance
			</EdTypography>
			<Spacer mb={{ sm: '0.5rem', lg: '1.625rem' }} />
			<Spacer mx={{ lg: '1rem' }}>
				<AttendanceCount />
				<Spacer mb={{ sm: '1.125rem', lg: '1.875rem' }} />
				<AttendanceTable />
			</Spacer>
		</PageWrapper>
	)
}

export default Attendance

const PageWrapper = styled.section`
	padding: 0.563rem;
	border-radius: 15px;
	width: 100%;
	height: 100%;
	background: ${props => props.theme.colors.light};
	${({ theme }) => `${theme.mediaQueries.large}{
		padding : 1.375rem 1.625rem;
	}`}
`
