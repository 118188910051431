import { useStudentScreenSize } from '@eduact/student-theme'
import React, { FC } from 'react'

type CircleProps = {
	colour?: string
	pct?: number
}

const Circle: FC<CircleProps> = ({ colour, pct }) => {
	const { isDesktop } = useStudentScreenSize()
	const r = isDesktop ? 70 : 60
	const circ = 2 * Math.PI * r
	const strokePct = pct && ((100 - pct) * circ) / 100

	return (
		<circle
			r={r}
			cx={isDesktop ? 10 : 60}
			cy={isDesktop ? 55 : 60}
			fill="transparent"
			stroke={strokePct === circ ? '' : colour}
			strokeWidth={'0.5rem'}
			strokeDasharray={circ}
			strokeDashoffset={pct ? strokePct : 0}
			strokeLinecap="round"
		/>
	)
}

export default Circle
