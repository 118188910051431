import AnimatedStudentCard from '@Components/Cards/AnimatedStudentCard/AnimatedStudentCard'
import { StudentCard } from '@Components/Cards/StudentCard'
import StudentQRCard from '@Components/Cards/StudentQRCard/StudentQRCard'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { Dialog, RaisedButton } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { Box, FlexLayout } from '@eduact/ed-system'
import { useStudentScreenSize } from '@eduact/student-theme'
import html2canvas from 'html2canvas'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ProfileStudentCard = () => {
	const [t] = useTranslation('studentDashboard')
	const [modelOpen, setModalOpened] = useState(false)
	const [isFlipped, setIsFlipped] = useState(false)
	const [scanModalOpen, setScanModalOpen] = useState(false)

	const handleCloseViewModal = () => {
		setModalOpened(false)
	}
	const handleCloseScanModal = () => {
		setScanModalOpen(false)
	}

	const downloadRef = useRef<HTMLDivElement | null>(null)

	const handleCardDownload = useCallback(() => {
		if (isFlipped || !downloadRef.current) return
		html2canvas(downloadRef.current).then(canvas => {
			var downloadLink = document.createElement('a')
			downloadLink.href = canvas.toDataURL('image/jpeg')
			downloadLink.download = 'Student_Card.png'
			downloadLink.click()
		})
	}, [downloadRef])
	const { isMobile, isDesktop } = useStudentScreenSize()
	return (
		<Box>
			<Dialog
				width={{ lg: 'unset' }}
				p={{ lg: '2rem' }}
				minWidth={{ sm: '90vw', lg: '40vw' }}
				minHeight={{ sm: '90vh', lg: 'fit-content' }}
				height={{ sm: '90vh', lg: 'fit-content' }}
				open={modelOpen}
				onClose={handleCloseViewModal}
			>
				<FlexLayout
					height={'100%'}
					width={'100%'}
					alignItems={'center'}
					// justifyContent={'flex-end'}
					justifyContent={'center'}
					flexDirection="column"
					gridGap={{ lg: '3.625rem' }}
				>
					{isMobile && <CardSpacer my={'4rem'} />}
					<RotatedCard>
						{isMobile && <StudentCard scale={0.9} />}
						{!isMobile && <AnimatedStudentCard bodyProps={{}} width={'fit-content'} customScale={0.9} scale={1.4} />}
					</RotatedCard>
					{isMobile && <CardSpacer my={'4rem'} />}
					<RaisedButton mb={{ sm: '2rem' }} onClick={handleCloseViewModal} btnSize="small" variant="princetonOrange">
						Close
					</RaisedButton>
				</FlexLayout>
			</Dialog>
			<Dialog
				width={{ lg: 'unset' }}
				p={{ lg: '2rem' }}
				minWidth={{ sm: '90vw', lg: '40vw' }}
				minHeight={{ sm: '90vh', lg: 'fit-content' }}
				height={{ sm: '90vh', lg: 'fit-content' }}
				onClose={handleCloseScanModal}
				open={scanModalOpen}
			>
				<FlexLayout
					height={'100%'}
					width={'100%'}
					alignItems={'center'}
					// justifyContent={'flex-end'}
					justifyContent={'center'}
					flexDirection="column"
					gridGap={{ lg: '3.625rem' }}
				>
					{isMobile && <CardSpacer my={'4rem'} />}

					<RotatedCard>
						<StudentQRCard width={'fit-content'} scale={isMobile ? 1 : 1.4} />
					</RotatedCard>
					{isMobile && <CardSpacer my={'4rem'} />}
					<RaisedButton mb={'2rem'} onClick={handleCloseScanModal} btnSize="small" variant="princetonOrange">
						Close
					</RaisedButton>
				</FlexLayout>
			</Dialog>
			<AnimatedStudentCard
				qrCodeSize={isMobile ? 100 : undefined}
				studentIDRef={downloadRef}
				onFlip={setIsFlipped}
				scale={isMobile ? 0.5 : 1}
				bodyProps={{ pb: isMobile ? '2rem' : '' }}
			/>
			<Spacer mb={{ lg: 3 }} />
			<FlexLayout justifyContent={'space-between'} mt={{ sm: '2rem', lg: 'unset' }}>
				<FlexLayout gridGap={{ sm: '4rem', lg: '2rem' }}>
					<CardAction onClick={() => setModalOpened(true)} color="primary">
						{t('view')}
					</CardAction>
					<CardAction disabled={isFlipped} onClick={handleCardDownload} color="purple">
						{t('download')}
					</CardAction>
				</FlexLayout>
				<Box>
					<CardAction onClick={() => setScanModalOpen(true)} color="princetonOrange">
						{t('scan')}
					</CardAction>
				</Box>
			</FlexLayout>
		</Box>
	)
}

export default ProfileStudentCard

const CardAction = styled(EdTypography)<{ disabled: boolean }>`
	text-decoration: underline;
	font-weight: 600;
	cursor: pointer;
	pointer-events: ${props => props.disabled && 'none'};
	opacity: ${props => props.disabled && '0.5'};
`

const RotatedCard = styled.div`
	transform: rotate(90deg);
	${({ theme }) => `${theme.mediaQueries.large}{
	transform: unset;
}`}

	@media screen and (orientation:landscape) {
		transform: unset;
	}
`

const CardSpacer = styled(Spacer)`
	@media screen and (orientation: landscape) {
		margin: unset;
	}
`
