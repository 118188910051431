import React from 'react'
import styled from 'styled-components'

type Props = {
	icon: any
	title: string
	body: string
}

const GuestCard: React.FC<Props> = ({ icon, title, body }) => {
	return (
		<StyledGuestCard>
			<GuestCardImage src={icon} alt="online classroom image" />
			<CardTitle>{title}</CardTitle>
			<CardText>{body}</CardText>
		</StyledGuestCard>
	)
}

const StyledGuestCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	padding: 1.25rem 1.563rem;
	height: 16rem;
	max-width: 20.438rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 2.125rem;
		height: 23.938rem;

	}`}
`

const GuestCardImage = styled.img``

const CardTitle = styled.h2`
	margin-top: 1.25rem;
	font-weight: bold;
	letter-spacing: 0.14px;
	text-align: center;
	color: black;
	font-size: 0.75rem;
	@media (min-width: 768px) {
		font-size: 14px;
	}
`

const CardText = styled.p`
	margin-top: 1.5rem;
	font-size: 0.625rem;
	letter-spacing: 0.14px;
	text-align: center;
	color: #2f2e41;
	@media (min-width: 768px) {
		font-size: 0.875rem;
		line-height: 1.3;
		/* font-size: 12px; */
	}
`

export default GuestCard
