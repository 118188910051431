import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import Modal from '@Components/Utilities/Popup/Modal'
import { FlexLayout } from '@eduact/ed-system'
import { Color } from '@eduact/student-theme'
import moment from 'moment'
import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Dropdown, { DropdownItem } from '@Components/InputFields/Dropdown'
import { useGetTestAttemptsQuery } from '@Features/Unit/TestMcqUnit/TestMcqApi/TestMcqApi'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import TestSummary from '@Features/Unit/TestMcqUnit/Components/TestSummary'
import { RaisedButton, SkeletonBox } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import PreviousNextSlots from '../PreviousNextSlots/PreviousNextSlots'
import { useTranslation } from 'react-i18next'

type Props = {
	show: boolean
	setShow: any
	attempt: Attempts | undefined
	test: Unit
	name: string
	attemptId: number | undefined
	setAttemptId: any
}

const ShowSummaryModel: FC<Props> = ({ show, setShow, attempt, name, test, attemptId, setAttemptId }) => {
	const [t] = useTranslation(['classroomTests', 'test_status'])
	const { refetch, data: attemptsData, isLoading } = useGetTestAttemptsQuery(Number(test?.test?.id) ?? skipToken)
	const [testSummmaryData, setTestSummmaryData] = useState<Attempts | undefined>()

	const getStatusColor = (status: string | undefined): Color => {
		if (status === 'passed') return 'green'
		return 'princetonOrange'
	}

	const percentage = useMemo(() => {
		if (!testSummmaryData) return
		const score = testSummmaryData?.score as number
		const overallScore = testSummmaryData?.overall_score as number
		return Math.round((score * 100) / overallScore).toFixed(1)
	}, [testSummmaryData])

	useEffect(() => {
		setAttemptId(attempt?.id)
	}, [attempt?.id])

	useEffect(() => {
		refetch()
	}, [attempt?.id])

	const currentAttemptIndex = useMemo(() => {
		if (attemptsData?.data) {
			const index = attemptsData?.data.findIndex(_ => _.id === attemptId)
			return index + 1
		}
	}, [attemptId, attemptsData?.data])

	const currentAttempt = useMemo(() => {
		if (attemptsData?.data) {
			return attemptsData?.data.find(_ => _.id === attemptId)
		}
	}, [attemptId, attemptsData?.data])

	const hideHeader = true

	return (
		<ModelStyle modalOpen={show}>
			<Content
				maxWidth={{ sm: '90vw', lg: '' }}
				minWidth={{ sm: 'unset', lg: 'fit-content' }}
				onClose={() => {
					setShow(false)
				}}
			>
				<ModelWrapper alignItems="baseline">
					<Container>
						<TestInfo>
							<Name>{name}</Name>
							<EdTypography
								fontSize={{ sm: '0.875rem', lg: '1.125rem' }}
								fontWeight={'600'}
								color={getStatusColor(testSummmaryData?.status)}
							>
								{t(`test_status.${testSummmaryData?.status}`)}
							</EdTypography>
						</TestInfo>
						<FlexLayout
							alignItems="center"
							justifyContent="space-between"
							flexDirection={{ lg: 'row', sm: 'column' }}
							gridGap={{ sm: '0.594rem' }}
						>
							<FlexLayout
								alignItems={{ sm: 'start', lg: 'center' }}
								justifyContent="start"
								gridGap={{ sm: '0.594rem', lg: '4rem' }}
								flexDirection={{ lg: 'row', sm: 'column' }}
								width="100%"
								order={{ sm: 2, lg: 1 }}
							>
								<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontWeight="600">
									{t('takenDate')} :{' '}
									{!testSummmaryData?.created_at ? '-' : <Span>{moment(testSummmaryData?.created_at).format('DD/MM/yyyy, hh:mm A')}</Span>}
								</EdTypography>
								<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontWeight="600">
									{t('testScore')} :{' '}
									<Span>
										{testSummmaryData?.score}/{testSummmaryData?.overall_score} ( <Percentage>{percentage}%</Percentage> )
									</Span>
								</EdTypography>
							</FlexLayout>
							<DropdownStyle>
								<AttemptDropdown
									placeholder="Attempts"
									selectedItemLabel={
										`${t('attempt')} # ${currentAttemptIndex} -${moment(currentAttempt?.created_at).format('DD/MM/yyyy, hh:mm A')}` as any
									}
									setSelectedItem={setAttemptId}
								>
									{attemptsData?.data?.map((attempt, index) => (
										<DropdownItem
											key={attempt.id}
											value={attempt.id}
											onClick={() => {
												setAttemptId(attempt.id)
											}}
										>
											{t('attempt')} #{index + 1} -{moment(attempt?.created_at).format('DD/MM/yyyy, hh:mm A')}
										</DropdownItem>
									))}
								</AttemptDropdown>
							</DropdownStyle>
						</FlexLayout>
						<Spacer mt={{ sm: '0.5rem' }} mb={{ sm: '0.713rem', lg: '-0.656rem' }}>
							<Line />
						</Spacer>
						<SummaryWrapper>
							<SkeletonBox isLoading={isLoading} height={'55vh'}>
								<TestSummary hideHeader={hideHeader} id={attemptId} setTestSummmaryData={setTestSummmaryData} />
							</SkeletonBox>
						</SummaryWrapper>
					</Container>
					<NavigateContainer>
						<PreviousNextSlots setAttemptId={setAttemptId} attemptId={attemptId} attempts={attemptsData} summmaryData={testSummmaryData} />
						<FlexLayout justifyContent="flex-end" mt="0.5rem">
							<DownloadButton color="primary" disabled>
								{t('download')}
							</DownloadButton>
						</FlexLayout>
					</NavigateContainer>
				</ModelWrapper>
			</Content>
		</ModelStyle>
	)
}

const Content = styled(Modal.Content)`
	height: 48rem;
	${({ theme }) => `${theme.mediaQueries.xlarge}{
		height: 57.375rem;
	}`}
`
const DownloadButton = styled(RaisedButton)`
	height: 1.625rem;
	width: 6.388rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    	height: 2.438rem;
		width: 9.094rem;
	}`}
`
const ModelStyle = styled(Modal)`
	display: flex;
	flex-direction: column;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
	margin: 32px;
	overflow-y: auto;
`
const ModelWrapper = styled(FlexLayout)`
	flex-direction: column;
	gap: 0.438rem;
	width: 100%;
	margin-top: 0.75rem;
	position: relative;
	font-family: Montserrat;
	${({ theme }) => `${theme.mediaQueries.large}{
    	gap: 1.438rem;
    	width: 100%;
	}`}
`
const NavigateContainer = styled(FlexLayout)`
	height: 7.5rem;
	box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #f5f5f5;
	flex-direction: column;
	padding: 0.8rem 3.25rem 1rem 3.25rem;
	width: 100%;
	position: absolute;
	bottom: 0px;
	z-index: 999;
	align-items: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		align-items: flex-end;
		height: 6.688rem;
	}`}
`
const Container = styled(FlexLayout)`
	flex-direction: column;
	width: 100%;
	padding: 2.5rem 0.938rem 0rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		width: 75rem;
    	gap: 1.438rem;
		padding: 3.188rem 2.313rem 0;
	}`}
`
const Name = styled.p`
	font-size: 1rem;
	font-weight: 600;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.375rem;
	}`}
`
const TestInfo = styled(FlexLayout)`
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.438rem;
	padding-inline-end: 0.938rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		justify-content: start;
    	gap: 0.9rem;
	}`}
`
const Span = styled.span`
	font-size: 1rem;
	font-weight: 500;
	color: #2f2e41;
`
const Percentage = styled.span`
	font-style: italic;
`
const DropdownStyle = styled.div`
	width: 100%;
	order: 1;
	display: flex;
	justify-content: flex-start;
	${({ theme }) => `${theme.mediaQueries.large}{
    	padding-inline-start: unset;
		padding-inline-start: 0.7rem;
      	order: 2;
		justify-content: flex-end;
	}`}
`
const AttemptDropdown = styled(Dropdown)`
	border: 0.5px solid #5ac0fc;
	padding-inline-start: 0.675rem;
	padding-inline-end: 0.675rem;
	border-radius: 5px;
	width: 14.769rem;
	height: 2.1rem;
	box-shadow: inset 11px 10px 50px -33px rgba(0, 0, 0, 0.13);
	${({ theme }) => `${theme.mediaQueries.large}{
    width: 18.5rem;
	}`}
	div {
		border: unset;
		min-height: 1.8rem;
		color: #707070;
		font-size: 0.75rem;
		font-weight: 600;
	}
	svg {
		margin-top: 2px;
		font-size: 1.2rem;
	}
`
const Line = styled.div`
	width: 100%;
	border: solid 1px #e4e4e4;
`
const SummaryWrapper = styled.div`
	overflow: scroll;
	height: 36.6rem;
	padding-bottom: 5.1rem;
	${({ theme }) => `${theme.mediaQueries.xlarge}{
    	height: 45.2rem;
	}`}
`
export default ShowSummaryModel
