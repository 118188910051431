import api from '@Api/Api'
import Api, { rtkPrepareHeadersCallback } from '@Api/Api'
import { RootState } from '@Redux/store'
import LocalStorageKeys from '@Constants/LocalStorageKeys'
import { setAuthData } from '@Features/Authentication/AuthenticationSlice/Authentication.slice'
import { BaseApi } from '@Features/Base/BaseApi/BaseApi'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import DashboardAPI from '../DashboardApi/DashboardApi'
import {
	DashboardInitalState,
	EditProfilePayload,
	Invoice,
	MethodRates,
	ProviderWithPayload,
	RechargeCallbackPayload,
	RechargePayloadType,
	RechargeProvider,
	RechargeRequestCallback,
	RechargeRequestPayload,
	RequestCallbackPayload,
	TimelineEvent,
	WalletHistory,
} from './Dashboard.slice.types'

const initialState: DashboardInitalState = {
	walletHistory: null,
	timelineEvents: null,
	userClassrooms: [],
	loaders: {
		userClassrooms: 'idle',
		walletHistory: 'idle',
		timelineEvents: 'idle',
	},
}

const SLICE_BASE_NAME = 'dashboard'

export const getWalletHistory = createAsyncThunk(`${SLICE_BASE_NAME}/wallethistory`, async (filters?: FiltersPayload) => {
	const { data } = await DashboardAPI.getInstance().getWalletHistory(filters)
	return data
})

export const getTimelineEvents = createAsyncThunk(`${SLICE_BASE_NAME}/timeline-events`, async (filters?: FiltersPayload) => {
	const { data } = await DashboardAPI.getInstance().getTimelineEvents(filters)
	return data
})

export const getUserClassrooms = createAsyncThunk(`${SLICE_BASE_NAME}/user-classrooms`, async () => {
	const { data } = await DashboardAPI.getInstance().getUserClassrooms()
	return data
})

export const getUserInvoices = createAsyncThunk(`${SLICE_BASE_NAME}/user-invoices`, async () => {
	const { data } = await DashboardAPI.getInstance().getUserInvoices()
	return data
})

export const dashboardAPI = BaseApi.enhanceEndpoints({
	addTagTypes: ['Profile', 'Invoices', 'Instructors', 'Classrooms', 'Redirection'],
}).injectEndpoints({
	endpoints: builder => ({
		getWalletHistory: builder.query<ApiResponse<WalletHistory[]>, FiltersPayload | null>({
			query: (filters: FiltersPayload | null) => {
				return { url: 'student/profile/wallet/history', body: filters ?? {}, method: 'POST' }
			},
		}),
		getTimelineEvents: builder.query<ApiResponse<TimelineEvent[]>, FiltersPayload | null>({
			query: (filters: FiltersPayload) => ({ url: 'student/profile/timeline', body: filters ?? {}, method: 'POST' }),
		}),
		getUserInvoices: builder.query<ApiResponse<Invoice[]>, FiltersPayload | null>({
			query: (filters: FiltersPayload | null) => ({ url: 'student/profile/invoices', method: 'POST', body: filters ?? {} }),
			providesTags: ['Invoices'],
		}),
		getUserClassrooms: builder.query<ApiResponse<Classroom[]>, void>({
			query: () => ({ url: 'student/profile/classrooms' }),
		}),
		getClassroomsAnalytics: builder.query<ApiResponse<ClassroomsAnalytics>, number | void>({
			query: payload => ({
				url: 'student/analytics/count',
				method: 'POST',
				body: payload,
			}),
		}),
		getUserProfile: builder.query<ApiResponse<StudentUser> | null, void>({
			providesTags: ['Profile'],
			async queryFn(args, { getState }) {
				const isAuth = localStorage.getItem(LocalStorageKeys.AUTH)
				if (isAuth) {
					const { data } = await api.get<ApiResponse<StudentUser>>('student/profile')
					return { data }
				}
				return { data: null }
			},
		}),
		changePassword: builder.query<ApiResponse<any>, ChangePasswordPayload>({
			query: payload => ({ url: 'student/profile/password/change', method: 'PUT', body: payload }),
		}),
		editUserProfile: builder.mutation<ApiResponse<any>, { data: EditProfilePayload; type?: 'educational_info' }>({
			query: payload => ({ url: 'student/profile', method: 'PUT', body: payload.data }),
			invalidatesTags: result => {
				return ['Classrooms', 'Instructors', 'Profile']
			},
		}),
		getMethodRates: builder.query<ApiResponse<MethodRates>, RechargeProvider | undefined>({
			query: provider => ({
				url: `recharge/rates?method=${provider}`,
			}),
		}),
		uploadProfilePicture: builder.mutation<ApiResponse<void>, FormData>({
			query: formData => ({ url: 'student/profile/picture/upload', method: 'POST', body: formData }),
			invalidatesTags: ['Profile'],
		}),
		proccedRechargeRequest: builder.mutation<RechargePayloadType, RechargeRequestPayload>({
			query: (payload: RechargeRequestPayload) => {
				let url = `recharge`
				return {
					url,
					method: 'POST',
					body: payload,
				}
			},

			transformResponse: (response: ApiResponse<any>, meta, arg) => {
				const { provider, method } = arg
				const _: RechargeRequestCallback<typeof provider, typeof method> = {
					method: method,
					provider: provider,
					payload: response.data,
				}
				return _ as RechargePayloadType
			},
		}),
		proccedScratchCodeRequest: builder.mutation<any, string>({
			query: code => ({
				url: 'recharge/direct',
				body: {
					code: code,
				},
				method: 'POST',
			}),
			invalidatesTags: result => (result ? ['Profile'] : []),
		}),
		sendRechargeCallback: builder.query<any, RechargeCallbackPayload>({
			query: payload => ({
				url: 'recharge/callback',
				body: payload,
				method: 'POST',
			}),
		}),
		sendRollback: builder.query<any, string>({
			query: id => ({
				url: 'recharge/rollback',
				body: {
					reference_number: id,
				},
				method: 'POST',
			}),
		}),
		checkRedirection: builder.query<ApiResponse<AuthUserData>, { classroom_id?: number }>({
			query: payload => ({ url: `student/profile/redirect/check/${payload.classroom_id ?? ''}` }),
			providesTags: ['Redirection'],
			async onQueryStarted(arg, api) {
				try {
					const {
						data: {
							data: { redirect },
						},
					} = await api.queryFulfilled
					api.dispatch(setAuthData({ redirect }))
				} catch (error) {}
			},
		}),
	}),
})
export const {
	useGetWalletHistoryQuery,
	useLazyGetWalletHistoryQuery,
	useGetTimelineEventsQuery,
	useLazyGetTimelineEventsQuery,
	useGetUserInvoicesQuery,
	useGetUserClassroomsQuery,
	useGetUserProfileQuery,
	useLazyGetUserProfileQuery,
	useProccedScratchCodeRequestMutation,
	useProccedRechargeRequestMutation,
	useGetMethodRatesQuery,
	useUploadProfilePictureMutation,
	useEditUserProfileMutation,
	useLazySendRechargeCallbackQuery,
	useLazyCheckRedirectionQuery,
	useLazyChangePasswordQuery,
	useGetClassroomsAnalyticsQuery,
} = dashboardAPI
