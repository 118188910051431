import {
	CairoBlack,
	CairoBold,
	CairoExtraBold,
	CairoExtraLight,
	CairoLight,
	CairoMedium,
	CairoRegular,
	CairoSemiBold,
} from '@Assets/Fonts/Cairo'
import {
	MontserratBlack,
	MontserratBold,
	MontserratLight,
	MontserratNormal,
	MontserratMedium,
	MontserratThin,
	MontserratExtraLight,
	MontserratSemiBold,
	MontserratExtraBold,
} from '@Assets/Fonts/Montserrat'
import { MulishBold, MulishLight, MulishRegular } from '@Assets/Fonts/Mulish'
import { AvantGardeRegular } from '@Assets/Fonts/AvantGarde'
import { rgba, normalize } from 'polished'
import { createGlobalStyle } from 'styled-components'
import { FawryStyles } from './FawryStyles'
import { OpayStyles } from './OpayStyles'
import {
	MaterialIconsOutlined,
	MaterialIconsRegular,
	MaterialIconsRound,
	MaterialIconsSharp,
	MaterialIconsTwoTone,
} from '@Assets/Fonts/MaterialIcons'
import { FAQsTable } from '@eduact/ed-system'
import { createGlobalFont, Fonts, generateMaterialIconsFontFaces, MaterialIconFontFace } from '@eduact/ed-system'
import { RobotoBlack, RobotoBold, RobotoLight, RobotoMedium, RobotoRegular, RobotoThin } from '@Assets/Fonts/Roboto'
const fontWeightsSrc: Fonts = {
	fonts: {
		Montserrat: {
			normal: MontserratNormal,
			bold: MontserratBold,
			bolder: MontserratBlack,
			light: MontserratLight,
			'500': MontserratMedium,
			'100': MontserratThin,
			'200': MontserratExtraLight,
			'300': MontserratLight,
			'400': MontserratNormal,
			'600': MontserratSemiBold,
			'700': MontserratBold,
			'800': MontserratExtraBold,
			'900': MontserratBlack,
		},
		Mulish: {
			bold: MulishBold,
			normal: MulishRegular,
			'300': MulishLight,
		},
		Cairo: {
			normal: CairoRegular,
			bold: CairoBold,
			bolder: CairoBlack,
			light: CairoLight,
			'200': CairoExtraLight,
			'300': CairoLight,
			'400': CairoRegular,
			'500': CairoMedium,
			'600': CairoSemiBold,
			'700': CairoBold,
			'800': CairoExtraBold,
			'900': CairoBlack,
		},
		AvantGarde: {
			normal: AvantGardeRegular,
		},
		Roboto: {
			'100': RobotoThin,
			'300': RobotoLight,
			'400': RobotoRegular,
			normal: RobotoRegular,
			'500': RobotoMedium,
			'700': RobotoBold,
			bold: RobotoBold,
			'900': RobotoBlack,
			bolder: RobotoBlack,
		},
	},
}

const materialFonts: MaterialIconFontFace = {
	Outlined: { src: MaterialIconsOutlined },
	Regular: { src: MaterialIconsRegular, mapped_name: '' },
	Round: { src: MaterialIconsRound },
	Sharp: { src: MaterialIconsSharp },
	TwoTone: { src: MaterialIconsTwoTone , mapped_name : 'TwoTone' },
}

const GlobalStyles = createGlobalStyle`
${normalize()}

* {
  /* outline: ${process.env.NODE_ENV === 'development' ? ' 1px solid tomato' : ''}; */
}


iframe {
	pointer-events: ${process.env.NODE_ENV === 'development' ? 'none' : ''};
}
	${generateMaterialIconsFontFaces(materialFonts)}
	${createGlobalFont('Roboto', fontWeightsSrc)};
	${createGlobalFont('Montserrat', fontWeightsSrc)};
	${createGlobalFont('Mulish', fontWeightsSrc)};
	${createGlobalFont('Cairo', fontWeightsSrc)};
	${createGlobalFont('AvantGarde', fontWeightsSrc)};

  * {
    box-sizing: border-box;
	
	}

  html ,body , #root{ 
	  padding : 0;
	  margin : 0;
	
	img {
		max-width: 100%;
	}
}
html ,body ,#root{ 
	  font-family: 'Montserrat';
	  ::-webkit-scrollbar {
		appearance: none;
		width : 5px;
	  }
	  ::-webkit-scrollbar-track{
		border-radius : 10px;
		background: ${props => props.theme.colors.silver}
	}
	::-webkit-scrollbar-thumb{
		border-radius : 10px;
		  background: ${props => props.theme.colors.spanishGray}
	  }
  }
  * {
  ::-webkit-scrollbar {
		appearance: none;
		width : 5px;
	  }
	  ::-webkit-scrollbar-track{
		border-radius : 10px;
		background: ${props => props.theme.colors.silver}
	}
	::-webkit-scrollbar-thumb{
		border-radius : 10px;
		  background: ${props => props.theme.colors.spanishGray}
	  }
  }
  html {
	  overflow-x :hidden;
	  height : 100%;
  }
  body , #root{
	min-height :100%;
  }
  html[dir="rtl"],body[dir="rtl"]{
	  font-family : 'Cairo';
	  
  }
  html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
 
  
  ${FawryStyles}
  ${OpayStyles}
  ${FAQsTable}

  :root {
	${({theme})=>`${theme.mediaQueries.min(1366)}{
		font-size :11px;
	}`}
  ${({ theme }) => `${theme.mediaQueries.min(1440)}{
		font-size :12px;
	}`}
	 ${({ theme }) => `${theme.mediaQueries.xlarge}{
		font-size :12px;
	}`}
	${({ theme }) => `${theme.mediaQueries.min(1536)}{
		font-size :16px;
	}`}
}
:root {
	--app-header-height: 4.938rem;
}
  `
export default GlobalStyles
