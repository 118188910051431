import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { RaisedButton } from '@eduact/ed-components'
import { FlexLayout, Typography } from '@eduact/ed-system'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'

type Props = {} & FallbackProps
const ErrorBoundaryFallback: React.FC<Props> = ({ error, resetErrorBoundary, children }) => {
	return (
		<FlexLayout alignItems={'center'} justifyContent={'center'} flex={'1'}>
			<FlexLayout alignItems={'center'} flexDirection={'column'} gridGap="2rem">
				<EdTypography color="red" fontWeight="bold" fontSize="2rem">
					An Error has occurred
				</EdTypography>
				<EdTypography>{error.name}</EdTypography>
				<RaisedButton onClick={resetErrorBoundary}>Retry</RaisedButton>
			</FlexLayout>
		</FlexLayout>
	)
}

export default ErrorBoundaryFallback
