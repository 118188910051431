import { BaseApi } from '@Features/Base/BaseApi/BaseApi'
import {
	RechargePayloadType,
	RechargeProcessed,
	RechargeRequestCallback,
	RechargeRequestPayload,
} from '@Features/Dashboard/DashboardSlice/Dashboard.slice.types'

const BaseURI = 'registration-fees/'
const SignupFeesApi = BaseApi.enhanceEndpoints({ addTagTypes: ['Redirection', 'Profile'] }).injectEndpoints({
	endpoints: builder => ({
		checkPendingTransactions: builder.query<ApiResponse<Invoice>, boolean>({ query: () => ({ url: `${BaseURI}transaction/check` }) }),
		paySignupFees: builder.mutation<RechargePayloadType, RechargeRequestPayload>({
			query: (payload: RechargeRequestPayload) => {
				let url = `${BaseURI}pay`
				return {
					url,
					method: 'POST',
					body: payload,
				}
			},
			invalidatesTags: ['Redirection', 'Profile'],
			transformResponse: (response: ApiResponse<any>, meta, arg) => {
				const { provider, method } = arg
				const _: RechargeRequestCallback<typeof provider, typeof method> = {
					method: method,
					provider: provider,
					payload: response.data,
				}
				return _ as RechargePayloadType
			},
		}),
	}),
})

export const { useCheckPendingTransactionsQuery, usePaySignupFeesMutation } = SignupFeesApi
