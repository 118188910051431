import { StudentID, StudentIDProps, StudentQRCode } from '@eduact/ed-components'
import { useStudentScreenSize } from '@eduact/student-theme'
import { useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
	scale?: number
} & Pick<StudentIDProps, 'onQRCodeClick' | 'qrCodeSize'>
const StudentCard: React.VoidFunctionComponent<Props> = ({ scale, ...props }) => {
	const { data, isLoading } = useGetUserProfileQuery()
	const { t: tEdu } = useTranslation('educational_info')
	const { t } = useTranslation(['inputs'])

	const studentCardData = useMemo(() => {
		return {
			educationalInfo: {
				diploma: tEdu(`educational_type.${data?.data.student.educationType?.name}`),
				year: tEdu(`academic_year.${data?.data.student.educationYear?.name}`),
				language: tEdu(`academic_lang.${data?.data.student.educationLanguage?.name}`),
				section: data?.data.student.educationSection ? tEdu(`educational_field.${data?.data.student.educationSection?.name}`) : '',
			},
			userInfo: {
				first_name: data?.data.first_name,
				last_name: data?.data.last_name,
				phone: data?.data.phone_number,
				username: data?.data.username,
				email: data?.data.email,
				profile_photo: data?.data.profile_photo,
			},
		}
	}, [data])
	const { isMobile } = useStudentScreenSize()

	const getScale = useMemo(() => {
		console.log({ scale })
		if (scale) return scale
		return isMobile ? 0.6 : 1
	}, [scale])

	return (
		<StudentID
			bodyProps={
				{
					// pb: '4rem',
				}
			}
			diplomaTitle={t(`diploma`)}
			yearTitle={t('academic_year_1')}
			languageTitle={t('academic_lang_1')}
			sectionTitle={t('educational_field_1')}
			withShadow={false}
			title="Student ID"
			scale={getScale}
			{...studentCardData}
			{...props}
		/>
	)
}

export default StudentCard
