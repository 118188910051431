import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SnackbarStatus } from '@Utilities/SnackbarConfig'

type openSnackbarArgs = { status?: SnackbarStatus; text: string }
type SnackbarStateArgs = openSnackbarArgs & { opened: boolean }

const initialState: SnackbarStateArgs = {
	opened: false,
	text: '',
}
const SnackbarSlice = createSlice({
	name: 'snackbar',
	initialState,
	reducers: {
		openSnackbarAction: (state: SnackbarStateArgs, action: PayloadAction<openSnackbarArgs>) => {
			state.opened = true
			state.status = action.payload.status
			state.text = action.payload.text
		},
		resetSnackbar: (state: SnackbarStateArgs) => {
			state.opened = false
			state.status = undefined
			state.text = ''
		},
	},
})

export const { openSnackbarAction, resetSnackbar } = SnackbarSlice.actions
export default SnackbarSlice
