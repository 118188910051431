import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Color } from '@eduact/student-theme'
import { layout, LayoutProps, typography, TypographyProps } from 'styled-system'

const CustomLink = styled(Link)<{ color?: Color } & TypographyProps & LayoutProps>`
	text-decoration: underline;
	text-decoration: none;
	color: unset;
	font-size: 0.5rem;
	font-family: 'Roboto';
	color: ${props => (props.color ? props.theme.colors[props.color] : '')};
	${typography}
	${layout}
`

export default CustomLink
