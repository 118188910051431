import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import Spacer from '@Components/Utilities/Spacer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import TestsCount from './Components/TestsCount/TestsCount'
import TestsTable from './Components/TestsTable/TestsTable'

const Tests = () => {
	const [t] = useTranslation('classroomTests')

	return (
		<Wrapper>
			<EdTypography as="h1" fontWeight={600} fontSize={{ sm: '0.75rem', lg: '1.125rem' }}>
				{t('tests')}
			</EdTypography>
			<Spacer mb={{ sm: '0.563rem', lg: '1.688rem' }} />

			<Spacer mx={{ lg: '1rem' }}>
				<TestsCount />
				<Spacer mb={{ sm: '1.125rem', lg: '2.5rem' }} />
				<TestsTable />
			</Spacer>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	padding: 0.644rem 0.844rem;
	border-radius: 15px;
	width: 100%;
	height: 100%;
	background: ${props => props.theme.colors.light};
	${({ theme }) => `${theme.mediaQueries.large}{
		padding : 1.394rem 1.656rem;
	}`}
`

export default Tests
