import { BaseApi } from '@Features/Base/BaseApi/BaseApi'

const ActivityApi = BaseApi.injectEndpoints({
	endpoints: builder => ({
		getInvoices: builder.query<ApiResponse<Array<Invoice>>, FiltersPayload>({
			query: payload => ({ url: 'student/profile/invoices', method: 'POST' }),
		}),
	}),
})

export const { useLazyGetInvoicesQuery, useGetInvoicesQuery } = ActivityApi
