import { BackgroundColorProps, ColorProps, ColorStyleProps, variant } from 'styled-system'
import React from 'react'
import styled from 'styled-components'
import { Color } from '@eduact/student-theme'

const StyledSvg = styled.svg<{ color: Color; stroke?: Color }>`
	fill: ${props => props.theme.colors[props.color]};
	stroke: ${props => (props.stroke ? props.theme.colors[props.stroke] : '')};
	path {
		fill: ${props => props.theme.colors[props.color]};
		stroke: ${props => (props.stroke ? props.theme.colors[props.stroke] : '')};
	}
`

export const LogoutIcon: React.FC<{ fill?: string }> = ({ fill = 'black' }) => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H8C8.55 18 9 17.55 9 17C9 16.45 8.55 16 8 16H2V2Z"
				fill={fill}
			/>
			<path
				d="M17.65 8.64954L14.86 5.85954C14.54 5.53954 14 5.75954 14 6.20954V7.99954H7C6.45 7.99954 6 8.44954 6 8.99954C6 9.54954 6.45 9.99954 7 9.99954H14V11.7895C14 12.2395 14.54 12.4595 14.85 12.1395L17.64 9.34954C17.84 9.15954 17.84 8.83954 17.65 8.64954Z"
				fill={fill}
			/>
		</svg>
	)
}

export const ParentIcon: React.FC = () => (
	<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7 7C8.93 7 10.5 5.43 10.5 3.5C10.5 1.57 8.93 0 7 0C5.07 0 3.5 1.57 3.5 3.5C3.5 5.43 5.07 7 7 7ZM7 2C7.83 2 8.5 2.67 8.5 3.5C8.5 4.33 7.83 5 7 5C6.17 5 5.5 4.33 5.5 3.5C5.5 2.67 6.17 2 7 2ZM7.05 12H2.77C3.76 11.5 5.47 11 7 11C7.11 11 7.23 11.01 7.34 11.01C7.68 10.28 8.27 9.68 8.98 9.2C8.25 9.07 7.56 9 7 9C4.66 9 0 10.17 0 12.5V14H7V12.5C7 12.33 7.02 12.16 7.05 12ZM14.5 9.5C12.66 9.5 9 10.51 9 12.5V14H20V12.5C20 10.51 16.34 9.5 14.5 9.5ZM15.71 7.68C16.47 7.25 17 6.44 17 5.5C17 4.12 15.88 3 14.5 3C13.12 3 12 4.12 12 5.5C12 6.44 12.53 7.25 13.29 7.68C13.65 7.88 14.06 8 14.5 8C14.94 8 15.35 7.88 15.71 7.68Z"
			fill="#FF8532"
		/>
	</svg>
)
type SvgProps = {
	color?: Color
} & React.HTMLProps<HTMLOrSVGScriptElement>
export const WalletIcon: React.FC<SvgProps> = ({ color = 'primary' }) => {
	return (
		<StyledSvg color={color} width="29" height="25" viewBox="0 0 29 25" xmlns="http://www.w3.org/2000/svg">
			<path
				id="Vector"
				d="M26.2208 0.618164H5.92755C4.39699 0.624877 3.15509 1.86678 3.15509 3.40404V3.43761H2.77917C1.24861 3.43761 0.00671296 4.67279 0 6.20335V21.5962C0.00671296 23.1267 1.2419 24.3753 2.77917 24.382H23.0725C24.603 24.3753 25.8449 23.1334 25.8449 21.5962V21.5626H26.2208C27.7514 21.5626 28.9933 20.3274 29 18.7969V3.40404C28.9933 1.86678 27.7581 0.624877 26.2208 0.618164ZM23.0725 23.0395H2.77917C1.98704 23.0327 1.34931 22.3883 1.34259 21.5962V6.20335C1.34931 5.41793 1.98704 4.7802 2.77917 4.7802H23.0725C23.8579 4.7802 24.5023 5.41122 24.5023 6.20335V10.0432C24.3815 10.07 24.2606 10.0834 24.1465 10.0834H20.2731C18.1653 10.0834 16.4535 11.7952 16.4535 13.9031C16.4535 16.011 18.1653 17.7228 20.2731 17.7295H24.1398C24.2606 17.7228 24.3815 17.7094 24.4956 17.6959L24.5023 21.5962C24.5023 22.3883 23.8646 23.0327 23.0725 23.0395ZM27.6574 18.7969C27.6507 19.5823 27.013 20.22 26.2208 20.22H25.8449V17.2126C26.1336 17.0179 26.3954 16.7763 26.6169 16.5077L27.6574 15.1652V18.7969ZM25.5562 15.6955C25.2206 16.1318 24.697 16.3869 24.1465 16.3936H20.2731C18.9037 16.3869 17.7961 15.2793 17.7961 13.9098C17.7961 12.5404 18.9037 11.4327 20.2731 11.426H24.1398C24.603 11.426 25.0595 11.3253 25.4757 11.1239C25.9187 10.9158 26.3081 10.6003 26.6102 10.211L27.6507 8.86839L27.6574 12.97L25.5562 15.6955ZM27.6574 6.67326L25.8449 9.00937V6.20335C25.8449 4.67279 24.603 3.43761 23.0725 3.43761H4.49768V3.40404C4.49768 2.61191 5.13542 1.96747 5.92755 1.96076H26.2208C27.013 1.96747 27.6507 2.61191 27.6574 3.40404V6.67326Z"
			/>
		</StyledSvg>
	)
}

export const BackIcon = ({}) => {
	return (
		<StyledSvg color="lavender" width="24" height="13" viewBox="0 0 24 13" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.287549 7.19117L5.96632 12.7008C6.61765 13.3327 7.71677 12.8982 7.71677 12.0096L7.71677 8.47476L21.9646 8.47476C23.0841 8.47476 24 7.58612 24 6.5C24 5.41388 23.0841 4.52524 21.9646 4.52524L7.71677 4.52524L7.71677 0.990412C7.71677 0.101769 6.61765 -0.33268 5.98668 0.299245L0.307905 5.80883C-0.099177 6.18404 -0.0991769 6.81596 0.287549 7.19117Z" />
		</StyledSvg>
	)
}

export const CloseIcon = ({}) => {
	return (
		<StyledSvg stroke="light" color="light" width="32" height="29" viewBox="0 0 32 29" xmlns="http://www.w3.org/2000/svg">
			<path d="M24.2778 8.19141L7.88124 21.694" strokeWidth="3" strokeLinecap="round" />
			<path d="M23.7247 22.1809L8.43457 7.70117" strokeWidth="3" strokeLinecap="round" />
		</StyledSvg>
	)
}
