import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { FlexLayout } from '@eduact/ed-system'
import styled from 'styled-components'

export const PageWrapper = styled.section`
	padding: 0.563em;
	border-radius: 15px;
	width: 100%;
	height: 100%;
	background: ${props => props.theme.colors.light};
	${({ theme }) => `${theme.mediaQueries.large}{
		padding : 1.375em 1.625em;
	}`}
`

export const AnalyticsCardsWrapper = styled.div`
	display: grid;
	grid-gap: 5px;
	grid-template-columns: repeat(2, 1fr);
	${({ theme }) => `${theme.mediaQueries.large}{
		display : flex;
		flex-wrap:wrap;
		grid-gap : 2em;
		font-size : 13px;
	}`}
	${({ theme }) => `${theme.mediaQueries.xlarge}{
		grid-gap : 4em;
		font-size : 16px;
	}`}
`
export const AnalyticInfoCard = styled(FlexLayout)`
	align-items: center;
	/* grid-gap: 5px; */
	border: 1px solid ${props => props.theme.colors.silver};
	padding: 8px;
	/* border: 1px inset #979797; */
	padding: 8px;
	/* border-radius: 5px; */
	border-radius: 10px;
	flex: 1;
	justify-content: space-between;
	/* max-width: 19.375em; */
	/* min-width: 19.375em; */
	${({ theme }) => `${theme.mediaQueries.large}{
		padding : 0.875em 1.125em;
	}`}
`

export const AnalyticInfoIcon = styled.div`
	min-width: 40px;
	width: 40px;
	height: 40px;
	background: ${props => props.theme.colors.dark};
	border-radius: 50%;
	display: grid;
	place-items: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		min-width: 67px;
		width: 67px;
		height: 67px;
		font-size: 2em;
	}`}
`

export const AnalyticInfoTitle = styled(EdTypography)`
	max-width: 8ch;
	text-align: center;
`
