import { themesMap } from '@Assets/Themes'
import { ThemeKey } from '@Assets/Themes/themes.map'
import { ITheme } from '@eduact/student-theme'
import { useAppDispatch, useAppSelector } from '@Hooks/ReduxHooks'
import React, { Dispatch, useEffect, useState } from 'react'

type ThemeSelectorProps = {
	children: (theme: ITheme, setTheme: Dispatch<ITheme>) => JSX.Element
}

const THEME_STORAGE_KEY = 'theme-key'

export const ThemeSelector: React.FC<ThemeSelectorProps> = ({ children }) => {
	const [theme, setTheme] = useState<ITheme | undefined>()
	const [isLoading, setIsLoading] = useState(true)
	const existedTheme = useAppSelector(s => s.settings.theme)

	useEffect(() => {
		setIsLoading(true)
		const getStoredTheme = () => {
			const name = localStorage.getItem(THEME_STORAGE_KEY)
			return name
		}

		const updateTheme = (key: string | null) => {
			if (!key) return
			setTheme(themesMap[key as ThemeKey])
			setIsLoading(false)
		}
		const changeTheme = () => {
			return getStoredTheme() !== existedTheme
		}

		if (changeTheme()) {
			updateTheme(existedTheme)
		} else {
			updateTheme(getStoredTheme())
		}
	}, [existedTheme])

	return <>{!isLoading && theme && <>{children(theme, setTheme)}</>}</>
}
