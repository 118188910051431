import { useAppSelector } from '@Hooks/ReduxHooks'
import { createContext, FC, useContext, useMemo } from 'react'

type ClassroomContextArgs = {
	classroom?: Classroom
	currentCourse?: Course
	enrolled: boolean
	hasAdmission: boolean
	admissionStatus: string
	isBanned: boolean
	isPending: boolean
	isRejected: boolean
	admissionAvailable: boolean
}

type ClassroomProviderArgs = {
	classroom?: Classroom
	currentCourse?: Course
}

const ClassroomContext = createContext<ClassroomContextArgs | null>(null)

export const ClassroomProvider: FC<ClassroomProviderArgs> = ({ children, ...props }) => {
	const { isAuth } = useAppSelector(state => state.auth)
	const { classroom } = props
	const enrolled = useMemo(() => {
		if (!isAuth || !classroom) return false
		const { enrolled } = classroom
		if (!enrolled || enrolled.length === 0) return false
		return true
	}, [classroom, isAuth])

	const hasAdmission = useMemo(() => {
		return classroom?.has_admission ?? false
	}, [classroom])

	const admissionAvailable = useMemo(() => {
		return classroom?.admission_status ?? false
	}, [classroom])

	const admissionStatus = useMemo(() => {
		if (!classroom) return ''
		const { admissionResponse } = classroom
		if (!admissionResponse) return ''
		return admissionResponse.status
	}, [classroom])

	const isBanned = useMemo(() => {
		if (!enrolled || !classroom || classroom?.enrolled.length === 0) return false
		return !classroom?.enrolled[0].pivot.active
	}, [classroom, enrolled])
	const isPending = useMemo(() => {
		if (!classroom) return false
		return admissionStatus === 'pending'
	}, [classroom, admissionStatus])

	const isRejected = useMemo(() => {
		if (!admissionStatus) return false
		return admissionStatus === 'rejected'
	}, [enrolled, admissionStatus])

	return (
		<ClassroomContext.Provider
			value={{ ...props, admissionStatus, enrolled, hasAdmission, isBanned, isPending, admissionAvailable, isRejected }}
		>
			{children}
		</ClassroomContext.Provider>
	)
}

export const useClassroom = () => {
	const context = useContext(ClassroomContext)
	if (!context) {
		throw new Error('No ClassroomProvider found !!!')
	}

	return { ...context }
}
