import React from 'react'
import styled from 'styled-components'
import { FaFacebookF, FaInstagram, FaLinkedin, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import { IconButton, TextButton } from '@eduact/ed-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import routes from '@Constants/Routes'
import { Link } from 'react-router-dom'
import { useAppSelector } from '@Hooks/ReduxHooks'
type Props = {}

const Footer: React.FC<Props> = () => {
	const [t] = useTranslation('layouts', { keyPrefix: 'footer' })
	const history = useNavigate()
	const { about_us_page, fb_link, privacy_link, terms_link, youtube_link, instagram_link, linkedin_link } = useAppSelector(s => s.settings)
	return (
		<FooterStyled>
			<FooterWrapper>
				<FooterActionsContainer>
					<FooterLinksContainer>
						{about_us_page && (
							<TextButton textDecoration={'underline'} onClick={() => history('/about')} variant="dark" padding={'0'} fontWeight={'bold'}>
								{t('aboutUs')}
							</TextButton>
						)}
						<a href={ terms_link ?? '/EduAct-Terms-of-Use.pdf' } target="_blank">
							{t('terms')}
						</a>
						<a href={ privacy_link ?? '/Eduact_Privacy_Policy.pdf' } target="_blank">
							{t('privacy')}
						</a>
						{/* <a>{t('contact_us')}</a> */}
						{/* <a onClick={() => history(routes.faqs)}>{t('FAQs')}</a> */}
					</FooterLinksContainer>
					<FooterSocialLinksContainer>
						<SocialMediaIcon href={fb_link ?? 'https://www.facebook.com/Eduact.me'} target="_blank">
							<FaFacebookF />
						</SocialMediaIcon>
						<SocialMediaIcon href={youtube_link ?? 'https://www.youtube.com/channel/UCrU8iCLGjYYfLtT8tGYJiMw'} target="_blank">
							<FaYoutube />
						</SocialMediaIcon>
						<SocialMediaIcon href={linkedin_link ?? 'https://www.linkedin.com/company/69526902/admin/'} target="_blank">
							<FaLinkedinIn />
						</SocialMediaIcon>
						<SocialMediaIcon href={instagram_link ?? 'https://www.instagram.com/eduact.me'} target="_blank">
							<FaInstagram />
						</SocialMediaIcon>
					</FooterSocialLinksContainer>
				</FooterActionsContainer>
				<FooterCopyRights>{t('copy_rights')}</FooterCopyRights>
			</FooterWrapper>
		</FooterStyled>
	)
}

export default Footer

const FooterStyled = styled.div`
	background: ${props => props.theme.colors.darkCultured};
	direction: ltr;
	z-index: 2;
	svg {
		transform: unset !important;
	}
`

const FooterWrapper = styled.div`
	padding: 2rem;
	@media (min-width: 1024px) {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		padding: 1rem 2rem;
		align-items: center;
	}
`
const FooterLinksContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	font-size: 0.875rem;
	white-space: nowrap;
	place-items: center;
	grid-row-gap: 1rem;
	font-weight: bold;
	@media (min-width: 1024px) {
		display: flex;
		a {
			margin: 0 0.5rem;
			text-decoration: none;
			color: ${props => props.theme.colors.dark};
		}
	}
`
const SocialMediaIcon = styled.a`
	cursor: pointer;
	padding: 0 0.5rem;
	color: ${props => props.theme.colors.dark};
	&:nth-child(even) {
		border-left: 1px solid ${props => props.theme.colors.platinum} !important;
		border-right: 1px solid ${props => props.theme.colors.platinum} !important;
	}
	&:last-of-type {
		border-right: 0 !important;
	}
`
const FooterSocialLinksContainer = styled.div`
	display: flex;
	padding: 0 2rem;
	justify-content: center;
	margin: 2rem 0;
	@media (min-width: 1024px) {
		margin: 0;
	}
`
const FooterCopyRights = styled.section`
	font-size: 0.625rem;
	text-align: center;
	font-weight: bold;
`
const FooterActionsContainer = styled.section`
	@media (min-width: 1024px) {
		display: flex;
		align-items: center;
	}
`
