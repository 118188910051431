import { StudentIDProps, StudentQRCode } from '@eduact/ed-components'
import { useStudentScreenSize } from '@eduact/student-theme'
import React, { MutableRefObject, RefObject, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { StudentCard } from '../StudentCard'
import StudentQRCard from '../StudentQRCard/StudentQRCard'

type Props = StudentIDProps & {
	onFlip?: (flipped: boolean) => void
	studentIDRef?: MutableRefObject<HTMLDivElement | null>
	customScale?: number
}
const AnimatedStudentCard: React.VoidFunctionComponent<Props> = ({ scale = 1, customScale, onFlip, studentIDRef, ...props }) => {
	const [isFlipped, setIsFlipped] = useState(false)

	const handleFlipChange = () => {
		onFlip?.(!isFlipped)
		setIsFlipped(!isFlipped)
	}
	const { isDesktop, isTablet, isMobile } = useStudentScreenSize()

	const getScale = useMemo(() => {
		return isMobile ? 0.5 : scale
	}, [isMobile])

	return (
		<Scene isFlipped={isFlipped} scale={getScale}>
			<div className="scene">
				<div className="card">
					<div ref={studentIDRef} className="card__face card__face--front">
						{isMobile && <StudentCard scale={customScale ?? 0.5} onQRCodeClick={handleFlipChange} {...props} />}
						{!isMobile && <StudentCard {...props} scale={getScale} onQRCodeClick={handleFlipChange} />}
					</div>
					<div className="card__face card__face--back" onClick={handleFlipChange}>
						{isMobile && <StudentQRCard scale={customScale ?? 0.5} {...props} />}
						{!isMobile && <StudentQRCard scale={getScale} {...props} />}
					</div>
				</div>
			</div>
		</Scene>
	)
}

export default AnimatedStudentCard
const Scene = styled.div<{ isFlipped: boolean; scale: number }>`
	.scene {
		width: 100%;
		/* width: 300px; */
		height: 170px;
		/* height:230px; */
		position: relative;
		perspective: 3000px;
		${({ theme, scale }) => `${theme.mediaQueries.small}{
			// width : 370px;
			min-height : ${scale * 170}px;
		}`}
		${({ theme, scale }) => `${theme.mediaQueries.medium}{
			height: 260px;
			min-width : ${scale * 405}px;
			min-height : ${scale * 254}px;

		}`}
	}
	.card {
		width: 100%;
		height: 100%;
		position: relative;
		transition: transform 1s;
		transform-style: preserve-3d;
		display: flex;
		transform: ${props => props.isFlipped && 'rotateY(180deg)'};
	}

	.card__face {
		position: absolute;
		height: 100%;
		width: 100%;
		backface-visibility: hidden;
	}
	.card__face--front {
		pointer-events: ${({ isFlipped }) => isFlipped && 'none'};
		/* background: red; */
	}

	.card__face--back {
		/* background: blue; */
		transform: rotateY(180deg);
	}
	.card.is-flipped {
	}
`
