import React, { FC, useState } from 'react'
import { Breakpoints } from '../Hooks/BreakPoints'
import useWindowResize from '../Hooks/WindowResize'

type CircleProps = {
	colour?: string
	pct?: number
}

const Circle: FC<CircleProps> = ({ colour, pct }) => {
	const [isMediumSize, setIsMediumSize] = useState<boolean>(false)

	const mediumResizeCallback = () => {
		const md = parseInt(Breakpoints.md.slice(0, -2))
		if (window.innerWidth >= md) {
			setIsMediumSize(true)
		} else {
			setIsMediumSize(false)
		}
	}
	useWindowResize(mediumResizeCallback)

	const r = !isMediumSize ? 50 : 70
	const circ = 2 * Math.PI * r
	const strokePct = pct && ((100 - pct) * circ) / 100
	return (
		<circle
			r={r}
			cx={!isMediumSize ? 75 : 100}
			cy={!isMediumSize ? 75 : 100}
			fill="transparent"
			stroke={strokePct !== circ ? colour : ''}
			strokeWidth={!isMediumSize ? '0.7rem' : '1rem'}
			strokeDasharray={circ}
			strokeDashoffset={pct ? strokePct : 0}
			strokeLinecap="square"
		/>
	)
}

export default Circle
