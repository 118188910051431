import { DrawerToggler } from '@Components/Button/DrawerToggler'
import routes from '@Constants/Routes'
import { useStudentScreenSize } from '@eduact/student-theme'
import TestMcqUnitApi from '@Features/Unit/TestMcqUnit/TestMcqApi/TestMcqApi'
import UnitApi from '@Features/Unit/UnitApi'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { useLayoutProvider } from '@Providers/LayoutProvider/LayoutProvider'
import { checkRunningTest, resetRunningTest } from '@Redux/RunningTestSlice/RunningTestSlice'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { generatePath, useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { useStartTestQuery } from '../Api/TestApi'
import MobileDrawer from '../Components/MobileDrawer/MobileDrawer'
import TestDrawer from '../Components/TestDrawer/TestDrawer'
import TestPlayground from '../Components/TestPlayground/TestPlayground'
import { FormQuestion, TestProvider } from '../Providers/TestProvider'

const TestOverview = () => {
	const { id } = useParams()

	const { data, isError } = useStartTestQuery({ test_id: Number(id) })

	const dispatch = useDispatch()
	const { setShowWallet, setShowFooter, setFixedHeader, fixedHeader } = useLayoutProvider()
	const { isMobile } = useStudentScreenSize()
	const navigate = useNavigate()

	const { testData } = useAppSelector(state => state.RunningTest)
	useEffect(() => {
		setShowWallet(false)
		setFixedHeader(false)
		if (testData) {
			dispatch(TestMcqUnitApi.util.invalidateTags([{ type: 'TEST', id: testData.unit_id }]))
		}
		return () => {
			setFixedHeader(true)
			setShowWallet(true)
		}
	}, [])
	useEffect(() => {}, [])

	if (isError) {
		if (testData) {
			const path = generatePath(`${routes.classroom}/${routes.course}/${routes.mcqUnit}`, {
				label: testData.classroomLabel,
				cId: testData.course_id.toString(),
				id: testData.unit_id.toString(),
			})
			dispatch(resetRunningTest())
			navigate(path)
		}
	}

	useEffect(() => {
		setShowFooter(!isMobile)
	}, [isMobile, setShowFooter])

	const overallProgress = useMemo(() => {
		if (data?.data.test.duration !== undefined) {
			return data.data.test.duration * 60
		}
	}, [data])

	const testRef = useRef(null)

	const [drawerOpened, openDrawer] = useState(true)
	const [bottomSheetOpened, openBottomSheet] = useState(false)
	const [formQuestions, setFormQuestions] = useImmer<Array<FormQuestion>>([])

	useEffect(() => {
		if (data) {
			setFormQuestions(data?.data.questions.map(question => ({ ...question, touched: false, submitted: !!question.answer })))
		}
	}, [data])

	const { isOnline } = useAppSelector(state => state.settings)
	const [showOfflineDisclaimer, setShowOfflineDisclaimer] = useState(false)
	const { t } = useTranslation(['test'])
	const [showOfflineText, setShowOfflineText] = useState(t('testOffline'))
	useEffect(() => {
		if (isOnline) {
			setShowOfflineText(t('testOnline'))
			setTimeout(() => {
				setShowOfflineDisclaimer(!isOnline)
			}, 3000)
		} else {
			setShowOfflineDisclaimer(!isOnline)
			setShowOfflineText(t('testOffline'))
		}
	}, [isOnline])

	return (
		<TestWrapper ref={testRef}>
			{data && (
				<TestProvider
					attempt={data.data.attempt}
					overallProgress={overallProgress}
					drawerOpened={drawerOpened}
					openDrawer={openDrawer}
					mode={data.data.test.view_mode}
					questions={data.data.questions}
					test={data.data.test}
					formQuestions={formQuestions}
					setFormQuestions={setFormQuestions}
					bottomSheetOpened={bottomSheetOpened}
					openBottomSheet={openBottomSheet}
				>
					<OfflineDisclaimer online={isOnline} show={showOfflineDisclaimer}>
						{showOfflineText}
					</OfflineDisclaimer>

					<DrawerToggler opened={drawerOpened} onClick={() => openDrawer(true)} />
					<TestPlayground />
					<MobileDrawer />
					<TestDrawer />
				</TestProvider>
			)}
		</TestWrapper>
	)
}

export default TestOverview

const TestWrapper = styled.div`
	background: #ecebfc;
	position: relative;
	padding: 11px 8px 0px;
	/* height: 100%; */
	width: 100%;
	display: flex;
	transition: all ease-in-out 300ms;
	${({ theme }) => `${theme.mediaQueries.large}{
		grid-gap : 1rem;
		padding :17px 21px;
	}`}
`

const OfflineDisclaimer = styled.div<{ show: boolean; online: boolean }>`
	position: fixed;
	padding: 2rem;
	background: ${props => (props.online ? props.theme.colors.green : props.theme.colors.princetonOrange)};
	left: 0;
	top: ${({ show }) => (show ? '0' : '-10%')};
	z-index: ${({ show }) => (show ? '99' : '-2')};
	width: 100%;
	transition: all ease-in-out 200ms;
	color: white;
`
