import api, { rtkPrepareHeadersCallback } from '@Api/Api'
import { logoutAction, setAuthData } from '@Features/Authentication/AuthenticationSlice/Authentication.slice'
import { openSnackbarAction, resetSnackbar } from '@Redux/SnackbarSlice/SnackbarSlice'
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { isBaseError } from '../Error/BaseError'
import { AxiosProgressEvent } from 'axios'
const baseQuery = fetchBaseQuery({
	baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
	prepareHeaders: rtkPrepareHeadersCallback,
})

const baseQueryWithInterceptor: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions)
	// debugger
	if (result.error && isBaseError(result.error) && result.error.data.show) {
		if (result.error.status === 401) {
			api.dispatch(logoutAction())
		}
		api.dispatch(openSnackbarAction({ text: result.error.data.message, status: 'warning' }))
		setTimeout(() => {
			api.dispatch(resetSnackbar())
		}, 3000)
	}
	if (isApiResponse(result.data) && result.data.show) {
		api.dispatch(openSnackbarAction({ text: result.data.message, status: 'success' }))
		setTimeout(() => {
			api.dispatch(resetSnackbar())
		}, 3000)
	}
	if (result.error && isBaseError(result.error) && result.error.status === 307) {
		api.dispatch(setAuthData({ redirect: result.error.data.data.redirect }))
	}
	return result
}

export const BaseApi = createApi({
	reducerPath: 'BaseApi',
	baseQuery: baseQueryWithInterceptor,
	refetchOnReconnect: true,
	// keepUnusedDataFor: 0,
	// refetchOnFocus: true,
	// refetchOnMountOrArgChange: true,
	endpoints: builder => ({
		upload: builder.query<ApiResponse<UploadResponse>, FormData>({
			query: payload => ({ url: '/upload/', method: 'POST', body: payload }),
		}),
		groups: builder.query<ApiResponse<Group[]>, number>({
			query: payload => ({ url: `/groups/${payload}` }),
		}),
		verifyPromoCode: builder.query<ApiResponse<Promocode>, { course_id: number; code: string }>({
			query: payload => ({ url: '/promocodes/verify', method: 'POST', body: payload }),
		}),
	}),
})

type UploadFileWithProgressArgs = {
	progressCallback: (progress: AxiosProgressEvent) => void
	formData: FormData
}
export const uploadFileWithProgress = async ({ formData, progressCallback }: UploadFileWithProgressArgs) => {
	return await api.post<ApiResponse<UploadResponse>>('/upload', formData, {
		onUploadProgress(progressEvent) {
			progressCallback(progressEvent)
		},
	})
}
export const { useLazyUploadQuery, useGroupsQuery, useLazyVerifyPromoCodeQuery } = BaseApi

export function isApiResponse(value: unknown): value is ApiResponse<any> {
	return typeof value === 'object' && value !== null && 'message' in value && 'data' in value
}
