var acceptEventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
var acceptEventer = window[acceptEventMethod]
var acceptMessageEvent = acceptEventMethod === 'attachEvent' ? 'onmessage' : 'message'

export class AcceptPay {
	constructor() {}

	static loadWidget() {
		var div = document.createElement('div')
		div.innerHTML = `<iframe  onload="${loaded()}" id="accept-iframe" style="width : 100%;height : 100% ; position : fixed;z-index:999999;top:0;left:0;" src=https://accept.paymobsolutions.com/api/acceptance/iframes/77872?payment_token=${
			AcceptPay.chargeRequest.token
		}></iframe>`
		document.body.prepend(div.childNodes[0])
	}

	static checkoutJS(chargeRequest, errorCallback, loadedCallback) {
		AcceptPay.chargeRequest = chargeRequest
		AcceptPay.errorCallback = errorCallback
		AcceptPay.loadedCallback = loadedCallback
		AcceptPay.loadWidget()
	}
}

function loaded(e) {
	if (AcceptPay.loadedCallback) {
		AcceptPay.loadedCallback('loaded')
	}
}

acceptEventer(
	acceptMessageEvent,
	function (e) {
		var key = e.message ? 'message' : 'data'
		var data = e[key]
		if (data == 'closeAccept') {
			deleteFrame()
		}
		if (data == 'acceptLoaded') {
			loaded()
		}
	},
	false
)

function failed() {
	if (AcceptPay.errorCallback) AcceptPay.errorCallback(AcceptPay.chargeRequest.referencrNumber)
}

function deleteFrame() {
	failed()
	var iframe = document.getElementById('accept-iframe')
	if (iframe) {
		iframe.remove()
	}
}
