import { Dropdown, Icon, Icons, RaisedButton, TextInput } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import { useStudentScreenSize } from '@eduact/student-theme'
import useURLQuery from '@Hooks/useURLQuery'
import { rgba } from 'polished'
import React, { FocusEventHandler, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { Params } from './MainTable.types'
import { flexbox, FlexboxProps, grid, GridProps } from 'styled-system'
import Autocomplete from '@eduact/ed-components/Inputs/Autocomplete'

type Props = {
	tableFilters: Array<Filter>
	dateFilter?: boolean
	showFilterIcon?: boolean
	showTitle?: boolean
	showMobileToggler?: boolean
	filtersWrapperStyles?: GridProps & FlexboxProps
	actionsWrapperStyles?: GridProps & FlexboxProps
	autoApplyFilters?: boolean
}
type FilterValuePair = {
	value: string
	name: string
}
const TableFilter: React.FC<Props> = ({
	tableFilters,
	dateFilter = true,
	showFilterIcon = false,
	showTitle = true,
	showMobileToggler = true,
	filtersWrapperStyles = { gridColumnGap: '2.625rem' },
	actionsWrapperStyles,
	autoApplyFilters = false,
}) => {
	const [params, setParams] = useSearchParams()
	const { removeAll } = useURLQuery<Params>()
	// dropfilter
	const [filtersVal, setFiltersVal] = useImmer<Array<FilterValuePair>>([])
	const [dateFrom, setDateFrom] = useState<string>()
	const [dateTo, setDateTo] = useState<string>()

	const _handleFilterChange = (value: string, name: string) => {
		setFiltersVal(prev => {
			const filter = prev.find(_ => _.name === name)
			if (filter) filter.value = value
			else prev.push({ name, value })
		})
		if (autoApplyFilters) {
			params.set(`FILTER_${name}`, value)
			setParams(params)
		}
	}

	const onApplyFilters = () => {
		filtersVal.forEach(val => {
			const filterKey = tableFilters.find(_ => _.value === val.name)?.value
			if (filterKey) params.set(`FILTER_${filterKey}`, val.value)
		})
		setParams(params)
	}

	const handleDateChange = (type: 'DateFrom' | 'DateTo', value: any) => {
		if (type === 'DateFrom') {
			setDateFrom(value)
			params.set('from', value)
		}
		if (type === 'DateTo') {
			setDateTo(value)
			params.set('to', value)
		}
	}

	const resetFilters = () => {
		removeAll()
		const _emptyParams = new URLSearchParams()
		setParams(_emptyParams)
		setFiltersVal([])
		setDateFrom('')
		setDateTo('')
	}

	const getFilterDefaultValue = useCallback(
		(name: string) => {
			const filter = params.get(`FILTER_${name}`)
			return filter ?? ''
		},
		[filtersVal]
	)

	const { isTablet, isMobile, isDesktop } = useStudentScreenSize()

	console.log('filtersVal', filtersVal.length)

	const [filtersOpened, setFiltersOpened] = useState(true)
	return (
		<div>
			{showMobileToggler && (isMobile || isTablet) && (
				<FlexLayout mb={'0.313rem'} alignItems={'center'} justifyContent={'space-between'}>
					<Icon color="dark">
						<Icons.ChevronLeft />
					</Icon>

					<FilterToggleButton onClick={() => setFiltersOpened(!filtersOpened)}>
						<Icon fontSize={10} color="primary">
							<Icons.Filter />
						</Icon>
					</FilterToggleButton>
				</FlexLayout>
			)}

			<FiltersContainer opened={filtersOpened || isDesktop} justifyContent={'space-between'} flexWrap={'wrap'}>
				{showTitle && <FiltersTitle>Filters</FiltersTitle>}
				<FiltersWrapper
					justifyContent={{ sm: 'space-between', lg: 'initial' }}
					alignItems="center"
					mb={'1rem'}
					flexWrap="wrap"
					{...filtersWrapperStyles}
				>
					{showFilterIcon && (
						<FilterIconButton>
							<Icon color="maxBluePurple">
								<Icons.Filter />
							</Icon>
						</FilterIconButton>
					)}
					{dateFilter && (
						<>
							<FilterInputWrapper>
								<FilterInput
									withHelperText={false}
									value={dateFrom || ''}
									onFocus={"(this.type='date')" as unknown as FocusEventHandler<HTMLInputElement>}
									onBlur={"(this.type='text')" as unknown as FocusEventHandler<HTMLInputElement>}
									background={'transparent'}
									type="date"
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										e.preventDefault()
										handleDateChange('DateFrom', e.target.value)
									}}
								/>
							</FilterInputWrapper>
							<FilterInputWrapper>
								<FilterInput
									withHelperText={false}
									value={dateTo || ''}
									onFocus={"(this.type='date')" as unknown as FocusEventHandler<HTMLInputElement>}
									onBlur={"(this.type='text')" as unknown as FocusEventHandler<HTMLInputElement>}
									background={'transparent'}
									type="date"
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										e.preventDefault()
										handleDateChange('DateTo', e.target.value)
									}}
								/>
							</FilterInputWrapper>
						</>
					)}
					{tableFilters.map((filter, index) => {
						const { name, options, value, type } = filter

						if (type === 'autocomplete') {
							return (
								<DropdownStyle key={`${name}-${value}-${index}-outer`}>
									<FilterAutocomplete
										withHelperText={false}
										placeholder={name}
										value={getFilterDefaultValue(name)}
										options={options.map(_ => ({ id: _.value, label: _.name })) as any}
										onChange={(_value: React.ChangeEvent<HTMLInputElement>) => _handleFilterChange(_value.target.value, value)}
									/>
								</DropdownStyle>
							)
						} else if (type === 'dropdown')
							return (
								<DropdownStyle key={`${name}-${value}-${index}-outer`}>
									<FilterDropdown
										withHelperText={false}
										placeholder={name}
										onChange={(_value: any) => _handleFilterChange(_value, value)}
										value={getFilterDefaultValue(value)}
									>
										{options?.map((opt: any) => (
											<Dropdown.Option key={opt.name + opt.value} value={opt?.value}>
												{opt.name}
											</Dropdown.Option>
										))}
									</FilterDropdown>
								</DropdownStyle>
							)
					})}
				</FiltersWrapper>
				<FlexLayout
					gridGap={'1rem'}
					width={{ sm: '100%', lg: 'unset' }}
					justifyContent={'flex-start'}
					alignItems="center"
					flexDirection="row-reverse"
					mb={'1rem'}
					{...actionsWrapperStyles}
				>
					{!autoApplyFilters && (
						<FilterButton onClick={onApplyFilters} mx={20} btnSize="small">
							Apply
						</FilterButton>
					)}
					<FilterButton onClick={resetFilters} variant="princetonOrange" outlined btnSize="small">
						Reset
					</FilterButton>
				</FlexLayout>
			</FiltersContainer>
		</div>
	)
}

const DropdownStyle = styled.div`
	min-width: 9.306rem;
	max-width: 9.25rem;
	/* height: 1.6rem; */
	div[tabIndex] {
		border-bottom: 1px solid #e0e0e0;
	}
	svg {
		width: 12px;
	}
	span {
		color: #a3a3a4 !important;
	}
`
const FilterInputWrapper = styled.div`
	min-width: 9.306rem;
	max-width: 9.25rem;
`

const FilterButton = styled(RaisedButton)`
	min-width: 3.925rem;
	max-width: 3.925rem;
	padding: 3px 8px;
	margin: 0;
	${({ theme }) => `${theme.mediaQueries.large}{
		max-width: 7.063rem;
		min-width: 7.063rem;
		padding : 0.5rem 2rem;
	}`}
`
const FiltersWrapper = styled(FlexLayout)<GridProps & FlexboxProps>`
	column-gap: 2.625rem;
	row-gap: 1rem;
	${grid};
	${flexbox};
`
const FiltersContainer = styled(FlexLayout)<{ opened: boolean }>`
	width: 100%;
	padding: 0.375rem 0.688rem;
	border-radius: 15px;
	background: #fff;
	margin-bottom: 1rem;
	max-height: ${props => (props.opened ? 'unset' : '0px')};
	opacity: ${props => (props.opened ? '1' : '0')};
	transition: all ease-in-out 200ms;
	${({ theme }) => `${theme.mediaQueries.large}{
	margin-bottom : unset;
	background : transparent;
	// padding : unset;
	width : unset;
	// justify-content: initial;
}`}
`

const FiltersTitle = styled.p`
	font-size: 0.625rem;
	font-weight: 600;
	margin-bottom: 0.375rem;
	${({ theme }) => `${theme.mediaQueries.large}{
	display : none;
}`}
`
const FilterToggleButton = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	cursor: pointer;
	background: ${props => rgba(props.theme.colors.primary, 0.25)};
`
const FilterIconButton = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	cursor: pointer;
	font-size: 10px;
	background: ${props => rgba(props.theme.colors.primary, 0.25)};
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size :1rem;
width: 40px;
	height: 40px;
	}`}
`
const FilterInput = styled(TextInput).attrs(p => ({ withHelperText: false }))``
const FilterDropdown = styled(Dropdown).attrs(p => ({ withHelperText: false }))``
const FilterAutocomplete = styled(Autocomplete).attrs(p => ({ withHelperText: false }))``
export default TableFilter
