import React, { useEffect } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { useLazyGetCompletedUnitsGraphQuery } from '../SingleClassroomSlice/SingleClassroomSlice'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Icon from '@Components/Icons/Icon/Icon'
import { ContentConfig } from '@eduact/utils'
import moment from 'moment'
import { Theme, useStudentScreenSize } from '@eduact/student-theme'
import { Box } from '@eduact/ed-system'

export const CourseGraphConfig: CourseGraphConfigArgs = {
	test: '#000',
	document: '#000',
	video: '#000',
	webcontent: '#000',
	webinar: '#000',
}

const CompletedUnitsGraph = () => {
	const { id } = useParams()
	const [t] = useTranslation('classroomsOverview')
	const { isDesktop } = useStudentScreenSize()
	const [graph, { data }] = useLazyGetCompletedUnitsGraphQuery()
	useEffect(() => {
		const fromDate = new Date()
		const date = new Date(fromDate.setDate(fromDate.getDate() - 7))
		graph({
			slice: 'day',
			from: date,
			to: new Date(),
			classroom_id: Number(id),
			dates: [date, new Date()],
		})
	}, [id])

	return (
		<Wrapper>
			<Title>{t('completedUnits')}</Title>
			<Box height={'100%'} width="100%">
				{data?.data && (
					<ResponsiveLine
						margin={{ bottom: 25, left: 25, right: 10, top: 25 }}
						colors={[Theme.colors.primary]}
						enableArea
						enableCrosshair
						enablePoints
						enableGridX={false}
						enableGridY={true}
						isInteractive
						areaOpacity={0.4}
						data={[data.data]}
						xScale={{ type: 'point' }}
						yScale={{ stacked: true, type: 'linear' }}
						pointSize={isDesktop ? 8 : 4}
						useMesh
						sliceTooltip={({ slice }) => {
							const slicePoint = slice.points[0].data as unknown as CompletedUnitGraphDetails
							const sliceDetails = slicePoint.details
							return (
								<Card>
									{sliceDetails
										? Object.entries(sliceDetails).map(([key, value]) => {
												return (
													<SliceItemWrapper>
														<Icon>{ContentConfig[key as UnitType].mdIcon}</Icon>
														<span> {value}</span>
														<span>{Number(value) > 1 ? `${key}s` : key}</span>
													</SliceItemWrapper>
												)
										  })
										: null}
								</Card>
							)
						}}
						axisLeft={{
							format: e => Math.floor(e) === e && e,
							tickSize: 0,
						}}
						axisBottom={{
							format: value => {
								const _value = value.toString().split('-')
								_value[1] = _value[1].charAt(0).toUpperCase() + _value[1].slice(1)
								const month = _value[1]
								_value[1] = moment().month(month).format('M').toString()
								_value.join('-')
								const date = moment(_value, 'DD-MM-YYY')
								return date.format('ddd')
							},
						}}
						crosshairType="cross"
						pointColor={'#5AC0FC'}
					/>
				)}
			</Box>
		</Wrapper>
	)
}
const Wrapper = styled.div`
	background: #fff;
	height: 13.869rem;
	padding: 0.669rem;
	border-radius: 7px;
	${({ theme }) => `${theme.mediaQueries.large}{
    	padding: 1.444rem 1.419rem;
		border-radius: 15px;
		height: 17.575rem;
	}`}
`
const Title = styled.p`
	font-size: 0.75rem;
	color: #251b33;
	font-weight: 600;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
	}`}
`
const ChartContainer = styled.div`
	/* height: 30vh;
	${({ theme }) => `${theme.mediaQueries.large}{
		height: 33vh;
	}`}
	@media screen and (min-width: 1920px) {
		height: 37vh;
	} */
`
const Card = styled.div`
	border: 2px solid ${props => props.theme.colors.primary};
	background: ${props => props.theme.colors.light};
	padding: 8px;
	border-radius: 10px;
	display: grid;
	row-gap: 10px;
`
const SliceItemWrapper = styled.div`
	font-size: 0.625rem;
	font-weight: 500;
	color: ${props => props.theme.colors.spanishGray};
	text-transform: capitalize;
	display: flex;
	align-items: center;
	column-gap: 5px;
	min-width: 7rem;
`

export default CompletedUnitsGraph
