import React, { Profiler, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import InstructorAvatar from '@Features/LandingPage/Components/InstructorAvatar'
import { Swiper } from '@Components/Utilities/Swiper'
import { useGetInstructorsQuery } from '@Features/LandingPage/LandingPageApi/LandingPageApi'
import { GridLoader } from 'react-spinners'
import CustomLink from '@Styles/CustomLink'
import { useGetAllInstructorsQuery } from '@Features/Instructor/InstructorApi/Instructor.api'
// import Avatar from '@Components/Utilities/Avatar/Avatar'
import { DefaultAvatarPlaceholderImage } from '@Assets/Images'
import AutoPlaySwiper from '@Components/Utilities/AutoPlaySwiper'
import { space, typography, TypographyProps } from 'styled-system'
import Skeleton from '@Components/Utilities/Skeleton/Skeleton'
import SkeletonCarousel from '@Components/Utilities/Skeleton/Components/SkeletonCarousel'
import { SkeletonLoadingAnimation } from '@Components/Utilities/Skeleton/Skeleton.styled'
import SkeletonTitle from '@Components/Utilities/Skeleton/Components/SkeletonTitle'
import SkeletonAvatar from '@Components/Utilities/Skeleton/Components/SkeletonAvatar'
import { SectionWrapper } from '@Features/LandingPage/Assets/LandingPage.styled'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@eduact/ed-components'
import { useStudentScreenSize } from '@eduact/student-theme'
import { FlexLayout } from '@eduact/ed-system'

const InstructorsCarousel: React.FC = () => {
	const { data, isLoading, isSuccess, status } = useGetAllInstructorsQuery()
	const [t] = useTranslation()
	const { isDesktop } = useStudentScreenSize()
	const itemsNumber = useMemo(() => {
		return data && data.data.length
	}, [data])
	return (
		<SkeletonCarousel
			gridGap={'1rem'}
			isLoading={isLoading}
			numberOfItems={4}
			renderComponent={
				<InstructorAvatarSkeletonStyled>
					<SkeletonAvatar />
					<SkeletonTitle height={'1rem'} width={'10rem'} />
				</InstructorAvatarSkeletonStyled>
			}
		>
			<StyledInstructorCarousel my={{ sm: '1rem', lg: '2rem' }} px={{ lg: '8.125rem' }}>
				<InstructorsTitle fontSize={{ sm: '.75rem', lg: '1.25rem' }}>
					<div>{t('landingPage:collaborate')}</div>
					<span>{t('landingPage:best')}</span>
				</InstructorsTitle>
				{isSuccess && data && itemsNumber && (itemsNumber > 7 || !isDesktop) && (
					<>
						<AutoPlaySwiper>
							{data.data.map((instructor, index) => (
								<AutoPlaySwiper.Item key={`${instructor.lms_id}-${index}-carousel`} label={`${instructor.instructor.label}`}>
									<CustomLink to={`/instructor/${instructor.instructor.label}`} key={`${instructor.lms_id}${index}`}>
										<InstructorAvatarStyled>
											<Avatar
												withBorder
												borderColor="purple"
												avatarSize={{ lg: 'xlarge', default: 'medium' }}
												img={instructor.profile_photo ?? DefaultAvatarPlaceholderImage}
											/>
											<InstructorLabel textAlign={'center'} fontSize={{ sm: '0.75rem', lg: '1rem' }}>
												{instructor.first_name} {instructor.last_name}
											</InstructorLabel>
										</InstructorAvatarStyled>
									</CustomLink>
								</AutoPlaySwiper.Item>
							))}
						</AutoPlaySwiper>
					</>
				)}
				{isSuccess && data && itemsNumber && itemsNumber <= 7 && isDesktop && (
					<FlexLayout gridGap={'4rem'}>
						{data.data.map((instructor, index) => (
							<AutoPlaySwiper.Item key={`${instructor.lms_id}-${index}-carousel`} label={`${instructor.instructor.label}`}>
								<CustomLink to={`/instructor/${instructor.instructor.label}`} key={`${instructor.lms_id}${index}`}>
									<InstructorAvatarStyled>
										<Avatar
											withBorder
											borderColor="purple"
											avatarSize={{ lg: 'xlarge', default: 'medium' }}
											img={instructor.profile_photo ?? DefaultAvatarPlaceholderImage}
										/>
										<InstructorLabel fontSize={{ sm: '0.75rem', lg: '1rem' }}>
											{instructor.first_name} {instructor.last_name}
										</InstructorLabel>
									</InstructorAvatarStyled>
								</CustomLink>
							</AutoPlaySwiper.Item>
						))}
					</FlexLayout>
				)}
			</StyledInstructorCarousel>
		</SkeletonCarousel>
	)
}

const StyledInstructorCarousel = styled(SectionWrapper)`
	margin: 0 auto;
	text-align: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		text-align : initial;
	}`}
	${space}
`
const InstructorAvatarStyled = styled.div`
	white-space: normal;
	display: grid;
	place-items: center;
	padding: 0.5rem 0;
`
const InstructorAvatarSkeletonStyled = styled(InstructorAvatarStyled)``
const InstructorLabel = styled.label<TypographyProps>`
	font-weight: 600;
	${typography}
	margin : 0.5rem 0;
`
const InstructorLabelSkeleton = styled(InstructorLabel)`
	height: 1rem;
	width: 4rem;
	animation: ${SkeletonLoadingAnimation} infinite 400ms;
	background: red;
`
const InstructorsTitle = styled.div<TypographyProps>`
	${typography}
	margin-bottom: 2rem;
	white-space: pre;
	display: flex;
	align-items: center;
	span {
		text-decoration: underline;
		font-weight: bolder;
		margin: 0 0.5rem;
	}
`
export default InstructorsCarousel
