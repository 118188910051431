import { BottomSheet } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout, Stack, useThemeScreenSize } from '@eduact/ed-system'
import { useStudentScreenSize } from '@eduact/student-theme'
import { useTest } from '@Features/Test/Providers/TestProvider'
import { useAppSelector } from '@Hooks/ReduxHooks'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useCountdown } from 'usehooks-ts'
import MultipleViewMode from '../MultipleViewMode/MultipleViewMode'
import SingleViewMode from '../SingleViewMode/SingleViewMode'
import TestTimer from '../TestTimer/TestTimer'
import { useWorkerInterval } from '@eduact/utils'
import { useUpdateUnitLearningTimeMutation } from '@Features/Unit/UnitApi'
import { useParams } from 'react-router'
import axios from 'axios'
const TestPlayground = () => {
	const { drawerOpened, mode, test, overallProgress, formQuestions } = useTest()

	const { isMobile } = useStudentScreenSize()

	const { id } = useParams<{ id: string }>()
	const [updateLearningTime] = useUpdateUnitLearningTimeMutation()
	const { worker, startWorker, stopTimer } = useWorkerInterval(10000)
	useEffect(() => {
		if (!worker) return
		worker.onmessage = e => {
			updateLearningTime({
				id: Number(test.unit_id),
				time: 10,
			})
		}
		startWorker()

		return () => {
			worker.terminate()
		}
	}, [worker, id])

	const [timeNow, setTimeNow] = useState<number | undefined>()
	useEffect(() => {
		const getTimeNow = async () => {
			const res = await axios.get(`${process.env.REACT_APP_API_BASE}/now`)
			setTimeNow(res.data as number)
		}
		getTimeNow()
	}, [])

	return (
		<TestContainer drawerOpened={drawerOpened}>
			{/* {timeNow} */}
			<TestHeaderContainer justifyContent={'space-between'}>
				<TestTitle>{test.unit?.name}</TestTitle>
				{test.duration && (
					<TimerWrapper>
						{overallProgress && timeNow && (
							<>
								<TestTimer overallProgress={overallProgress} timeNow={timeNow} />
							</>
						)}
					</TimerWrapper>
				)}
			</TestHeaderContainer>
			<div>
				{formQuestions.length > 0 && mode === 'single' && <QuestionsWrapper>{<SingleViewMode />}</QuestionsWrapper>}
				{formQuestions.length > 0 && mode === 'multiple' && <QuestionsWrapper>{<MultipleViewMode />}</QuestionsWrapper>}
				{isMobile && <Spacer mb="6.5rem" />}
			</div>
		</TestContainer>
	)
}

export default TestPlayground

const TestHeaderContainer = styled(FlexLayout)`
	/* position: sticky; */
	/* top: 0; */
`
const TestContainer = styled.section<{ drawerOpened: boolean }>`
	background: #f8f8fe;
	position: relative;
	flex: 3;
	border-radius: 30px;
	height: 100%;
	padding: 12px 5px;
	transition: all ease-in-out 200ms;
	${({ theme, drawerOpened }) => `${theme.mediaQueries.large}{
		max-height: unset;
		padding : 46px 60px;
		grid-gap : ${!drawerOpened && '2rem'};
		grid-template-areas: ${drawerOpened ? '"title . timer" "section section section"' : '"title section timer" ". section ."'};
	}`}
`

const TestTitle = styled.h1`
	font-weight: bold;
	font-size: 14px;
	/* grid-area: title; */
	padding: 0.688rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding :0;
		font-size : 24px;
		display:flex;
		align-items : center;
	}`}
`
const TimerWrapper = styled.div`
	/* grid-area: timer; */

	padding: 0.688rem;
	position: sticky;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding :0;
	}`}
`
const QuestionsWrapper = styled.div`
	/* grid-area: section; */
	${({ theme }) => `${theme.mediaQueries.large}{
		margin-top : 1rem;
	}`}
`
