import { ToggleButton } from '@Components/Button/ToggleButton'
import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout, Typography } from '@eduact/ed-system'
import { NumberFormatter } from '@eduact/utils'
import { useTest } from '@Features/Test/Providers/TestProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import DrawerContent from '../DrawerContent/DrawerContent'

const TestDrawer = () => {
	const { drawerOpened, openDrawer, questions } = useTest()
	const { t } = useTranslation(['test'])

	return (
		<StyledTestDrawer opened={drawerOpened}>
			<DrawerHeader>
				<ToggleButton opened={drawerOpened} onClick={() => openDrawer(false)} />
				<Spacer mb={'2.875rem'} />
				<Typography fontSize={{ lg: '1.25rem' }} fontWeight={{ lg: '600' }}>
					{t('all_questions')} {NumberFormatter(questions.length)}
				</Typography>
			</DrawerHeader>
			<DrawerContent />
		</StyledTestDrawer>
	)
}

export default TestDrawer

const StyledTestDrawer = styled.div.attrs(p => ({ dir: document.documentElement.dir }))<{ opened: boolean }>`
	display: none;
	border-radius: 30px;
	flex: ${props => (!props.opened ? '0' : '1')};
	background: #f8f8fe;
	border-radius: 30px;
	width: ${props => (!props.opened ? '0px' : '100%')};
	opacity: ${props => (!props.opened ? '0' : '1')};
	transition: all cubic-bezier(0.77, 0, 0.18, 1) 600ms, opacity ease-in-out 200ms;
	max-height: 85vh;
	position: sticky;
	overflow: auto;
	top: 0;
	svg {
		transform: ${props => props.dir === 'rtl' && 'rotate(180deg)'};
	}
	${({ theme }) => `${theme.mediaQueries.large}{
		display:block;
	}`}
`

const DrawerHeader = styled.div`
	padding: 20px;
`
