import React, { useRef, useState } from 'react'
import Avatar from '@Components/Utilities/Avatar/Avatar'
import SkeletonAvatar from '@Components/Utilities/Skeleton/Components/SkeletonAvatar'
import { useGetUserProfileQuery } from '../DashboardSlice/Dashboard.slice'
import styled from 'styled-components'
import { FlexLayout } from '@eduact/ed-system'
import { rgba } from 'polished'
import { useTranslation } from 'react-i18next'
import ProfilePictureModal from './Components/ProfilePictureModal'

const UploadProfilePhoto = () => {
	const [t] = useTranslation('dashboard')
	const _ref = useRef<HTMLInputElement>(null)
	const [ppOpened, setPPOpened] = useState(false)
	const [ppFile, setPPFile] = useState<string | ArrayBuffer | null>(null)
	const { data, isLoading } = useGetUserProfileQuery()
	const handlePPFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			getBase64(e.target.files[0])
		}
	}
	const getBase64 = (file: File) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setPPOpened(true)
			setPPFile(reader.result)
		}
		reader.onerror = error => {
			console.log('Error: ', error)
		}
	}
	return (
		<AvatarWrapper>
			<SkeletonAvatar isLoading={isLoading}>
				<AvatarStyle
					withBorder
					borderColor="silver"
					onClick={() => {
						if (_ref?.current) {
							_ref.current.click()
						}
					}}
					avatarSize={{ default: 'large', md: 'large', lg: 'xlarge' }}
					img={data?.data.profile_photo}
				>
					<Overlay>
						<FlexLayout alignItems="center" gridGap="4px">
							<Icon className="material-symbols-outlined">add</Icon>
							<OverlayText>{t('upload')}</OverlayText>
						</FlexLayout>
					</Overlay>
				</AvatarStyle>
			</SkeletonAvatar>
			<input
				type="file"
				ref={_ref}
				hidden={true}
				id="pp_input"
				onChange={handlePPFileChanged}
				onClick={(event: React.MouseEvent<HTMLInputElement>) => {}}
				accept="image/*"
			/>
			<ProfilePictureModal showModal={ppOpened} setShowModal={setPPOpened} file={ppFile} onSuccess={() => {}} />
		</AvatarWrapper>
	)
}

const AvatarStyle = styled(Avatar)`
	overflow: hidden;
	position: relative;
	width: 6.313rem;
	height: 6.313rem;
`
const AvatarWrapper = styled(FlexLayout)`
	justify-content: center;
	margin-top: 2.75rem;
	z-index: 999;
`
const Overlay = styled(FlexLayout)`
	position: absolute;
	width: 100%;
	height: 1.475rem;
	bottom: 0.575rem;
	background: ${rgba('#5AC0FC', 0.25)};
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&:hover {
		background: ${rgba('#5AC0FC', 0.4)};
		transition: background 0.2s ease-in-out;
	}
`
const OverlayText = styled.p`
	font-size: 0.52rem;
	font-weight: 600;
	color: #2196f3;
`
const Icon = styled.span`
	color: #2196f3;
	font-size: 0.875rem;
	font-weight: bold;
`
export default UploadProfilePhoto
