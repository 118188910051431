import ViewAllButton from '@Components/Button/ViewAllButton/ViewAllButton'
import ClassroomCard from '@Components/Cards/ClassroomCard'
import SkeletonCard from '@Components/Utilities/Skeleton/Components/SkeletonCard'
import SkeletonCarousel from '@Components/Utilities/Skeleton/Components/SkeletonCarousel'
import { Swiper } from '@Components/Utilities/Swiper'
import { FlexLayout } from '@eduact/ed-system'
import { SectionWrapper } from '@Features/LandingPage/Assets/LandingPage.styled'
import { useGetClassroomsQuery } from '@Features/LandingPage/LandingPageApi/LandingPageApi'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SectionTitle } from '../Sections.styled'

const RevisionsSection: React.FC = () => {
	const { revisions, isLoading, isFetching } = useGetClassroomsQuery(skipToken, {
		selectFromResult: data => {
			return {
				revisions: data.data?.data.filter(_ => _.sub_type === 'revision') ?? [],
				isLoading: data.isLoading,
				isFetching: data.isFetching,
			}
		},
	})
	const {
		classroomsSections: { revision_section },
	} = useAppSelector(state => state.settings)
	const history = useNavigate()
	const [t] = useTranslation('landingPage', { keyPrefix: 'courses' })

	return (
		<React.Fragment>
			<SkeletonCarousel my={'5.25rem'} hasTitle isLoading={isLoading || isFetching} numberOfItems={4} renderComponent={<SkeletonCard />}>
				{revision_section && (
					<SectionWrapper my={{ sm: '2.8rem', lg: '5.25rem' }}>
						{revisions.length > 0 && (
							<div>
								<FlexLayout justifyContent={'space-between'} alignItems="baseline">
									<SectionTitle>
										<label>{t('revision_classrooms')}</label>
									</SectionTitle>
									<ViewAllButton />
								</FlexLayout>
								<Swiper isIndicator padding={'1rem'} withProgress gridGap={'2rem'}>
									{revisions.map((cl, index) => {
										return (
											<ClassroomCard
												classroom={cl}
												badgeLabel={cl.type}
												title={cl.title}
												grade={cl.educationYears[0].name}
												subject={cl.category.name}
												subjectImage={cl.category.icon}
												thumbnail={cl.thumbnail}
												instructor={cl.instructor.user.first_name}
												key={`${cl.id}-${index}`}
											/>
										)
									})}
								</Swiper>
							</div>
						)}
					</SectionWrapper>
				)}
			</SkeletonCarousel>
		</React.Fragment>
	)
}

export default RevisionsSection
