import routes from '@Constants/Routes'
import { Icon, Icons } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import i18next from 'i18next'
import { rgba } from 'polished'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router'
import styled from 'styled-components'
import { NavigationLayoutProps } from './DashboardLayout.types'
import { Tab, TabList, Tabs } from './Tabs'
import UploadProfilePhoto from './UploadProfilePhoto'

type DashboardRoute = 'profile' | '' | 'activity' | 'classrooms' | 'settings'
const SideMenuNavigation: React.VoidFunctionComponent<NavigationLayoutProps> = ({ currentTab }) => {
	const [t] = useTranslation("studentDashboard")
	const { dir } = i18next
	const navigate = useNavigate()
	const handleOnNavigate = (route: DashboardRoute) => {
		navigate(`${routes.dashboard}/${route}`)
	}

	return (
		<Wrapper>
			<InnerWrapper>
				<Tabs active={currentTab}>
					<TabsListWrapper>
						<Container>
							<Header> {t("dashboard")} </Header>
							<UploadProfilePhoto />
							<TabList label="profile">
								<span onClick={() => handleOnNavigate('')}>
									<Tab id="/">
										<TabContainer direction={dir()} alignItems="center" justifyContent="start" width="6.25rem" gridGap="0.844rem">
											<Icon color="dark" fontSize={'1rem'}>
												<Icons.Dashboard />
											</Icon>
											<SideItem>{t("overview")}</SideItem>
										</TabContainer>
									</Tab>
								</span>
								<span onClick={() => handleOnNavigate('profile')}>
									<Tab id="/profile">
										<TabContainer direction={dir()} alignItems="center" justifyContent="start" width="100px" gridGap="0.844rem">
											<Icon color="dark" fontSize={'1rem'}>
												<Icons.User />
											</Icon>
											<SideItem>{t("profile")}</SideItem>
										</TabContainer>
									</Tab>
								</span>
								<span onClick={() => handleOnNavigate('classrooms')}>
									<Tab id="/classrooms">
										<TabContainer direction={dir()} alignItems="center" justifyContent="start" width="100px" gridGap="0.844rem">
											<Icon color="dark" fontSize={'1rem'}>
												<Icons.ClassBookmark />
											</Icon>
											<SideItem>{t("classrooms")}</SideItem>
										</TabContainer>
									</Tab>
								</span>
								<span onClick={() => handleOnNavigate('activity')}>
									<Tab id="/activity">
										<TabContainer direction={dir()} alignItems="center" justifyContent="start" width="100px" gridGap="0.844rem">
											<Icon color="dark" fontSize={'1rem'}>
												<Icons.Activity />
											</Icon>
											<SideItem>{t("activity")}</SideItem>
										</TabContainer>
									</Tab>
								</span>
								<span onClick={() => handleOnNavigate('settings')}>
									<Tab id="/settings">
										<TabContainer direction={dir()} alignItems="center" justifyContent="start" width="100px" gridGap="0.844rem">
											<Icon color="dark" fontSize={'1rem'}>
												<Icons.Settings />
											</Icon>
											<SideItem>{t("settings")}</SideItem>
										</TabContainer>
									</Tab>
								</span>
							</TabList>
						</Container>
					</TabsListWrapper>
					<Content>
						<Outlet />
					</Content>
				</Tabs>
			</InnerWrapper>
		</Wrapper>
	)
}

const TabsListWrapper = styled.div`
	position: sticky;
	top: 0;
`
export const Wrapper = styled.div`
	display: none;
	width: 100%;
	background-color: ${rgba('#ecebfc', 0.7)};
	${({ theme }) => `${theme.mediaQueries.large}{
		display: flex;
		height: inherit;
		-webkit-box-align: stretch;
		align-items: stretch;
		padding: 20px 28px;
	}`}
`
const InnerWrapper = styled.div`
	width: 100%;
	overflow: auto;
	@media screen and (min-width: 769px) {
		display: flex;
		height: inherit;
		-webkit-box-align: stretch;
		align-items: stretch;
		background-color: rgba(256, 256, 256, 0.4);
		padding: 12.5px 21px;
		border-radius: 30px;
		grid-gap: 1.875rem;
	}
`
export const Container = styled.div`
	width: 17.225rem;
	background: #fff;
	border-radius: 15px;
	box-shadow: none;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	height: 100%;
	transition: width 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
`
const Header = styled.p`
	font-size: 1.125rem;
	font-weight: 600;
	color: #251b33;
	margin: 0;
	padding: 20.9px 74px 0 27.8px;
`
const Content = styled.div`
	width: 100%;
	/* padding: 1rem; */
	/* padding-top: 0.813rem; */
`
const SideItem = styled.p`
	font-size: 1.125rem;
	font-weight: 500; ;
`
const TabContainer = styled(FlexLayout)<{ direction: string }>`
	margin-left: ${({ direction }) => direction === 'ltr' && '-2.25rem'};
	margin-right: ${({ direction }) => direction === 'rtl' && '-2.25rem'};
`

export default SideMenuNavigation
