import routes from '@Constants/Routes'
import { Icon, Icons, NavigationBar } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout, Typography } from '@eduact/ed-system'
import { rgba } from 'polished'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Outlet, useNavigate } from 'react-router'
import styled from 'styled-components'
import { NavigationLayoutProps } from './DashboardLayout.types'

const ResponsiveLayout: React.VoidFunctionComponent<NavigationLayoutProps> = ({ currentTab }) => {
	const [t] = useTranslation("studentDashboard")
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const handleOnChange = (value: string | number) => {
		if (pathname.endsWith(value.toString())) return
		navigate(`${routes.dashboard}${value}`)
	}
	return (
		<Wrapper>
			<FlexLayout minHeight={'90vh'} flex={1}>
				<Outlet />
			</FlexLayout>
			<FixedNavigationBar onChange={(value: any) => handleOnChange(value as string)} value={currentTab ?? ''}>
				<NavigationBar.Item
					icon={
						<Icon>
							<Icons.Dashboard />
						</Icon>
					}
					value="/"
					label={t("overview")}
				/>
				<NavigationBar.Item
					icon={
						<Icon>
							<Icons.User />
						</Icon>
					}
					value="/profile"
					label={t("profile")}
				/>
				<NavigationBar.Item
					icon={
						<Icon>
							<Icons.ClassBookmark />
						</Icon>
					}
					value="/classrooms"
					label={t("classrooms")}
				/>
				<NavigationBar.Item
					icon={
						<Icon>
							<Icons.Activity />
						</Icon>
					}
					value="/activity"
					label={t("activity")}
				/>
				<NavigationBar.Item
					icon={
						<Icon>
							<Icons.Settings />
						</Icon>
					}
					value="/settings"
					label={t("settings")}
				/>
			</FixedNavigationBar>
		</Wrapper>
	)
}

const FixedNavigationBar = styled(NavigationBar)`
	width: 100%;
	height: fit-content;
	position: sticky;
	bottom: 0;
	z-index: 999;
`
const IconStyle = styled(Icon)`
	color: #000;
`
export const Wrapper = styled.div`
	width: 100%;
	background-color: ${rgba('#ecebfc', 0.7)};
	padding: 11px 9px;
	padding-bottom: 0;
	${({ theme }) => `${theme.mediaQueries.large}{
		display: none;
		height: inherit;
		-webkit-box-align: stretch;
		align-items: stretch;
	}`}
`
export const Container = styled.div`
	width: 100%;
	background: #fff;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	box-shadow: none;
	transition: width 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
	position: absolute;
	bottom: 0;
	box-shadow: -4px -2px 4px 0 rgba(0, 0, 0, 0.16);
	height: 2.563rem;
	display: flex;
	padding: 11.4px 8px 0 8.5px;
`
const Content = styled.div`
	padding: 2.5rem 1.5rem 0 2rem;
`

export default ResponsiveLayout
