import React from 'react'
import styled from 'styled-components'
import Category from './Category'
import * as CategoriesIcons from './CategoriesIcons'
import { useTranslation } from 'react-i18next'
import { Colors } from '@eduact/student-theme'

const Categories = () => {
	const [t] = useTranslation('landingPage', { keyPrefix: 'categories' })
	return (
		<StyledCategories>
			<Header>
				{t('sentence')} <SubjectsSpan>{t('subjects')}</SubjectsSpan>
			</Header>

			<SubjectWrapper>
				<SubjectsContainer>
					<Category icon={<CategoriesIcons.ChemistryIcon />} name="Chemistry" />
					<Category icon={<CategoriesIcons.PhysicsIcon />} name="Physics" />
					<Category icon={<CategoriesIcons.BiologyIcon />} name="Biology" />
				</SubjectsContainer>

				<SubjectsContainer>
					<Category icon={<CategoriesIcons.MathIcon color={Colors.princetonOrange} />} name="Math" />
					<Category icon={<CategoriesIcons.EnglishIcon color={Colors.princetonOrange} />} name="English" />
					<Category icon={<CategoriesIcons.FrenchIcon color={Colors.princetonOrange} />} name="French" />
				</SubjectsContainer>

				<SubjectsContainer>
					<Category icon={<CategoriesIcons.GermanIcon />} name="German" />
					<Category icon={<CategoriesIcons.ItalianIcon />} name="Italian" />
					<Category icon={<CategoriesIcons.MathIcon />} name="رياضيات" />
				</SubjectsContainer>

				<SubjectsContainer>
					<Category icon={<CategoriesIcons.HistoryIcon color={Colors.princetonOrange} />} name="تاريخ" />
					<Category icon={<CategoriesIcons.GeographyIcon color={Colors.princetonOrange} />} name="جغرافيا" />
					<Category icon={<CategoriesIcons.GeologyIcon color={Colors.princetonOrange} />} name="جيولوجيا" />
				</SubjectsContainer>

				<SubjectsContainer>
					<Category icon={<CategoriesIcons.ArabicIcon />} name="لغة عربية" />
					<Category icon={<CategoriesIcons.PsychologyIcon />} name="علم نفس و اجتماع" />
					<Category icon={<CategoriesIcons.PhilosophyIcon />} name="فلسفة و منطق" />
				</SubjectsContainer>

				<SubjectsContainer>
					<Category icon={<CategoriesIcons.ChemistryIcon color={Colors.princetonOrange} />} name="كيمياء" />
					<Category icon={<CategoriesIcons.PhysicsIcon color={Colors.princetonOrange} />} name="فيزياء" />
					<Category icon={<CategoriesIcons.BiologyIcon color={Colors.princetonOrange} />} name="أحياء" />
				</SubjectsContainer>
			</SubjectWrapper>
		</StyledCategories>
	)
}

const StyledCategories = styled.div`
	margin: 60px 0;
	padding: 0 1.25rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 0 8.375rem;
	}`}
`

const Header = styled.p`
	color: black;
	display: block;
	font-size: 0.75rem;
	margin: 0 1rem;
	margin-bottom: 26px;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.5rem;
	}`}
`

const SubjectsSpan = styled.span`
	font-weight: bold;
	text-decoration: underline;
`

const SubjectWrapper = styled.div`
	margin: 0 auto;
	flex-wrap: wrap;
	justify-content: space-evenly;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 3rem;

	@media (min-width: 768px) {
		padding: 0 5.5rem;
		width: 100%;
		display: flex;
	}
	${({ theme }) => `${theme.mediaQueries.large}{
		grid-column-gap: 1rem;
		padding: 0 0.5rem;
	}`}
`

const SubjectsContainer = styled.div`
	${({ theme }) => `${theme.mediaQueries.medium}{
	min-width: 200px;
	margin: 16px 0;

}`}

	${({ theme }) => `${theme.mediaQueries.large}{
	min-width: 160px;
	// margin: 16px 0;

}`}
`

export default Categories
