import AuthenticationApi from '@Features/Authentication/AuthenticationApi/Authentication.api'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import SettingsApi from '../SettingsApi/Settings.api'
import { SettingsSliceInitialState } from './Settings.slice.types'

const SLICE_BASE_NAME = 'settings'

export const getSettings = createAsyncThunk(`${SLICE_BASE_NAME}/all`, async () => {
	const { data } = await SettingsApi.getInstance().getSettings()
	return data
})

export const getAssets = createAsyncThunk(`${SLICE_BASE_NAME}/all/assets`, async () => {
	const { data } = await SettingsApi.getInstance().getAssets()
	return data
})
export const getEducationalInfo = createAsyncThunk(`${SLICE_BASE_NAME}/all-edu-info`, async () => {
	const { data } = await AuthenticationApi.getEducationalInfo()
	return data
})
const initialState: SettingsSliceInitialState = {
	assetsLoading: false,
	classroomsSections: {
		exams_section: false,
		online_cl: false,
		revision_section: false,
		device_limit: 0,
	},
	paymentMethods: {
		recharge_card: false,
		aman_accept: false,
		credit_card_accept: false,
		ewallet_accept: false,
		ewallet_opay: false,
		fawry_fawry: false,
		masary_accept: false,
		credit_card_opay: false,
		ewallet_fawry: false,
	},
	fees: {},
	parent_portal: false,
	progress: false,
	created_at: undefined,
	updated_at: undefined,
	isOnline: true,
	theme: 'primary',
	assets: {},
}

const settingsSlice = createSlice({
	name: SLICE_BASE_NAME,
	initialState,
	reducers: {
		updateOnlineStatus: (state, { payload }: PayloadAction<boolean>) => {
			state.isOnline = payload
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getSettings.pending, () => {})
			.addCase(getSettings.fulfilled, (state, { payload: { data } }) => {
				state.paymentMethods = {
					aman_accept: data.aman_accept,
					credit_card_accept: data.credit_card_accept,
					ewallet_accept: data.ewallet_accept,
					ewallet_opay: data.ewallet_opay,
					fawry_fawry: data.fawry_fawry,
					masary_accept: data.masary_accept,
					recharge_card: data.recharge_card,
					ewallet_fawry: data.ewallet_fawry,
					credit_card_opay: data.credit_card_opay,
				}

				state.classroomsSections = {
					exams_section: data.exams_section,
					online_cl: data.online_cl,
					revision_section: data.revision_section,
					device_limit: data.device_limit,
				}
				state.fees = {
					amount: data.registration_fees,
					old_amount: data.surcharge_registration_fees,
				}
				state.parent_portal = data.parent_portal
				state.progress = data.progress
				state.created_at = data.created_at
				state.updated_at = data.updated_at
				state.theme = data.theme
				state.landing_headline = data.landing_headline
				state.landing_subheadline = data.landing_subheadline
				state.about_us_page = data.about_us_page
				state.fb_link = data.fb_link
				state.youtube_link = data.youtube_link
				state.instructor_section = data.instructor_section
				state.subjects_section = data.subjects_section
				state.instagram_link = data.instagram_link
				state.linkedin_link = data.linkedin_link
				state.privacy_link = data.privacy_link
				state.terms_link = data.terms_link
				state.meta = {
					title: data.meta_title,
				}
				state.app_wallet = data.app_wallet
			})
		builder.addCase(getEducationalInfo.fulfilled, (state, { payload }) => {
			state.educationalInfo = payload.data
		})
		builder
			.addCase(getAssets.pending, state => {
				state.assetsLoading = true
			})
			.addCase(getAssets.fulfilled, (state, { payload }) => {
				state.assets = payload.data
				state.assetsLoading = false
			})
	},
})

export const { updateOnlineStatus } = settingsSlice.actions
export default settingsSlice
