import React from 'react'
import styled from 'styled-components'
import GuestCards from '@Features/LandingPage/GuestLanding/GuestCards'
import InstructorsCarousel from '@Features/LandingPage/Components/InstructorsCarousel'
import FindClassroomSection from '@Features/LandingPage/GuestLanding/Components/FindClassroomSection'
import GuestLandingClassrooms from '@Features/LandingPage/GuestLanding/Components/GuestLandingClassrooms'
import Categories from '@Features/LandingPage/Components/Categories'
import Stats from '@Features/LandingPage/Components/Stats'
import NewsFeedsSection from '../Components/NewsFeedsSection/NewsFeedsSection'
import CTASection from '../Components/CTASection/CTASection'
import OfferSection from '../Components/OfferSection/OfferSection'
import Spacer from '@eduact/ed-components/Spacer'
import { useAppSelector } from '@Hooks/ReduxHooks'

const GuestLanding: React.FC = () => {
	const { instructor_section, subjects_section } = useAppSelector(s => s.settings)
	return (
		<>
			<StyledGuestLandingContainer>
				<CTASection />
				<GuestLandingInnerContainer>
					{instructor_section && <InstructorsCarousel />}
					<FindClassroomSection />
					<Spacer px={{ lg: '12.688rem' }} my={{ sm: '1.5rem', lg: '2rem' }}>
						<NewsFeedsSection />
					</Spacer>
					<GuestCards />
					<GuestLandingClassrooms />
					{subjects_section && <Categories />}
					<Stats />
					<OfferSection />
				</GuestLandingInnerContainer>
			</StyledGuestLandingContainer>
		</>
	)
}

const StyledGuestLandingContainer = styled.div`
	width: 100%;
`
const GuestLandingInnerContainer = styled.div`
	padding: 1rem;
	${({ theme }) => `${theme.mediaQueries.large}{
	padding: 26px 0;
	
}`}
`

export default GuestLanding
