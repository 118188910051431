import { FlexLayout } from '@eduact/ed-system'
import { useStudentScreenSize } from '@eduact/student-theme'
import React from 'react'
import InstructorWidget from './InstructorWidget'
import LearningTimeWidget from './LearningTimeWidget'

const InstrcutorInfo = () => {
	const { isDesktop } = useStudentScreenSize()
	return (
		<FlexLayout flexDirection={isDesktop ? 'column' : 'row'} gridGap={isDesktop ? '1.169rem' : '0.363rem'}>
			<InstructorWidget />
			<LearningTimeWidget />
		</FlexLayout>
	)
}

export default InstrcutorInfo
