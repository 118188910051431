const routes = {
	// Main
	home: '/',
	// Auth
	login: '/login',
	signup: '/signup',
	about: '/about',
	forgotPassword: '/forgot-password',
	resetPassword: '/reset-password',
	resendEmail: '/resend-email',
	verifyEmail: '/verify/:token',
	completeProfile: '/complete-profile',
	// User
	dashboard: '/dashboard',
	progress: '/progress',
	timeline: '/timeline',
	invoices: '/invoices',
	wallet: '/wallet',
	// Classroom
	classroom: '/classroom/:label',
	classroomOverview: '/classroom/:label',
	// Course
	course: 'course/:cId',
	// Units
	videoUnit: 'video/:id',
	documentUnit: 'document/:id',
	webContentUit: 'webcontent/:id',
	webinarUnit: 'webinar/:id',
	// Test
	test: '/test/:id',
	// Instructor
	instructor: '/instructor/:label',
	// Others

	mcqUnit: 'test/:id',
	assignmentUnit: 'assignment/:id',
	results: '/results',
	paymentCallback: '/payment/callback',
	faqs: '/faqs',
} as const

type Keys = keyof typeof routes
export type Route = typeof routes[Keys]

export default routes
