import { FlexLayout } from '@eduact/ed-system'
import { useGetUserClassroomsQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ClassroomCard from './ClassroomCard'

const ClassroomsWidget = () => {
	const [t] = useTranslation('classroomsOverview')
	const { data } = useGetUserClassroomsQuery()
	return (
		<Wrapper>
			<Title>{t('myClassrooms')}</Title>
			<FlexLayout flexDirection="column" gridGap="0.375rem">
				{data?.data.map((cl, index) => {
					return <ClassroomCard classroom={cl} />
				})}
			</FlexLayout>
		</Wrapper>
	)
}

export const Wrapper = styled.div`
	border-radius: 15px;
`
export const Title = styled.p`
	font-size: 0.75rem;
	font-weight: 600;
	color: #251b33;
	margin-bottom: 0.5rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: 1.125rem;
    margin-bottom: 0.875rem;
	}`}
`
export default ClassroomsWidget
