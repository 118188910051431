import { Attachment, RaisedButton, useSnackbar, AttachmentProps, AttachmentType, Dialog, imageTypes } from '@eduact/ed-components'
import { uploadFileWithProgress, useLazyUploadQuery } from '@Features/Base/BaseApi/BaseApi'
import { SnackbarConfig } from '@Utilities/SnackbarConfig'
import { AxiosProgressEvent } from 'axios'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { CircleLoader, PuffLoader } from 'react-spinners'
import { rgba } from 'polished'
import { FlexLayout, Stack } from '@eduact/ed-system'

// Allowed Files
const allowedTypes = ['application/pdf', 'image/*']

// Props

type AttachmentData = {
	url: string
	type: AttachmentType
}
type Props = {
	onChange: (value: string) => void
	value: string
	isEdit?: boolean
}
const AttachmentAnswer: React.VoidFunctionComponent<Props> = ({ onChange, value, isEdit = true }) => {
	const inputRef = useRef<HTMLInputElement | null>(null)

	const { openSnackbar } = useSnackbar()
	const handleOnClick = () => inputRef.current?.click()

	const [files, setFiles] = useState<Array<File> | null>(null)
	const answer = useMemo<Array<AttachmentData>>(() => {
		if (!value || value === '') return []
		const _parsedArray = JSON.parse(value) as Array<string>
		return _parsedArray.map<AttachmentData>(attachment => ({ url: attachment, type: attachment.split('.').pop() as AttachmentType }))
	}, [value])
	const onFilesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files
		if (!files) return
		for (let file of files) {
			if (!allowedTypes.some(_ => file.type.match(_))) {
				openSnackbar({ ...SnackbarConfig.disclaimer, text: 'Only Images & Pdfs are allows' })
				return
			}
		}
		setFiles(Array.from(files))
	}

	const onFileUploadFinish = (uri: string, index: number) => {
		if (!files) return
		const file = files[index]
		const _ = Array.from(files)
		_.splice(index, 1)
		setFiles(_)
		onChange(JSON.stringify(answer.map(_ => _.url).concat(...[uri])))
	}
	const onFail = (index: number) => {
		if (!files) return
		const _ = files
		_.splice(index, 1)
		setFiles(_)
	}
	const onDelete = (index: number) => {
		const _ = answer
		_.splice(index, 1)
		onChange(JSON.stringify(_.map(_ => _.url)))
	}
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [openedAttachment, setOpenedAttachment] = useState<AttachmentData | undefined>()
	const handleAttachmentClick = (attachment: AttachmentData) => {
		if (!imageTypes.includes(attachment.type) && attachment.type !== 'pdf') {
			window.open(attachment.url, '_blank')
			return
		}
		setOpenedAttachment(attachment)
		setIsDialogOpen(true)
	}
	const handleDialogClose = () => {
		// setOpenedAttachment(undefined)
		setIsDialogOpen(false)
	}
	return (
		<div>
			<Dialog persistent={false} overflow={'auto'} maxWidth={{ sm: '90vw', lg: '80vw' }} open={isDialogOpen} onClose={handleDialogClose}>
				<FlexLayout justifyContent={'center'} alignItems="center" width={'100%'} height="100%">
					{openedAttachment && imageTypes.includes(openedAttachment.type) && <img style={{ width: '100%' }} src={openedAttachment.url} />}
					{openedAttachment && openedAttachment.type === 'pdf' && <iframe width={'100%'} height="100%" src={openedAttachment.url}></iframe>}
				</FlexLayout>
			</Dialog>
			<input
				onClick={(e: React.MouseEvent<HTMLInputElement>) => {
					e.currentTarget.value = ''
				}}
				onChange={onFilesChange}
				ref={inputRef}
				type={'file'}
				multiple
				hidden
				accept="application/pdf , image/*"
			/>
			<FlexLayout gridGap={'1.5rem'} alignItems={'center'}>
				{answer.map((answer, index) => {
					return (
						<Attachment
							withDelete={isEdit}
							onDelete={() => onDelete(index)}
							key={`${answer.url}-${index}`}
							onClick={handleAttachmentClick}
							type={answer.type}
							url={answer.url}
						/>
					)
				})}
				{files &&
					files.map((file, index) => {
						return <UploadProgress onFail={onFail} key={`${file.name}-${index}`} onFinish={onFileUploadFinish} index={index} file={file} />
					})}
				{isEdit && (
					<RaisedButton
						minWidth={'unset'}
						maxWidth={'2.875rem'}
						onClick={handleOnClick}
						btnShape="square"
						btnSize={'small'}
						outlined
						variant="princetonOrange"
						withRipple
					>
						ADD
					</RaisedButton>
				)}
			</FlexLayout>
		</div>
	)
}

export default AttachmentAnswer

const UploadProgress: React.VoidFunctionComponent<{
	file: File
	onFinish: (uri: string, index: number) => void
	index: number
	onFail: (index: number) => void
}> = ({ file, index, onFinish, onFail }) => {
	const [progress, setProgress] = useState<AxiosProgressEvent | null>(null)

	useEffect(() => {
		const formData = new FormData()
		formData.set('folder', 'answers')
		formData.set('file', file as Blob)

		uploadFileWithProgress({
			formData,
			progressCallback(progress) {
				setProgress(progress)
			},
		})
			.then(value => {
				setProgress(null)
				onFinish(value.data.data.uri, index)
			})
			.catch(error => onFail(index))
	}, [])
	return (
		<StyledAttachment>
			<Loader>
				<PuffLoader />
			</Loader>
			<Attachment type={file.name.split('.')[1] as any} url="" folded={false} />
		</StyledAttachment>
	)
}

const Loader = styled.div`
	position: absolute;
	content: '';
	/* top: 50%; */
	/* left: 50%; */
	width: 100%;
	height: 100%;
	/* transform: translate(-50%, -50%); */
	z-index: 4;
	display: grid;
	place-content: center;
`
const StyledAttachment = styled.div`
	position: relative;
	width: fit-content;
	height: fit-content;
	&::after {
		position: absolute;
		content: '';
		background: ${rgba('#000', 0.3)};
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`
