import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { CgClose } from 'react-icons/cg'
import Modal from '@Components/Utilities/Popup/Modal'
import PaymentChoice from './Components/PaymentChoice'
import WalletPayment from './Components/WalletPayment'
import ScratchcardPayment from './Components/ScratchPayment'
import { Dialog } from '@eduact/ed-components'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

type Props = {
	showPaymentPopup: any
	setShowPaymentPopup: any
	courseId: any
	onSuccess?: any
	onReject?: any
	paymentMethodsAllowed?: any
	price: any
	classroomName?: any
}
const PaymentModal: React.FC<Props> = ({
	showPaymentPopup,
	setShowPaymentPopup,
	courseId,
	onSuccess,
	onReject,
	paymentMethodsAllowed,
	price,
	classroomName,
}) => {
	const [activeSection, setActiveSection] = useState('payment-choice')

	const onCloseRequest = () => {
		setShowPaymentPopup(false)
		setActiveSection('payment-choice')
	}

	return (
		<>
			<Dialog
				height={{ sm: '90vh', lg: 'fit-content' }}
				width={{ sm: '90vw', lg: 'fit-content' }}
				open={showPaymentPopup}
				onClose={() => onCloseRequest()}
			>
				<ContentWrapper>
					{/*  Payment type choice  */}
					{activeSection !== 'payment-choice' && (
						<BackButton onClick={() => setActiveSection('payment-choice')}>
							<IoMdArrowRoundBack />
						</BackButton>
					)}
					{activeSection === 'payment-choice' && (
						<PaymentChoice paymentMethodsAllowed={paymentMethodsAllowed} setActiveSection={setActiveSection} />
					)}

					{/*  Wallet Payment  */}
					{activeSection === 'wallet-payment' && (
						<WalletPayment
							setShowPaymentPopup={setShowPaymentPopup}
							courseId={courseId}
							setActiveSection={setActiveSection}
							onSuccess={onSuccess}
							onReject={onReject}
							price={price}
							classroomName={classroomName} // classroom name has the course name instead in this case
						/>
					)}

					{/*  scratch card payment  */}
					{activeSection === 'scratchcard-payment' && (
						<ScratchcardPayment
							setShowPaymentPopup={setShowPaymentPopup}
							courseId={courseId}
							courseName={classroomName} // classroom name has the course name instead in this case
							onSuccess={onSuccess}
							onReject={onReject}
							price={price}
						/>
					)}

					{paymentMethodsAllowed === null && <h1>No payment methods available</h1>}
				</ContentWrapper>
			</Dialog>
			{/* <Modal modalOpen={showPaymentPopup}>
				<Modal.Content minHeight={'unset'} minWidth={'42.244rem'} onClose={() => onCloseRequest()}>
					<ContentWrapper>
						{activeSection === 'payment-choice' && (
							<PaymentChoice paymentMethodsAllowed={paymentMethodsAllowed} setActiveSection={setActiveSection} />
						)}

						{activeSection === 'wallet-payment' && (
							<WalletPayment
								setShowPaymentPopup={setShowPaymentPopup}
								courseId={courseId}
								setActiveSection={setActiveSection}
								onSuccess={onSuccess}
								onReject={onReject}
								price={price}
								classroomName={classroomName} 
							/>
						)}

						{activeSection === 'scratchcard-payment' && (
							<ScratchcardPayment
								setShowPaymentPopup={setShowPaymentPopup}
								courseId={courseId}
								courseName={classroomName} 
								onSuccess={onSuccess}
								onReject={onReject}
							/>
						)}

						{paymentMethodsAllowed === null && <h1>No payment methods available</h1>}
					</ContentWrapper>
				</Modal.Content>
			</Modal> */}
		</>
	)
}

const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	padding: 3rem 0;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 3rem 5rem;
	}`}
`

const BackButton = styled.div`
	font-size: 1.5rem;
	position: absolute;
	left: 0;
	top: 0;
	padding: 0.5rem 1rem;
`
export default PaymentModal
