import { RaisedButton } from '@eduact/ed-components'
import { GridLayout } from '@eduact/ed-system'
import styled from 'styled-components'

export const FormButton = styled(RaisedButton)`
	font-size: 0.625rem;
	min-width: 3.925rem;
	padding: 3px 8px;
	height: 100%;
`

export const ProfileForm = styled.form`
	width: 100%;
`

export const FormGrid = styled(GridLayout)`
	place-items: end;
	align-items: flex-start;
	grid-gap: 1rem;
	flex: 1;
	${({ theme }) => `${theme.mediaQueries.large}{
		grid-gap: 4rem;

	}`}
`
