import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

type QueryEditProps = {
	[key: string]: string | undefined | null
}
type QueryDeleteProps = {
	key: string
}
function useURLQuery<T>() {
	const { pathname, search } = useLocation()
	const navigate = useNavigate()

	const edit = (props: QueryEditProps) => {
		return new Promise<void>(function (resolve, reject) {
			const _params = new URLSearchParams(search)
			Object.keys(props).forEach(key => {
				if (props[key]) {
					if (_params.has(key)) {
						_params.set(key, props[key] ?? '')
					} else {
						_params.set(key, props[key] ?? '')
					}
				}
			})
			navigate({
				pathname: pathname,
				search: `?${_params.toString()}`,
			})
			resolve()
		})
	}

	const remove = (props: QueryDeleteProps | QueryDeleteProps[]) => {
		const _params = new URLSearchParams(search)
		if (Array.isArray(props)) {
			props.forEach(prop => {
				if (_params.has(prop.key)) _params.delete(prop.key)
			})
		} else {
			if (_params.has(props.key)) _params.delete(props.key)
		}
		navigate({
			pathname: pathname,
			search: `?${_params.toString()}`,
		})
	}

	const removeAll = () => {
		navigate({
			pathname: pathname,
		})
	}

	const params = React.useMemo(() => {
		const _params = new URLSearchParams(search)
		const _paramsArr: Array<Array<string>> = Array.from(_params)
		const _obj = {} as any
		_paramsArr.forEach((param: Array<string>) => {
			if (param) {
				_obj[param[0]] = param[1]
			}
		})

		return {
			params: _obj as T,
		}
	}, [search])

	return {
		params: params.params,
		edit,
		remove,
		removeAll,
	}
}
export default useURLQuery
