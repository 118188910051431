import { Box, FlexLayout, Typography } from '@eduact/ed-system'
import React, { useMemo, useState } from 'react'
import { RaisedButton, TextButton, TextInput, useSnackbar } from '@eduact/ed-components'
import styled from 'styled-components'
import Spacer from '@eduact/ed-components/Spacer'
import { useLazyVerifyPromoCodeQuery } from '@Features/Base/BaseApi/BaseApi'
import { useCourseContext } from '@Features/Course/Providers/CourseProvider/CourseProvider'
import { SnackbarConfig } from '@Utilities/SnackbarConfig'
import { isAxiosError } from 'axios'
import Icon from '@Components/Icons/Icon/Icon'
import { useTranslation } from 'react-i18next'
import { NumberFormatter } from '@eduact/utils'

type Props = {
	onApply: (value: any) => void
	price: number
}
export const PromocodeButton = ({ onApply, price }: Props) => {
	const [verify] = useLazyVerifyPromoCodeQuery()
	const [value, setValue] = useState('')

	const [discount, setDiscount] = useState<number | undefined>()
	const { course } = useCourseContext()
	const { openSnackbar } = useSnackbar()
	const [applied, setApplied] = useState(false)
	const handleeonApply = async () => {
		if (!course) return
		try {
			const resp = await verify({ course_id: course.id, code: value }).unwrap()
			onApply({ value: price - resp.data.value, code: value })
			setDiscount(resp.data.value)
			setApplied(true)
			openSnackbar({ ...SnackbarConfig.success, text: resp.message })
		} catch (error) {}
	}

	const discountData = useMemo(() => {
		if (!discount) return
		const percent = Math.floor((discount / price) * 100)

		return { price, percent, discount }
	}, [discount])
	const handleOnRemove = () => {
		setApplied(false)
		setValue('')
		onApply(undefined)
	}

	const [t] = useTranslation('payment')
	return (
		<>
			{!applied && (
				<FlexLayout flexDirection="column" px={{ sm: '2rem', lg: '0' }}>
					<Typography fontSize="0.8rem" fontWeight="bold">
						{t('have_promo')}
					</Typography>
					<Spacer mb="0.5rem" />
					<FlexLayout justifyContent="flex-start" alignItems="stretch">
						<Input value={value} onChange={e => setValue(e.target.value)} placeholder={t('promo_code')} />
						<Button onClick={handleeonApply} btnSize="small">
							{t('apply')}
						</Button>
					</FlexLayout>
				</FlexLayout>
			)}
			{applied && (
				<FlexLayout px={{ sm: '2rem', lg: 0 }} justifyContent={{ sm: 'center', md: 'flex-start' }}>
					<AppliedContainer>
						<FlexLayout gridGap="0.5rem" alignItems="center">
							<Typography display={'grid'} fontSize="2rem">
								<Icon color="green" type="TwoTone">
									check_circle
								</Icon>
							</Typography>
							<FlexLayout flexDirection="column">
								<FlexLayout gridGap="0.5rem">
									<Typography fontWeight="700" fontSize="0.8rem">
										{value}
									</Typography>

									<Typography fontSize="0.8rem">{t('applied')}</Typography>
								</FlexLayout>
								{discountData && (
									<div>
										<Typography fontSize="0.7rem">
											-{NumberFormatter(discountData.discount)} {t('egp')} ({NumberFormatter(discountData.percent)}% {t('off')})
										</Typography>
									</div>
								)}
							</FlexLayout>
						</FlexLayout>
						<FlexLayout>
							<TextButton onClick={handleOnRemove} btnSize="small" variant="purpleNavy" p={{ lg: '0' }}>
								{t('remove')}
							</TextButton>
						</FlexLayout>
					</AppliedContainer>
				</FlexLayout>
			)}
		</>
	)
}

const Input = styled.input`
	border: none;
	outline: none;
	box-sizing: content-box;
	/* border: 1px solid ${p => p.theme.colors.darkSilver}; */
	border: 2px solid ${p => p.theme.colors.spanishGray};
	&:focus {
		border: 2px solid ${p => p.theme.colors.primary};
	}
	border-right-color: ${p => (p.theme.direction === 'ltr' ? p.theme.colors.primary : p.theme.colors.spanishGray)};
	border-left-color: ${p => (p.theme.direction === 'rtl' ? p.theme.colors.primary : p.theme.colors.spanishGray)};
	border-bottom-left-radius: ${p => p.theme.direction === 'ltr' && '5px'};
	border-top-left-radius: ${p => p.theme.direction === 'ltr' && '5px'};
	border-bottom-righ-radius: ${p => p.theme.direction === 'rtl' && '5px'};
	border-top-right-radius: ${p => p.theme.direction === 'rtl' && '5px'};
	padding: 0.5rem;
`
const Button = styled(RaisedButton)`
	border-bottom-left-radius: ${p => p.theme.direction === 'ltr' && '0'};
	border-top-left-radius: ${p => p.theme.direction === 'ltr' && '0'};
	border-bottom-right-radius: ${p => p.theme.direction === 'rtl' && '0'};
	border-top-right-radius: ${p => p.theme.direction === 'rtl' && '0'};
	min-width: unset;
`
const AppliedContainer = styled(FlexLayout)`
	border-radius: 5px;
	border: 2px solid ${p => p.theme.colors.green};
	padding: 0.5rem 1rem;
	width: fit-content;
	/* min-width: 20rem; */
	${({ theme }) => `${theme.mediaQueries.large}{
        min-width: 20rem;
    }`}
	justify-content: space-between;
	align-items: center;
`
