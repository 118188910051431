import { rtkPrepareHeadersCallback } from '@Api/Api'
import { BaseApi } from '@Features/Base/BaseApi/BaseApi'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export type GetInstructorsResponsePayload = ApiResponse<BriefInstructor[]>
export type GetClassroomsResponsePayload = ApiResponse<Classroom[]>

export const landingPageApi = BaseApi.enhanceEndpoints({
	addTagTypes: ['Instructors', 'Classrooms'],
}).injectEndpoints({
	endpoints: builder => ({
		getInstructors: builder.query<GetInstructorsResponsePayload, void>({
			query: () => '/instructor/all',
			providesTags: ['Instructors'],
		}),
		getClassrooms: builder.query<GetClassroomsResponsePayload, void>({
			query: () => '/classroom/all',
			providesTags: ['Classrooms'],
		}),
		searchClassroom: builder.query<ApiResponse<Classroom[]>, SearchResultsPayload>({
			query: (payload: SearchResultsPayload) => `/classroom/search?query=${payload.searchQuery}`,
		}),
		getNewsFeed: builder.query<ApiResponse<NewsFeed[]>, void>({
			query: () => ({ url: '/newsfeed' }),
		}),
		getStatistics: builder.query<ApiResponse<Statistics>, void>({
			query: () => ({ url: '/statistics' }),
		}),
	}),
})

type SearchResultsPayload = {
	searchQuery?: string
}

export const { useGetInstructorsQuery, useGetClassroomsQuery, useSearchClassroomQuery, useGetNewsFeedQuery, useGetStatisticsQuery } =
	landingPageApi
