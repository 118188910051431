import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { ButtonsGrid } from '@eduact/ed-components'
import { Typography } from '@eduact/ed-system'
import { rgba } from 'polished'
import styled from 'styled-components'

export const SignupFeesContainer = styled.div`
	grid-gap: 2rem;
	grid-template-columns: 1fr auto 1fr;
	${({ theme }) => `${theme.mediaQueries.large}{
    display : grid;
}`}
`
export const ProvidersWrapper = styled.div`
	${({ theme }) => `${theme.mediaQueries.large}{
	overflow: auto;
	padding: 0.5rem;
    max-height:19rem;
}`}
`
export const FeaturesList = styled.ul`
	font-size: 0.625rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: 1rem;
	li {
		margin-bottom : 1.438rem;
		line-height: 1.3;
	}
}`};
	li {
		display: flex;
		align-items: center;
		grid-column-gap: 10px;
		&::before {
			content: '';
			width: 6px;
			height: 6px;
			background: ${props => props.theme.colors.primary};
			display: block;
			border-radius: 50%;
		}
		margin-bottom: 1rem;
		${({ theme }) => `${theme.mediaQueries.large}{
		margin-bottom : 1.438rem;}`};
	}
`

export const Disclaimer = styled.div`
	padding: 0.563rem;
	border-radius: 10px;
	font-family: 'Roboto';
	background: ${props => rgba(props.theme.colors.primary, 0.2)};
	font-weight: 500;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 1rem;

	}`}
`
export const DisclaimerTitle = styled(EdTypography)`
	color: ${props => props.theme.colors.purple};
`
export const FeesAmountCard = styled.div`
	border-radius: 13.5px;
	border: solid 1px #ddd;
	padding: 1.813rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	${({ theme }) => `${theme.mediaQueries.large}{
		min-height: 15.594rem;
		padding :3.125rem 0;
	}`}
`

export const FeesAmount = styled.span`
	font-size: 26px;
	color: ${props => props.theme.colors.dark};
	font-weight: bold;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size:2.75rem;
	}`}
`

export const Instead = styled.span`
	font-size: 12px;
	font-style: italic;
	margin: 0.2rem 0;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size:1rem;
		margin: 1rem 0;
	}`}
`

export const FeesOldAmount = styled.span`
	font-size: 24px;
	font-weight: 500;
	color: ${props => props.theme.colors.princetonOrange};
	position: relative;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size:1.625rem;
	}`}
	&::before {
		background: ${props => props.theme.colors.dark};
		position: absolute;
		width: 100%;
		height: 1px;
		content: '';
		top: 50%;
		transform: translateY(-50%);
	}
`

export const ProvidersButtonsGrid = styled(ButtonsGrid)`
	button {
		max-height: 5rem !important;
		img {
			max-height: 100%;
		}
	}
`
