import { Icons, Icon, RaisedButton, Card, Dialog } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { Typography, FlexLayout } from '@eduact/ed-system'
import { useSubmitAssignmentMutation } from '@Features/Unit/UnitApi'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { Dispatch, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { number, object, string } from 'yup'
import AttachmentAnswer from './AttachmentAnswer'
import AnswerButton from './AnswerButton'
import moment from 'moment'
import { MdInfoOutline } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useCourseContext } from '@Features/Course/Providers/CourseProvider/CourseProvider'

type Props = {
	assignment: AssignmentUnit
	submission: AssignmentSubmission | null
	isEdit?: boolean
	setIsEdit?: React.Dispatch<boolean>
	canSubmit: boolean
	onChooseType: Dispatch<boolean>
}

const schema = object({
	assignment_id: number(),
	content: string().required(),
	content_type: string().required(),
})
const AssignmentAnswer: React.VoidFunctionComponent<Props> = ({
	assignment,
	submission,
	isEdit = true,
	setIsEdit,
	canSubmit,
	onChooseType,
}) => {
	const [submitAssignment] = useSubmitAssignmentMutation()

	const { course, section } = useCourseContext()
	console.log({ course, section })
	const { setValue, watch, control, formState, handleSubmit, reset, register } = useForm<SubmitAssignmentPayload>({
		resolver: yupResolver(schema),
		mode: 'all',
		values: {
			assignment_id: Number(assignment.id),
			content: submission?.content ?? '',
			content_type: submission?.content_type,
			course_id: course?.id.toString(),
			section_id: section?.id.toString(),
		},
	})

	useEffect(() => {
		setValue('content_type', submission?.content_type)
	}, [submission])

	const handleChooseType = (type: AssignmentContentType) => {
		setValue('content_type', type, {
			shouldDirty: true,
			shouldTouch: true,
			shouldValidate: true,
		})
		onChooseType(true)
	}
	const toggleAnswerType = () => {
		// setValue('content', '')
		setSwitchModalOpen(true)
		// handleChooseType(answerType === 'attachment' ? 'text' : 'attachment')
	}

	const answerType = watch('content_type')

	const { isValid, isDirty, isSubmitting } = formState

	const handleSubmitAssignment = handleSubmit(async values => {
		try {
			await submitAssignment(values)
			setIsEdit?.(false)
			reset(values, { keepDirty: false })
		} catch (error) {}
	})

	const hasStatus = !!submission?.status

	const [switchModalOpen, setSwitchModalOpen] = useState(false)

	const handleCloseSwitchModal = () => {
		setSwitchModalOpen(false)
	}
	const changeType = () => {
		setSwitchModalOpen(false)
		setValue('content', '')
		setValue('content_type', answerType === 'text' ? 'attachment' : 'text')
	}
	const { t } = useTranslation('assignment')
	return (
		<>
			{(canSubmit || hasStatus) && (
				<div>
					<Dialog maxWidth={'90vw'} width={'fit-content'} height={'fit-content'} onClose={handleCloseSwitchModal} open={switchModalOpen}>
						<FlexLayout p={'2rem'} flexDirection={'column'}>
							<FlexLayout p={'2rem'} flexDirection={'column'}>
								<Typography>Are you sure to switch answer type ?</Typography>
								<Typography>NB: Answer will be erased</Typography>
							</FlexLayout>
							<FlexLayout
								gridGap={{ sm: '0.5rem', lg: '2rem' }}
								flexWrap={{ sm: 'wrap' }}
								justifyContent={{ sm: 'flex-end', lg: 'initial' }}
							>
								<RaisedButton onClick={changeType} btnSize="small">
									Switch
								</RaisedButton>
								<RaisedButton onClick={handleCloseSwitchModal} variant="princetonOrange" btnSize="small" outlined>
									Cancel
								</RaisedButton>
							</FlexLayout>
						</FlexLayout>
					</Dialog>
					<DevTool control={control} />
					{!answerType && !submission && (
						<>
							{!answerType && !submission && (
								<Typography fontFamily={'Roboto'} fontSize={{ lg: 22 }}>
									{t('choose')}
								</Typography>
							)}

							<Spacer mb={{ lg: '1.438rem' }} />
							{!answerType && !submission && (
								<FlexLayout>
									<AnswerButton onClick={() => handleChooseType('text')} icon={<Icons.Text />} title={t('text')} />
									<Spacer mx={'2rem'} my={'2rem'}>
										<Typography fontSize={{ lg: 20 }}>Or</Typography>
									</Spacer>
									<AnswerButton onClick={() => handleChooseType('attachment')} icon={<Icons.Attachment />} title={t('attachment')} />
								</FlexLayout>
							)}
						</>
					)}
					{submission && !isEdit && (
						<>
							<Typography fontFamily={'Roboto'} fontWeight={300} fontSize={{ lg: '1.375rem' }}>
								{t('you_answer')}
							</Typography>
							<Spacer mb={{ lg: '1.438rem' }} />
						</>
					)}
					{answerType && isEdit && (
						<FlexLayout onClick={toggleAnswerType} justifyContent={'flex-end'}>
							<SwitchLabel>Switch to {answerType === 'attachment' ? 'Text' : 'Attachment'}</SwitchLabel>
							{answerType === 'text' && (
								<Icon>
									<Icons.Attachment />
								</Icon>
							)}
							{answerType === 'attachment' && (
								<Icon>
									<Icons.Text />
								</Icon>
							)}
						</FlexLayout>
					)}
					<Spacer mb={'0.5rem'} />

					<FlexLayout gridGap={'1rem'} alignItems={'center'}>
						<FlexLayout flex={1}>
							{answerType === 'text' && (
								<Controller
									control={control}
									name="content"
									render={({ field: { ref, value, ...props } }) => {
										return <TextArea value={value ?? undefined} {...props} placeholder="Type something here" disabled={!isEdit} />
									}}
								/>
							)}
							{answerType === 'attachment' && (
								<div>
									<Controller
										control={control}
										name="content"
										render={({ field: { ref, ...props } }) => {
											return <AttachmentAnswer {...props} isEdit={isEdit} />
										}}
									/>
								</div>
							)}
						</FlexLayout>
						{isEdit && (
							<RaisedButton
								onClick={handleSubmitAssignment}
								disabled={!isValid || isSubmitting || !isDirty}
								minWidth={'4.188rem'}
								btnSize={'small'}
								outlined
								btnShape="square"
								withRipple
							>
								Submit
							</RaisedButton>
						)}
					</FlexLayout>
				</div>
			)}
			{!canSubmit && !hasStatus && (
				<div>
					<NoSubmissionCard>
						<Icon color="dark">
							<MdInfoOutline size={'1.2rem'} />
						</Icon>
						<Spacer mx="0.5rem" />
						<Typography fontSize={'1.25rem'} fontFamily="Roboto" fontWeight={500}>
							{t('disclaimer')}
						</Typography>
					</NoSubmissionCard>
				</div>
			)}

			{hasStatus && submission.feedback && (
				<>
					<Spacer mt="1.875rem" />
					<Typography fontWeight={600} fontSize="1.5rem">
						{t('feedback')}
					</Typography>
					<Spacer mb="1rem" />
					<div dangerouslySetInnerHTML={{ __html: submission.feedback }}></div>
				</>
			)}
		</>
	)
}

export default AssignmentAnswer

const SwitchLabel = styled.p`
	color: #adadad;
	text-decoration: underline;
	font-weight: 500;
	cursor: pointer;
`
const TextArea = styled.textarea`
	border-radius: 5px;
	box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.16);
	border: solid 1px #c4c4c4;
	width: 100%;
	resize: vertical;
	padding: 1rem 1.625rem;
	font-size: 0.75rem;
	min-height: 8.438rem;
`
// const NoSubmission = styled.div``

const NoSubmissionCard = styled.div`
	background: #fcecd8;
	padding: 1.125rem;
	border-radius: 4px;
	color: #0d3c61;
	display: flex;
`
