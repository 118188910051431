import { css } from 'styled-components'

export const OpayStyles = css`
	iframe#oi01 {
		display: none;
		position: fixed;
		left: 0;
		z-index: 9999999999999999999999999;
		top: 0;
		width: 100vw;
		height: 100vh;
	}
`
