import React from 'react'
import { SkeletonBox } from '@eduact/ed-components'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useParams } from 'react-router'
import { useGetTestsCountQuery } from '../../Tests.api'
import Average from '../../Assets/average.svg'
import Passed from '../../Assets/passed.svg'
import Failed from '../../Assets/failed.svg'
import notStarted from '../../Assets/notstarted.svg'
import styled from 'styled-components'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { useTranslation } from 'react-i18next'

const TestsCount = () => {
	const [t] = useTranslation('classroomTests')
	const { id } = useParams<{ id: string }>()
	const { data, isLoading } = useGetTestsCountQuery({
		classroom_id: Number(id) ?? skipToken,
	})

	return (
		<SkeletonBox isLoading={isLoading} minHeight={{ sm: '120px' }} borderRadius={5}>
			<Card>
				<CardInfo>
					<Icon>
						<Img src={Average} alt={t('averageScore')} />
					</Icon>
					<Title color="silver" fontWeight={500} fontSize={{ sm: '0.625rem', lg: '1rem' }}>
						{t('averageScore')}
					</Title>
					<EdTypography fontSize={{ sm: '1rem', lg: '1.8rem' }} fontWeight={600}>
						{data && (data?.data?.average_score * 100).toFixed(1)}%
					</EdTypography>
				</CardInfo>
				<CardInfo>
					<Icon>
						<Img src={Passed} alt={t('passed')} />
					</Icon>
					<Title color="silver" fontWeight={500} fontSize={{ sm: '0.625rem', lg: '1.25rem' }}>
						{t('passed')}
					</Title>
					<EdTypography fontSize={{ sm: '1.125rem', lg: '2.25rem' }} fontWeight={600}>
						{data?.data.passed_tests}
					</EdTypography>
				</CardInfo>
				<CardInfo>
					<Icon>
						<Img src={Failed} alt={t('failed')} size={1.55} />
					</Icon>
					<Title color="silver" fontWeight={500} fontSize={{ sm: '0.625rem', lg: '1.25rem' }}>
						{t('failed')}
					</Title>
					<EdTypography fontSize={{ sm: '1.125rem', lg: '2.25rem' }} fontWeight={600}>
						{data?.data.failed_tests}
					</EdTypography>
				</CardInfo>
				<CardInfo>
					<Icon>
						<Img src={notStarted} alt={t('notStarted')} />
					</Icon>
					<Title color="silver" fontWeight={500} fontSize={{ sm: '0.625rem', lg: '1.25rem' }}>
						{t('notStarted')}
					</Title>
					<EdTypography fontSize={{ sm: '1.125rem', lg: '2.25rem' }} fontWeight={600}>
						{data?.data.not_started_tests}
					</EdTypography>
				</CardInfo>
			</Card>
		</SkeletonBox>
	)
}

const Card = styled.div`
	display: grid;
	grid-gap: 5.6px;
	grid-template-columns: repeat(2, 1fr);
	${({ theme }) => `${theme.mediaQueries.large}{
		display: flex;
		flex-wrap: wrap;
		grid-gap : 4rem;

	}`}
`

const CardInfo = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid ${props => props.theme.colors.silver};
	padding: 8px;
	padding: 8px;
	border-radius: 5px;
	flex: 1;
	justify-content: space-between;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding : 0.875rem 1.125rem;
	}`}
`

const Img = styled.img<{ size?: number }>`
	max-width: 1.063rem !important;
	width: 1.063rem !important;
	height: 1.063rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    max-width: 2.625rem !important;
    width: 2.625rem !important;
		height: 2.625rem;
	}`}
`

const Icon = styled.div`
	min-width: 1.806rem;
	min-height: 1.806rem;
	background: ${props => props.theme.colors.dark};
	border-radius: 50%;
	display: grid;
	place-items: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		min-width: 4.188rem;
		min-height: 4.188rem;
		font-size: 2rem;
	}`}
`
const Title = styled(EdTypography)`
	max-width: 8ch;
	text-align: center;
	line-height: 1.3;
`

export default TestsCount
