import React, { FC } from 'react'
import styled from 'styled-components'

type TextProps = {
	percentage: number
	score: number | undefined
	data: Test | undefined
	overallScore?: number
}

const Text: FC<TextProps> = ({ percentage, data, score, overallScore }) => {
	const isScore = data?.show_score_value
	const isPercentage = data?.show_score_percentage
	const scoreNum = score ?? 0
	return (
		<>
			{isScore && (
				<Grade x="50%" y="42%" dominantBaseline="central" textAnchor="middle">
					{scoreNum}/{overallScore}
				</Grade>
			)}
			{isPercentage && (
				<TextStyle x="50%" y={isScore ? '61%' : '48%'} dominantBaseline="central" textAnchor="middle">
					{percentage.toFixed(0)}%
				</TextStyle>
			)}
		</>
	)
}

const TextStyle = styled.text`
	font-size: 1.125rem;
	font-weight: 600;
	letter-spacing: 2px;
	@media (max-width: 610px) {
		font-size: 1rem;
		line-height: 1.25;
	}
`
const Grade = styled.text`
	fill: #6c63ff;
	font-size: 1.125rem;
	font-weight: 600;
	letter-spacing: 2px;
	@media (max-width: 610px) {
		font-size: 1rem;
		line-height: 1.25;
	}
`

export default Text
