import { FlexLayout } from '@eduact/ed-system'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useGetClassroomAttendanceRateQuery } from '../SingleClassroomSlice/SingleClassroomSlice'
import { Brogressbar } from './Progressbar'

const AttendanceRate = () => {
	const { id } = useParams()
	const [t] = useTranslation('classroomsOverview')
	const { data } = useGetClassroomAttendanceRateQuery({
		classroom_id: Number(id) ?? skipToken
	})
	const [flipCard, setFlipCard] = useState<boolean>()

	const overallScore = useMemo(() => {
		if (data?.data?.overall_rate) {
			return (data?.data?.overall_rate * 100).toFixed(2)
		}
	}, [data?.data?.overall_rate])
	const sequenceScore = useMemo(() => {
		if (data?.data?.sequence_rate) {
			return (data?.data?.sequence_rate * 100).toFixed(2)
		}
	}, [data?.data?.sequence_rate])

	return (
		<Wrapper flexDirection="column">
			<FlexLayout flexDirection="column" alignItems="center" justifyContent="center" gridGap="0">
				<Text>{t('attendanceRate')}</Text>
				{flipCard && <Span>{t('inSequence')}</Span>}
			</FlexLayout>
			<Brogressbar overallScore={flipCard ? Number(sequenceScore) : Number(overallScore)} color="#5AC0FC" />
			<Icon className="material-symbols-outlined" onClick={() => setFlipCard(!flipCard)}>
				360
			</Icon>
		</Wrapper>
	)
}

const Wrapper = styled(FlexLayout)`
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	border-radius: 10px;
	background: #fff;
	justify-content: start;
	align-items: center;
	height: 8.275rem;
	position: relative;
	padding-top: 0.531rem;
	width: 100%;
	${({ theme }) => `${theme.mediaQueries.large}{
		  border-radius: 15px;
		  height: 17.119rem;
		  padding-top: 1.313rem;
	}`}
	@media (min-width: 1201px) and (max-width: 1600px) {
		width: 14rem;
	}
	@media (min-width: 1601px) {
		width: 19.813rem;
	}
`
const Text = styled.p`
	font-size: 0.625rem;
	font-weight: 600;
	color: #251b33;
	margin-bottom: 0.2rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		  font-size: 1.125rem;
		  margin-bottom: 1.313rem;
	}`}
`
const Span = styled.span`
	font-size: 0.5rem;
	color: #251b33;
	font-weight: 600;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 0.875rem;
		margin-top: -13px;
	}`}
`
const Icon = styled.span`
	position: absolute;
	cursor: pointer;
	font-size: 1.088rem;
	bottom: 5px;
	right: 5px;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.5rem;
		bottom: 12px;
		right: 17px;
	}`}
`

export default AttendanceRate
