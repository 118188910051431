import { FlexLayout } from '@eduact/ed-system'
import i18next from 'i18next'
import React, { FC } from 'react'
import styled from 'styled-components'
import edEn from '../Assets/eden.svg'

type Props = {
	text: string
}

const NoCard: FC<Props> = ({ text }) => {
	const { dir } = i18next
	return (
		<Container justifyContent="center" alignItems="center">
			<FlexLayout>
				{dir() === 'ltr' && <EddieImg src={edEn} alt="eddie" />}
				<Message>{text}</Message>
				{dir() === 'rtl' && <EddieImg src={edEn} alt="eddie" />}
			</FlexLayout>
		</Container>
	)
}

const Container = styled(FlexLayout)`
	min-height: 21rem;
	border-radius: 15px;
	background-color: #fff;
	padding: 0 1.031rem 0 2.031rem;
	gap: 4.375rem;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	@media only screen and (max-width: 1024px) {
		flex-wrap: wrap;
		padding: 0.8rem 1.031rem 0.8rem 2.031rem;
	}
	@media (min-width: 1024px) and (max-width: 1440px) {
		gap: 2.1rem;
	}
	@media (max-width: 768px) {
		min-height: 14rem;
		padding: 0 1rem 0 1.5rem;
	}
`
const Message = styled.div`
	position: relative;
	max-width: 17.669rem;
	height: 11.188rem;
	padding: 20px 25px;
	background-color: #f8f8fe;
	border-radius: 50%;
	color: #6e7577;
	font-size: 1rem;
	font-weight: 500;
	text-align: center;
	line-height: 1.22;
	letter-spacing: 0.54px;
	margin-top: -55px;
	display: flex;
	align-items: center;
	@media (max-width: 1024px) {
		margin-top: -20px;
	}
	@media (max-width: 768px) {
		max-width: 11.813rem;
		height: 5.938rem;
		padding: 20px;
		font-size: 0.625rem;
		font-weight: normal;
		line-height: 1.25;
		letter-spacing: 0.24px;
		z-index: 0;
	}
	@media (max-width: 375px) {
		max-width: 7.856rem;
		height: 4.95rem;
		padding: 14px;
		font-size: 0.5rem;
		font-weight: normal;
		line-height: 1.25;
		letter-spacing: 0.24px;
		z-index: 0;
	}
`
const EddieImg = styled.img`
	object-fit: contain;
	@media (max-width: 768px) {
		width: 140.6px;
	}
	@media (max-width: 375px) {
		width: 80px;
	}
`
export default NoCard
