import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
// import API from '../../../services/api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Header } from '@Components/Wallet/Components/Tabs/Components/RechargeCodeTab'
import { ButtonCss } from '../PaymentModal.styled'
import { ScratchCardImage } from '@Components/Layout/Header/Assets/images'
import { useCheckoutSingleCourseMutation } from '@Features/Classroom/ClassroomSlice/ClassroomSlice'
// import { refreshWalletValue } from './Utils'
import FacebookPixel from 'react-facebook-pixel'

const initialValues = ['', '', '', '', '', '', '', '', '', '']

type Props = {
	courseId: any
	courseName: any
	setShowPaymentPopup: any
	onSuccess: any
	onReject: any
	price: any
}
const ScratchcardPayment: React.FC<Props> = ({ courseId, courseName, setShowPaymentPopup, onSuccess, onReject, price }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation(['payment'], { keyPrefix: 'scratch_card_payment' })

	const ref0 = useRef<HTMLInputElement>(null)
	const ref1 = useRef<HTMLInputElement>(null)
	const ref2 = useRef<HTMLInputElement>(null)
	const ref3 = useRef<HTMLInputElement>(null)
	const ref4 = useRef<HTMLInputElement>(null)
	const ref5 = useRef<HTMLInputElement>(null)
	const ref6 = useRef<HTMLInputElement>(null)
	const ref7 = useRef<HTMLInputElement>(null)
	const ref8 = useRef<HTMLInputElement>(null)
	const ref9 = useRef<HTMLInputElement>(null)

	const refArr = [ref0, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9]
	const [values, setValues] = useState([...initialValues])
	const [isValid, setIsValid] = useState(false)
	const [checkout, checkoutData] = useCheckoutSingleCourseMutation()

	useEffect(() => {
		for (let value of values) {
			if (value === '' || value === undefined || value === null) {
				setIsValid(false)
				return
			}
		}
		setIsValid(true)
	}, [values])

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const index = Number(event.target.name)
		const newValues = [...values]
		newValues[index] = event.target.value
		setValues(newValues)
		if (event.target.value === '') return
		if (index !== 9) {
			const nextRef = refArr[index + 1]
			if (nextRef && nextRef.current) {
				nextRef.current.focus()
			}
		}
	}

	// focus previous input on delete
	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const index = Number((event.target as HTMLInputElement).name)
		const value = values[index]
		if (index !== 0 && event.keyCode === 8 && (value === '' || value === undefined)) {
			refArr[index - 1]?.current?.focus()
		}
	}

	const submitHandler = async () => {
		const scratchCard = values.join('')
		if (scratchCard.length < 10) {
		} else {
			try {
				checkout({
					course_id: courseId,
					payment_method: 'scratchCard',
					code: values.join(''),
				})
			} catch (e) {
				if (onReject) onReject()
				setValues([...initialValues])
			}
		}
	}
	useEffect(() => {
		if (checkoutData.isSuccess) {
			FacebookPixel.track('Purchases', {
				content_ids: [courseId],
				content_name: courseName,
				delivery_category: 'curbside',
				currency: 'EGP',
				value: price,
			})
			onSuccess && onSuccess()
		}
	}, [checkoutData.isSuccess, onSuccess])
	useEffect(() => {
		if (checkoutData.isError) {
			onReject && onReject()
		}
	}, [checkoutData.isError, onReject])
	return (
		<StyledScratchCardPayment>
			<Header>{t('title')}</Header>
			<ContentWrapper>
				<Image src={ScratchCardImage} alt="scratch card image" />

				<InputContainer>
					<InputSection>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'0'}
							defaultValue=""
							value={values[0]}
							ref={ref0}
							maxLength={1}
						/>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'1'}
							defaultValue=""
							value={values[1]}
							ref={ref1}
							maxLength={1}
						/>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'2'}
							defaultValue=""
							value={values[2]}
							ref={ref2}
							maxLength={1}
						/>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'3'}
							defaultValue=""
							value={values[3]}
							ref={ref3}
							maxLength={1}
						/>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'4'}
							defaultValue=""
							value={values[4]}
							ref={ref4}
							maxLength={1}
						/>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'5'}
							defaultValue=""
							value={values[5]}
							ref={ref5}
							maxLength={1}
						/>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'6'}
							defaultValue=""
							value={values[6]}
							ref={ref6}
							maxLength={1}
						/>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'7'}
							defaultValue=""
							value={values[7]}
							ref={ref7}
							maxLength={1}
						/>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'8'}
							defaultValue=""
							value={values[8]}
							ref={ref8}
							maxLength={1}
						/>
						<InputUnit
							onChange={onChangeHandler}
							onKeyDown={onKeyDown}
							name={'9'}
							defaultValue=""
							value={values[9]}
							ref={ref9}
							maxLength={1}
						/>
					</InputSection>
					<SendButton disabled={!isValid} onClick={submitHandler}>
						{t('confirm')}
					</SendButton>
				</InputContainer>
			</ContentWrapper>
		</StyledScratchCardPayment>
	)
}

const StyledScratchCardPayment = styled.div`
	height: 100%;

	@media (max-width: 425px) {
		min-height: 200px;
	}
`

const ContentWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	padding-top: 26px;

	@media (max-width: 425px) {
		flex-direction: column;
	}
`

const Image = styled.img`
	max-width: 177px;
	margin-top: -56px;

	@media (max-width: 425px) {
		margin-top: 0;
		display: none;
	}
`

const InputContainer = styled.div`
	/* width: 100%; */
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	direction: ltr;

	@media (max-width: 425px) {
		top: 80px;
		/* position: absolute; */
	}
`

const InputSection = styled.div`
	width: 100%;
	display: flex;
	gap: 2px;
	justify-content: space-between;
`

const InputUnit = styled.input`
	border-radius: 5px;
	border: 1px solid #c4c4c4;
	font-family: Montserrat, serif;
	font-size: 18px;
	padding: 0 ;
	width: 25px;
	text-align: center;
	height: 40px;
	/* font-size : 0.625rem; */
	&:focus {
		outline: none;
	}
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size : 1rem;
		padding: 0 8px;
		width: 30px;
		min-width: 40px;
		height: 50px;

	}`}
`

const SendButton = styled.button`
	${ButtonCss};
	//background-color: #5ac0fc;
	background-color: ${props => (props.disabled ? 'gray' : '#5ac0fc')};
	margin-top: 34px;

	&:hover {
		cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
	}
`

export default ScratchcardPayment
