import { BaseApi } from '@Features/Base/BaseApi/BaseApi'

const AttendanceApi = BaseApi.injectEndpoints({
	endpoints: builder => ({
		getAttendanceCount: builder.query<ApiResponse<AttendanceCountResponse>, AttendanceQueryPayload>({
			query: payload => ({ url: 'classroom/analytics/attendance/count', method: 'POST', body: payload }),
		}),
		getClassroomAttendance: builder.query<ApiResponse<ClassroomAttendanceResponse>, AttendanceQueryPayload>({
			query: payload => ({ url: 'classroom/analytics/attendance', method: 'POST', body: payload }),
		}),
	}),
})

export const { useGetAttendanceCountQuery, useGetClassroomAttendanceQuery } = AttendanceApi
