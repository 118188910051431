import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { SkeletonBox } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { Box, FlexLayout } from '@eduact/ed-system'
import { useGetClassroomByLabelQuery } from '@Features/Classroom/ClassroomSlice/ClassroomSlice'
import { ClassroomProvider } from '@Features/Classroom/Providers/ClassroomProvider/ClassroomProvider'
import { CourseProvider } from '@Features/Course/Providers/CourseProvider/CourseProvider'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import AttendanceCourseCard from '../AttendanceCourseCard/AttendanceCourseCard'

const AttendanceTable = () => {
	const { id } = useParams<{ id: string }>()

	const { data, isLoading } = useGetClassroomByLabelQuery(id ?? skipToken)

	return (
		<SkeletonBox isLoading={isLoading} height={'55vh'}>
			<Box>
				{data?.data.tabs.map((tab, tIndex) => (
					<React.Fragment key={`${tab.id}-${tIndex}`}>
						{tab.sections
							.filter(_ => _.courses.length > 0)
							.map((section, index) => {
								return (
									<SectionWrapper key={`${section.id}-${index}`} mb={{ sm: '2rem' }}>
										<TableHeaderWrapper px={{ sm: '1.125rem' }}>
											<FlexLayout flex={{ lg: 1 }} width="100%">
												<EdTypography color="purple" fontSize={{ lg: '1.5rem' }} fontWeight={'bold'}>
													{section.name}
												</EdTypography>
											</FlexLayout>
											<FlexLayout display={{ sm: 'none', lg: 'flex' }} flex={{ lg: 1 }} width="100%" justifyContent="start">
												<TableHeader>State</TableHeader>
											</FlexLayout>
											<FlexLayout display={{ sm: 'none', lg: 'flex' }} flex={{ lg: 1 }} width="100%" justifyContent="center">
												<TableHeader>My Status</TableHeader>
											</FlexLayout>
											<FlexLayout display={{ sm: 'none', lg: 'flex' }} flex={{ lg: 1 }} width="100%">
												<TableHeader>Learning Time</TableHeader>
											</FlexLayout>
										</TableHeaderWrapper>
										<Spacer mb={{ sm: '0.625rem' }} />
										<ClassroomProvider classroom={data.data}>
											{section.courses.map((course, index) => {
												return (
													<CourseProvider key={`${course.id}-${index}`} classroomLabel={data.data.label} classroomTabs={[]} course={course}>
														<AttendanceCourseCard isBookmarked={data.data.current_course === course.id} course={course} />
													</CourseProvider>
												)
											})}
										</ClassroomProvider>
									</SectionWrapper>
								)
							})}
					</React.Fragment>
				))}
			</Box>
		</SkeletonBox>
	)
}

export default AttendanceTable

const TableHeader = styled(Spacer)``
const SectionWrapper = styled(Box)`
	:not(:first-of-type) {
		${TableHeader} {
			display: none;
		}
	}
`
const TableHeaderWrapper = styled(Box)`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-items: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		grid-template-columns: repeat(4, 1fr);
	}`}
`
