import React, { createContext, useContext } from 'react'
type ProfileTabsContextArgs = {
	setIsDirty: React.Dispatch<boolean>
	isDirty: boolean
	blockedNavigationDone: boolean
}

const ProfileTabsContext = createContext<ProfileTabsContextArgs | null>(null)

export const ProfileTabsProvider: React.FC<ProfileTabsContextArgs> = ({ children, ...props }) => (
	<ProfileTabsContext.Provider value={props}>{children}</ProfileTabsContext.Provider>
)

export const useProfileTabs = () => {
	const context = useContext(ProfileTabsContext)
	if (!context) throw new Error('No ProfileTabsProvider found !!!')
	return context
}
