import { TextButton } from '@eduact/ed-components'
import styled, { css, keyframes } from 'styled-components'
import { DisplayProps, flexbox, FlexboxProps } from 'styled-system'

const MoveHeader = keyframes`
from {
	top : -10%;
	box-shadow : none;
}
to { 
	top : 0%;
	box-shadow : 0px 2px 10px -1px #00000024;
}
`
export const MainHeaderContainer = styled.div<{ fixed: boolean; layoutConfigFixed: boolean }>`
	direction: ltr;
	/* position: ${props => (props.fixed ? 'fixed' : 'relative')}; */
	position: fixed;
	box-shadow: ${props => (!props.fixed ? 'none' : '')};
	z-index: 99;

	animation: ${props =>
		props.fixed
			? css`
					${MoveHeader} 500ms ease-in-out forwards
			  `
			: ''};
	width: 100vw;
`
export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.813rem 0.3rem;
	background: ${props => props.theme.colors.cultured};
	background: ${props => props.theme.colors.light};
	width: 100vw;
	overflow-x: hidden;
	@media (min-width: 768px) {
		padding: 0.5rem 2rem;
	}
`

export const LogoImageWrapper = styled.div`
	/* flex-basis: 20%; */
	flex: 1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	img {
		max-width: 100% !important;
		height: auto;
		display: block;
	}
`
export const SearchInputStyled = styled.div`
	border: none;
	border: 1px solid #c4c4c4;
	border-radius: ${props => props.theme.borderRadii.small}px;
	padding: 0.375rem;
	background: ${props => props.theme.colors.light};
	font-size: 0.625rem;
	display: inline-flex;
	align-items: center;
	text-overflow: ellipsis;
	width: 100%;
	@media (min-width: 1024px) {
		font-size: 0.875rem;
		width: 75%;
		padding: 0.375rem 2.813rem;
	}
	input {
		font-size: inherit;
		border: none;
		outline: none;
		background: none;
		text-overflow: ellipsis;
		flex-grow: 1;
	}
`
export const SearchInputWrapper = styled.div<FlexboxProps>`
	${flexbox};
	margin: 0 0.4rem;
	width: 10.25rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		width: 650px;

	}`}
	input {
		padding: 0.375rem 0.75rem;
	}
`
export const RightSideWrapper = styled.div<DisplayProps>`
	flex: 1;
	font-size: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	/*  */
	@media (min-width: 1024px) {
		display: none;
	}
`
export const DesktopRHS = styled.div`
	display: none;
	@media (min-width: 1024px) {
		display: flex;
		justify-content: flex-end;
	}
	flex: 1;
	align-items: center;
	justify-content: space-between;
`
export const UserPrefsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: initial;
	span {
		padding: 0 0.4rem;
	}
`
export const UsernameChip = styled.div`
	margin: 0 1rem;
	background: ${props => props.theme.colors.platinum};
	padding: 0.2rem;
	display: flex;
	align-items: center;
	border-radius: ${props => props.theme.borderRadii.medium}px;
	width: fit-content;
	font-size: 0.75rem;
`
export const LogoutButton = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 0.625rem;
	text-decoration: underline;
	span {
		margin-top: 0.25rem;
	}
`

export const LangugaeButton = styled(TextButton)`
	display: none;
	width: fit-content;
	@media (min-width: 1024px) {
		display: flex;
	}
`
