import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import logo from './Assets/logo.svg'
import { GrLanguage } from 'react-icons/gr'
import { MdOutlineMenu } from 'react-icons/md'
import { AiOutlineSearch } from 'react-icons/ai'
import Spacer from '@Components/Utilities/Spacer'
import { TextButton, IconButton, SkeletonBox } from '@eduact/ed-components'
import { LogoutIcon, ParentIcon } from './Assets/Icons'
import Avatar from '@Components/Utilities/Avatar/Avatar'
import { Divider } from '@Components/Utilities/Divider'
import {
	MainHeaderContainer,
	HeaderWrapper,
	LogoImageWrapper,
	SearchInputWrapper,
	SearchInputStyled,
	RightSideWrapper,
	DesktopRHS,
	UserPrefsWrapper,
	UsernameChip,
	LogoutButton,
	LangugaeButton,
} from './Header.styled'
import CustomLink from '@Styles/CustomLink'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { useDispatch } from 'react-redux'
import { logoutAction } from '@Features/Authentication/AuthenticationSlice/Authentication.slice'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import landingPageApi from '@Features/LandingPage/LandingPageApi'
import classroomApi from '@Features/Classroom/ClassroomSlice/ClassroomSlice'
import { useTranslation } from 'react-i18next'
import { FlexLayout, GridItem } from '@eduact/ed-system'
import styled from 'styled-components'
import { createTheme, useStudentScreenSize, useTheme } from '@eduact/student-theme'
import { Drawer } from '@eduact/ed-components'
import EdDrawer from './Components/EdDrawer/EdDrawer'
import routes from '@Constants/Routes'
import { useLayoutProvider } from '@Providers/LayoutProvider/LayoutProvider'
import WalletAvatar from './Components/Wallet/WalletAvatar'
import { dashboardAPI, useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { BaseApi } from '@Features/Base/BaseApi/BaseApi'
import { setCurrentURL } from '@Redux/UtilsSlice/UtilsSlice'
import { defaultAssets } from '@Constants/assets.defaults'
const Header: React.FC = () => {
	const [fixed, setFixed] = useState(false)
	const headerRef = useRef<HTMLDivElement>(null)
	const { user, isAuth } = useAppSelector(state => state.auth)
	const { data } = useGetUserProfileQuery(undefined, {
		skip: !isAuth,
	})

	const { assets, assetsLoading } = useAppSelector(s => s.settings)

	const dispatch = useDispatch()
	const [t, i18n] = useTranslation('layouts', { keyPrefix: 'header' })
	const history = useNavigate()
	const location = useLocation()

	const { showWallet, fixedHeader, embed } = useLayoutProvider()
	const { app_wallet } = useAppSelector(s => s.settings)
	const { setTheme, theme } = useTheme()
	const [drawerOpen, setDrawerOpen] = useState(false)
	const { isDesktop } = useStudentScreenSize()
	useEffect(() => {
		if (i18n.language === 'ar-EG') {
			setTheme?.({ ...theme, direction: 'rtl' })
		} else {
			setTheme?.({ ...theme, direction: 'ltr' })
		}
		window.addEventListener('scroll', onWindowScroll, false)
		return () => {
			window.removeEventListener('scroll', onWindowScroll, false)
		}
	}, [])

	const onWindowScroll = (e: Event) => {
		const scrollPosition = window.scrollY
		if (headerRef && headerRef.current != null) {
			if (scrollPosition > 300) {
				headerRef.current.style.zIndex = '99'
				setFixed(true)
			} else {
				headerRef.current.style.zIndex = ''
				setFixed(false)
			}
		}
	}
	const handleLogout = () => {
		dispatch(logoutAction())
		dispatch(BaseApi.util.resetApiState())
		setDrawerOpen(false)
		history('/')
	}
	const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key !== 'Enter') return
		const { value } = e.target as HTMLInputElement
		if (value === '') {
			history(`/results?query=`)
		} else {
			history(`/results?query=${value}`)
		}
	}

	const changeLanguage = () => {
		i18n.changeLanguage(i18n.language === 'ar-EG' ? 'en-GB' : 'ar-EG')
		if (i18n.language === 'ar-EG') {
			setTheme?.({ ...theme, direction: 'rtl' })
		} else {
			setTheme?.({ ...theme, direction: 'ltr' })
		}
	}

	const handleAuthNavigation = (route: string) => {
		dispatch(setCurrentURL(location.pathname))
		history(route)
	}

	return (
		<MainHeaderContainer layoutConfigFixed={fixedHeader} fixed={fixed} ref={headerRef}>
			<HeaderWrapper>
				<EdDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} handleLogout={handleLogout} />
				<LogoImageWrapper>
					<GridItem gridColumn={{ sm: '1/3', lg: '1/2' }}>
						{/* <CustomLink width="70px" to={embed ? '' : '/'} display='block'> */}
						<LogoLink to={embed ? '' : '/'} display="block">
							{assetsLoading && <SkeletonBox isLoading width="90px" height="60px" />}
							{!assetsLoading && <img alt="eduact" src={assets.logo ?? defaultAssets.logo} />}
						</LogoLink>
					</GridItem>
					<LangugaeButton onClick={changeLanguage} variant="dark" padding="0">
						<GrLanguage />
						<Spacer mx={2} />
						{i18n.language === 'ar-EG' ? 'English' : 'عربي'}
					</LangugaeButton>
				</LogoImageWrapper>
				<SearchInputWrapper>
					<SearchInputStyled>
						<AiOutlineSearch />
						<input
							// value={search}
							// onChange={e => e.preventDefault()}
							onKeyPress={handleSearch}
							placeholder={t('search_hint')}
						/>
					</SearchInputStyled>
				</SearchInputWrapper>
				<RightSideWrapper display={{ md: 'hidden' }}>
					<RightSideContainer>
						<TextButton onClick={changeLanguage} variant="dark" padding="0">
							<GrLanguage />
							<Spacer mx={2} />
							{i18n.language === 'ar-EG' ? 'English' : 'عربي'}
						</TextButton>
						<Spacer mx={3} />
						<IconButton onClick={() => setDrawerOpen(!drawerOpen)} padding={'0'} variant="dark" icon={<MdOutlineMenu size={'1.1rem'} />} />
					</RightSideContainer>
				</RightSideWrapper>
				<DesktopRHS>
					<UserPrefsWrapper>
						{!isAuth && (
							<>
								<TextButton
									textDecoration={'none'}
									onClick={() => handleAuthNavigation('/login')}
									variant="dark"
									padding={'0'}
									fontWeight={'bold'}
								>
									{t('login')}
								</TextButton>
								<Spacer />
								<TextButton
									textDecoration={'none'}
									onClick={() => handleAuthNavigation('/signup')}
									variant="dark"
									padding={'0'}
									fontWeight={'bold'}
								>
									{t('signup')}
								</TextButton>
							</>
						)}
						{isAuth && (
							<CustomLink to={routes.dashboard}>
								<UsernameChip>
									<Avatar img={data?.data?.profile_photo} avatarSize="small" />
									<Spacer mx={'6px'} />
									<span>{user?.username}</span>
								</UsernameChip>
							</CustomLink>
						)}
						{isAuth && !embed && (
							<LogoutButton onClick={() => handleLogout()}>
								<LogoutIcon />
								<span>{t('logout')}</span>
							</LogoutButton>
						)}
					</UserPrefsWrapper>
				</DesktopRHS>
			</HeaderWrapper>
			{app_wallet && showWallet && isDesktop && <WalletAvatar />}
		</MainHeaderContainer>
	)
}

export default Header

const RightSideContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
`

const LogoLink = styled(CustomLink)`
	img {
		max-width: unset;
		max-height: 60px;
	}
`
