import { BaseWidget, Mosaic } from '@eduact/ed-components'
import React from 'react'
import ClassroomProgress from './Components/ClassroomProgress'
import CompletedUnitsGraph from './Components/CompletedUnitsGraph'
import InstrcutorInfo from './Components/InstructorInfo'
import TimeLineWidget from './Components/TimelineWidget'
import SingleClassroomAnalyticsWidget from './SingleClassroomAnalyticsWidget'

const SingleClassroomOverview = ({ ...args }) => {
	return (
		<Mosaic height={'100%'}>
			<Mosaic.Row flex={1}>
				<Mosaic.Col flex={1}>
					<Mosaic.Row flex={1}>
						<Mosaic.Col flex={2}>
							<InstrcutorInfo />
						</Mosaic.Col>
						<Mosaic.Col flex={2}>
							<Mosaic.Row flex={2}>
								<Mosaic.Col flex={2}>
									<ClassroomProgress />
									<CompletedUnitsGraph />
								</Mosaic.Col>
							</Mosaic.Row>
						</Mosaic.Col>
					</Mosaic.Row>
				</Mosaic.Col>
				<Mosaic.Col flex={1}>
					<Mosaic.Col flex={2}>
						<SingleClassroomAnalyticsWidget />
					</Mosaic.Col>
				</Mosaic.Col>
			</Mosaic.Row>
			<Mosaic.Row flex={1}>
				<Mosaic.Col flex={2}>
					<BaseWidget widgetProps={undefined} onClick={() => {}} widget={TimeLineWidget} {...args} bg="light" />
				</Mosaic.Col>
			</Mosaic.Row>
		</Mosaic>
	)
}

export default SingleClassroomOverview
