import { AcceptPay } from '@Components/Layout/Header/Assets/js/AcceptPay'
import { DISPLAY_MODE, FawryPay } from '@Components/Layout/Header/Assets/js/FawryPay'
import { store } from '@Redux/store'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'
import {
	MethodsWithPayload,
	ProviderWithPayload,
	RechargeCallbackPayload,
	RechargePayloadType,
	RechargeProcessed,
	RechargeProvider,
} from '@Features/Dashboard/DashboardSlice/Dashboard.slice.types'
import { useLazySendRechargeCallbackQuery, useProccedRechargeRequestMutation } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { FlexLayout } from '@eduact/ed-system'
import MainTab from './Tabs/Components/MainTab'
import MethodsTab from './Tabs/Components/MethodsTab'
import ValuesTab from './Tabs/Components/ValuesTab'
import VodafoneTab from './Tabs/Components/VodafoneTab'
import { WalletContext } from '../WalletContext'
import RechargeCodeTab from './Tabs/Components/RechargeCodeTab'
import { Tabs, TabsContent, TabsContents, TabsHeader, TabsHeaders, TabsProvider } from '@eduact/ed-components'
type Props = {}
const WalletTabs: React.FC<Props> = () => {
	const [proccedRecharge, { isLoading, isError, isSuccess, data }] = useProccedRechargeRequestMutation()
	const [sendCallback] = useLazySendRechargeCallbackQuery()

	const history = useNavigate()
	const { closeWallet, walletIndex, setWalletIndex } = useContext(WalletContext)

	const proccessRecharge = React.useCallback(
		async (rechargeAmount, provider: RechargeProcessed, phone?: string) => {
			try {
				if (rechargeAmount && provider) {
					const result = await proccedRecharge({
						amount: rechargeAmount,
						provider: provider.provider as ProviderWithPayload,
						phone: phone,
						method: provider.method as MethodsWithPayload,
					}).unwrap()

					if (!result) return

					if (provider.provider === 'fawry' ) {
						FawryPay.checkout(result.payload, {
							returnUrl: `${window.location.href}/invoices`,
							mode: DISPLAY_MODE.SEPARATED,
							onSuccess: (e: RechargeCallbackPayload) => {
								sendCallback(e)
							},
							onFailure: (e: any) => {},
						})
					}
					if (result.provider === 'opay' && result.method) {
						window.open(result.payload.data.cashierUrl, '_self')
					}
					if (result.provider === 'accept' && result.method && result.method === 'card') {
						AcceptPay.checkoutJS(
							result.payload,
							() => {},
							() => {}
						)
					}
					if (result.method === 'aman' || result.method === 'masary') {
						history(`/payment/callback?${queryString.stringify(result.payload)}&bill_reference=${result.payload.data.bill_reference}`)
					}
					if (result.method === 'ewallet') {
						var win = window.open(result.payload.redirection_url, '_self')
						win?.focus()
					}
					closeWallet && closeWallet()
				}
			} catch (error) {}
		},
		[isError, isSuccess, isLoading, proccedRecharge, data, closeWallet, sendCallback, history]
	)

	return (
		<>
			<TabsProvider
				onChange={value => {
					setWalletIndex?.(Number(value))
				}}
				index={walletIndex}
				active={walletIndex?.toString() ?? ''}
			>
				<CustomStyledTabs noHeader>
					{{
						tabs: (
							<TabsHeaders>
								<TabsHeader label="Main" value="0" />
								<TabsHeader label="Methods" value="1" />
								<TabsHeader label="Values" value="2" />
								<TabsHeader label="vodafone" value="3" />
								<TabsHeader label="rechargecode" value="4" />
							</TabsHeaders>
						),
						contents: (
							<TabsContents>
								<TabsContent value="0">
									<MainTab />
								</TabsContent>
								<TabsContent value="1">
									<MethodsTab />
								</TabsContent>
								<TabsContent value="2">
									<ValuesTab proccessRecharge={proccessRecharge} />
								</TabsContent>
								<TabsContent value="3">
									{/* <VodafoneTab proccessRecharge={proccessRecharge} /> */}
								</TabsContent>
								<TabsContent value="4">{walletIndex && walletIndex === 4 && <RechargeCodeTab />}</TabsContent>
							</TabsContents>
						),
					}}
				</CustomStyledTabs>
			</TabsProvider>
		</>
	)
}

export default WalletTabs
const CustomStyledTabs = styled(Tabs)`
	padding: 0 1rem;
`
export type ProccessRechargeCallback = (rechargeAmount: number, provider: RechargeProcessed, phone?: string) => Promise<void>
