import AuthenticationApi from '@Features/Authentication/AuthenticationApi/Authentication.api'
import React, { useEffect, useMemo, useState } from 'react'

type UseEducationalInfoArgs = {
	currentDiploma: number | undefined
	currentYear: number | undefined
}
const useEducationalInfo = ({ currentDiploma, currentYear }: UseEducationalInfoArgs) => {
	const [educationalInfo, setEducationalInfo] = useState<EducationalType[]>([])

	const getEducationalInfo = async () => {
		const {
			data: { data },
		} = await AuthenticationApi.getEducationalInfo()
		setEducationalInfo(data)
	}

	useEffect(() => {
		getEducationalInfo()
	}, [])

	const diplomas = useMemo(() => {
		return educationalInfo.map(_ => ({ value: _.id, name: _.name }))
	}, [educationalInfo])

	const years = useMemo(() => {
		const selectedDiploma = educationalInfo.find(_ => _.id === Number(currentDiploma))
		if (selectedDiploma) {
			return selectedDiploma.educationYears.map(_ => ({ value: _.id, name: _.name, sections: _.educationSections }))
		}
		return []
	}, [currentDiploma, educationalInfo])

	const languages = useMemo(() => {
		const selectedDiploma = educationalInfo.find(_ => _.id === Number(currentDiploma))
		if (selectedDiploma) {
			return selectedDiploma.educationLanguages.map(_ => ({ value: _.id, name: _.name }))
		}
		return []
	}, [educationalInfo, currentDiploma])

	const sections = useMemo(() => {
		const selectedYear = years?.find(_ => _.value === Number(currentYear))
		if (selectedYear) {
			const _sections = selectedYear.sections.map(_ => ({ value: _.id, name: _.name }))
			return _sections
		}
		return []
	}, [currentYear, years])

	return {
		diplomas,
		years,
		languages,
		sections,
	}
}

export default useEducationalInfo
