import styled from 'styled-components'

export const StudentLandingPageWrapper = styled.div`
	padding: 1.5rem;
	width: 100%;
	@media (min-width: 1024px) {
		padding: 0;
		/* padding: 4.25rem 5.75rem; */
	}
`
