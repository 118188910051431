import React, { useMemo } from 'react'
import { Color } from '@eduact/student-theme'
import {
	Invoice,
	InvoiceHeader,
	ReferenceNumberLabel,
	ReferenceNumber,
	InvoiceContent,
	Container,
	Row,
	Col,
	InvoiceLabel,
	InvoiceValue,
	ProviderRefNumber,
} from './InvoiceCard.styled'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Box, FlexLayout, Typography } from '@eduact/ed-system'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'

export type InvoiceCardProps = {
	invoiceReferenceNumber: string
	date: string
	amount: string
	type: InvoiceType
	provider: string
	status: InvoiceStatus
	providerReference: string
	headerColor?: Color
}

const InvoiceCard: React.FC<InvoiceCardProps> = ({
	invoiceReferenceNumber,
	date,
	amount,
	type,
	provider,
	status,
	providerReference,
	headerColor = 'independence',
}) => {
	const [t] = useTranslation('dashboard')

	const typeLabelColor = useMemo<Color>(() => {
		switch (type) {
			case 'purchase':
				return 'primary'
			case 'recharge':
				return 'princetonOrange'
			case 'refund':
				return 'purple'
			default:
				return 'purpleNavy'
		}
	}, [type])

	const statusLabelColor = useMemo<Color>(() => {
		switch (status) {
			case 'failed':
				return 'red'
			case 'new':
				return 'purple'
			case 'paid':
				return 'primary'
			default:
				return 'purpleNavy'
		}
	}, [type])
	return (
		<Invoice>
			<InvoiceHeader variant={headerColor}>
				<ReferenceNumberLabel>{t('invoice_id')}</ReferenceNumberLabel>
				<ReferenceNumber>{invoiceReferenceNumber}</ReferenceNumber>
			</InvoiceHeader>
			<InvoiceContent>
				<Container>
					<Row>
						<Col>
							<InvoiceLabel>{t('invoice_date')}</InvoiceLabel>
							<InvoiceValue maxWidth={'10ch'}>
								<FlexLayout flexDirection={'column'}>
									<span>{moment(date).format('DD/MM/YYYY')}</span>
									<Typography fontWeight={500}>{moment(date).format('hh:mm A')}</Typography>
								</FlexLayout>
							</InvoiceValue>
						</Col>
						<Col>
							<InvoiceLabel>{t('amount')}</InvoiceLabel>
							<InvoiceValue>
								<Typography fontWeight={500}>{amount} L.E</Typography>
							</InvoiceValue>
						</Col>
					</Row>

					<Row>
						<Col>
							<InvoiceLabel>{t('type')}</InvoiceLabel>
							<InvoiceValue>
								<EdTypography fontWeight={500} fontSize={'0.75rem'} fontStyle={'italic'} color={typeLabelColor}>
									{type}
								</EdTypography>
							</InvoiceValue>
						</Col>
						<Col>
							<InvoiceLabel>{t('status')}</InvoiceLabel>
							<InvoiceValue highlighted>
								<EdTypography fontWeight={500} fontSize={'0.75rem'} color={statusLabelColor}>
									{status}
								</EdTypography>
							</InvoiceValue>
						</Col>
					</Row>

					<Row>
						<Col>
							<InvoiceLabel>{t('method')}</InvoiceLabel>
							<InvoiceValue>
								<EdTypography fontWeight={500}>{provider}</EdTypography>
							</InvoiceValue>
						</Col>
						<Col>
							<InvoiceLabel>{t('reference_no')}</InvoiceLabel>
							<InvoiceValue>
								<ProviderRefNumber>
									<Typography>{providerReference}</Typography>
								</ProviderRefNumber>
							</InvoiceValue>
						</Col>
					</Row>
				</Container>
			</InvoiceContent>
		</Invoice>
	)
}

export default InvoiceCard
