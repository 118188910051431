import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type RunningTestData = {
	classroomLabel: string
	course_id: number
	unit_id: number
}
type RunningTestState = {
	testData?: RunningTestData
}
const initialState: RunningTestState = {}
const RunningTestSlice = createSlice({
	initialState,
	name: 'RunningTest',
	reducers: {
		setRunningTest: (state, action: PayloadAction<RunningTestState>) => {
			localStorage.setItem('testData', JSON.stringify(action.payload.testData))
			state.testData = action.payload.testData
		},
		resetRunningTest: state => {
			localStorage.removeItem('testData')
			state.testData = undefined
		},
		checkRunningTest: state => {
			const test = localStorage.getItem('testData')
			if (test) {
				state.testData = JSON.parse(test)
			}
		},
	},
})

export const { resetRunningTest, setRunningTest, checkRunningTest } = RunningTestSlice.actions
export default RunningTestSlice
