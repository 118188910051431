import { Stack } from '@eduact/ed-system'
import React from 'react'
import styled from 'styled-components'
import { ToggleButton } from '../ToggleButton'

type Props = {
	opened: boolean
	onClick: () => void
}
const DrawerToggler: React.VoidFunctionComponent<Props> = ({ opened, onClick }) => {
	return (
		<ToggleStack position="fixed" opened={opened}>
			<ToggleButton onClick={onClick} opened={opened} />
		</ToggleStack>
	)
}

export default DrawerToggler

const ToggleStack = styled(Stack).attrs(props => ({ dir: document.documentElement.dir }))<{ opened: boolean }>`
	background: #fff;
	right: 0;
	left: unset;
	&[dir='rtl'] {
		right: unset;
		left: 0;
	}
	transform: rotate(180deg);
	border-radius: ${props => (props.theme.direction === 'ltr' ? '5px 0 0 5px' : '0 5px 5px 0')};
	opacity: 0;
	z-index: -5;
	transition: all ease-in-out 600ms;
	${({ theme, opened }) => `${theme.mediaQueries.large}{
		opacity: ${!opened ? '1' : '0'};
		z-index: ${!opened ? '9999' : '-5'};
	}`}
`
