import { ResetPasswordForm } from '@Components/Form/ResetPasswordForm'
import {
	Card,
	Dialog,
	Modal,
	RaisedButton,
	SkeletonBox,
	Tabs,
	TabsContent,
	TabsContents,
	TabsHeader,
	TabsHeaders,
	TabsProvider,
} from '@eduact/ed-components'
import { FlexLayout, Typography } from '@eduact/ed-system'
import { rgba } from 'polished'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useGetUserProfileQuery } from '../DashboardSlice/Dashboard.slice'
import { ProfileBasicInfo } from './ProfileBasicInfo'
import { ProfileEducationalInfo } from './ProfileEducationalInfo'
import { PanelInfo, ProfileInfoPanelProvider } from './ProfileInfoPanelContext'
import { ProfilePersonalInfo } from './ProfilePersonalInfo'
import { ProfileTabsProvider } from './ProfileTabsProvider'
const Profile = () => {
	const [active, setActive] = useState('0')
	const [t] = useTranslation('studentDashboard')
	const [activeIndex, setActiveIndex] = useState(0)
	const { data, isLoading } = useGetUserProfileQuery()
	const [panelInfo, setPanelInfo] = useState<PanelInfo>('card')
	const [isFormDirty, setIsFormDirty] = useState(false)
	const [blockedTabValue, setBlockTabValue] = useState<string | undefined>()
	const [openWarningDialog, setOpenWarningDialog] = useState(false)
	const [navigatedBlockedTab, setNavigatedBlockedTab] = useState(false)
	const [openPasswordModal, setOpenPasswordModal] = useState(false)
	const handleCloseWarning = () => {
		setBlockTabValue(undefined)
		setOpenWarningDialog(false)
	}
	const handleConfirmWarning = () => {
		setIsFormDirty(false)
		blockedTabValue && setActive(blockedTabValue)
		setNavigatedBlockedTab(true)
		setOpenWarningDialog(false)
	}
	const handleTabChange = (value: string) => {
		setPanelInfo('card')
		if (!isFormDirty) {
			setActive(value)
			return
		}
		setBlockTabValue(value)
		setOpenWarningDialog(true)
		setNavigatedBlockedTab(false)
	}
	return (
		<ProfileContainer>
			<Dialog
				center
				padding={{ sm: '1rem', lg: '2rem' }}
				width={{ lg: '20vw' }}
				height={'fit-content'}
				open={openWarningDialog}
				persistent
				onClose={handleCloseWarning}
			>
				<FlexLayout flexDirection={'column'} gridGap="2rem">
					<Typography fontWeight={600}>There are unsaved changes, do you wish to discard them ?</Typography>
					<FlexLayout flexWrap={'wrap'} flexDirection={{ sm: 'column', md: 'row' }} justifyContent={'flex-end'} gridGap="1rem" width="100%">
						<RaisedButton onClick={handleCloseWarning} variant="princetonOrange" outlined btnSize={'small'}>
							Stay on this page
						</RaisedButton>
						<RaisedButton onClick={handleConfirmWarning} btnSize={'small'}>
							Leave this page
						</RaisedButton>
					</FlexLayout>
				</FlexLayout>
			</Dialog>
			<SkeletonBox
				borderRadius={'inherit'}
				height={{ sm: '100vh', lg: '100%' }}
				minWidth={{ sm: '100%', xl: '63.081rem' }}
				isLoading={isLoading}
			>
				<ProfileInnerContainer>
					<Title>{t("profileSettings")}</Title>
					<ProfileInfoPanelProvider
						currentInfo={panelInfo}
						setCurrentInfo={setPanelInfo}
						openPasswordModal={openPasswordModal}
						setPasswordModal={setOpenPasswordModal}
					>
						<Modal
							width={'fit-content'}
							height="fit-content"
							onClose={() => {
								console.log('CLOSE')
								setPanelInfo('card')
								setOpenPasswordModal(false)
							}}
							withBackdrop
							withStyling
							center
							open={openPasswordModal}
						>
							<Card width={'90vw'} padding={'2rem'} variant={'light'}>
								<ResetPasswordForm />
							</Card>
						</Modal>
						<ProfileTabsProvider isDirty={isFormDirty} blockedNavigationDone={navigatedBlockedTab} setIsDirty={setIsFormDirty}>
							<TabsProvider onChange={handleTabChange} active={active} index={activeIndex}>
								<Tabs onChange={value => console.log(value)}>
									{{
										tabs: (
											<StyledTabsHeader gridGap={'1.5rem'}>
												<TabsHeader index={0} value={'0'}>
													{({ selected }) => <TabItem selected={selected}>{t('basicInfo')}</TabItem>}
												</TabsHeader>

												<TabsHeader index={1} value={'1'}>
													{({ selected }) => <TabItem selected={selected}>{t('educationalInfo')}</TabItem>}
												</TabsHeader>

												<TabsHeader index={2} value={'2'}>
													{({ selected }) => <TabItem selected={selected}>{t('personalInfo')}</TabItem>}
												</TabsHeader>
											</StyledTabsHeader>
										),
										contents: (
											<StyledTabsContents height={'100%'} flex={1}>
												<TabsContent height={'100%'} value="0">
													{data && <ProfileBasicInfo />}
												</TabsContent>
												<TabsContent height={'100%'} value="1">
													{data && <ProfileEducationalInfo />}
												</TabsContent>
												<TabsContent height={'100%'} value="2">
													{data && <ProfilePersonalInfo />}
												</TabsContent>
											</StyledTabsContents>
										),
									}}
								</Tabs>
							</TabsProvider>
						</ProfileTabsProvider>
					</ProfileInfoPanelProvider>
				</ProfileInnerContainer>
			</SkeletonBox>
		</ProfileContainer>
	)
}

export default Profile

const ProfileContainer = styled.div`
	background: ${rgba('#FFF', 0.5)};
	width: 100%;
	height: 100%;
	padding: 11px;
	border-radius: 15px;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 0;
	}`}
	${({ theme }) => `${theme.mediaQueries.xlarge}{
		min-width : 63.081rem;
		max-width : 63.081rem;
	}`}
`
const ProfileInnerContainer = styled.div`
	background: #fff;
	width: 100%;
	height: 100%;
	padding: 11px;
	border-radius: 9px;
	display: flex;
	flex-direction: column;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 22px 30px;
	}`}
`

const Title = styled.h1`
	font-weight: 600;
	font-size: 0.75rem;
	margin-bottom: 0.688rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
		margin-bottom: 1.8rem;
	}`}
`

const StyledTabsHeader = styled(TabsHeaders)`
	margin-bottom: 1rem;
	width: 100%;
	div {
		font-size: 10px;
		${({ theme }) => `${theme.mediaQueries.large}{
			font-size: 14px;

		}`}
		white-space: nowrap;
		line-height: 1;
	}
	border-bottom: 1px solid;
	padding-bottom: 1px;
	border-color: lightgrey;
`

const TabItem = styled.div<{ selected?: boolean }>`
	/* border-bottom: 1px solid #c7c5f4; */
	position: relative;
	color: ${props => (props.selected ? '#c7c5f4' : '#b6b6b6')};
	font-size: 10px;
	font-weight: 600;
	padding: 0.5em 0px;
	cursor: pointer;
	&::before {
		display: ${props => (props.selected ? 'block' : 'none')};
		content: '';
		position: absolute;
		height: 3px;
		width: 100%;
		background: #c7c5f4;
		left: 0;
		top: 100%;
	}
`

const StyledTabsContents = styled(TabsContents)`
	.tabs-contents-swiper,
	.tabs-contents-inner-wrapper {
		height: 100%;
	}
`
