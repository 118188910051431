import { ITheme } from '@eduact/student-theme'
import { primaryTheme } from './primary.theme'
import { secondaryTheme } from './secondary.theme'

export type ThemeKey = 'primary' | 'secondary'

type ThemeMap = { [key in ThemeKey]: ITheme }

export const themesMap: ThemeMap = {
	primary: primaryTheme,
	secondary: secondaryTheme,
}
