import { useEditUserProfileMutation, useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { yupResolver } from '@hookform/resolvers/yup'
import useEducationalInfo from '@Hooks/useEducationalInfo'
import React, { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { cloneDeep, merge } from 'lodash'
import CustomTextInput from '@Components/InputFields/CustomTextInput/CustomTextInput'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { TextInput, RaisedButton, Dropdown } from '@eduact/ed-components'
import { FlexLayout, Box, GridLayout } from '@eduact/ed-system'
import { DevTool } from '@hookform/devtools'
import { ProfileInfoPanel } from '../ProfileInfoPanel'
import CustomDropdown from '@Components/InputFields/CustomDropdown/CustomDropdown'
import { useStudentScreenSize } from '@eduact/student-theme'
import { useTranslation } from 'react-i18next'
import { useProfileTabs } from '../ProfileTabsProvider'
import { FormButton, FormGrid, ProfileForm } from '../Profile.styled'
import { PhoneRegex } from '@Constants/Regex'
import moment from 'moment'
import Cities from '@Constants/Cities'
import Governorates from '@Constants/Governorates'
import { EditProfilePayload } from '@Features/Dashboard/DashboardSlice/Dashboard.slice.types'

//
//

const schema = yup.object().shape({
	address: yup.object().shape({
		country: yup.string().nullable(true),
		building_number: yup.string().nullable(true),
		postal_code: yup.string().nullable(true),
		apartment: yup.string().nullable(true),
		floor: yup.string().nullable(true),
		street: yup.string().nullable(true),
		governorate: yup.number().required('field_required'),
		city: yup.string().required('field_required'),
	}),
	student: yup.object({
		parent_relation: yup.string().required(),
		parent_phone: yup.string().required().matches(PhoneRegex, 'invalid_phone'),
	}),
	birth_date: yup.string().required(),
	gender: yup.string().required(),
})
const ProfilePersonalInfo = () => {
	const { data } = useGetUserProfileQuery()
	const isInitialMount = useRef(true)

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { isValid, isDirty, isSubmitting },
	} = useForm<PersonalInfoFormPayload>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: cloneDeep({
			gender: data?.data.gender,
			birth_date: moment(data?.data.birth_date).format('YYYY-MM-DD'),
			address: data?.data.student.address,
			student: {
				parent_phone: data?.data.student.parent_phone,
				parent_relation: data?.data.student.parent_relation,
			},
		}),
	})

	const { setIsDirty, blockedNavigationDone } = useProfileTabs()

	useEffect(() => {
		setIsDirty(isDirty)
	}, [isDirty])

	useEffect(() => {
		if (blockedNavigationDone) {
			reset()
		}
	}, [blockedNavigationDone])

	const watchGov = watch('address.governorate')

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			setValue('address.city', '', { shouldValidate: true })
		}
	}, [watchGov, isInitialMount])

	const selectedCities = useMemo(() => {
		if (watchGov) {
			if (watchGov != data?.data.student.address.governorate) {
				setValue('address.city', undefined)
			}
			return Cities.filter(_ => Number(_.governorate_id) == watchGov)
		}
		return undefined
	}, [watchGov])

	const { isDesktop } = useStudentScreenSize()
	const { t, i18n } = useTranslation(['dropdowns', 'inputs', 'dashboard'])
	const [update] = useEditUserProfileMutation()

	const handleSave = async (formData: PersonalInfoFormPayload) => {
		try {
			const _data = Object.assign({}, [formData, data?.data])[0] as EditProfilePayload
			const isSuccess = await update({ type: 'educational_info', data: _data }).unwrap()
			formData.address.city = Number(formData.address.city)
			console.log(formData)
			if (isSuccess) {
				reset(formData, { keepDefaultValues: false, keepValues: true, keepDirty: false, keepIsSubmitted: false })
				// reset(formData, { keepDirty: false, keepIsSubmitted: false })
			}
		} catch (error) {}
	}
	const handleCancel = () => reset()
	return (
		<ProfileForm onSubmit={handleSubmit(handleSave)}>
			<DevTool placement="left" control={control} />
			<FlexLayout height={'100%'} width={'100%'} flexDirection={'column'}>
				<FormGrid width={'100%'} gridTemplateColumns={{ lg: 'repeat(2,1fr)' }}>
					<Box width="100%">
						<CustomDropdown control={control} name="gender">
							<Dropdown.Option value="male">Male</Dropdown.Option>
							<Dropdown.Option value="female">Female</Dropdown.Option>
						</CustomDropdown>
						<CustomTextInput type="date" control={control} name="birth_date" />
						<CustomDropdown control={control} name="address.governorate">
							{Governorates.map((gov, index) => {
								return (
									<Dropdown.Option key={gov.id} value={gov.id}>
										{i18n.dir() === 'ltr' ? gov.name : gov.governorate_name_ar}
									</Dropdown.Option>
								)
							})}
						</CustomDropdown>
						<CustomDropdown control={control} name="address.city">
							{selectedCities &&
								selectedCities.map((city, index) => {
									return (
										<Dropdown.Option key={city.id} value={city.id}>
											{i18n.dir() === 'ltr' ? city.name : city.city_name_ar}
										</Dropdown.Option>
									)
								})}
						</CustomDropdown>
						<CustomDropdown control={control} name="student.parent_relation" placeholder="parent_relation">
							<Dropdown.Option value="Father">Father</Dropdown.Option>
							<Dropdown.Option value="Mother">Mother</Dropdown.Option>
						</CustomDropdown>
						<CustomTextInput placeholder="parent_phone" control={control} name="student.parent_phone" />
					</Box>
					{!isDesktop && (
						<FlexLayout px={'2.063rem'} width="100%" justifyContent={'space-between'} alignItems="center">
							<FormButton onClick={handleCancel} type="button" disabled={!isDirty} variant="princetonOrange" btnSize={'small'}>
								Cancel
							</FormButton>
							<FormButton type="submit" disabled={!isDirty || !isValid || isSubmitting} btnSize={'small'}>
								Save
							</FormButton>
						</FlexLayout>
					)}
					<Box width="100%">
						<ProfileInfoPanel />
					</Box>
				</FormGrid>
				{isDesktop && (
					<FlexLayout justifyContent={'flex-end'} gridGap={'1rem'}>
						<RaisedButton onClick={handleCancel} type="button" disabled={!isDirty} variant="princetonOrange">
							Cancel
						</RaisedButton>
						<RaisedButton type="submit" disabled={!isDirty || !isValid || isSubmitting}>
							Save
						</RaisedButton>
					</FlexLayout>
				)}
			</FlexLayout>
		</ProfileForm>
	)
}

export default ProfilePersonalInfo
