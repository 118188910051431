import { Typography } from '@eduact/ed-system'
import { Color } from '@eduact/student-theme'
import React, { ElementType } from 'react'
import styled from 'styled-components'
import { TypographyProps } from 'styled-system'
import { TextDecorationProps } from '@eduact/ed-system'

type Props<C> = { color?: Color } & TypographyProps & TextDecorationProps & { as?: C } & React.ComponentPropsWithoutRef<C> & React.HTMLProps<HTMLSpanElement>

const EdTypography = <C extends ElementType>({ children, as, ...props }: React.PropsWithChildren<Props<C>>) => {
	const Component = as || 'span'
	return (
		<StyledTypography as={Component} {...props}>
			{children}
		</StyledTypography>
	)
}

export default EdTypography

const StyledTypography = styled(Typography)<TypographyProps & TextDecorationProps & { color?: Color }>`
	color: ${props => props.theme.colors[(props.color as Color) ?? 'dark']};
`
