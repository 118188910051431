import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout, Typography } from '@eduact/ed-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsFillBookmarkFill } from 'react-icons/bs'
import styled from 'styled-components'
import { TypographyProps } from 'styled-system'

type Props = {
	withCourseName?: boolean
	courseName: string
} & TypographyProps
const CurrentCourseBookmark: React.FC<Props> = ({ withCourseName = true, courseName, ...props }) => {
	const { t } = useTranslation(['classroom'])
	return (
		<BookmarkWrapper mb={{ sm: '1.669rem', lg: '2.05rem' }}>
			<BsFillBookmarkFill />
			<Spacer mx={{ sm: 2, lg: 7 }} />
			<FlexLayout flexDirection={'column'}>
				<Typography fontWeight={'bold'} fontSize={{ sm: 9, lg: '1rem' }} {...props}>
					{t('highlighted_courses')}
				</Typography>
				{withCourseName && <Typography fontSize={{ sm: 9, lg: '0.875rem' }}>{courseName}</Typography>}
			</FlexLayout>
		</BookmarkWrapper>
	)
}

export default CurrentCourseBookmark
const BookmarkWrapper = styled(FlexLayout)`
	color: ${props => props.theme.colors.princetonOrange};
`
