import { useEditUserProfileMutation, useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { yupResolver } from '@hookform/resolvers/yup'
import useEducationalInfo from '@Hooks/useEducationalInfo'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { cloneDeep } from 'lodash'
import CustomTextInput from '@Components/InputFields/CustomTextInput/CustomTextInput'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { TextInput, RaisedButton, Dropdown } from '@eduact/ed-components'
import { FlexLayout, Box, GridLayout } from '@eduact/ed-system'
import { DevTool } from '@hookform/devtools'
import styled from 'styled-components'
import { ProfileInfoPanel } from '../ProfileInfoPanel'
import CustomDropdown from '@Components/InputFields/CustomDropdown/CustomDropdown'
import { useStudentScreenSize } from '@eduact/student-theme'
import { useTranslation } from 'react-i18next'
import { useProfileTabs } from '../ProfileTabsProvider'
import { FormButton, FormGrid, ProfileForm } from '../Profile.styled'

//
//

const schema = yup.object().shape({
	student: yup.object().shape({
		education_type_id: yup.number().required(),
		education_year_id: yup.number().required().positive('field_required'),
		education_language_id: yup.number().required().positive('field_required'),
		hasSections: yup.boolean().default(false),
		education_section_id: yup
			.number()
			.typeError('field_required')
			.when('hasSections', {
				is: (value: boolean) => {
					console.log(value)
					return value === true
				},
				then: yup.number().required('field_required').nullable().positive('field_required'),
				otherwise: yup.number().nullable(true).typeError('field_required'),
			}),
		school: yup.string().required('field_required'),
	}),
})
const ProfileEducationalInfo = () => {
	const { data } = useGetUserProfileQuery()
	const isInitialMount = useRef(true)

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { isValid, isDirty, isSubmitting },
	} = useForm<EducationalFormPayload>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: {
			student: cloneDeep({
				education_language_id: data?.data.student.education_language_id,
				education_type_id: data?.data.student.education_type_id,
				education_section_id: data?.data.student.education_section_id,
				education_year_id: data?.data.student.education_year_id,
				school: data?.data.student.school,
				hasSections: !!data?.data.student.education_section_id,
			}),
		},
	})

	const { setIsDirty, blockedNavigationDone } = useProfileTabs()

	useEffect(() => {
		setIsDirty(isDirty)
	}, [isDirty])

	useEffect(() => {
		if (blockedNavigationDone) {
			reset()
		}
	}, [blockedNavigationDone])
	const currentDiploma = watch('student.education_type_id')
	const currentYear = watch('student.education_year_id')
	const hasSections = watch('student.hasSections')

	const { diplomas, languages, sections, years } = useEducationalInfo({
		currentDiploma,
		currentYear,
	})

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			setValue('student.education_section_id', null, { shouldValidate: true })
		}
	}, [currentYear, isInitialMount])

	useEffect(() => {
		console.log({ sections, hasSections: sections.length > 0 })
		setValue('student.hasSections', sections.length > 0)
	}, [sections, setValue])

	const { isDesktop } = useStudentScreenSize()
	const [t] = useTranslation(['dropdowns', 'inputs', 'dashboard'])
	const [update] = useEditUserProfileMutation()

	const handleSave = async (formData: EducationalFormPayload) => {
		try {
			const isSuccess = await update({ type: 'educational_info', data: { ...data?.data, ...formData } }).unwrap()
			if (isSuccess) {
				reset(
					{
						...data?.data,
						...formData,
					},
					{ keepDirty: false, keepIsSubmitted: false }
				)
			}
		} catch (error) {}
	}
	const handleCancel = () => reset()
	return (
		<ProfileForm onSubmit={handleSubmit(handleSave)}>
			<DevTool placement="left" control={control} />
			<FlexLayout height={'100%'} width={'100%'} flexDirection={'column'}>
				<FormGrid width={'100%'} gridTemplateColumns={{ lg: 'repeat(2,1fr)' }}>
					<Box width="100%">
						<CustomTextInput placeholder="school" control={control} localeName="school" name="student.school" />
						{diplomas.length > 0 && (
							<CustomDropdown
								onChange={() => {
									setValue('student.education_year_id', -1, { shouldValidate: true })
									setValue('student.education_language_id', -1, { shouldValidate: true })
								}}
								placeholder="diploma"
								control={control}
								name={'student.education_type_id'}
								localeName="diploma"
							>
								{diplomas.map(diploma => {
									return (
										<Dropdown.Option key={`${diploma.name}-${diploma.value}`} value={diploma.value}>
											{t(`educational_type.${diploma.name}`)}
										</Dropdown.Option>
									)
								})}
							</CustomDropdown>
						)}

						{years.length > 0 && (
							<CustomDropdown localeName="academic_year" control={control} name="student.education_year_id" placeholder="academic_year">
								{years?.map(year => {
									return (
										<Dropdown.Option key={`${year.name}-${year.value}`} value={year.value}>
											{t(`academic_year.${year.name}`)}
										</Dropdown.Option>
									)
								})}
							</CustomDropdown>
						)}
						{languages.length > 0 && (
							<CustomDropdown placeholder="academic_lang" localeName="academic_lang" control={control} name="student.education_language_id">
								{languages?.map(lang => {
									return (
										<Dropdown.Option key={`${lang.name}-${lang.value}`} value={lang.value}>
											{t(`academic_lang.${lang.name}`)}
										</Dropdown.Option>
									)
								})}
							</CustomDropdown>
						)}
						{sections.length > 0 && (
							<>
								<CustomDropdown
									control={control}
									localeName="educational_field"
									placeholder="educational_field"
									name="student.education_section_id"
								>
									{sections?.map(section => {
										return (
											<Dropdown.Option key={`${section.name}-${section.value}`} value={section.value}>
												{t(`educational_field.${section.name}`)}
											</Dropdown.Option>
										)
									})}
								</CustomDropdown>
							</>
						)}
					</Box>
					{!isDesktop && (
						<FlexLayout px={'2.063rem'} width="100%" justifyContent={'space-between'} alignItems="center">
							<FormButton onClick={handleCancel} type="button" disabled={!isDirty} variant="princetonOrange" btnSize={'small'}>
								Cancel
							</FormButton>
							<FormButton type="submit" disabled={!isDirty || !isValid || isSubmitting} btnSize={'small'}>
								Save
							</FormButton>
						</FlexLayout>
					)}
					<Box width="100%">
						<ProfileInfoPanel />
					</Box>
				</FormGrid>
				{isDesktop && (
					<FlexLayout justifyContent={'flex-end'} gridGap={'1rem'}>
						<RaisedButton onClick={handleCancel} type="button" disabled={!isDirty} variant="princetonOrange">
							Cancel
						</RaisedButton>
						<RaisedButton type="submit" disabled={!isDirty || !isValid || isSubmitting}>
							Save
						</RaisedButton>
					</FlexLayout>
				)}
			</FlexLayout>
		</ProfileForm>
	)
}

export default ProfileEducationalInfo
