import React, { createContext, useContext } from 'react'
type LayoutProviderContextArgs = {
	showWallet: boolean
	showFooter: boolean
	setShowWallet: React.Dispatch<boolean>
	fixedHeader: boolean
	setFixedHeader: React.Dispatch<boolean>
	setShowFooter: React.Dispatch<boolean>
	embed: boolean
}

const LayoutProviderContext = createContext<LayoutProviderContextArgs | null>(null)

export const LayoutProvider: React.FC<LayoutProviderContextArgs> = ({ children, ...props }) => {
	return <LayoutProviderContext.Provider value={props}>{children}</LayoutProviderContext.Provider>
}

export const useLayoutProvider = () => {
	const context = useContext(LayoutProviderContext)
	if (!context) {
		throw new Error('No LayoutProvider found !!!')
	}
	return { ...context }
}
