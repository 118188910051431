import api from '@Api/Api'
import { AssetsResponse, SettingsResponsePayload } from './Settings.api.types'

class SettingsApi {
	private static instance: SettingsApi
	private static endpoint: string = 'settings'

	public static getInstance() {
		if (!SettingsApi.instance) {
			return new SettingsApi()
		}
		return SettingsApi.instance
	}

	getSettings() {
		return api.get<ApiResponse<SettingsResponsePayload>>(`${SettingsApi.endpoint}`)
	}
	getAssets() {
		return api.get<ApiResponse<AssetsResponse>>(`${SettingsApi.endpoint}/assets`)
	}
}

export default SettingsApi
