import { Container, FlexLayout } from '@eduact/ed-system'
import React from 'react'
import { NotFoundImage } from '@Assets/Images'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import Spacer from '@eduact/ed-components/Spacer'
import styled from 'styled-components'

const NotFoundPage = () => {
	return (
		<StyledContainer>
			<FlexLayout flexDirection={{ sm: 'column', lg: 'row' }} p={{ sm: '2rem', lg: '0' }} alignItems="center" gridGap={'2rem'}>
				<img alt="Not Found" src={NotFoundImage} />
				<FlexLayout flexDirection={'column'} alignItems={{ sm: 'center', lg: 'flex-start' }}>
					<EdTypography color="princetonOrange" fontWeight={'bold'}>
						Opps, we can't seem to find the page your're looking for!
					</EdTypography>
					<Spacer mb={'1rem'} />
					<EdTypography color="darkSilver" fontWeight={'bold'}>
						Go Back to{' '}
						<EdTypography color="primary" textDecoration={'underline'}>
							Homepage
						</EdTypography>{' '}
						instead
					</EdTypography>
				</FlexLayout>
			</FlexLayout>
		</StyledContainer>
	)
}

export default NotFoundPage

const StyledContainer = styled(Container)`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`
