import { BaseWidget, Mosaic } from '@eduact/ed-components'
import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { InvoicesWidget } from './Components/InvoicesWidget'
import TimelineWidget from './Components/TimelineWidget/TimeLineWidget'
import WalletHistoryWidget from './Components/WalletWidget/WalletHistoryWidget'

const Activity = () => {
	return (
		<Container>
			<Mosaic height={'100%'}>
				<Mosaic.Row flex={1}>
					<Mosaic.Col flex={1}>
						{/* <Mosaic.Row flex={1}> */}
						<WalletHistoryWidget />
						{/* </Mosaic.Row> */}
					</Mosaic.Col>
					<Mosaic.Col flex={1}>
						{/* <Mosaic.Row flex={1}> */}
						<TimelineWidget />
						{/* </Mosaic.Row> */}
					</Mosaic.Col>
				</Mosaic.Row>
				<Mosaic.Row flex={1}>
					<Mosaic.Col flex={1}>
						{/* <Mosaic.Row flex={1}> */}
						<BaseWidget onClick={() => {}} widget={InvoicesWidget} />
						{/* </Mosaic.Row> */}
					</Mosaic.Col>
				</Mosaic.Row>
			</Mosaic>
		</Container>
	)
}

export default Activity

const Container = styled.div`
	background: ${rgba('#FFF', 0.5)};
	width: 100%;
	height: 100%;
	padding: 11px;
	border-radius: 15px;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 0;
		background : transparent;
	}`}
`
