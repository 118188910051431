import routes from '@Constants/Routes'
import { BaseWidget, Mosaic } from '@eduact/ed-components'
import { WidgetProps } from '@eduact/ed-components/Widgets/BaseWidget/BaseWidget'
import React from 'react'
import { useNavigate } from 'react-router'
import WalletWidgetElement from './WalletWidgetElement'

const WalletHistoryWidget = ({ ...args }) => {
	const navigate = useNavigate()
	return (
		<BaseWidget
			widgetProps={{}}
			onClick={() => navigate(`${routes.dashboard}/activity`)}
			widget={WalletWidgetElement}
			{...args}
			bg="light"
			height={'22.95rem'}
		/>
	)
}

export default WalletHistoryWidget
