import NoSlotCard from '@Features/Unit/Webinar/Components/NoCard'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NotActive = () => {
	const [t] = useTranslation('test')
	return <NoSlotCard text={t('noTest')} />
}

export default NotActive
