import { MaterialIconsType } from '@eduact/ed-system'
import { Color } from '@eduact/student-theme'
import React from 'react'
import styled from 'styled-components'

type Props = {
	type?: MaterialIconsType
	color?: Color
}
const Icon: React.FC<Props> = ({ children, type = 'Regular', color }) => {
	const getClassName = React.useMemo(() => {
		const baseClassName = 'material-icons'
		if (type === 'Regular') return baseClassName
		return `${baseClassName}-${type.toLowerCase()}`
	}, [type])

	return (
		<IconWrapper color={color} className={getClassName}>
			{children}
		</IconWrapper>
	)
}

export default Icon

const IconWrapper = styled.div<{ color?: Color }>`
	width: fit-content;
	color: ${props => (props.color ? props.theme.colors[props.color] : '')};
`
