import ClassroomCard from '@Components/Cards/ClassroomCard'
import SkeletonCard from '@Components/Utilities/Skeleton/Components/SkeletonCard'
import SkeletonCarousel from '@Components/Utilities/Skeleton/Components/SkeletonCarousel'
import { Swiper } from '@Components/Utilities/Swiper'
import { SectionWrapper } from '@Features/LandingPage/Assets/LandingPage.styled'
import { useGetClassroomsQuery } from '@Features/LandingPage/LandingPageApi/LandingPageApi'
import { useAppSelector } from '@Hooks/ReduxHooks'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SectionTitle } from '../Sections.styled'
import _uniqueId from 'lodash/uniqueId'
import { useTranslation } from 'react-i18next'
import { FlexLayout } from '@eduact/ed-system'
import ViewAllButton from '@Components/Button/ViewAllButton/ViewAllButton'
const ExamsSection: React.FC = () => {
	const { data, isLoading, isFetching } = useGetClassroomsQuery()
	const {
		classroomsSections: { exams_section },
	} = useAppSelector(state => state.settings)
	const history = useNavigate()
	const [t] = useTranslation('landingPage', { keyPrefix: 'courses' })

	return (
		<React.Fragment>
			<SkeletonCarousel my={'5.25rem'} hasTitle isLoading={isLoading || isFetching} numberOfItems={4} renderComponent={<SkeletonCard />}>
				{exams_section && (
					<SectionWrapper my={{ sm: '2.8rem', lg: '5.25rem' }}>
						{data && data.data.filter(_ => _.sub_type === 'exam').length > 0 && (
							<div>
								<FlexLayout justifyContent={'space-between'} alignItems="baseline">
									<SectionTitle>
										<label>{t('exam_classrooms')}</label>
									</SectionTitle>
									<ViewAllButton />
								</FlexLayout>
								<Swiper isIndicator padding={'1rem'} withProgress gridGap={'2rem'}>
									{data.data
										.filter(_ => _.sub_type === 'exam')
										.map((cl, index) => {
											return (
												<div key={`${cl.id}-${index}-exams-${_uniqueId()}`}>
													<ClassroomCard
														badgeLabel={cl.type}
														title={cl.title}
														grade={cl.educationYears[0].name}
														subject={cl.category.name}
														subjectImage={cl.category.icon}
														thumbnail={cl.thumbnail}
														instructor={cl.instructor.user.first_name}
														buttonBackgroundColor={cl.enrolled && cl.enrolled.length > 0 ? 'purple' : 'primary'}
														onClick={() => {
															history(`/classroom/${cl.label}`)
														}}
														classroom={cl}
													/>
												</div>
											)
										})}
								</Swiper>
							</div>
						)}
					</SectionWrapper>
				)}
			</SkeletonCarousel>
		</React.Fragment>
	)
}

export default ExamsSection
