import BuyCourseButton from '@Components/Button/BuyCourseButton'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { CircularProgress, Icon, Icons, RaisedButton } from '@eduact/ed-components'
import { Box, FlexLayout, Stack } from '@eduact/ed-system'
import { Color } from '@eduact/student-theme'
import { useCourseContext } from '@Features/Course/Providers/CourseProvider/CourseProvider'
import moment from 'moment'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

type Time = {
	[key: string]: number
}

type AttendanceCourseCardProps = { course: Course; isBookmarked: boolean }
const AttendanceCourseCard: React.VoidFunctionComponent<AttendanceCourseCardProps> = ({ course, isBookmarked }) => {
	const { isClosed, isExpired, isFree, expiredAt, coursePivot, navigateToCourse } = useCourseContext()

	const learningTime = useMemo(() => {
		return Number(course?.pivot?.time)
	}, [course])

	const findTime = (num: number) => {
		if (num < 1) {
			return '0'
		}
		const qualifier = (num: number) => (num > 1 ? '' : '')
		const numToStr = (num: number, unit: string) => (num > 0 ? `${num}${unit} ${qualifier(num)}` : '')
		const oneMinute = 60
		const oneHour = oneMinute * 60
		const oneDay = oneHour * 24
		const oneYear = oneDay * 365
		const times: Time = {
			year: ~~(num / oneYear),
			day: ~~((num % oneYear) / oneDay),
			hour: ~~((num % oneDay) / oneHour),
			minute: ~~((num % oneHour) / oneMinute),
		}
		let str = ''
		for (let [key, value] of Object.entries(times)) {
			str += numToStr(times[key], key[0])
		}
		const arr = str.trim().split(' ')
		const res: Array<any> = []
		arr.forEach((x, i) => {
			res.push(x)
		})
		return res.slice(0, 3).join(' ')
	}

	const getStatusColor = useMemo<Color>(() => {
		if (coursePivot) return 'primary'
		return 'princetonOrange'
	}, [coursePivot])

	const getStatus = useMemo(() => {
		if (coursePivot) return 'Attended'
		return 'Absent'
	}, [coursePivot])

	const getAction = useMemo(() => {
		if (coursePivot && isExpired) {
			return (
				<CourseStatus fontStyle={'italic'} color="silver">
					Expired
				</CourseStatus>
			)
		}
		if (coursePivot)
			return (
				<ActionButton onClick={navigateToCourse} variant="purple" outlined btnSize={{ sm: 'small', lg: 'medium' }}>
					Go to lesson
				</ActionButton>
			)
		if (isClosed)
			return (
				<CourseStatus fontStyle={'italic'} color="red">
					Closed
				</CourseStatus>
			)

		return (
			<>
				<BuyButton course={course} outlined btnSize={{ sm: 'small', lg: 'medium' }}>
					{isFree ? 'Enroll' : 'Buy Now'}
				</BuyButton>
			</>
		)
	}, [coursePivot, course])

	return (
		<CourseCard px={{ sm: '1.125rem' }} py={{ sm: '0.875rem' }}>
			{isBookmarked && (
				<Stack top={'0'} right="0">
					<Box display={{ sm: 'block', lg: 'none' }} px={6}>
						<Icon size={25} color="princetonOrange">
							<Icons.Bookmark />
						</Icon>
					</Box>
				</Stack>
			)}
			<FlexLayout alignItems={{ lg: 'center' }} flex={{ lg: 1 }} gridGap={{ lg: 6 }}>
				<Box display={{ sm: 'none', lg: 'block' }} visibility={isBookmarked ? 'visible' : 'hidden'}>
					<Icon size={25} color="princetonOrange">
						<Icons.Bookmark />
					</Icon>
				</Box>
				<EdTypography as="h4" fontSize={{ sm: '0.75rem', lg: '1.125rem' }} fontWeight={600}>
					{course.name}
				</EdTypography>
			</FlexLayout>
			<FlexLayout flexWrap={{ lg: 'wrap' }} alignItems={'center'} flex={{ lg: 1.5 }} gridGap={{ sm: 5, lg: 8 }}>
				{!isExpired && (
					<>
						<FlexLayout gridGap={5} alignItems="center">
							{getStatus === 'Attended' && (
								<>
									<ActiveIndicator />
									<EdTypography fontSize={{ sm: '0.75rem', lg: '1.125rem' }} fontWeight={600}>
										Active
									</EdTypography>
								</>
							)}
						</FlexLayout>
						<>
							{!!expiredAt && (
								<FlexLayout gridGap={{ sm: 3, lg: 6 }}>
									<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontWeight={500}>
										until
									</EdTypography>
									<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontStyle={'italic'}>
										{moment(expiredAt).format('DD/MM/YYYY')}
									</EdTypography>
									<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontWeight={500}>
										{moment(expiredAt).format('hh:mm A')}
									</EdTypography>
								</FlexLayout>
							)}
						</>
					</>
				)}
				{isExpired && (
					<FlexLayout gridGap={{ sm: 3, lg: 6 }} alignItems={{ lg: 'center' }}>
						<Box style={{ visibility: 'hidden' }}>
							<ActiveIndicator />
						</Box>

						<CourseStatus fontStyle={'italic'} color="silver">
							Expired
						</CourseStatus>
						<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontWeight={500}>
							on
						</EdTypography>
						<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontStyle={'italic'}>
							{moment(expiredAt).format('DD/MM/YYYY')}
						</EdTypography>
						<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontWeight={500}>
							{moment(expiredAt).format('hh:mm A')}
						</EdTypography>
					</FlexLayout>
				)}
			</FlexLayout>
			<FlexLayout
				gridGap={{ lg: '0.75rem', xl: '3.75rem' }}
				flex={{ lg: 2 }}
				justifyContent={{ sm: 'space-between', lg: 'flex-end' }}
				alignItems="center"
			>
				<FlexLayout justifyContent={{ lg: 'center' }} flex={{ lg: 1 }}>
					<EdTypography fontSize={{ sm: '0.75rem', lg: '1.125rem' }} fontWeight={600} color={getStatusColor}>
						{getStatus}
					</EdTypography>
				</FlexLayout>
				<FlexLayout justifyContent={{ lg: 'center' }} flex={{ lg: 1 }}>
					<Box display={{ sm: 'none', lg: 'flex' }}>
						{coursePivot && <CircularProgress size={'medium'} progress={coursePivot.pivot_progress} />}
						{!coursePivot && (
							<Icon size={'1.8rem'} color="red">
								<Icons.WideClose />
							</Icon>
						)}
					</Box>
				</FlexLayout>
				{course?.pivot?.time === null || course?.pivot?.time === '0' ? (
					<FlexLayout alignItems="center" flexDirection="column" width="100%">
						<EdTypography>-</EdTypography>
					</FlexLayout>
				) : (
					<FlexLayout flexDirection="column" alignItems="center" gridGap="0.4rem" width="100%">
						<EdTypography fontSize={{ sm: '0.875rem', lg: '1.125rem' }} fontWeight={600}>
							{findTime(learningTime)}
						</EdTypography>
						<Text fontSize={{ sm: '0.7rem', lg: '0' }} fontWeight={500}>
							Learning Time
						</Text>
					</FlexLayout>
				)}
				<FlexLayout maxWidth={{ lg: '8rem', xl: '11rem' }} minWidth={{ lg: '9rem', xl: '11rem' }} justifyContent={{ lg: 'center' }}>
					{getAction}
				</FlexLayout>
			</FlexLayout>
		</CourseCard>
	)
}

export default AttendanceCourseCard

const CourseCard = styled(Box)`
	position: relative;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	margin-bottom: 0.625rem;
	grid-gap: 0.75rem;
	display: flex;
	flex-direction: column;
	${({ theme }) => `${theme.mediaQueries.large}{
		flex-direction : row;
		padding :0.5rem 1rem;
		align-items : center;
		min-height : 4.063rem;
	}`}
`
const ActiveIndicator = styled.div`
	width: 9px;
	height: 9px;
	border-radius: 50%;
	background-color: ${props => props.theme.colors.green};
	${({ theme }) => `${theme.mediaQueries.large}{
		width : 12px;
		height : 12px;
	}`}
`
const ActionButtonCss = css`
	${({ theme }) => `${theme.mediaQueries.small}{
	
	padding: 0.375rem 0.75rem;
	min-width: 6rem;
	font-size: 0.625rem;
}`}
	padding : 0.75rem 1.75rem;
	font-weight: bold;
`
const ActionButton = styled(RaisedButton)`
	${ActionButtonCss}
`
const BuyButton = styled(BuyCourseButton)`
	${ActionButtonCss}
`
const CourseStatus = styled(EdTypography)`
	font-size: 0.75rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size:1.125rem;
	}`}
`
const Text = styled(EdTypography)`
	display: block;
	text-align: center;
	color: #c4c4c4;
	${({ theme }) => `${theme.mediaQueries.large}{
		display: none;
	}`}
`
