import AnimatedStudentCard from '@Components/Cards/AnimatedStudentCard/AnimatedStudentCard'
import { StudentCard } from '@Components/Cards/StudentCard'
import StudentQRCard from '@Components/Cards/StudentQRCard/StudentQRCard'
import { ResetPasswordForm } from '@Components/Form/ResetPasswordForm'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { Card, Modal, Dialog, RaisedButton, Icon, Icons } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { Box, FlexLayout, Typography } from '@eduact/ed-system'
import { useStudentScreenSize } from '@eduact/student-theme'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useProfileInfoPanel } from '../ProfileInfoPanelContext'
import ProfileStudentCard from './ProfileStudentCard'

const ProfileInfoPanel = React.memo(() => {
	const { currentInfo, setCurrentInfo } = useProfileInfoPanel()
	const { isDesktop } = useStudentScreenSize()
	const handleCancelResetPassword = () => {
		setCurrentInfo('card')
	}
	if (currentInfo === 'card') {
		return <ProfileStudentCard />
	}

	if (isDesktop)
		return (
			<StyledBox>
				<Card width={'100%'} withShadow px={'2.875rem'} py={'2.125rem'}>
					<FlexLayout width={'100%'} justifyContent={'flex-end'}>
						<Icon onClick={handleCancelResetPassword}>
							<Icons.WideClose />
						</Icon>
					</FlexLayout>
					<ResetPasswordForm />
				</Card>
			</StyledBox>
		)

	return <></>
})

export default ProfileInfoPanel

const StyledBox = styled(Box)`
	margin-inline-end: 0.5rem;
`
