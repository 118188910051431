import Tabs from '@Components/Utilities/Tabs/Tabs'
import styled from 'styled-components'
import { flexbox, FlexboxProps, typography, TypographyProps } from 'styled-system'

export const TabTitle = styled.div`
	font-weight: bold;
`

export const TabBody = styled.div<FlexboxProps & TypographyProps>`
	padding: 1rem;
	margin: 2rem 0;
	display: flex;
	${flexbox}
	${typography}
`
