import { Orientation, Color } from '@eduact/student-theme'
import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps, variant } from 'styled-system'
type Props = {
	orientation: Orientation
	color: Color
	content?: string
} & LayoutProps &
	SpaceProps
export const StyledDivider = styled.div<Props>`
	${variant({ prop: 'color', scale: 'backgrounds' })}
	flex-shrink : 0;
	width: ${props => (props.orientation === 'vertical' ? '1px' : 'auto')};
	height: ${props => (props.orientation === 'horizontal' ? '1px' : 'auto')};
	/* display: flex; */
	align-self: stretch;
	margin: ${props => (props.orientation === 'vertical' ? '0 5px' : '5px 0')};
	${space}
	${layout}
	position :relative;
	&::before {
		content: '${props => props.content ?? ''}';
		position: absolute;
		top: 50%;
		/* left: 5%; */
		/* right :0; */
		padding: 0 0.5rem;
		font-style: italic;
		font-family: 'Montserrat';
		font-size: 0.625rem;
		${({ theme }) => `${theme.mediaQueries.large}{
			font-size: 1rem;
		}`}
		transform: translateY(-50% );
		background: #fff;
		width: fit-content;
		display: flex;
		justify-content: flex-start;
		margin: 0 1rem;
	}
	&[dir='rtl'] {
		background: red;
		left: unset;
		right: 5%;
	}
`
