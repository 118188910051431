import { Divider } from '@Components/Utilities/Divider'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import Spacer from '@eduact/ed-components/Spacer'
import { useAppDispatch, useAppSelector } from '@Hooks/ReduxHooks'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ButtonsGrid, Icon, Icons, RaisedButton, TextButton } from '@eduact/ed-components'
import {
	WalletFawryImage,
	WalletAmanImage,
	WalletCodeImage,
	WalletMasaryImage,
	WalletOpayImage,
	WalletPaymobImage,
	WalletVodafoneImage,
	WalletInfoImage,
	FawryVodafoneImage,
	OpayVodafoneImage,
} from '@Components/Layout/Header/Assets/images'
import { MethodsWithPayload, ProviderWithPayload, RechargeProcessed } from '@Features/Dashboard/DashboardSlice/Dashboard.slice.types'
import { useCheckPendingTransactionsQuery, usePaySignupFeesMutation } from './SignupFees.api'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { rgba } from 'polished'
import { FlexLayout, Typography, FlexItem, GridLayout } from '@eduact/ed-system'
import usePayment from '@Hooks/usePayment'
import {
	FeaturesList,
	Disclaimer,
	DisclaimerTitle,
	FeesAmountCard,
	FeesAmount,
	Instead,
	FeesOldAmount,
	SignupFeesContainer,
	ProvidersWrapper,
	ProvidersButtonsGrid,
} from './SignupFeesCard.styled'
import { HiLightningBolt } from 'react-icons/hi'
import { MdBolt } from 'react-icons/md'
import { DateFormatter } from '@eduact/utils'
import { BarLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'
import { setAuthData } from '@Features/Authentication/AuthenticationSlice/Authentication.slice'
import FacebookPixel from 'react-facebook-pixel'
type Props = {
	checkPendingTransactions?: boolean
	skippable?: boolean
	onSuccess?: () => void
	onSkip?: () => void
	hideWallet?: boolean
}

const SignupFeesCard: React.VoidFunctionComponent<Props> = ({
	checkPendingTransactions,
	skippable,
	onSkip,
	onSuccess,
	hideWallet = false,
}) => {
	const { fees, paymentMethods } = useAppSelector(state => state.settings)
	const [t] = useTranslation('fees')

	const [provider, setProvider] = useState<RechargeProcessed | undefined>()
	const dispatch = useAppDispatch()
	const { user } = useAppSelector(state => state.auth)
	const [payFess, { isLoading, isSuccess, data }] = usePaySignupFeesMutation()
	const { hasPendingTransaction, transaction } = useCheckPendingTransactionsQuery(checkPendingTransactions ?? skipToken, {
		selectFromResult: result => {
			const hasPendingTransaction = !!result.data?.data

			return { hasPendingTransaction, transaction: result.data?.data }
		},
	})

	const { handlePayment } = usePayment()

	const handlePayFees = async () => {
		try {
			if (!provider) return
			const data = await payFess({
				method: provider.method as MethodsWithPayload,
				provider: provider.provider as ProviderWithPayload,
			}).unwrap()
			FacebookPixel.track('Subscriptions')
			if (provider.provider !== 'eduact') {
				await handlePayment(provider, data)
			}
			if (user) {
				dispatch(setAuthData({ user: { ...user, student: { ...user.student, registration_paid: true } } }))
			}

			onSuccess?.()
		} catch (error) {}
	}
	return (
		<SignupFeesContainer>
			<Spacer p={{ lg: '2rem' }}>
				{!hasPendingTransaction && (
					<Spacer px={'6px'}>
						<EdTypography fontSize={{ sm: '0.75rem', lg: '1.25rem' }} fontWeight="500" color="darkSilver">
							{t('title')}
						</EdTypography>
						<Spacer mb={{ sm: '0.625rem', lg: '1.88rem' }} />
						<FeaturesList>
							<li>{t('feature1')}</li>
							<li>{t('feature2')}</li>
							<li>{t('feature3')}</li>
							<li>{t('feature4')}</li>
						</FeaturesList>
					</Spacer>
				)}
				{hasPendingTransaction && (
					<>
						<Disclaimer>
							<FlexLayout>
								<DisclaimerIconWrapper>
									<Icon color="purple">
										<MdBolt />
									</Icon>
								</DisclaimerIconWrapper>
								<Spacer mx={{ sm: 5 }} />
								<GridLayout gridGap={'0'}>
									<DisclaimerTitle fontWeight={600} color="purple" fontSize={{ sm: '0.563rem', lg: '1.125rem' }}>
										{t('disclaimer')}
									</DisclaimerTitle>
									<Spacer mb={{ lg: '0.8rem' }} />
									<Typography fontWeight={600} fontSize={{ sm: '0.438rem', lg: '0.875rem' }}>
										{t('disclaimer_message')}
									</Typography>
									<Spacer mb={{ sm: 8, lg: '1rem' }} />
									<Typography fontWeight={600} fontSize={{ sm: '0.438rem', lg: '0.875rem' }}>
										{t('invoice_ref')} {transaction?.invoice_ref}
									</Typography>
									<Spacer mb={{ sm: 4 }} />
									<Typography fontWeight={600} fontSize={{ sm: '0.438rem', lg: '0.875rem' }}>
										{t('invoice_date')}
										{DateFormatter(transaction?.updated_at, {
											year: 'numeric',
											hour12: true,
											minute: '2-digit',
											withTime: true,
										})}
									</Typography>
									<Spacer mb={{ sm: 4 }} />
									<Typography fontWeight={600} fontSize={{ sm: '0.438rem', lg: '0.875rem' }}>
										{t('invoice_method')}
										{transaction?.transaction.provider}
									</Typography>
								</GridLayout>
							</FlexLayout>
						</Disclaimer>
						<Spacer mb={{ sm: '1rem' }} />
					</>
				)}
				<Spacer pb={{ lg: '2.125rem' }} />
				<FeesAmountCard>
					<FeesAmount>
						{fees.amount} {t('egp')}
					</FeesAmount>
					{fees.old_amount && (
						<>
							<Instead>{t('instead_of')}</Instead>
							<FeesOldAmount>
								{fees.old_amount ?? 40} {t('egp')}
							</FeesOldAmount>
						</>
					)}
				</FeesAmountCard>
			</Spacer>

			<Spacer my={{ sm: '1.25rem', lg: '0' }} mx={{ lg: '0' }} py={{ lg: '2.5rem' }}>
				<Divider orientation="vertical" height={'100%'} />
				{/* <Divider orientation="horizontal" /> */}
			</Spacer>
			<Spacer p={{ lg: '2rem' }}>
				<EdTypography color="darkSilver" fontWeight={500} textAlign={{ sm: 'center' }} fontSize={{ sm: '0.75rem', lg: '1.25rem' }}>
					{t('payment_selection')}
				</EdTypography>
				<Spacer mb={{ sm: '0.688rem', lg: '1.188rem' }} />

				<ProvidersWrapper>
					<ProvidersButtonsGrid
						withBorder
						value={{
							value: provider?.provider,
							args: {
								method: provider?.method ?? 'aman',
							},
						}}
						onChange={value => {
							if (!value || !value.args) return
							if (value && value.value) {
								setProvider &&
									setProvider({
										method: value?.args?.method,
										provider: value.value,
									})
							}
						}}
					>
						<ButtonsGrid.Item value={{ value: 'eduact', args: { method: 'wallet' } }}>
							<img alt="Wallet Code" src={WalletInfoImage} />
						</ButtonsGrid.Item>
						{paymentMethods.fawry_fawry && (
							<ButtonsGrid.Item value={{ value: 'fawry', args: { method: 'fawry' } }}>
								<img alt="Fawry" src={WalletFawryImage} />
							</ButtonsGrid.Item>
						)}
						{paymentMethods.credit_card_accept && (
							<ButtonsGrid.Item value={{ value: 'accept', args: { method: 'card' } }}>
								<img alt="Paymob" src={WalletPaymobImage} />
							</ButtonsGrid.Item>
						)}
						{paymentMethods.aman_accept && (
							<ButtonsGrid.Item value={{ value: 'accept', args: { method: 'aman' } }}>
								<img alt="Aman" src={WalletAmanImage} />
							</ButtonsGrid.Item>
						)}
						{paymentMethods.masary_accept && (
							<ButtonsGrid.Item value={{ value: 'accept', args: { method: 'masary' } }}>
								<img alt="Masary" src={WalletMasaryImage} />
							</ButtonsGrid.Item>
						)}
						{paymentMethods.ewallet_accept && (
							<ButtonsGrid.Item value={{ value: 'accept', args: { method: 'ewallet' } }}>
								<img alt="Vodafone" src={WalletVodafoneImage} />
							</ButtonsGrid.Item>
						)}
						{paymentMethods.ewallet_fawry && (
							<ButtonsGrid.Item value={{ value: 'fawry', args: { method: 'ewallet' } }}>
								<img alt="Vodafone" src={FawryVodafoneImage} />
							</ButtonsGrid.Item>
						)}
						{paymentMethods.ewallet_opay && (
							<ButtonsGrid.Item value={{ value: 'opay', args: { method: 'ewallet' } }}>
								<img alt="Vodafone" src={OpayVodafoneImage} />
							</ButtonsGrid.Item>
						)}
						{paymentMethods.credit_card_opay && (
							<ButtonsGrid.Item value={{ value: 'opay', args: { method: 'card' } }}>
								<img alt="Opay" src={WalletOpayImage} />
							</ButtonsGrid.Item>
						)}
					</ProvidersButtonsGrid>
				</ProvidersWrapper>
				<Spacer mb={{ sm: '1.25rem', lg: '1.5rem' }} />
				<RaisedButton onClick={handlePayFees} disabled={!provider} btnSize={{ sm: 'small', lg: 'large' }} width={1}>
					{isLoading ? (
						<BarLoader color="#FFF" />
					) : (
						<Typography fontWeight={600} fontSize={{ sm: '0.875rem', lg: '1rem' }}>
							{t('pay_now')}
						</Typography>
					)}
				</RaisedButton>
				{skippable && (
					<TextButton
						justifyContent={'center'}
						btnSize={{ sm: 'small', lg: 'large' }}
						onClick={onSkip}
						variant="princetonOrange"
						width={'100%'}
					>
						<Typography fontWeight={600} fontSize={{ sm: '0.625rem', lg: '1rem' }}>
							{t('pay_later')}
						</Typography>
					</TextButton>
				)}
			</Spacer>
		</SignupFeesContainer>
	)
}

export default SignupFeesCard

const DisclaimerIconWrapper = styled.div`
	width: 27px;
	height: 27px;
	border-radius: 50%;
	border: 2px solid ${props => props.theme.colors.purple};
	color: ${props => props.theme.colors.purple};
	display: grid;
	place-content: center;
`
