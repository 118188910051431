import React from 'react'
import styled, { css } from 'styled-components'
// import { useTranslation } from 'react-i18next'
import { Header } from '../PaymentModal.styled'
import { ScratchCardImage, WalletInfoImage } from '@Components/Layout/Header/Assets/images'
import Spacer from '@eduact/ed-components/Spacer'
import { useTranslation } from 'react-i18next'

type Props = {
	setActiveSection: any
	paymentMethodsAllowed: any
}
const PaymentChoice: React.FC<Props> = ({ setActiveSection, paymentMethodsAllowed }) => {
	// const { t } = useTranslation()
	const [t] = useTranslation(['payment'])

	return (
		<StyledContainer>
			<Header>{t('payment_title')}</Header>
			<Spacer mb={{ sm: '1rem' }} />
			<CardsContainer paymentMethodsAllowed={paymentMethodsAllowed}>
				{(paymentMethodsAllowed === '*' || paymentMethodsAllowed === 'wallet') && (
					<PaymentCard onClick={() => setActiveSection('wallet-payment')}>
						<WalletImage src={WalletInfoImage} alt="wallet image" />
						<Spacer mb={{ lg: '1.5rem' }} />
						<CardText>{t('wallet')}</CardText>
					</PaymentCard>
				)}
				{(paymentMethodsAllowed === '*' || paymentMethodsAllowed === 'scratchcard') && (
					<PaymentCard margin="26px" onClick={() => setActiveSection('scratchcard-payment')}>
						<ScratchCardImageStyled src={ScratchCardImage} alt="scratch card image" />
						<Spacer mb={{ lg: '1.5rem' }} />
						<CardText>{t('scratch_card')}</CardText>
					</PaymentCard>
				)}
			</CardsContainer>
		</StyledContainer>
	)
}

const StyledContainer = styled.div`
	${({ theme }) => `${theme.mediaQueries.large}{
	display : flex;
	height : 100%;
	flex-direction : column;
	justify-content : space-between;
}`}
`

const CardsContainer = styled.div<{ paymentMethodsAllowed: '*' }>`
	display: flex;
	//justify-content: space-between;
	justify-content: ${props => (props.paymentMethodsAllowed === '*' ? 'space-between' : 'center')};
	/* padding-top: 8px; */

	align-items: center;
	flex-direction: column;
	grid-gap: 1rem;
	width: 100%;
	${({ theme }) => `${theme.mediaQueries.large}{
		flex-direction : row;
		grid-gap: 5rem;
	}`}
`

const PaymentCard = styled.div<{ margin?: any }>`
	width: 100%;
	/* height: 242px; */
	max-width: 14.656rem;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	padding: 1rem;
	&:hover {
		cursor: pointer;
	}

	@media (min-width: 768px) {
		width: 234px;
	}

	@media (max-width: 425px) {
		margin-top: ${props => props?.margin};
	}
`

const CardImage = css`
	width: 200px;
	height: 130px;
`

const WalletImage = styled.img`
	${CardImage};
`

const ScratchCardImageStyled = styled.img`
	${CardImage};
`

const CardText = styled.p`
	font-family: Montserrat, serif;
	font-size: 14px;
	color: #3d3e4e;
	/* margin-top: 46px; */
`

export default PaymentChoice
