import Icon from '@Components/Icons/Icon/Icon'
import { RaisedButton } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import { useCourseContext } from '@Features/Course/Providers/CourseProvider/CourseProvider'
import i18next from 'i18next'
import moment from 'moment'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import edEn from '../Assets/eden.svg'
import Flags from '../Assets/flags.svg'
import Modal from '@Components/Utilities/Popup/Modal'
import { CancelButtonConfirm, ConfirmButton } from '@Features/Unit/Webinar/Components/SlotCard'
import { generatePath, useNavigate } from 'react-router'
import routes from '@Constants/Routes'
import { useAppDispatch } from '@Hooks/ReduxHooks'
import { setRunningTest } from '@Redux/RunningTestSlice/RunningTestSlice'

type Props = {
	data: Test | undefined
	isContinue?: boolean
}

const StartTest: FC<Props> = ({ data, isContinue = false }) => {
	const [open, setOpen] = useState<boolean>(false)
	const [t] = useTranslation('test')
	const { dir } = i18next
	const { course, classroomLabel } = useCourseContext()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const onStartTest = async () => {
		setOpen(false)

		if (data && course && classroomLabel && data.unit_id) {
			dispatch(
				setRunningTest({
					testData: {
						classroomLabel,
						course_id: course.id,
						unit_id: data.unit_id,
					},
				})
			)
			if (data.id) navigate(generatePath(routes.test, { id: data.id.toString() }))
		}
	}

	return (
		<CardWrapper>
			<ModelStyle modalOpen={open}>
				<Modal.Content
					minWidth={'fit-content'}
					minHeight={'fit-content'}
					onClose={() => {
						setOpen(false)
					}}
				>
					<FlexLayoutStyle justifyContent="space-between" alignItems="center" flexDirection="column">
						<p>{t('startNow')}</p>
						<FlexLayout justifyContent="flex-end" alignItems="center" gridGap="0.9rem" paddingTop="2rem">
							<CancelButtonConfirm
								onClick={() => {
									setOpen(false)
								}}
							>
								{t('cancel')}
							</CancelButtonConfirm>
							<ConfirmButton
								onClick={() => {
									onStartTest()
								}}
							>
								{t('confirm')}
							</ConfirmButton>
						</FlexLayout>
					</FlexLayoutStyle>
				</Modal.Content>
			</ModelStyle>

			<Title>{data?.unit?.name}</Title>
			<InfoContainer>
				{data?.allow_movement === false && <Header>{t('startHeader')}</Header>}
				{data?.active_end_date !== null && (
					<DateWrapper>
						<Hint>{t('hint')}</Hint>
						<Date>{moment(data?.active_end_date).format('DD/MM/YY')}</Date>
					</DateWrapper>
				)}
				<InfoWrapper>
					<TextWrapper>
						<Number>
							{t('numOfQuestion')} : {data?.questions_count}
						</Number>
						{data?.duration !== null && (
							<Duration>
								{t('duration')} :
								<FlexLayout alignItems="center">
									<Icon color="primary">schedule</Icon>&nbsp; {data?.duration}
									{t('min')}
								</FlexLayout>
							</Duration>
						)}
						<Attempt>{t('attempt')} : 1</Attempt>
					</TextWrapper>
					{data?.start_text && (
						<FlexLayout>
							{dir() === 'rtl' && <EddieImg src={edEn} alt="eddie" />}
							<Message maxWidth="12.719rem" maxHeight="8.094rem" minWidth="unset">
								{data?.start_text}
							</Message>
							{dir() === 'ltr' && <EddieImg src={edEn} alt="eddie" />}
						</FlexLayout>
					)}
				</InfoWrapper>
				<StartTestBtn
					variant="primary"
					onClick={() => {
						setOpen(true)
					}}
				>
					{isContinue ? t('continueTest') : t('startTest')}
				</StartTestBtn>
			</InfoContainer>
		</CardWrapper>
	)
}

const CardWrapper = styled(FlexLayout)`
	min-height: 8.563rem;
	border-radius: 30px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	padding: 3.5rem 1.688rem 2.5rem 1.25rem;
	margin-bottom: 0.75rem;
	flex-direction: column;
	align-items: start;
	justify-items: flex-start;
	position: relative;
	gap: 1rem;
	overflow: hidden;
	@media (min-width: 610px) and (max-width: 1440px) {
		padding: 3.5rem 1.688rem 2.5rem 1.25rem;
	}
	@media (max-width: 610px) {
		gap: 0.5rem;
		padding: 1.031rem 0 1.031rem 0;
		min-height: 9rem;
		border-radius: 10px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		margin-bottom: 0.75rem;
		justify-items: flex-start;
		justify-content: space-between;
		overflow: unset;
	}
`
const Flag = styled.div`
	position: absolute;
	right: -46px;
	top: -45px;
	z-index: 0;
	@media (max-width: 610px) {
		display: none;
	}
	@media (max-width: 768px) {
		right: -34px;
		top: -33px;
	}
`
const Img = styled.img`
	max-width: 46.875rem !important;
	height: 26.125rem !important;
	@media (max-width: 768px) {
		max-width: 36.875rem !important;
		height: 17.125rem !important;
	}
`
const InfoContainer = styled(FlexLayout)`
	width: 100%;
	flex-direction: column;
	align-items: start;
	justify-items: flex-start;
	padding: 0 8rem;
	z-index: 1;
	@media (min-width: 768px) and (max-width: 1440px) {
		padding: 0 4rem;
	}
	@media (max-width: 768px) {
		align-items: center;
		padding: 0;
		z-index: 0;
	}
`
export const Title = styled.p`
	font-size: 1.75rem;
	font-weight: bold;
	line-height: 2.27;
	padding: 0 5rem;
	@media (min-width: 610px) and (max-width: 1440px) {
		padding: 0 2rem;
	}
	@media (max-width: 768px) and (min-width: 610px) {
		font-size: 1.3rem;
	}
	@media (max-width: 610px) {
		font-size: 0.875rem;
		font-weight: bold;
		line-height: 1.29;
		padding: 0 1rem;
		margin-bottom: 1rem;
	}
`
const Header = styled.p`
	font-size: 1.625rem;
	font-weight: 600;
	line-height: 2.45;
	margin-bottom: 0.5rem;
	@media (max-width: 768px) and (min-width: 610px) {
		font-size: 1.3rem;
	}
	@media (max-width: 610px) {
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 1.17;
		margin-bottom: 0.5rem;
	}
`
const DateWrapper = styled(FlexLayout)`
	gap: 1.5rem;
	margin-bottom: 2rem;
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		justify-content: start;
		margin-bottom: 0;
		gap: 0;
	}
`
const TextWrapper = styled(FlexLayout)`
	flex-direction: column;
	align-items: start;
	justify-content: start;
	@media (max-width: 610px) {
		width: 100%;
		padding: 0 1rem;
		margin-top: 1rem;
	}
`
const InfoWrapper = styled(FlexLayout)`
	justify-content: space-between;
	align-items: center;
	width: 100%;
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 1.25rem;
	}
`
const Hint = styled.p`
	font-size: 2rem;
	font-weight: 500;
	line-height: 1.99;
	@media (max-width: 768px) and (min-width: 610px) {
		font-size: 1.5rem;
	}
	@media (max-width: 610px) {
		font-size: 0.75rem;
		font-weight: 600;
		line-height: 1.5;
	}
`
const Date = styled.p`
	font-size: 2rem;
	font-weight: 500;
	line-height: 1.99;
	font-style: italic;
	@media (max-width: 768px) and (min-width: 610px) {
		font-size: 1.5rem;
	}
	@media (max-width: 610px) {
		font-size: 1rem;
		font-weight: 600;
	}
`
const Number = styled.p`
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 2.65;
	@media (max-width: 768px) and (min-width: 610px) {
		font-size: 1rem;
	}
	@media (max-width: 610px) {
		font-size: 0.75rem;
		font-weight: 600;
		line-height: 3.3;
	}
`
const Duration = styled(FlexLayout)`
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 0.44;
	align-items: center;
	gap: 0.9rem;
	@media (max-width: 768px) and (min-width: 610px) {
		font-size: 1rem;
	}
	@media (max-width: 610px) {
		font-size: 0.75rem;
		font-weight: 600;
		line-height: 0.88;
		gap: 0.3rem;
	}
	.material-icons {
		@media (max-width: 610px) {
			font-size: 15px;
			margin-top: -2px;
		}
	}
`
const Attempt = styled.p`
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 2.65;
	@media (max-width: 768px) and (min-width: 610px) {
		font-size: 1rem;
	}
	@media (max-width: 610px) {
		font-size: 0.75rem;
		font-weight: 600;
		line-height: 3.3;
	}
`
export const Message = styled.div<{
	maxWidth: string
	minWidth: string
	maxHeight: string
}>`
	position: relative;
	word-break: break-word;
	overflow: hidden;
	width: ${({ maxWidth }) => maxWidth};
	min-width: ${({ minWidth }) => minWidth};
	height: ${({ maxHeight }) => maxHeight};
	padding: 1rem;
	line-height: 1.26;
	white-space: pre-wrap;
	background-color: #f8f8fe;
	border-radius: 50%;
	color: #6e7577;
	font-size: 0.799rem;
	font-weight: 500;
	text-align: center;
	z-index: 999;
	margin-top: -20px;
	display: flex;
	align-items: center;
	object-fit: contain;
	justify-content: center;

	@media (max-width: 1024px) {
		margin-top: -20px;
	}
	@media (max-width: 768px) {
		width: 8.813rem;
		height: 8.938rem;
		font-size: 0.6rem;
		line-height: 1.25;
		letter-spacing: 0.24px;
		padding: 12px;
		margin-top: -8px;
		color: #000;
	}
	@media (max-width: 500px) {
		width: 7.813rem;
		height: 4.938rem;
		padding: 14px;
		font-size: 0.5rem;
		font-weight: normal;
		line-height: 1.25;
		letter-spacing: 0.24px;
		margin-top: 0px;
		z-index: 0;
	}
`
export const EddieImg = styled.img`
	max-width: 130px !important;
	object-fit: contain;
	@media (max-width: 768px) {
		max-width: 140.6px !important;
	}
	@media (max-width: 500px) {
		max-width: 80px !important;
	}
`
const StartTestBtn = styled(RaisedButton)`
	width: 19.5rem;
	height: 3.25rem;
	font-weight: 600;
	font-size: 1.25rem;
	margin: auto;
	margin-top: 1rem;
	@media (max-width: 610px) {
		width: 14.313rem;
		height: 2.375rem;
		font-size: 0.875rem;
		margin-top: 1.1rem;
	}
`
const ModelStyle = styled(Modal)`
	display: flex;
	flex-direction: column;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	border-radius: 4px;
	box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
	margin: 32px;
	position: relative;
	overflow-y: auto;
`
const FlexLayoutStyle = styled(FlexLayout)`
	padding-left: 5rem;
	padding-right: 5rem;
	padding-top: 3rem;
	padding-bottom: 1rem;
	@media (max-width: 610px) {
		width: 300px;
		padding-left: 3.5rem;
		padding-right: 3.5rem;
		padding-top: 1rem;
	}
`
export default StartTest
