import Avatar from '@Components/Utilities/Avatar/Avatar'
import Spacer from '@Components/Utilities/Spacer'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { Color, Theme } from '@eduact/student-theme'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { WalletIcon } from '../../Assets/Icons'
import { useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import SkeletonAvatar from '@Components/Utilities/Skeleton/Components/SkeletonAvatar'
import { display, DisplayProps } from 'styled-system'
import Wallet from '@Components/Wallet/Wallet'
import { useTranslation } from 'react-i18next'
import { NumberFormatter } from '@eduact/utils'
const WalletAvatar = () => {
	const { isAuth } = useAppSelector(state => state.auth)
	const { data, isLoading } = useGetUserProfileQuery(undefined, { skip: !isAuth })
	const [walletColor, setWalletColor] = useState<Color>('light')
	const [walletOpened, setWalletOpened] = useState(false)
	const [t] = useTranslation(['wallet', 'generic'])

	useEffect(() => {
		if (data) {
			const { amount } = data.data.student.wallet
			if (amount === 0 || amount < 0) setWalletColor('red')
			else if (amount > 0 && amount < 200) setWalletColor('princetonOrange')
			else setWalletColor('primary')
		}
	}, [data, data?.data?.student.wallet.amount])

	return (
		<React.Fragment>
			{isAuth && (
				<WalletWrapper>
					<AvatarWrapper display={{ sm: 'none', lg: 'block' }}>
						<SkeletonAvatar isLoading={isLoading}>
							<Avatar onClick={() => setWalletOpened(true)} avatarSize="xlarge" background="independence">
								<StyledAvatarContent color={walletColor}>
									<WalletIcon color={walletColor} />
									<Spacer mx={10} />
									<span>
										{data?.data.student.wallet.amount !== undefined && NumberFormatter(data?.data.student.wallet.amount)}{' '}
										{t('egp', { ns: 'generic' })}
									</span>
								</StyledAvatarContent>
							</Avatar>
						</SkeletonAvatar>
					</AvatarWrapper>
					<WalletBody opened={walletOpened}>
						<Wallet onFinish={() => setWalletOpened(false)} />
					</WalletBody>
				</WalletWrapper>
			)}
		</React.Fragment>
	)
}
const WalletWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 100%;
	z-index: 10;
`
const AvatarWrapper = styled.div<DisplayProps>`
	margin: 1rem 0;
	/* transform: translateX(15%); */
	${display}
`
const WalletBody = styled.div<{ opened: boolean }>`
	min-height: 70vh;
	/* max-height: 70vh; */
	/* overflow: scroll; */
	width: 90vw;
	display: grid;
	grid-template-rows: 10% 90%;
	background: ${props => props.theme.colors.independence};
	color: ${props => props.theme.colors.light};
	padding: 1.5rem 1.5rem;
	position: absolute;
	top: 0;
	right: ${props => (props.opened ? '0' : '-100vw')};
	transition: all ease-in-out 400ms;
	border-bottom-left-radius: ${props => props.theme.borderRadii.medium}px;
	${({ theme }) => `${theme.mediaQueries.large}{
		width :30vw;
	}`}
`

const StyledAvatarContent = styled.div<{ color: Color }>`
	width: 100%;
	height: 100%;
	display: flex;
	font-size: 0.75rem;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: ${props => props.theme.colors[props.color]};
	font-size: 1rem;
`

export default WalletAvatar
