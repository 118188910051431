import InvoiceCard from '@Components/Cards/InvoiceCard'
import MainTable from '@Components/MainTable/MainTable'
import { MainTableRequestArgs } from '@Components/MainTable/MainTable.types'
import TableFilter from '@Components/MainTable/TableFilter'
import { Icon, Icons, TextInput } from '@eduact/ed-components'
import { Box, FlexLayout, TableDataFetch, Typography, useElementScroll } from '@eduact/ed-system'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useLazyGetInvoicesQuery } from '../ActivityApi/ActivityApi'
import { DotLoader, SyncLoader } from 'react-spinners'
import { Theme } from '@eduact/student-theme'
import { rgba } from 'polished'
//
const Invoices = () => {
	const [getInvoices, data] = useLazyGetInvoicesQuery()
	const [params, setParams] = useSearchParams()
	const tableRef = useRef(null)
	const intersectingRef = useRef(null)
	const hasMoreData = useMemo(() => {
		if (data.data) {
			return data.data.data.length < data.data?.meta?.total
		}
	}, [data])
	const [headerActionVisible, setHeaderActionVisible] = useState(false)

	const [isOnEnd, setIsOnEnd] = useState(false)
	useEffect(() => {
		params.set('perPage', '12')
		params.set('page', '1')
		setParams(params)
	}, [])

	useEffect(() => {
		const observer = new IntersectionObserver(function (entries, observer) {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					const perPage = Number(params.get('perPage'))
					setIsOnEnd(true)
					if (hasMoreData) {
						params.set('perPage', (perPage + 12).toString())
						setParams(params)
					}
				} else {
					setIsOnEnd(false)
				}
			})
		})
		if (intersectingRef.current) {
			observer.observe(intersectingRef.current)
		}
		return () => observer.disconnect()
	}, [intersectingRef, hasMoreData])

	const tableRequest = useCallback(
		(args: MainTableRequestArgs) => {
			getInvoices(args)
		},
		[getInvoices]
	)

	const searchValue = useMemo(() => {
		return params.get('query') ?? undefined
	}, [params])
	const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		params.set('query', e.target.value)
		setParams(params)
	}, [])

	const SearchElement = useMemo(() => {
		return (
			<TextInput
				withHelperText={false}
				value={searchValue}
				onChange={onSearchChange}
				placeholder="search"
				endAdornment={
					<Icon>
						<Icons.Activity />
					</Icon>
				}
			/>
		)
	}, [onSearchChange, searchValue])

	useElementScroll({
		ref: tableRef,
		onChange(scrollPosition) {
			setHeaderActionVisible(scrollPosition > 300)
		},
	})

	const HeaderAction = useMemo(() => {
		return <>{headerActionVisible && <StyledHeaderElement isVisible={headerActionVisible}>{SearchElement}</StyledHeaderElement>}</>
	}, [SearchElement, headerActionVisible])
	return (
		<InnerContainer>
			<FlexLayout maxHeight={'100%'} overflow="hidden" flexDirection={'column'}>
				<TableFilter tableFilters={data.data?.filters ?? []} />
				<FlexLayout flex={'1'} overflow="hidden">
					<TableDataFetch
						filters={data.data?.filters ?? []}
						request={tableRequest}
						render={() => {
							return (
								<MainTable
									headerAction={() => <>{HeaderAction}</>}
									innerRef={tableRef}
									title="Invoices"
									hasHeader={false}
									renderItems={() => {
										return (
											<FlexLayout padding="2rem 2.75rem" paddingTop="0" flexDirection={'column'}>
												<FlexLayout alignSelf={'flex-end'} maxWidth={'22.181rem'} mb={'2rem'}>
													{SearchElement}
												</FlexLayout>
												<InvoicesGrid>
													{data.data?.data.map((iv, index) => {
														return (
															<InvoiceCard
																key={`${iv.id}-${index}`}
																amount={iv.transaction.amount.toString()}
																date={iv.created_at.toString()}
																invoiceReferenceNumber={iv.invoice_ref}
																provider={iv.transaction.provider}
																providerReference={iv.transaction.provider_ref}
																status={iv.status}
																type={iv.type}
															/>
														)
													})}
												</InvoicesGrid>
												<div ref={intersectingRef}></div>
												{hasMoreData && isOnEnd && (
													<FlexLayout py="1rem" justifyContent={'center'}>
														<SyncLoader color={Theme.colors.primary} />
													</FlexLayout>
												)}
											</FlexLayout>
										)
									}}
								/>
							)
						}}
					/>
				</FlexLayout>
			</FlexLayout>
		</InnerContainer>
	)
}

export default Invoices

const InvoicesGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 2.875rem;
	row-gap: 2rem;
	border-radius: inherit;
	justify-content: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		justify-content: initial;
	}`}
`

const StyledHeaderElement = styled.div<{ isVisible: boolean }>`
	opacity: ${props => (props.isVisible ? '1' : '0')};
	transition: all ease-in-out 300ms;
`

const InnerContainer = styled.div`
	background: ${rgba('#FFF', 0.5)};
	width: 100%;
	height: 100%;
	padding: 11px;
	border-radius: 15px;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 0;
		background : transparent;
	}`}
`
