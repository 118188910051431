import React, { useCallback, useEffect, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import convert from 'image-file-resize'

import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Modal from '@Components/Utilities/Popup/Modal'
import { RaisedButton } from '@eduact/ed-components'
import { useUploadProfilePictureMutation } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import Skeleton from '@Components/Utilities/Skeleton/Skeleton'
import SkeletonTitle from '@Components/Utilities/Skeleton/Components/SkeletonTitle'

type Props = {
	showModal: any
	setShowModal: any
	file: any
	onSuccess: any
}
const ProfilePictureModal: React.FC<Props> = ({ showModal, setShowModal, file, onSuccess }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, onZoomChange] = React.useState(1)

	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [croppedImage, setCroppedImage] = useState(null)
	const dispatch = useDispatch()

	const [uploadPicture, responseData] = useUploadProfilePictureMutation()
	const _getCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(file, croppedAreaPixels)
			if (!croppedImage) return
			const fileBinary = new File([croppedImage], 'profile_picture.png', {
				lastModified: new Date().getTime(),
				type: 'image/png',
			})
			const resizedFile = await convert({
				file: fileBinary,
				width: 300,
				height: 300,
				type: 'png',
			})

			const formData = new FormData()
			formData.append('photo', resizedFile)
			uploadPicture(formData)

			// const { data, status } = await new API().DoPost('student/profile/picture/upload', formData)
			// if (status) {
			// 	onSuccess && onSuccess()
			// 	setLoading(false)
			// }
		} catch (e) {}
	}, [croppedAreaPixels])

	useEffect(() => {
		if (responseData.isSuccess) {
			setShowModal(false)
		}
	}, [responseData.isSuccess, setShowModal])

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	return (
		<Modal modalOpen={showModal}>
			<Skeleton borderRadius={30} isLoading={responseData.isLoading} height={'70vh'} width={'60vw'}>
				<Modal.Content onClose={() => setShowModal(false)}>
					<PPModal>
						<Cropper
							onCropComplete={onCropComplete}
							onCropChange={setCrop}
							onZoomChange={onZoomChange}
							zoom={zoom}
							image={file}
							cropShape="round"
							zoomWithScroll
							crop={crop}
							aspect={1 / 1}
						/>
						<ModalAction>
							<RaisedButton onClick={_getCroppedImage}>Save</RaisedButton>
						</ModalAction>
					</PPModal>
				</Modal.Content>
			</Skeleton>
		</Modal>
	)
}
const PPModal = styled.div<{ backgroundImage?: string }>`
	width: 70vw;
	height: 70vh;
	position: relative;
	background: #fff;
	border-radius: 30px;
	padding: 2rem;
	overflow: hidden;
	background: ${props => (props.backgroundImage ? `url(${props.backgroundImage})` : '')};
`
const ModalAction = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 9999;
	margin: 2rem 4rem;
`

export default ProfilePictureModal

async function getCroppedImg(imageSrc: any, pixelCrop: any, rotation = 0): Promise<Blob | undefined> {
	const image: any = await createImage(imageSrc)
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')
	if (!ctx) return

	const maxSize = Math.max(image.width, image.height)
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea
	canvas.height = safeArea

	// translate canvas context to a central location on image to allow rotating around the center.
	ctx.translate(safeArea / 2, safeArea / 2)
	ctx.rotate(getRadianAngle(rotation))
	ctx.translate(-safeArea / 2, -safeArea / 2)

	// draw rotated image and store data.
	ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
	const data = ctx.getImageData(0, 0, safeArea, safeArea)

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width
	canvas.height = pixelCrop.height

	// paste generated rotate image with correct offsets for x,y crop values.
	ctx.putImageData(
		data,
		Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
		Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
	)
	// As a blob
	return new Promise(resolve => {
		canvas.toBlob(file => {
			if (!file) {
				return
			}
			resolve(file)
		}, 'image/png')
	})
}

function resizeTo(canvas: any, pct: any) {
	var tempCanvas = document.createElement('canvas')
	var tctx = tempCanvas.getContext('2d')
	if (!tctx) return
	var cw = canvas.width
	var ch = canvas.height
	tempCanvas.width = cw
	tempCanvas.height = ch
	tctx.drawImage(canvas, 0, 0)
	canvas.width *= pct
	canvas.height *= pct
	var ctx = canvas.getContext('2d')
	ctx.drawImage(tempCanvas, 0, 0, cw, ch, 0, 0, cw * pct, ch * pct)
	return tempCanvas
}
const createImage = (url: string) =>
	new Promise((resolve, reject) => {
		const image = new Image()
		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', error => reject(error))
		image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
		image.src = url
	})

function getRadianAngle(degreeValue: number) {
	return (degreeValue * Math.PI) / 180
}

function blobToFile(theBlob: any, fileName: string) {
	//A Blob() is almost a File() - it's just missing the two properties below which we will add
	theBlob.lastModifiedDate = new Date()
	theBlob.name = fileName
	return theBlob
}
