import Spacer from '@Components/Utilities/Spacer'
import { FlexLayout } from '@eduact/ed-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useGetClassroomBasicInfoQuery } from '../SingleClassroomSlice/SingleClassroomSlice'
import CurrentCourseBookmark from './CurrentCourseMark'

const InstructorWidget = () => {
	const [t] = useTranslation(['classroomsOverview', 'dropdowns', 'classroom_status', 'classroom'])
	const { id } = useParams()

	const { data } = useGetClassroomBasicInfoQuery(id as string)
	const label = (label: string | undefined) => {
		switch (label) {
			case 'online':
				return t('online')
			default:
				return t('center')
		}
	}

	const renderStatus = (status: string | undefined) => {
		switch (status) {
			case 'on track':
				return '#5AC0FC'
			case 'late':
				return '#FF8532'
			case 'new':
				return '#6C63FF'
			case 'inconsistent':
				return '#FFD037'
			default:
				return
		}
	}

	return (
		<Wrapper flexDirection="column">
			<Type background="#3F3D56">{label(data?.data.type)}</Type>
			<InstructorInfo>
				{data?.data.current_course && <CurrentCourseBookmark courseName={data?.data.current_course} />}
				<FlexLayout flexDirection="column" gridGap="0.281rem">
					<Text>{t('taughtBy')}</Text>
					<InstructorName>{data?.data.instructor_name}</InstructorName>
					<Spacer mb="0.3rem" />
					{data?.data.classroomInfo?.group && (
						<Text>
							{t('classroom:group')} : {data?.data.classroomInfo.group?.name}
						</Text>
					)}
				</FlexLayout>
			</InstructorInfo>
			<Type background={renderStatus(data?.data?.enrollment_status)}>
				{t(`classroom_status.${data?.data?.enrollment_status}`, { ns: 'dropdowns' })}
			</Type>
		</Wrapper>
	)
}
const Wrapper = styled(FlexLayout)`
	gap: 0.3rem;
	width: 100%;
	${({ theme }) => `${theme.mediaQueries.large}{
		gap: 0.5rem;
		min-width: 18.938rem;
	}`}
`
const Type = styled.div<{ background: string | undefined }>`
	height: 1.5rem;
	border-radius: 5px;
	font-size: 0.875rem;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	background-color: ${({ background }) => background};
	${({ theme }) => `${theme.mediaQueries.large}{
		height: 2.75rem;
		border-radius: 10px;
		font-size: 1.375rem;
	}`}
`
const InstructorInfo = styled.div`
	background-color: #fff;
	padding-inline-start: 0.188rem;
	padding: 0.281rem 0;
	height: 7.738rem;
	border-radius: 5px;
	padding: 0.281rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 0.706rem 0;
		padding-inline-start: 1.688rem;
		height: 10.569rem;
		border-radius: 15px;
	}`}
`
const Text = styled.span`
	font-size: 0.5rem;
	font-weight: 500;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
	}`}
`
const InstructorName = styled.p`
	font-size: 0.625rem;
	font-weight: 600;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.375rem;
	}`}
`
export default InstructorWidget
