import api from '@Api/Api'
import { BaseApi } from '@Features/Base/BaseApi/BaseApi'

const SettingsApi = BaseApi.injectEndpoints({
	endpoints: builder => ({
		logoutDevice: builder.mutation<ApiResponse<Device>, DeviceId>({
			query: payload => ({
				url: 'devices/logout/single',
				method: 'POST',
				body: {
					device_id: payload.device_id,
				},
			}),
		}),
	}),
})

export const { useLogoutDeviceMutation } = SettingsApi

export default class TrustedDevicesApi {
	public static getAllDevices() {
		return api.get<ApiResponse<Device[]>>('/devices/fetch')
	}
	public static logoutAllDevices() {
		return api.get<ApiResponse<Device[]>>('/devices/logout/all')
	}
}
