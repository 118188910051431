import { CircularProgress } from '@eduact/ed-components'
import { Typography } from '@eduact/ed-system'
import { Color } from '@eduact/student-theme'
import { useCountdown, useCountdownInterval } from '@eduact/utils'
import useSubmitTest from '@Features/Test/Hooks/useSubmitTest'
import { useTest } from '@Features/Test/Providers/TestProvider'
import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
type TimerProps = {
	overallProgress: number
	timeNow: number
}
const TestTimer: React.VoidFunctionComponent<TimerProps> = ({ overallProgress, timeNow }) => {
	const submitTest = useSubmitTest()
	const {
		attempt: { end_date },
		test,
	} = useTest()
	const start = useCallback(() => {
		const nowDate = timeNow
		const endDate = new Date(end_date)
		const diff = endDate.getTime() - nowDate
		let seconds = Math.floor(diff / 1000)
		let minutes = Math.floor(seconds / 60)
		let hours = Math.floor(minutes / 60)
		seconds = seconds % 60
		minutes = minutes % 60
		hours = hours % 24
		return { hours, seconds, minutes }
	}, [end_date])

	const [timeEnded, setTimeEnded] = useState(false)
	const [time, setTime] = useState(start())
	const { countdown, startCountdown, resetCountdown } = useCountdown({
		start: time,
		end: { minutes: 0, seconds: 0, hours: 0 },
		onEnd() {
			setTimeEnded(true)
		},
	})

	useEffect(() => {
		resetCounter()
	}, [])

	const { counterString, counterNumber, counter, startCounter, resetCounter } = useCountdownInterval({
		start: new Date(end_date).getTime() - timeNow,
		end: 0,
		initOnStart: true,
		onEnd() {
			setTimeEnded(true)
		},
	})

	useEffect(() => {
		if (timeEnded) {
			submitTest(false, false)
		}
	}, [submitTest, timeEnded])

	const progress = useMemo(() => {
		document.title = `${counterString.hours}:${counterString.minutes}:${counterString.seconds} | ${test.unit?.name}`
		return Number(counterNumber.minutes) * 60 + Number(counterNumber.hours) * 60 * 60 + Number(counterNumber.seconds)
	}, [counter])

	const handleVisibilityChange = (e: Event) => {
		if (document.visibilityState === 'visible') {
			setTime(start())
		}
	}

	useEffect(() => {
		startCountdown()
		document.addEventListener('visibilitychange', handleVisibilityChange)
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange)
			document.title = 'Eduact'
		}
	}, [])

	const progressColor = useMemo<Color>(() => {
		if (!progress) return 'primary'
		const percent = progress / overallProgress
		if (percent > 0.5) return 'primary'
		if (percent > 0.3) return 'yellow'
		return 'princetonOrange'
	}, [overallProgress, progress])

	return (
		<>
			{progress && (
				<CircularProgress
					color={progressColor}
					size={{ sm: 'large', lg: 'xlarge' }}
					progress={progress}
					overallProgress={overallProgress}
					label={
						<Typography fontSize={{ sm: '10px', lg: '18px' }}>
							{!!counterString?.hours && <>{counterString.hours}:</>}
							{counterString.minutes}:{counterString.seconds}
						</Typography>
					}
				/>
			)}
		</>
	)
}

export default React.memo(TestTimer)
