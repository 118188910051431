import { GridLayout } from '@eduact/ed-system'
import { RechargeMethod } from '@eduact/utils'
import { RechargeProcessed, RechargeProvider } from '@Features/Dashboard/DashboardSlice/Dashboard.slice.types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

type ButtonGridValue = {
	value?: any
	args?: {
		method: RechargeMethod
	}
}
type CallbackValue = {
	value: string
	method?: RechargeMethod
}
type ButtonGridProps = {
	value: ButtonGridValue
	onClick?: (value: ButtonGridValue) => void
	isSelected?: boolean
}
const ButtonGridItem: React.FC<ButtonGridProps> = ({ children, value, isSelected = false, onClick }) => {
	return (
		<ButtonGridItemStyled onClick={() => onClick && onClick(value)} isSelected={isSelected}>
			{children}
		</ButtonGridItemStyled>
	)
}
interface ButtonsGridComposition {
	Item: React.FC<ButtonGridProps>
}

type ButtonsGridProps = {
	onChange?: (value: ButtonGridValue | undefined) => void
	value: ButtonGridValue
}
const ButtonsGrid: React.FC<ButtonsGridProps> & ButtonsGridComposition = ({ children, onChange, value }) => {
	return (
		<GridLayout width="100%" gridTemplateColumns={'repeat(2,1fr)'} gridTemplateRows={'1fr'}>
			{React.Children.map(children, (child, index) => {
				if (React.isValidElement(child)) {
					let isSelected = false
					const childValue = child.props.value as ButtonGridValue
					if (value.value) {
						if (value.args) {
							if (value.args.method === childValue.args?.method && value.value == childValue.value) {
								isSelected = true
							}
						} else {
							if (value.value == childValue.value) {
								isSelected = true
							}
						}
					}

					return React.cloneElement(child as React.ReactElement<ButtonGridProps>, {
						value: child.props.value,
						onClick: (btnValue: ButtonGridValue) => {
							let canBeSelected = true

							if (btnValue.args?.method) {
								if (btnValue.args.method === value.args?.method && btnValue.value === value.value) {
									canBeSelected = false
								}
							} else {
								if (btnValue.value === value.value) {
									canBeSelected = false
								}
							}
							if (canBeSelected) {
								onChange && onChange(btnValue)
							} else {
								onChange && onChange(undefined)
							}
						},
						isSelected: isSelected,
					})
				}
			})}
		</GridLayout>
	)
}

ButtonsGrid.Item = ButtonGridItem

export default ButtonsGrid

const ButtonGridItemStyled = styled.button<{ isSelected: boolean }>`
	padding: 0.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	border-radius: 3px;
	background: ${props => (props.isSelected ? props.theme.colors.maxBluePurple : props.theme.colors.light)};
	cursor: pointer;
	min-height: 4rem;
	color: ${props => (props.isSelected ? props.theme.colors.light : props.theme.colors.dark)};
	* {
		color: ${props => (props.isSelected ? props.theme.colors.light : '')} !important;
	}
`
