import { IconButton, SkeletonBox } from '@eduact/ed-components'
import { RaisedButton } from '@eduact/ed-components'
import { CTAImage } from '@Features/LandingPage/Assets/Images'
import CTA_Image from '@Features/LandingPage/GuestLanding/Assets/cta.png'
import { GridItem, GridLayout } from '@eduact/ed-system'
import React from 'react'
import { RiParentFill } from 'react-icons/ri'
import styled from 'styled-components'
import { Typography } from '@eduact/ed-system'
import CustomLink from '@Styles/CustomLink'
import routes from '@Constants/Routes'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { defaultAssets } from '@Constants/assets.defaults'

const CTASection = () => {
	const navigate = useNavigate()
	const { t } = useTranslation(['landingPage', 'generic', 'login'])

	const { assets, assetsLoading, landing_headline, landing_subheadline } = useAppSelector(s => s.settings)

	t('landing_page.cta_title')
	return (
		<>
			{assetsLoading && <SkeletonBox width="100%" height="40vh" isLoading />}
			{!assetsLoading && (
				<SectionWrapper background={assets.landing_main ?? defaultAssets.landing_main}>
					<SectionContent>
						<SectionTitle>
							{landing_headline ?? t('cta_title')}{' '}
							<Typography fontWeight={'bold'}>{!landing_headline && t('you', { ns: 'generic' })}</Typography>
						</SectionTitle>
						<SectionSubTitle>{landing_subheadline ?? t('cta_sub_title')}</SectionSubTitle>

						<ActionsWrapper>
							<RaisedButton
								onClick={() => navigate(routes.login)}
								// maxWidth={{ sm: '6.313rem', lg: '' }}
								minWidth={{ sm: 'unset', lg: '12rem' }}
								btnSize="small"
							>
								{t('login', { ns: 'login' })}
							</RaisedButton>
							<RaisedButton
								onClick={() => navigate(routes.signup)}
								// maxWidth={{ sm: '6.313rem', lg: '' }}
								minWidth={{ sm: 'unset', lg: '12rem' }}
								bgFallback
								btnSize="small"
								outlined
							>
								{t('signup', { ns: 'login' })}
							</RaisedButton>
							{/* <GridItem alignItems="center" gridColumn={'1 / 3'}>
						<RaisedButton minWidth={{ lg: '12rem' }} btnSize="small" variant="princetonOrange">
							<IconButton variant="light" mx={10} icon={<RiParentFill />} />
							Parent
						</RaisedButton>
					</GridItem> */}
						</ActionsWrapper>
					</SectionContent>
				</SectionWrapper>
			)}
		</>
	)
}

export default CTASection

const SectionWrapper = styled.div<{ background: string }>`
	/* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props => props.background}); */
	background: url(${props => props.background});

	background-position-x: center;
	background-size: cover;
	padding: 1.5rem;
	padding: 3.375rem 2.625rem;
	${({ theme }) => `${theme.mediaQueries.large}{
        padding: 5.813rem 12.688rem;
		padding-top :8rem;
    }`}
`
const SectionContent = styled.div`
	color: ${props => props.theme.colors.light};
	${({ theme }) => `${theme.mediaQueries.large}{
        width: 30%;
        
    }`}
`
const SectionTitle = styled.h2`
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.2;
	margin-bottom: 0.5rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 2.25rem;
		margin-bottom : 1rem;
        
    }`}
`
const SectionSubTitle = styled.h4`
	line-height: 1.2;
	font-size: 0.75rem;
	/* margin: 1.5rem 0rem; */
	margin-bottom: 2rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.375rem;
		margin-bottom: 3rem;
        // margin: 1.5rem 2rem;
        
    }`}
`
const ActionsWrapper = styled.div`
	/* display: grid; */
	/* grid-template-columns: repeat(2, 1fr); */
	/* grid-template-rows: repeat(2, 1fr); */
	/* grid-gap: 1rem; */
	/* place-items: center; */
	display: flex;
	grid-gap: 0.625rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		grid-gap: 1rem;
        // margin: 0rem 4rem;
    }`}
`
