import { rtkPrepareHeadersCallback } from '@Api/Api'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { UserProgress, UserProgressPayload } from './Progress.types'

export const ProgressAPI = createApi({
	reducerPath: 'ProgressAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_BASE_URL}/student/progress/`,
		prepareHeaders: rtkPrepareHeadersCallback,
	}),
	endpoints: builder => ({
		getAllClassroomsProgress: builder.query<ApiResponse<UserProgress>, UserProgressPayload>({
			query: (info: UserProgressPayload) => {
				return {
					url: '',
					method: 'POST',
					body: info,
				}
			},
		}),
	}),
})

export const { useGetAllClassroomsProgressQuery } = ProgressAPI
