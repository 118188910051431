import { MainTableRequestArgs, TableHeader } from '@Components/MainTable/MainTable.types'
import { useWidget } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useLazyGetTimelineQuery } from '../SingleClassroomSlice/SingleClassroomSlice'

const TableHeaders: Array<TableHeader> = [
	{ displayName: 'Events', fieldName: 'tag', width: '0%' },
	{ displayName: 'Date', fieldName: 'date', width: '40%' },
	{ displayName: 'Comment', fieldName: 'Description', width: '50%' },
]

const TimeLineWidget = () => {
	const { id } = useParams()
	const { setTitle } = useWidget()

	const [getTimelineEvents, { data }] = useLazyGetTimelineQuery()

	const pageRequest = useCallback((args: MainTableRequestArgs) => {
		getTimelineEvents({
			...args,
			perPage: 5,
			reference_id: Number(id),
		})
	}, [])

	useEffect(() => {
		pageRequest({
			filters: [],
			perPage: 5,
			from: '',
			to: '',
		})
	}, [])

	useEffect(() => {
		setTitle('Timeline events')
	}, [])

	const renderType = (type: string) => {
		switch (type) {
			case 'purchase':
				return '#6C63FF'
			case 'system':
				return '#3F3D56'
			case 'recharge':
				return '#FF8532'
			case 'Refund':
				return '#c7c5f4'
			case 'registration':
				return '#5AC0FC'
			case 'unban':
				return '#2bd982'
			case 'code':
				return '#2196F3'
			case 'ban':
				return '#F55F44'
			default:
				return
		}
	}

	return (
		<Wrapper>
			<Border />
			<Table>
				<StyledHead>
					<TableHead>
						{TableHeaders?.map((head, index) => {
							return <th key={typeof head === 'object' ? `${head.fieldName}-${index}` : `${head}-${index}`}>{head.displayName}</th>
						})}
					</TableHead>
				</StyledHead>
				<Tbody>
					{data?.data?.map((timeline, index) => {
						return (
							<MainTableRow key={`timeline-${index}`}>
								{timeline?.tag ? (
									<EventCell>
										<WalletType bg={renderType(timeline?.tag)}>{timeline?.tag}</WalletType>
									</EventCell>
								) : (
									<EventCell>-</EventCell>
								)}
								<DateCell>
									<FlexLayout gridGap={{ lg: '0.938rem', sm: '0.1rem' }} flexDirection={{ lg: 'row', sm: 'column' }} justifyContent="center">
										<Date>{moment(timeline.created_at).format('DD/MM/yyyy')}</Date>
										<Time>{moment(timeline.created_at).format('hh:mm A')}</Time>
									</FlexLayout>
								</DateCell>
								<TableCellStyle>{timeline.description}</TableCellStyle>
							</MainTableRow>
						)
					})}
				</Tbody>
			</Table>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	overflow-y: scroll;
	min-height: 15.813rem;
	@media screen and (min-width: 769px) {
		min-height: 14.95rem;
	}
	&::-webkit-scrollbar {
		width: 3px;
		@media screen and (min-width: 769px) {
			width: 6px;
		}
	}
	&::-webkit-scrollbar-track {
		background: #fff;
	}

	&::-webkit-scrollbar-thumb {
		background: #979797;
		border-radius: 3px;
	}
`
const TableHead = styled.tr`
	width: 100%;
	font-size: 0.5rem;
	font-weight: normal;
	color: #b6b6b6;
	height: 1rem;
	display: table-row;
	vertical-align: middle;
	outline: 0px;
	@media screen and (min-width: 769px) {
		font-size: 0.75rem;
	}
	th:nth-of-type(1) {
		text-align: start;
		padding-inline-start: 1rem;
		${({ theme }) => `${theme.mediaQueries.large}{
			padding-inline-start: 1.8rem;
	}`}
	}
`
const StyledHead = styled.thead`
	display: table-header-group;
	th {
		text-transform: capitalize;
	}
`
const Tbody = styled.tbody`
	display: table-row-group;
`
const Table = styled.table`
	display: table;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0px;
	margin-top: 1rem;
`
const Border = styled.div`
	height: 1px;
	background: #d3d3d3;
	margin-top: 1rem;
`
export const MainTableRow = styled.tr`
	width: 100%;
	display: table-row;
	outline: 0px;
	height: 3.3rem;
`
export const TableCell = styled.td`
	font-size: 0.75rem;
	text-align: center;
	font-weight: 500;
	color: #251b33;
	vertical-align: middle;
	@media screen and (min-width: 769px) {
		font-size: 0.875rem;
	}
`
export const TableCellStyle = styled(TableCell)`
	white-space: pre-wrap;
	text-overflow: ellipsis;
	line-height: 1.3;
	font-size: 0.575rem;
	font-weight: 500;
	width: 12.5rem;
	@media screen and (min-width: 769px) {
		font-size: 0.875rem;
	}
`
const EventCell = styled(TableCell)`
	width: 5rem;
`
const DateCell = styled(TableCell)`
	width: 11.25rem;
`
export const WalletType = styled.div<{ bg: string | undefined }>`
	background: ${({ bg }) => bg};
	width: 3.8rem;
	height: 0.9rem;
	border-radius: 4px;
	padding: 1px 2px;
	color: #f9fafc;
	font-weight: 600;
	font-size: 0.6rem;
	line-height: 1.3;
	@media screen and (min-width: 769px) {
		font-size: 1rem;
		width: 6rem;
		height: 1.813rem;
		border-radius: 10px;
		padding: 5px 7px;
		line-height: 1;
	}
`
const Time = styled.p`
	font-size: 0.5rem;
	font-weight: 500;
	@media screen and (min-width: 769px) {
		font-size: 0.75rem;
	}
`
const Date = styled.p`
	font-size: 0.5rem;
	font-weight: normal;
	font-style: italic;
	@media screen and (min-width: 769px) {
		font-size: 0.75rem;
	}
`

export default TimeLineWidget
