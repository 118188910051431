import { Icon, IconButton, Icons } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import React, { FC, useMemo } from 'react'
import styled from 'styled-components'

type Props = {
	setAttemptId: any
	attemptId: number | undefined
	summmaryData: Attempts | undefined
	attempts: TestAttepmtsDropdwonPayload | undefined
}

const PreviousNextSlots: FC<Props> = ({ setAttemptId, attemptId, summmaryData, attempts }) => {
	const slotIndex = useMemo(() => {
		if (!summmaryData || !attempts) return
		return attempts?.data.findIndex(_ => _.id === attemptId)
	}, [summmaryData, attemptId, attempts])

	const handleBackButtonClick = () => {
		if (slotIndex === undefined) return
		if (!attempts) return
		const prevAttempt = attempts?.data[slotIndex - 1]
		setAttemptId(prevAttempt?.id)
	}

	const handleNextButtonClick = () => {
		if (slotIndex === undefined) return
		if (!attempts?.data) return
		const attemptIndex = slotIndex + 1
		const nextAttempt = attempts?.data[attemptIndex]
		setAttemptId(nextAttempt.id)
	}

	const isLast = useMemo(() => {
		if (!summmaryData || !attempts) return
		const ind = attempts?.data.findIndex(_ => _.id === attemptId)
		return ind === attempts?.data.length - 1
	}, [summmaryData, attempts, attemptId])

	const isFirst = useMemo(() => {
		if (!summmaryData || !attempts) return
		const ind = attempts?.data.findIndex(_ => _.id === attemptId)
		return ind === 0
	}, [summmaryData, attempts, attemptId])

	return (
		<Wrapper
			alignItems="center"
			justifyContent="space-between"
			width={{ sm: '100%', lg: '39.188rem' }}
			margin="auto"
			pt={{ sm: '0.3rem', lg: '0.4rem' }}
		>
			<IconButton
				disabled={isFirst}
				className={`${isFirst && 'disable'}`}
				icon={
					<IconStyle disabled={isFirst} onClick={handleBackButtonClick} aria-label="previous page">
						<Icons.ChevronLeft />
					</IconStyle>
				}
			/>
			<IconButton
				disabled={isLast}
				className={`${isLast && 'disable'}`}
				icon={
					<IconStyle disabled={isLast} onClick={handleNextButtonClick} aria-label="next page">
						<Icons.ChevronRight />
					</IconStyle>
				}
			/>
		</Wrapper>
	)
}

const Wrapper = styled(FlexLayout)`
	[disabled] {
		opacity: 0.6;
		cursor: not-allowed;
		pointer-events: none;
	}
`

const IconStyle = styled(Icon)`
	font-size: 1.563rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.875rem;
	}`}
`

export default PreviousNextSlots
