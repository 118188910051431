import { FlexLayout } from '@eduact/ed-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Devices from './Components/Devices'

const Settings = () => {
	const [t] = useTranslation('settings')
	return (
		<Wrapper>
			<Header>{t('devices')}</Header>
			<Devices />
		</Wrapper>
	)
}
const Wrapper = styled(FlexLayout)`
	padding-top: 1.313rem;
	flex-direction: column;
	gap: 0.781rem;
	align-items: stretch;
	margin: auto;
	width: 100%;
	${({ theme }) => `${theme.mediaQueries.large}{
	  	padding-top: 2.313rem;
    	gap: 1.781rem;
		width: auto;
	}`}
`
const Header = styled.p`
	font-size: 0.9rem;
	font-weight: 600;
	padding-inline-start: 0.9rem;
  ${({ theme }) => `${theme.mediaQueries.large}{
	  font-size: 1.125rem;
	  padding-inline-start: 1.125rem;
	}`}
`

export default Settings
