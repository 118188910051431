import EdModal from '@Components/Utilities/Popup/Modal'
import { Dialog, Modal, ModalProps, RaisedButton } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout, Typography } from '@eduact/ed-system'
import { useClassroom } from '@Features/Classroom/Providers/ClassroomProvider/ClassroomProvider'
import { useCourseContext } from '@Features/Course/Providers/CourseProvider/CourseProvider'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
	prerequisites?: { [key: string]: Array<Course> }
} & ModalProps
const PrerequisitesModal: FC<Props> = ({ open, onClose, prerequisites }) => {
	const { course } = useCourseContext()
	const data = useMemo(() => {
		if (!prerequisites) return
		return Object.values(prerequisites)
	}, [prerequisites])
	const [t] = useTranslation(['classroom'])

	return (
		<Dialog
			persistent={false}
			onClose={onClose}
			open={open}
			width={{ sm: '90vw', lg: '40.375rem' }}
			height="fit-content"
			maxHeight={'90vh'}
		>
			<FlexLayout
				flexDirection={'column'}
				py={{ sm: '2rem', lg: '3.75rem' }}
				px={{ sm: '1.125rem', lg: '3.125rem' }}
				pb={{ sm: '1rem', lg: '2rem' }}
				maxHeight={'90vh'}
			>
				<Typography fontWeight={700}>
					{course?.prerequisite_criteria === 'purchase'
						? t('prere_title_purchase')
						: course?.prerequisite_criteria === 'complete'
						? t('prere_title_complete')
						: ''}
				</Typography>
				<Spacer mb={'1rem'} />
				<>
					{data &&
						data.map((path, pIndex) => {
							return (
								<React.Fragment key={`path-${pIndex}`}>
									{path.map(course => {
										return (
											<Spacer key={`${course.id}-prereq`} my={{ lg: '0.563rem' }} px={{ lg: '1.25rem' }}>
												<Typography fontWeight={600}>{course.name}</Typography>
											</Spacer>
										)
									})}
									{pIndex < data.length - 1 && (
										<Spacer mx={{ sm: '2rem', lg: '4.375rem' }} my={{ sm: '1.2rem', lg: '0.875rem' }}>
											<PathOrDivider content={t('prere_or')} />
										</Spacer>
									)}
								</React.Fragment>
							)
						})}
				</>
				<FlexLayout mt={'2rem'} justifyContent={'center'}>
					<RaisedButton onClick={onClose} variant="princetonOrange" btnSize={'small'}>
						{t('prere_close')}
					</RaisedButton>
				</FlexLayout>
			</FlexLayout>
		</Dialog>
	)
}

export default PrerequisitesModal

const PathOrDivider = styled.div<{ content: string }>`
	width: 100%;
	height: 2px;
	background: #afafaf;
	color: #afafaf;
	position: relative;
	::before {
		font-weight: bold;
		font-size: 1.5rem;
		content: ${props => `'${props.content}'`};
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 0.5rem 1rem;
		background: ${props => props.theme.colors.light};
	}
`
