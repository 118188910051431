import styled, { css } from 'styled-components'

export const Header = styled.p`
	font-family: Montserrat, serif;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	color: black;
`

export const ButtonCss = css`
	border: none;
	width: 134px;
	height: 48px;
	font-family: Montserrat, serif;
	font-size: 14px;
	font-weight: bold;
	color: white;
	border-radius: 100px;
`
