import SignupFeesCard from '@Components/Cards/SignupFeesCard/SignupFeesCard'
import { Dialog, DialogProps } from '@eduact/ed-components'
import React from 'react'

type Props = {} & DialogProps
const RegistrationFeesModal: React.VoidFunctionComponent<Props> = props => {
	return (
		<Dialog
			p={{ sm: '2rem', lg: '' }}
			persistent={false}
			onClose={props.onClose}
			width={{ lg: '77.625rem', sm: '90vw' }}
			height="fit-content"
			{...props}
		>
			<SignupFeesCard onSuccess={props.onClose} checkPendingTransactions />
		</Dialog>
	)
}

export default RegistrationFeesModal
