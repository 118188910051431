import React, { useEffect, useMemo, useState } from 'react'
import Layout from '@Components/Layout'
import { useAppDispatch, useAppSelector } from '@Hooks/ReduxHooks'
import { checkAuthAction } from '@Features/Authentication/AuthenticationSlice/Authentication.slice'
import { getAssets, getEducationalInfo, getSettings, updateOnlineStatus } from '@Features/Settings/SettingsSlice/Settings.slice'
import { useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { RoutingProvider } from './Providers/RoutingProvider/RoutingProvider'
import { SnackbarProvider } from '@eduact/ed-components'
import ApiProvider from '@Providers/ApiProvider/ApiProvider'
import FacebookPixel from 'react-facebook-pixel'
import EdSnackbarProvider from '@Providers/EdSnackbarProvider/EdSnackbarProvider'
import { checkRunningTest } from '@Redux/RunningTestSlice/RunningTestSlice'
import { Outlet, Route, Routes, useParams } from 'react-router'
import NotFoundPage from '@Features/Others/NotFoundPage/NotFoundPage'
import { useSearchParams } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@eduact/student-theme'
import { Reset } from 'styled-reset'
import GlobalStyles from '@Styles/GlobalStyles'
import { useDeviceInfo } from '@eduact/utils'
import LocalStorageKeys from '@Constants/LocalStorageKeys'

import { ThemeSelector } from '@Providers/ThemeSelector/ThemeSelector'
import { useBrowserTabInit } from '@Hooks/useBrowserTabInit'

export default function App() {
	const dispatch = useAppDispatch()
	const { isAuth } = useAppSelector(state => state.auth)
	useDeviceInfo({
		keyName: LocalStorageKeys.DeviceId,
		detectLocation: true,
	})

	useGetUserProfileQuery(undefined, {
		skip: !isAuth,
	})

	useBrowserTabInit({ auto: true })

	useEffect(() => {
		FacebookPixel.init('779721573324673', undefined, {
			autoConfig: true,
			debug: true,
		})
		FacebookPixel.track('PageView')
		dispatch(checkAuthAction())
		dispatch(getSettings())
		dispatch(getAssets())
		dispatch(getEducationalInfo())
		dispatch(checkRunningTest())

		window.addEventListener('online', e => handleOnlineChange(true))
		window.addEventListener('offline', e => handleOnlineChange(false))
		return () => {
			window.removeEventListener('online', e => handleOnlineChange(true))
			window.removeEventListener('offline', e => handleOnlineChange(false))
		}
	}, [])

	const handleOnlineChange = (online: boolean) => {
		dispatch(updateOnlineStatus(online))
	}
	const [params] = useSearchParams()

	const isEmbed = useMemo(() => {
		return Boolean(params.get('embed'))
	}, [])

	const [theme, setTheme] = useState(createTheme({}))

	return (
		<ThemeSelector>
			{(theme, setTheme) => {
				;<ThemeProvider theme={theme} setTheme={setTheme}>
					<GlobalStyles />
					<Reset />
					<RoutingProvider>
						<SnackbarProvider>
							<EdSnackbarProvider>
								<ApiProvider>
									<Layout embed={isEmbed} />
								</ApiProvider>
							</EdSnackbarProvider>
						</SnackbarProvider>
					</RoutingProvider>
				</ThemeProvider>
				return (
					<>
						<ThemeProvider theme={theme} setTheme={setTheme}>
							<GlobalStyles />
							<Reset />
							<RoutingProvider>
								<SnackbarProvider>
									<EdSnackbarProvider>
										<ApiProvider>
											<Layout embed={isEmbed} />
										</ApiProvider>
									</EdSnackbarProvider>
								</SnackbarProvider>
							</RoutingProvider>
						</ThemeProvider>
					</>
				)
			}}
		</ThemeSelector>
	)
}
