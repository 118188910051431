import { skipToken } from '@reduxjs/toolkit/dist/query'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useGetTestSummaryQuery } from '../TestMcqApi/TestMcqApi'
import Answers from './Answers'

type Props = {
	id: number | undefined
	setTestSummmaryData: any
	hideHeader?: boolean
}

const TestSummary: FC<Props> = ({ id, setTestSummmaryData, hideHeader }) => {
	const [t] = useTranslation('test')

	const { currentData: summaryData, status: summaryStatus } = useGetTestSummaryQuery({
		attempt_id: (id as number) ?? skipToken,
	})

	useEffect(() => {
		if (summaryStatus === 'fulfilled' && summaryData) {
			setTestSummmaryData(summaryData?.data.attempt)
		}
	}, [setTestSummmaryData, summaryData, summaryStatus])

	return (
		<div>
			{summaryStatus === 'fulfilled' && summaryData?.data?.attempt.questions && summaryData?.data.attempt?.test?.test_summary && (
				<>
					{!hideHeader && <SummaryHeader>{t('testSummary')}</SummaryHeader>}
					<Answers data={summaryData?.data} />
				</>
			)}
		</div>
	)
}

const SummaryHeader = styled.div`
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	height: 4.5rem;
	display: flex;
	align-items: center;
	margin: 1rem 0;
	font-size: 0.75rem;
	font-weight: 600;
	padding: 0 1rem;
	@media screen and (min-width: 1201px) {
		padding: 0 8rem;
		font-size: 1.25rem;
	}
`

export default TestSummary
