import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import routes from '@Constants/Routes'
import { RaisedButton } from '@eduact/ed-components'
import { FlexLayout, GridLayout } from '@eduact/ed-system'
import { Color } from '@eduact/student-theme'
import { useGetClassroomBasicInfoQuery } from '@Features/Dashboard/Classrooms/Components/Tabs/SingleClassroomOverview/SingleClassroomSlice/SingleClassroomSlice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import moment from 'moment'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import ShowSummaryModel from '../ShowSummaryModel/ShowSummaryModel'

type Props = {
	unit: Unit
}

const UnitTestCard: FC<Props> = ({ unit }) => {
	const { id } = useParams<{ id: string }>()
	const [t] = useTranslation(['classroomTests', 'test_status'])
	const [attemptId, setAttemptId] = useState<number | undefined>(undefined)
	const [showModel, setShowModel] = useState<boolean>(false)
	const { data } = useGetClassroomBasicInfoQuery((id as string) ?? skipToken)
	const navigate = useNavigate()
	const getStatusColor = (status: string | undefined): Color => {
		if (status === 'passed') return 'green'
		return 'lightRed'
	}

	const getTestScore = (score: number | undefined, overallScore: number | undefined) => {
		return `${score}/ ${overallScore}`
	}
	const getTestScorePercentage = (score: number, overallScore: number) => {
		if (!score && !overallScore) return
		return ((score / overallScore) * 100).toFixed(1)
	}

	const navigateToCourse = () => {
		if (unit) {
			const path = generatePath(`${routes.classroom}/${routes.course}/test/${unit.id}?unit=${unit.id}`, {
				label: data?.data.label as string,
				cId: unit.course_id.toString(),
			})
			navigate(path)
		}
	}

	const attemptsLength = useMemo(() => {
		if (unit.test.attempts) {
			return unit.test.attempts.length > 0
		}
	}, [unit?.test?.attempts])

	const show = () => {
		setShowModel(true)
	}

	const attempt = useMemo(() => {
		if (!unit.test.attempts.length) return
		const testAttempt = unit.test.attempts.find(attempt => attempt)
		return testAttempt
	}, [unit?.test?.attempts])

	console.log('attempt?.created_at', attempt?.created_at)

	return (
		<Card>
			{attemptsLength ? (
				<CardWrapper>
					<TestName fontSize={{ lg: '1.125rem' }} fontWeight={'600'}>
						{unit.name}
					</TestName>
					<TopSpacer />
					<AttemptWrapper flexDirection="column" alignItems="center">
						<AttemptCount fontSize={'1.375rem'} fontWeight={'600'}>
							{unit.test.attempts_count}
						</AttemptCount>
						<AttemptText>{t('attempts')}</AttemptText>
					</AttemptWrapper>
					<Status fontSize={{ sm: '0.875rem', lg: '1.125rem' }} fontWeight={'600'} color={getStatusColor(attempt?.status)}>
						{t(`test_status.${attempt?.status}`)}
					</Status>
					<ScoreWrapper flexDirection="column" gridGap="0.39rem" alignItems="center">
						<EdTypography fontSize={{ sm: '0.875rem', lg: '1.25rem' }} fontWeight={'600'}>
							{getTestScore(attempt?.score, attempt?.overall_score)}
						</EdTypography>
						<EdTypography fontSize={{ sm: '0.75rem', lg: '1.125rem' }} fontWeight={'500'}>
							({getTestScorePercentage(attempt?.score as number, attempt?.overall_score as number)}%)
						</EdTypography>
					</ScoreWrapper>
					<DateWrapper flexDirection="column" gridGap="0.39rem" alignItems="center">
						<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontWeight={'normal'} fontStyle="italic" color="dark">
							{moment(attempt?.created_at).format('DD/MM/YYYY')}
						</EdTypography>
						<EdTypography fontSize={{ sm: '0.75rem', lg: '1rem' }} fontWeight={'500'}>
							{moment(attempt?.created_at).format('hh:mm A')}
						</EdTypography>
					</DateWrapper>
					<Show
						onClick={() => {
							setAttemptId(attempt?.id)
							show()
						}}
					>
						{t('showSummary')}
					</Show>
					<BottomSpacer />
					<ShowSummaryModel
						show={showModel}
						setShow={setShowModel}
						attempt={attempt}
						test={unit}
						name={unit?.test.title}
						attemptId={attemptId}
						setAttemptId={setAttemptId}
					/>
					{/* <ActionButton onClick={navigateToCourse} variant="purple" outlined btnSize={{ sm: 'small', lg: 'medium' }}>
						{t('goToUnit')}
					</ActionButton> */}
				</CardWrapper>
			) : (
				<NotStartedCard>
					<EdTypography fontSize={{ lg: '1.125rem' }} fontWeight={'600'}>
						{unit.name}
					</EdTypography>
					<FlexLayout display={{ sm: 'none', lg: 'flex' }} />
					<NotStarted>{t('notStarted')}</NotStarted>
				</NotStartedCard>
			)}
		</Card>
	)
}

const NotStartedCard = styled(GridLayout)`
	background: #fff;
	height: 4.063rem;
	border-radius: 5px;
	padding: 0.719rem 0.938rem;
	justify-content: start;
	align-items: center;
	width: 100%;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 0;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 1.313rem 2.25rem;
		grid-template-columns: repeat(7, 1fr);
	}`}
`
const Card = styled.div`
	position: relative;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	margin-bottom: 0.625rem;
	display: flex;
	align-items: center;
	min-height: 8rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		min-height : 4.063rem;
		margin-bottom: 1rem;
	}`}
`
const CardWrapper = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: repeat(3, 1fr);
	width: 100%;
	gap: 0.938rem;
	background: #fff;
	border-radius: 5px;
	padding: 0rem 1.25rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		justify-content: space-between;
		grid-template-columns: repeat(7,1fr);
		gap: 1rem;
		padding: 0rem 2.25rem;
	}`}
`
const TopSpacer = styled.div`
	display: block;
	order: 2;
	${({ theme }) => `${theme.mediaQueries.large}{
		display: none;
	}`}
`
const BottomSpacer = styled.div`
	display: block;
	order: 8;
	${({ theme }) => `${theme.mediaQueries.large}{
		display: none;
	}`}
`
const NotStarted = styled.p`
	font-size: 0.875rem;
	font-weight: 600;
	text-align: center;
	/* max-width: 7ch; */
	color: ${props => props.theme.colors.princetonOrange};
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
	}`}
`
const Show = styled.p`
	font-size: 0.75rem;
	font-weight: bold;
	color: ${props => props.theme.colors.purple};
	text-decoration: underline;
	cursor: pointer;
	order: 8;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
	}`}
`
const ActionButtonCss = css`
	${({ theme }) => `${theme.mediaQueries.small}{
	
	padding: 0.375rem 0.75rem;
	min-width: 6rem;
	font-size: 0.625rem;
}`}
	padding : 0.75rem 1.75rem;
	font-weight: bold;
`
const ActionButton = styled(RaisedButton)`
	order: 9;
	${ActionButtonCss}
`
const ScoreWrapper = styled(FlexLayout)`
	order: 4;
`
const DateWrapper = styled(FlexLayout)`
	order: 5;
`
const TestName = styled(EdTypography)`
	order: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 70px;
	${({ theme }) => `${theme.mediaQueries.large}{
		order: 1;
	}`}
`
const AttemptWrapper = styled(FlexLayout)`
	order: 3;
	${({ theme }) => `${theme.mediaQueries.large}{
		order: 1;
	}`}
`
const AttemptText = styled(EdTypography)`
	font-size: 0.5rem;
	font-weight: 500;
	${({ theme }) => `${theme.mediaQueries.large}{
		display: none;
	}`}
`
const AttemptCount = styled(EdTypography)``
const Status = styled(EdTypography)`
	order: 2;
	text-align: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		order: 3;
	}`}
`

export default UnitTestCard
