import { WalletContext } from '@Components/Wallet/WalletContext'
import { useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { RechargeProcessed } from '@Features/Dashboard/DashboardSlice/Dashboard.slice.types'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { darken } from 'polished'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import WalletBodyHeader from './Components/WalletBodyHeader'
import WalletTabs from './Components/WalletTabs'

type Props = {
	withCloseAction?: boolean
	onFinish?: () => void
}
const Wallet: React.VoidFunctionComponent<Props> = ({ onFinish, withCloseAction = true }) => {
	const [walletIndex, setWalletIndex] = useState(0)
	const { isAuth } = useAppSelector(state => state.auth)
	const { data, isLoading } = useGetUserProfileQuery(undefined, { skip: !isAuth })
	const [provider, setProvider] = useState<RechargeProcessed>()
	const [rechargeAmount, setRechargeAmount] = useState<number>()
	const [code, setCode] = useState<string>()
	const [walletOpened, setWalletOpened] = useState(false)
	const [t] = useTranslation(['wallet'])

	const onBackward = React.useCallback(
		(index: number) => {
			if (walletIndex === 4) {
				setWalletIndex(1)
			} else if (walletIndex > 0) {
				setWalletIndex(walletIndex - 1)
			}
		},
		[walletIndex]
	)
	const closeWallet = () => {
		onFinish?.()
		setWalletIndex(0)
		setRechargeAmount(undefined)
		setProvider(undefined)
	}
	return (
		<>
			<WalletBodyHeader withClose={withCloseAction} walletIndex={walletIndex} onClose={() => closeWallet()} onBackward={onBackward} />
			<WalletBodyContent>
				<WalletHeaderDescription>
					{walletIndex > 0 && walletIndex !== 4 && (
						<>
							<DescriptionTitle>{t('recharge_wallet')}</DescriptionTitle>
							<DescriptionSubTitle>{t('wallet_message2')}</DescriptionSubTitle>
						</>
					)}
					{walletIndex === 0 && <DescriptionTitle>{t('wallet_message')}</DescriptionTitle>}
				</WalletHeaderDescription>

				{data && (
					<WalletContext.Provider
						value={{
							walletIndex: walletIndex,
							closeWallet: closeWallet,
							walletAmount: data.data.student.wallet.amount,
							provider: provider,
							rechargeAmount: rechargeAmount,
							setProvider: setProvider,
							setRechargeAmount: setRechargeAmount,
							setWalletIndex: setWalletIndex,
							code: code,
							setCode: setCode,
							closed: walletOpened,
						}}
					>
						<WalletTabs />
					</WalletContext.Provider>
				)}
			</WalletBodyContent>
		</>
	)
}

export default Wallet

const WalletBodyContent = styled.div`
	overflow-y: auto;
	/* height: inherit; */
	::-webkit-scrollbar {
		height: 2px;
		position: absolute;
		/* overflow: hidden; */
		width: 4px;
	}

	::-webkit-scrollbar-track {
		width: 3px;
		border-radius: ${props => props.theme.borderRadii.small}px;
		background: ${props => props.theme.colors.light};
	}

	::-webkit-scrollbar-thumb {
		border-radius: ${props => props.theme.borderRadii.small}px;
		background: ${props => darken(0.5, props.theme.colors.platinum)};
	}

	::-webkit-scrollbar-thumb:hover {
		background: ${props => darken(0.5, props.theme.colors.platinum)};
	}
	color: white;
`

const WalletHeaderDescription = styled.div`
	padding: 0 1rem;
`
export const TabTitle = styled.div`
	font-weight: bold;
`

const DescriptionTitle = styled(TabTitle)`
	margin-bottom: 1rem;
	font-size: 0.625rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size : 1rem;
	}`}
`
const DescriptionSubTitle = styled.div`
	font-size: 0.625rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size : 1rem;
	}`}
`
