import { SkeletonBox } from '@eduact/ed-components'
import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useGetAssignmentUnitQuery } from '../UnitApi'
import Assignment from './Components/Assignment'
import AssignmentAnswer from './Components/AssignmentAnswer'
import AssignmentContent from './Components/AssignmentContent'

const AssignmentUnit = () => {
	const { id } = useParams<{ id: string }>()

	// const [answerType, setAnswerType] = useState<AnswerType | undefined>()
	const dataQuery = useGetAssignmentUnitQuery(Number(id))

	if (dataQuery.isLoading)
		return (
			<AssignmentContainer>
				<SkeletonBox isLoading width={'100%'} />
			</AssignmentContainer>
		)

	if (dataQuery.isError || !dataQuery.data) return <p>error</p>

	return (
		<AssignmentContainer>
			<Assignment submission={dataQuery.data.data.submission} assignment={dataQuery.data.data.assignment} />
		</AssignmentContainer>
	)
}

export default AssignmentUnit

const AssignmentContainer = styled.div`
	padding: 2.188rem 4.125rem;
`
