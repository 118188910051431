import { SnackbarProvider, useSnackbar } from '@eduact/ed-components'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { SnackbarConfig } from '@Utilities/SnackbarConfig'
import React, { useEffect } from 'react'

const EdSnackbarProvider: React.FC = ({ children }) => {
	const { opened, text, status } = useAppSelector(state => state.snackbar)

	const { openSnackbar } = useSnackbar()
	useEffect(() => {
		if (status) {
			openSnackbar({ ...SnackbarConfig[status], text })
		}
	}, [text, status])
	return <>{children}</>
}

export default EdSnackbarProvider
