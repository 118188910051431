import React from 'react'
import styled, { css } from 'styled-components'

type IconBaseProps = {
	fill?: string
}
export const InProgress = ({ fillColor = '#967FB899' }) => {
	return (
		<svg width="59" height="66" viewBox="0 0 59 66" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M41.9233 19.5378L36.9362 21.5622C36.5157 21.2871 36.081 21.0366 35.6333 20.8095L34.8923 15.4785H24.0382L23.2972 20.8095C22.8495 21.0366 22.4147 21.2871 21.9943 21.5622L17.0071 19.5378L11.5801 28.9384L15.8275 32.2463C15.8003 32.7485 15.8003 33.2469 15.8275 33.7491L11.5801 37.0569L17.0071 46.4576L21.9943 44.4332C22.4147 44.7083 22.8495 44.9587 23.2972 45.1859L24.0382 50.5169H34.8923L35.6333 45.1859C36.081 44.9587 36.5157 44.7083 36.9362 44.4332L41.9233 46.4576L47.3503 37.0569L43.1029 33.7491C43.1302 33.2469 43.1302 32.7485 43.1029 32.2463L47.3503 28.9384L41.9233 19.5378ZM29.4652 38.8374C26.2456 38.8374 23.6255 36.2173 23.6255 32.9977C23.9468 25.2506 34.9848 25.2529 35.3049 32.9977C35.3049 36.2173 32.6848 38.8374 29.4652 38.8374Z"
				fill={fillColor}
			/>
			<path
				d="M58.9263 32.9985C59.1325 19.355 48.787 6.68907 35.4135 4.13867L34.6314 7.95253C62.6437 14.4267 61.2693 53.6389 33.098 58.3071L33.972 56.8792L30.6514 54.8467L27.0967 60.6539L31.3146 65.9988L34.3709 63.5869L33.2873 62.214C47.5566 60.6235 59.2078 47.4149 58.9263 32.9985Z"
				fill={fillColor}
			/>
			<path
				d="M9.73217 49.2561C-3.00791 34.018 6.41728 10.2983 25.8393 7.68832L24.9637 9.12256L28.2865 11.151L31.834 5.33959L27.6096 0L24.5565 2.41544L25.6385 3.7831C11.3714 5.37696 -0.276604 18.5823 0.00500096 32.998C-0.201336 46.6406 10.1442 59.3076 23.5176 61.8576L24.2997 58.0438C18.6191 56.8787 13.4456 53.7579 9.73217 49.2561Z"
				fill={fillColor}
			/>
		</svg>
	)
}

export const Expired = ({ fillColor = '#967FB899' }) => {
	return (
		<svg width="61" height="55" viewBox="0 0 61 55" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M46.1035 28.3299C45.7813 27.7659 45.2434 27.705 45.0269 27.7051C44.8105 27.7051 44.2726 27.766 43.9506 28.3299L29.7789 53.1456C29.4594 53.705 29.6756 54.1971 29.7834 54.3827C29.8911 54.5683 30.2113 54.9998 30.8553 54.9998H59.1986C59.8428 54.9998 60.1629 54.5682 60.2707 54.3827C60.3785 54.1971 60.5945 53.705 60.275 53.1456L46.1035 28.3299ZM43.2988 37.0094H46.8208V45.0185H43.2988V37.0094ZM45.0598 51.4161C44.0726 51.4161 43.3415 50.6092 43.2988 49.6551C43.2562 48.704 44.1387 47.8941 45.0598 47.8941C46.047 47.8941 46.778 48.701 46.8208 49.6551C46.8634 50.6062 45.9809 51.4161 45.0598 51.4161Z"
				fill={fillColor}
			/>
			<path
				d="M40.2313 27.8043C40.2912 27.4617 40.34 27.1156 40.3777 26.7675H36.8454V23.2455H40.3762C39.5592 15.7563 33.5946 9.79171 26.1053 8.97473V12.5056H22.5833V8.97461C15.0941 9.79171 9.1296 15.7562 8.3125 23.2454H11.8433V26.7674H8.31262C9.1296 34.2565 15.0942 40.2212 22.5834 41.0383V37.5074H26.1054V41.038C29.341 40.6838 32.4005 39.3587 34.8811 37.2161L40.2538 27.808L40.2313 27.8043ZM27.974 30.8201L22.5834 26.9019V16.0779H26.1054V25.1078L30.0449 27.9713L27.974 30.8201Z"
				fill={fillColor}
			/>
			<path
				d="M24.344 44.6565C13.5088 44.6565 4.69375 35.8414 4.69375 25.0061C4.69375 14.1708 13.5088 5.35586 24.344 5.35586C34.9471 5.35586 43.6141 13.7975 43.9808 24.3128C44.3373 24.2295 44.7073 24.1839 45.0869 24.1839C46.3323 24.1839 47.4802 24.6504 48.3469 25.4708C48.3498 25.3159 48.354 25.1612 48.354 25.0061C48.354 11.7669 37.5832 0.996094 24.3439 0.996094C11.1048 0.996094 0.333984 11.7669 0.333984 25.0061C0.333984 38.2453 11.1048 49.0162 24.344 49.0162C25.6818 49.0162 27.0197 48.905 28.3301 48.6864L31.3732 43.3576C29.149 44.2095 26.7696 44.6565 24.344 44.6565Z"
				fill={fillColor}
			/>
		</svg>
	)
}

export const InComplete = ({ fillColor = '#967FB899' }) => {
	return (
		<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28 0C12.6 0 0 12.6 0 28C0 43.4 12.6 56 28 56C43.4 56 56 43.4 56 28C56 12.6 43.4 0 28 0ZM28 5.6C31.08 5.6 33.32 8.12 33.04 11.2L30.8 33.6H25.2L22.96 11.2C22.68 8.12 24.92 5.6 28 5.6ZM28 50.4C24.92 50.4 22.4 47.88 22.4 44.8C22.4 41.72 24.92 39.2 28 39.2C31.08 39.2 33.6 41.72 33.6 44.8C33.6 47.88 31.08 50.4 28 50.4Z"
				fill={fillColor}
			/>
		</svg>
	)
}

export const Completed = ({ fillColor = '#967FB899' }) => {
	return (
		<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28 0C12.5609 0 0 12.5605 0 27.9999C0 43.4392 12.5609 56 28 56C43.4391 56 56 43.4392 56 27.9999C56 12.5605 43.4394 0 28 0ZM44.0781 23.2704L26.4871 40.8613C25.7392 41.6093 24.7449 42.021 23.6872 42.021C22.6295 42.021 21.6353 41.6093 20.8873 40.8613L11.9219 31.896C11.1739 31.148 10.762 30.1538 10.762 29.0961C10.762 28.0381 11.1739 27.0439 11.9219 26.2959C12.6696 25.5479 13.6639 25.136 14.7218 25.136C15.7795 25.136 16.7741 25.5479 17.5217 26.2962L23.6869 32.461L38.4777 17.6704C39.2257 16.9224 40.2199 16.5107 41.2776 16.5107C42.3353 16.5107 43.3295 16.9224 44.0775 17.6704C45.6219 19.2148 45.6219 21.7266 44.0781 23.2704Z"
				fill={fillColor}
			/>
		</svg>
	)
}
export const Failed = ({ fillColor = '#967FB899' }) => {
	return (
		<svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.89188 8.21993C12.9743 3.05134 19.7625 0.133566 27.005 0.00603202L27.0525 0.00517031C27.2016 0.0025851 27.3508 0 27.5 0C42.6638 0 55 12.3364 55 27.5C55 33.5225 53.0874 39.2418 49.47 44.0407C44.2194 51.0059 36.2119 55 27.5 55C12.3362 55 0 42.6636 0 27.5C0 20.2409 2.80251 13.3937 7.89188 8.21993ZM18.118 32.049C16.8108 33.3278 16.7892 35.4226 18.068 36.7281C18.7174 37.3899 19.5753 37.7226 20.4342 37.7226C21.2707 37.7226 22.1062 37.408 22.7512 36.7773L27.5 32.1309L32.2496 36.7773C32.8938 37.408 33.7302 37.7226 34.5658 37.7226C35.4255 37.7226 36.2835 37.3899 36.932 36.7281C38.2116 35.4226 38.1892 33.3269 36.8828 32.049L32.2332 27.5L36.8828 22.951C38.1892 21.6722 38.2116 19.5774 36.932 18.2719C35.6532 16.9655 33.556 16.944 32.2496 18.2219L27.5 22.8691L22.7512 18.2219C21.444 16.9431 19.3477 16.9655 18.068 18.2719C16.7892 19.5774 16.8108 21.6722 18.118 22.951L22.7668 27.5L18.118 32.049Z"
				fill={fillColor}
			/>
		</svg>
	)
}

export const NotStarted = ({ fillColor = '#967FB899' }) => {
	return (
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M67.0349 34.0303L11.9209 35.2713C11.4291 38.4663 11.4772 41.8258 12.1433 45.1501L67.2573 43.909C67.748 40.7207 67.7023 37.3611 67.0349 34.0303Z"
				fill={fillColor}
			/>
			<path
				d="M38.9586 11.5994C26.2553 11.8854 15.8525 20.5517 12.6383 31.9596L66.1685 30.7543C62.4491 19.5173 51.6772 11.313 38.9586 11.5994Z"
				fill={fillColor}
			/>
			<path
				d="M66.5397 47.2192L13.0094 48.4245C16.7289 59.6615 27.5007 67.8657 40.2195 67.5794C52.9229 67.2933 63.3256 58.6268 66.5397 47.2192Z"
				fill={fillColor}
			/>
		</svg>
	)
}

export const SeparatorImage = ({}) => {
	return (
		<svg width="1045" height="55" viewBox="0 0 1045 55" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				id="Ethereum_graph_selected"
				opacity="0.382417"
				d="M2 34.5415C87.2093 34.5415 70.8621 18.7122 156.906 18.7122C242.949 18.7122 261.93 53 347.42 53C432.909 53 437.218 2 522.408 2C607.597 2 611.818 38.5658 696.696 38.5658C781.574 38.5658 779.762 11.8546 866.21 11.8546C952.658 11.8546 955.295 38.5658 1043 38.5658"
				stroke="#B5B1FF"
				strokeWidth="3.51"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export const CloseIcon = ({}) => {
	return (
		<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M23.6762 17.8774L33.7989 7.75554C35.4006 6.15386 35.4006 3.55737 33.7989 1.95651C32.1989 0.355643 29.6007 0.355643 28.0007 1.95651L17.878 12.08L7.75534 1.95651C6.15529 0.355643 3.55717 0.355643 1.95712 1.95651C0.355438 3.55819 0.355438 6.15468 1.95712 7.75554L12.079 17.8774L1.95712 27.9993C0.355438 29.6009 0.355438 32.1974 1.95712 33.7983C2.75673 34.5995 3.8073 34.9989 4.85623 34.9989C5.90434 34.9989 6.95572 34.5995 7.75534 33.7983L17.878 23.6764L28.0007 33.7991C28.8011 34.6004 29.8509 34.9998 30.8998 34.9998C31.9487 34.9998 32.9993 34.6004 33.7989 33.7991C35.4006 32.1974 35.4006 29.6009 33.7989 28.0001L23.6762 17.8774Z"
				fill="#F55F44"
			/>
		</svg>
	)
}
export const CorrectIcon = ({}) => {
	return (
		<svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.9106 22.3434C14.1847 23.3287 13.0541 23.9343 11.8317 23.9945C10.6093 24.0553 9.42544 23.5627 8.60639 22.653L1.26653 14.5077C0.099992 13.214 0.203615 11.2197 1.49735 10.0532C2.79233 8.88667 4.78536 8.99091 5.9519 10.2853L11.1448 16.0484C11.2218 16.1341 11.3335 16.18 11.4483 16.1744C11.5637 16.1688 11.6692 16.1111 11.738 16.0193L22.4118 1.54246C23.445 0.140141 25.4194 -0.158318 26.8223 0.87543C28.224 1.90918 28.5219 3.8836 27.4881 5.2853L14.9106 22.3434Z"
				fill="#8DD070"
			/>
		</svg>
	)
}

export const BackIcon = ({}) => {
	return (
		<svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.287549 7.19117L5.96632 12.7008C6.61765 13.3327 7.71677 12.8982 7.71677 12.0096L7.71677 8.47476L21.9646 8.47476C23.0841 8.47476 24 7.58612 24 6.5C24 5.41388 23.0841 4.52524 21.9646 4.52524L7.71677 4.52524L7.71677 0.990412C7.71677 0.101769 6.61765 -0.33268 5.98668 0.299245L0.307905 5.80883C-0.099177 6.18404 -0.0991769 6.81596 0.287549 7.19117Z"
				fill="black"
			/>
		</svg>
	)
}
export const ForwardIcon = ({}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="13" viewBox="0 0 24 13">
			<g id="af7037b0-0f6c-4684-b84b-ba32eb3fc648" data-name="Layer 2">
				<g id="bc1ff7a7-3f3f-4309-8846-2af47c6b6d8d" data-name="Layer 1">
					<path
						fill="#FFF"
						d="M23.7125,5.8088,18.0337.2992a1.024,1.024,0,0,0-1.75.6912V4.5253H2.0354A2.0116,2.0116,0,0,0,0,6.5,2.0117,2.0117,0,0,0,2.0354,8.4748H16.2832V12.01a1.0115,1.0115,0,0,0,1.73.6912l5.6788-5.51A.9515.9515,0,0,0,23.7125,5.8088Z"
					/>
				</g>
			</g>
		</svg>
	)
}

export const NextIcon = ({}) => {
	return (
		<svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.5 2L10 12.1074L1.5 22.2149" stroke="#5AC0FC" strokeWidth="3" strokeLinecap="round" />
		</svg>
	)
}

export const PrevIcon = ({}) => {
	return (
		<svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.5 22.2168L2 12.1093L10.5 2.0019" stroke="#5AC0FC" strokeWidth="3" strokeLinecap="round" />
		</svg>
	)
}

export const ModalToggleIcon = ({}) => {
	return (
		<svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M24.2778 8.19141L7.88124 21.694" stroke="#9E9F9E" strokeWidth="3" strokeLinecap="round" />
			<path d="M23.7247 22.1809L8.43457 7.70117" stroke="#9E9F9E" strokeWidth="3" strokeLinecap="round" />
		</svg>
	)
}

export const DoubleNextIcon: React.FC<IconBaseProps> = ({ fill = '#5ac0fc' }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="39" height="31" viewBox="0 0 39 31">
			<g data-name="Group 1902">
				<g data-name="navigate back">
					<path fill="none" d="M0 0h31v31H0z" transform="rotate(180 19.5 15.5)" />
					<path
						data-name="↳Color"
						d="M9.669 1.842 7.829 0 0 7.84l7.829 7.84 1.84-1.842-5.976-6z"
						transform="rotate(180 14.273 11.58)"
						fill="#5ac0fc"
					/>
				</g>
				<g data-name="navigate back" style={{ opacity: '.5' }}>
					<path data-name="Boundary" fill="none" d="M0 0h31v31H0z" transform="rotate(180 15.5 15.5)" />
					<path
						data-name="↳Color"
						d="M9.669 1.842 7.829 0 0 7.84l7.829 7.84 1.84-1.842-5.976-6z"
						transform="rotate(180 10.273 11.58)"
						fill={fill}
					/>
				</g>
			</g>
		</svg>
	)
}

const IconCss = css`
	width: 18px;
	height: 18px;
	@media (min-width: 768px) {
		width: 32px;
		min-width: 32px;
		height: 32px;
	}
`

const BlackFilledIcon = styled.svg`
	${IconCss};
	fill: black;
`
export const OnlineClassroomsIcon = () => (
	<BlackFilledIcon
		id="bold"
		enable-background="new 0 0 24 24"
		height="512"
		viewBox="0 0 24 24"
		width="512"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="m12 6.997c-.103 0-.206-.021-.302-.063l-6.25-2.75c-.272-.121-.448-.389-.448-.687s.176-.566.448-.687l6.25-2.75c.192-.084.412-.084.604 0l6.25 2.75c.272.121.448.389.448.687s-.176.566-.448.687l-6.25 2.75c-.096.042-.199.063-.302.063z" />
		<path d="m12.906 8.307c-.291.127-.594.19-.906.19s-.615-.063-.902-.188l-4.098-1.804v.688c0 .61.319 1.184.832 1.495 1.441.875 2.805 1.312 4.168 1.312s2.727-.438 4.168-1.312c.513-.311.832-.884.832-1.495v-.688z" />
		<path d="m22.25 4h-1.3c-.16.88-.74 1.63-1.57 2h2.62v12h-20v-12h2.62c-.83-.37-1.41-1.12-1.57-2h-1.3c-.96 0-1.75.79-1.75 1.75v12.5c0 .96.79 1.75 1.75 1.75h8.25v2h-2c-.55 0-1 .45-1 1s.45 1 1 1h8c.55 0 1-.45 1-1s-.45-1-1-1h-2v-2h8.25c.96 0 1.75-.79 1.75-1.75v-12.5c0-.96-.79-1.75-1.75-1.75z" />
	</BlackFilledIcon>
)
