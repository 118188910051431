import { useAppSelector } from '@Hooks/ReduxHooks'
import React from 'react'
import GuestLanding from '../GuestLanding'
import StudentLanding from '../StudentLanding'

const LandingPage = () => {
	const { isAuth } = useAppSelector(state => state.auth)

	// return <GuestLanding/>
	return isAuth ? <StudentLanding /> : <GuestLanding />
}

export default LandingPage
