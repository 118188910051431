import { FlexLayout } from '@eduact/ed-system'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useGetClassroomAverageScoreQuery } from '../SingleClassroomSlice/SingleClassroomSlice'
import { Brogressbar } from './Progressbar'

const AverageScore = () => {
	const { id } = useParams()
	const [t] = useTranslation('classroomsOverview')
	const { data } = useGetClassroomAverageScoreQuery({
		classroom_id: Number(id) ?? skipToken,
	})

	const averageCore = useMemo(() => {
		if (data?.data?.average_score) {
			return (data?.data?.average_score * 100).toFixed(2)
		}
	}, [data?.data?.average_score])

	return (
		<Wrapper flexDirection="column">
			<Text>{t('averageScore')}</Text>
			<Brogressbar overallScore={Number(averageCore)} color="#6C63FF" />
		</Wrapper>
	)
}

const Wrapper = styled(FlexLayout)`
	border-radius: 10px;
	background: #fff;
	justify-content: start;
	align-items: center;
	height: 8.275rem;
	position: relative;
	padding-top: 0.531rem;
	width: 100%;
	${({ theme }) => `${theme.mediaQueries.large}{
		  border-radius: 15px;
		  height: 17.119rem;
		  padding-top: 1.313rem;
	}`}
	@media (min-width: 1201px) and (max-width: 1600px) {
		width: 14rem;
	}
	@media (min-width: 1601px) {
		width: 19.813rem;
	}
`
const Text = styled.p`
	font-size: 0.625rem;
	font-weight: 600;
	color: #251b33;
	margin-bottom: 0.2rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		  font-size: 1.125rem;
		  margin-bottom: 1.313rem;
	}`}
`

export default AverageScore
