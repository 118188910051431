const Governorates = [
	{
		id: '1',
		governorate_name_ar: 'القاهرة',
		name: 'Cairo',
	},
	{
		id: '2',
		governorate_name_ar: 'الجيزة',
		name: 'Giza',
	},
	{
		id: '3',
		governorate_name_ar: 'الأسكندرية',
		name: 'Alexandria',
	},
	{
		id: '4',
		governorate_name_ar: 'الدقهلية',
		name: 'Dakahlia',
	},
	{
		id: '5',
		governorate_name_ar: 'البحر الأحمر',
		name: 'Red Sea',
	},
	{
		id: '6',
		governorate_name_ar: 'البحيرة',
		name: 'Beheira',
	},
	{
		id: '7',
		governorate_name_ar: 'الفيوم',
		name: 'Fayoum',
	},
	{
		id: '8',
		governorate_name_ar: 'الغربية',
		name: 'Gharbiya',
	},
	{
		id: '9',
		governorate_name_ar: 'الإسماعلية',
		name: 'Ismailia',
	},
	{
		id: '10',
		governorate_name_ar: 'المنوفية',
		name: 'Menofia',
	},
	{
		id: '11',
		governorate_name_ar: 'المنيا',
		name: 'Minya',
	},
	{
		id: '12',
		governorate_name_ar: 'القليوبية',
		name: 'Qaliubiya',
	},
	{
		id: '13',
		governorate_name_ar: 'الوادي الجديد',
		name: 'New Valley',
	},
	{
		id: '14',
		governorate_name_ar: 'السويس',
		name: 'Suez',
	},
	{
		id: '15',
		governorate_name_ar: 'اسوان',
		name: 'Aswan',
	},
	{
		id: '16',
		governorate_name_ar: 'اسيوط',
		name: 'Assiut',
	},
	{
		id: '17',
		governorate_name_ar: 'بني سويف',
		name: 'Beni Suef',
	},
	{
		id: '18',
		governorate_name_ar: 'بورسعيد',
		name: 'Port Said',
	},
	{
		id: '19',
		governorate_name_ar: 'دمياط',
		name: 'Damietta',
	},
	{
		id: '20',
		governorate_name_ar: 'الشرقية',
		name: 'Sharkia',
	},
	{
		id: '21',
		governorate_name_ar: 'جنوب سيناء',
		name: 'South Sinai',
	},
	{
		id: '22',
		governorate_name_ar: 'كفر الشيخ',
		name: 'Kafr Al sheikh',
	},
	{
		id: '23',
		governorate_name_ar: 'مطروح',
		name: 'Matrouh',
	},
	{
		id: '24',
		governorate_name_ar: 'الأقصر',
		name: 'Luxor',
	},
	{
		id: '25',
		governorate_name_ar: 'قنا',
		name: 'Qena',
	},
	{
		id: '26',
		governorate_name_ar: 'شمال سيناء',
		name: 'North Sinai',
	},
	{
		id: '27',
		governorate_name_ar: 'سوهاج',
		name: 'Sohag',
	},
]

export default Governorates
