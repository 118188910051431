import { ScratchCardImage } from '@Components/Layout/Header/Assets/images'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { castDraft } from 'immer'
import { RaisedButton } from '@eduact/ed-components'
import { useProccedScratchCodeRequestMutation } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { WalletContext } from '../../../WalletContext'
import { FlexLayout } from '@eduact/ed-system'
import { useTranslation } from 'react-i18next'
const inputCount = 10
type Props = {}
const RechargeCodeTab = () => {
	const [proccedRequest] = useProccedScratchCodeRequestMutation()
	const [refArray, setRefArray] = useImmer<HTMLInputElement[]>(Array.from({ length: inputCount }))
	const { closeWallet, closed } = useContext(WalletContext)
	const [values, setValues] = useImmer<any[]>(Array(inputCount).join('.').split('.'))
	const [t] = useTranslation(['payment'], { keyPrefix: 'recharge_code' })
	const inputRefCallback = (element: HTMLInputElement | null, index: number) => {
		if (element) {
			setRefArray(draft => {
				draft[index] = castDraft(element)
			})
		}
	}

	const getValues = React.useMemo(() => {
		return values.join('')
	}, [values])

	const validation = React.useMemo(() => {
		const value = values.join('')
		return value && value.length === 10
	}, [values])

	const onKeyDown = React.useCallback(
		(event: any, index: number) => {
			const value = values[index]
			if (index !== 0 && event.keyCode === 8 && (value === '' || value === undefined)) {
				refArray[index - 1].focus()
			}
		},
		[refArray, values]
	)

	const onChangeHandler = React.useCallback(
		(event: any, index: number) => {
			const value = event.target.value.trim()
			setValues(draft => {
				draft[index] = value
			})
			if (index < 9 && value) {
				refArray[index + 1].focus()
			}
		},
		[refArray, setValues]
	)

	const sendWalletCode = async () => {
		try {
			await proccedRequest(getValues)
			refArray.forEach(_ => {
				_.value = ''
			})
			closeWallet?.()
			// result.then(value => {
			// 	refArray.forEach(_ => {
			// 		_.value = ''
			// 	})

			// })
		} catch (error) {}
	}

	return (
		<React.Fragment>
			<FlexLayout flexDirection={'column'} alignItems="center" width="100%">
				<Header>{t('enter_recharge_code')}</Header>
				<ContentWrapper>
					<Image src={ScratchCardImage} />
					<InputSection>
						{refArray.map((reference, index) => (
							<InputUnit
								key={`${reference?.id}-${index}`}
								maxLength={1}
								onKeyDown={event => onKeyDown(event, index)}
								value={values[index]}
								onChange={event => onChangeHandler(event, index)}
								ref={element => inputRefCallback(element, index)}
							/>
						))}
					</InputSection>
				</ContentWrapper>
				<RaisedButton width={'100%'} onClick={sendWalletCode} disabled={!validation}>
					{t('send')}
				</RaisedButton>
			</FlexLayout>
		</React.Fragment>
	)
}

export default RechargeCodeTab

export const Header = styled.h3`
	font-family: Montserrat, serif;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	/* color: black; */
`

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	/* margin-top: 50px; */
	margin: 1rem 0;
	padding: 0 16px;

	@media (max-width: 425px) {
		flex-direction: column;
	}
`

export const Image = styled.img`
	max-width: 177px;
	margin-bottom: 16px;
`

export const InputSection = styled.div`
	width: 350px;
	display: flex;
	direction: ltr;
	justify-content: space-between;
`

export const InputUnit = styled.input`
	width: 30px;
	height: 50px;
	border-radius: 5px;
	border: 1px solid #c4c4c4;
	font-family: Montserrat, serif;
	font-size: 16px;
	padding: 0 8px;

	&:focus {
		outline: none;
	}
`

export const SendButton = styled.button`
	background-color: ${props => (props.disabled ? 'gray' : '#5ac0fc')};
	margin-top: 34px;

	&:hover {
		cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
	}
`
