import { FlexLayout } from '@eduact/ed-system'
import { useStudentScreenSize } from '@eduact/student-theme'
import React from 'react'
import AttendanceRate from './AttendenceRate'
import AverageScore from './AverageScore'

const ClassroomProgress = () => {
	const { isDesktop } = useStudentScreenSize()
	return (
		<FlexLayout gridGap={isDesktop ? '1.5rem' : '0.494rem'} justifyContent="space-between" overflow="hidden">
			<AttendanceRate />
			<AverageScore />
		</FlexLayout>
	)
}

export default ClassroomProgress
