import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import qs from 'querystring'
import { TableHeader, TableSortBy } from './MainTable.types'
import addQueryString from '@Utilities/addQueryString'
import { Icon, Icons } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import styled from 'styled-components'
interface Props {
	head: string | TableHeader
	setSortBy: any
	sortBy: TableSortBy | null
	renderCustomHeads?: boolean
	customHead?: string
	canSort?: boolean
	showArrow?: boolean
}

enum SORTING {
	NONE,
	ASC,
	DESC,
}

const SortingHeader: React.FC<Props> = ({ head, setSortBy, sortBy, customHead, canSort = true, renderCustomHeads, showArrow = true }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const [sorting, setSorting] = useState<SORTING>(SORTING.NONE)

	const [params, setParams] = useSearchParams()
	const clickHandler = () => {
		if (!showArrow) return
		if (typeof head === 'object' && head.canSort === false) return
		let search = ''
		const _field = typeof head == 'object' ? head.fieldName : head
		search = addQueryString(location.search, 'field', _field as string)

		if (sortBy?.field !== _field) {
			setSorting(SORTING.ASC)
			setSortBy({ field: _field, direction: 'asc' })
			search = addQueryString(search, 'direction', 'asc')
			navigate({ search })
			return
		}

		if (sorting === SORTING.NONE) {
			setSorting(SORTING.ASC)
			setSortBy({ field: head, direction: 'asc' })
			params.set('direction', 'asc')
			// search = addQueryString(search, 'direction', 'asc')
		} else if (sorting === SORTING.ASC) {
			setSorting(SORTING.DESC)
			setSortBy({ field: _field, direction: 'desc' })
			params.set('direction', 'desc')
			// search = addQueryString(search, 'direction', 'desc')
		} else {
			setSorting(SORTING.NONE)
			setSortBy(null)
			params.delete('direction')
			params.delete('field')
			// search = addQueryString(search, 'direction', '')
			// search = addQueryString(search, 'field', '')
		}
		setParams(params)
		// navigate({ search })
	}

	const getHeader = () => {
		if (typeof head === 'object') return head.displayName
		return head
	}

	const getIcon = useMemo(() => {
		if (!canSort) return
		return (
			<SortIcon direction={sortBy?.direction} selected={typeof head === 'object' && head.fieldName === sortBy?.field} fontSize={10}>
				<Icons.ChevronMore />
			</SortIcon>
		)
	}, [canSort, head, sortBy])
	return head !== 'Actions' ? (
		<SortingItem onClick={clickHandler}>
			<FlexLayout alignItems="center" gridGap="0.5rem" justifyContent="center">
				{renderCustomHeads ? customHead : getHeader()}
				{showArrow && getIcon}
			</FlexLayout>
		</SortingItem>
	) : null
}
export default SortingHeader

const SortingItem = styled.span`
	cursor: pointer;
	font-size: 0.5rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size : 1rem;
	}`}
`

const SortIcon = styled(Icon)<{ selected: boolean; direction?: SortDirection }>`
	opacity: ${props => (props.selected ? '1' : '0')};
	transform-origin: center;
	transition: all ease-in-out 200ms;
	transform: ${props => {
		if (props.direction === 'asc') return 'rotate(90deg)'
		return ''
	}};
`
