import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout } from '@eduact/ed-system'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Icon from '@Components/Icons/Icon/Icon'
import { RaisedButton, TextButton } from '@eduact/ed-components'
import { useBookWebinarSlotMutation } from '../WebinarApi/WebinarUnitApi'
import Modal from '@Components/Utilities/Popup/Modal'
import { useTranslation } from 'react-i18next'
import { useCourseContext } from '@Features/Course/Providers/CourseProvider/CourseProvider'

type Props = {
	slot: Slot | undefined
	webinarId: number | undefined
}

const SlotCard: FC<Props> = ({ slot, webinarId }) => {
	const [t] = useTranslation('webinar')
	const [minute, setMinute] = useState<number | undefined>(0o0)
	const [bookSlotModel, setBookSlotModel] = useState<boolean>(false)
	const [bookSlot] = useBookWebinarSlotMutation()
	const { course, section } = useCourseContext()
	const BookSlot = async () => {
		bookSlot({
			webinar_id: webinarId as number,
			slot_id: slot?.id as number,
			course_id: course?.id,
			section_id: section?.id,
		})
	}

	useEffect(() => {
		setInterval(() => {
			const start = +new Date(slot?.start_time as any)
			const current = +new Date()
			const difference = current - start
			if (difference) {
				const minutes = Math.floor((difference / (1000 * 60)) % 60)
				setMinute(minutes)
			}
		}, 1000)
	}, [slot?.start_time, slot?.end_time])

	//disabled cards
	const currentTime = moment().format()
	const isPassed: boolean = currentTime >= moment(slot?.end_time).format()
	const isCurrent: boolean = currentTime >= moment(slot?.start_time).format() && currentTime < moment(slot?.end_time).format()

	const isFullyBooked = slot?.students_count === slot?.capacity

	useEffect(() => {
		window.location.href = '#scroll'
	}, [])

	return (
		<CardWrapper id={`${!isPassed && 'scroll'}`} background={!isPassed && isFullyBooked ? '#eaeafe' : '#fff'}>
			<ModelStyle modalOpen={bookSlotModel}>
				<Modal.Content
					minWidth={'fit-content'}
					minHeight={'fit-content'}
					onClose={() => {
						setBookSlotModel(false)
					}}
				>
					<FlexLayoutStyle justifyContent="space-between" alignItems="center" flexDirection="column">
						<p>{t('bookWebinar')}</p>
						<FlexLayout justifyContent="flex-end" alignItems="center" gridGap="0.9rem" paddingTop="2rem">
							<CancelButtonConfirm
								onClick={() => {
									setBookSlotModel(false)
								}}
							>
								{t('cancel')}
							</CancelButtonConfirm>
							<ConfirmButton
								onClick={() => {
									BookSlot()
								}}
							>
								{t('confirm')}
							</ConfirmButton>
						</FlexLayout>
					</FlexLayoutStyle>
				</Modal.Content>
			</ModelStyle>
			<div>
				<Title color={isPassed ? '#a0a0a0' : '#251b33'} opacity={isPassed ? 0.5 : 1}>
					{slot?.name}
				</Title>
			</div>
			<WrapperFlexLayout justifyContent="space-between" alignItems="center" width="100%">
				<TextFlexLayout>
					<Text color={isPassed ? '#a0a0a0' : '#251b33'} opacity={isPassed ? 0.5 : 1}>
						<Icon color="primary">event</Icon>
						<p>{moment(slot?.start_time).format('ddd. DD/MM')}</p>
					</Text>
					<Text color={isPassed ? '#a0a0a0' : '#251b33'} opacity={isPassed ? 0.5 : 1}>
						<IconStyle color="silver">schedule</IconStyle>
						<p>{moment(slot?.start_time).format('kk:mm a')}</p>
					</Text>
					<Text color={isPassed ? '#a0a0a0' : '#251b33'} opacity={isPassed ? 0.5 : 1}>
						<FlexLayout alignItems="flex-end">
							<Icon color="purple">hourglass_bottom</Icon>
							<p>{slot?.duration}</p>
							<Spacer mx="0.2rem" />
							<span>{t('min')}</span>
						</FlexLayout>
					</Text>
				</TextFlexLayout>

				<BtnsFlexLayout>
					<FlexLayout justifyContent="center" alignItems="center" flexDirection="column" gridGap="0.313rem">
						{!isFullyBooked && isCurrent && (
							<>
								<Live>
									<Icon>fiber_manual_record</Icon>
									{t('liveNow')}
								</Live>
								<Minute>
									{minute && minute > 1 ? minute : 0o0} {t('minsAgo')}
								</Minute>
							</>
						)}
					</FlexLayout>
					{isPassed ? (
						<Text color={isPassed ? '#a0a0a0' : '#251b33'} opacity={isPassed ? 0.5 : 0}>
							{t('passed')}
						</Text>
					) : isFullyBooked ? (
						<Booked>{t('fullyBooked')}</Booked>
					) : (
						<BookButton
							variant="primary"
							outlined={true}
							onClick={() => {
								setBookSlotModel(true)
							}}
						>
							{t('book')}
						</BookButton>
					)}
				</BtnsFlexLayout>
			</WrapperFlexLayout>
		</CardWrapper>
	)
}

const CardWrapper = styled.div<{ background: string }>`
	scroll-behavior: smooth;
	height: 5.563rem;
	border-radius: 15px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: ${({ background }) => background};
	padding: 0 1.031rem 0 2.031rem;
	margin-bottom: 0.75rem;
	display: flex;
	align-items: center;
	justify-items: flex-start;
	@media (max-width: 610px) {
		flex-direction: column;
		gap: 0.5rem;
		padding: 1.031rem 0 1.031rem 0;
		min-height: 9rem;
		border-radius: 15px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		margin-bottom: 0.75rem;
		align-items: center;
		justify-items: flex-start;
		justify-content: space-between;
	}
`
const BtnsFlexLayout = styled(FlexLayout)`
	justify-content: space-around;
	width: 100%;
	@media (max-width: 610px) {
		flex-direction: column;
		grid-gap: 0.625rem;
		align-items: end;
		justify-content: space-between;
	}
`
const WrapperFlexLayout = styled(FlexLayout)`
	@media (max-width: 610px) {
		padding: 0 1.75rem 0 0.875rem;
	}
`
const TextFlexLayout = styled(FlexLayout)`
	justify-content: space-around;
	align-items: start;
	width: 100%;
	@media (max-width: 610px) {
		justify-content: center;
		flex-direction: column;
		grid-gap: 0.625rem;
		justify-content: space-between;
	}
`
const Title = styled.div<{ color: string; opacity: number }>`
	font-size: 1rem;
	font-weight: 600;
	color: ${({ color }) => color};
	opacity: ${({ opacity }) => opacity};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	@media (max-width: 610px) {
		margin-bottom: 0.5rem;
		overflow: unset;
		font-size: 0.75rem;
	}
`
const Text = styled.div<{ color: string; opacity: number }>`
	font-weight: 600;
	color: ${({ color }) => color};
	opacity: ${({ opacity }) => opacity};
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.375rem;
	p {
		font-size: 0.8rem;
	}
	span {
		font-size: 0.8rem;
	}
	.material-icons {
		font-size: 18px;
	}
`
const IconStyle = styled(Icon)`
	opacity: 0.5;
`
const Booked = styled.div`
	color: #6c63ff;
	font-size: 1rem;
	font-weight: 600;
	@media (max-width: 610px) {
		font-size: 0.875rem;
	}
`
const BookButton = styled(RaisedButton)`
	width: 9.375rem;
	height: 3.25rem;
	@media (max-width: 610px) {
		width: 5.25rem;
		min-width: 5.25rem;
		height: 1.625rem;
		margin-right: -10px;
		font-size: 0.8rem;
	}
`
const Live = styled.div`
	color: #00d66b;
	font-size: 1rem;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 610px) {
		font-size: 0.625rem;
	}
`
const Minute = styled.div`
	color: #a0a0a0;
	font-size: 0.875rem;
	@media (max-width: 610px) {
		font-size: 0.438rem;
	}
`
const ModelStyle = styled(Modal)`
	display: flex;
	flex-direction: column;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	border-radius: 4px;
	box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
	margin: 32px;
	position: relative;
	overflow-y: auto;
`
const FlexLayoutStyle = styled(FlexLayout)`
	padding-left: 5rem;
	padding-right: 5rem;
	padding-top: 3rem;
	padding-bottom: 1rem;
	@media (max-width: 610px) {
		width: 300px;
		padding-left: 3.5rem;
		padding-right: 3.5rem;
		padding-top: 1rem;
	}
`
export const CancelButtonConfirm = styled(TextButton)`
	color: #ff8532;
	font-size: 1rem;
	font-weight: 600;
`
export const ConfirmButton = styled(TextButton)`
	color: #6c63ff;
	font-size: 1rem;
	font-weight: 600;
`
export default SlotCard
