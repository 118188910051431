import { MobileToggleButton } from '@Components/Button/MobileToggleButton'
import { ToggleButton } from '@Components/Button/ToggleButton'
import { BottomSheet, Icon, Icons } from '@eduact/ed-components'
import { FlexLayout, Typography } from '@eduact/ed-system'
import { useStudentScreenSize } from '@eduact/student-theme'
import { NumberFormatter } from '@eduact/utils'
import { useTest } from '@Features/Test/Providers/TestProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import DrawerContent from '../DrawerContent/DrawerContent'

const MobileDrawer = () => {
	const { bottomSheetOpened, openBottomSheet, questions } = useTest()
	const { isMobile } = useStudentScreenSize()
	const { t } = useTranslation(['test'])
	return (
		<>
			{isMobile && (
				<div>
					<BottomSheet
						onBackdropClick={() => openBottomSheet(false)}
						variant="cultured"
						headerVariant={'darkCultured'}
						maxWidth={'90%'}
						headerHeight={'44px'}
						maxHeight={'100vh'}
						expanded={bottomSheetOpened}
						onChange={value => openBottomSheet(value)}
						header={
							<DrawerHeader
								opened={bottomSheetOpened}
								onClick={() => {
									openBottomSheet(!bottomSheetOpened)
								}}
								width={'100%'}
								height="100%"
								alignItems="center"
								justifyContent={'center'}
							>
								<MobileToggleButton
									opened={bottomSheetOpened}
									onClick={() => {
										openBottomSheet(!bottomSheetOpened)
									}}
								/>
							</DrawerHeader>
						}
					>
						<FlexLayout display={'block'} minHeight={'40vh'}>
							<Typography fontSize={{ lg: '1.25rem' }} fontWeight={{ lg: '600' }}>
								{t('all_questions')} {NumberFormatter(questions.length)}
							</Typography>
							<DrawerContent />
						</FlexLayout>
					</BottomSheet>
				</div>
			)}
		</>
	)
}

export default MobileDrawer

const DrawerHeader = styled(FlexLayout)<{ opened: boolean }>`
	border-radius: inherit;
	transition: all ease-in-out 200ms;
	box-shadow: ${({ opened }) => !opened && `0px -4px 12px 0px #00000036`};
`
