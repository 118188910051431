import { OpenSnackbarOptions } from '@eduact/ed-components/Snackbar/SnackbarContext/SnackbarContext'
import { BsCheck2 } from 'react-icons/bs'

export type SnackbarStatus = 'success' | 'warning' | 'disclaimer' | 'light'

type ISnackbarConfig = { [key in SnackbarStatus]: OpenSnackbarOptions }

const BaseSnackbarConfig: OpenSnackbarOptions = {
	text: '',
	horizontal: 'right',
	vertical: 'top',
}

const WarningIcon = () => (
	<svg width="6" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3 .07c-.863 0-1.563.699-1.563 1.562v10.062a1.562 1.562 0 1 0 3.125 0V1.632C4.563.769 3.864.069 3 .069zM3 19.388a2.11 2.11 0 1 0 0-4.22 2.11 2.11 0 0 0 0 4.22z"
			fill="#fff"
		/>
	</svg>
)

const SnackbarConfig: ISnackbarConfig = {
	warning: { ...BaseSnackbarConfig, variant: 'princetonOrange', icon: <WarningIcon /> },
	disclaimer: { ...BaseSnackbarConfig, variant: 'princetonOrange', icon: <WarningIcon /> },
	success: { ...BaseSnackbarConfig, variant: 'primary', icon: <BsCheck2 size={'1.2rem'} /> },
	light: { ...BaseSnackbarConfig, variant: 'princetonOrange', icon: <WarningIcon /> },
}

export { SnackbarConfig }
