import { FlexLayout } from '@eduact/ed-system'
import { useStudentScreenSize } from '@eduact/student-theme'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'
// import { useGetClassroomsAnalyticsQuery } from './SingleClassroomSlice/SingleClassroomSlice'
import courses from '../../AnalyticsWidget/assets/courses.png'
import tests from '../../AnalyticsWidget/assets/tests.png'
import videos from '../../AnalyticsWidget/assets/videos.png'
import documents from '../../AnalyticsWidget/assets/documents.png'
import webinar from '../../AnalyticsWidget/assets/webinar.png'
import content from '../../AnalyticsWidget/assets/content.png'
import { useGetClassroomsAnalyticsQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'

const SingleClassroomAnalyticsWidget = () => {
	const { id } = useParams()
	const { isDesktop } = useStudentScreenSize()
	const [t] = useTranslation('classroomsOverview')
	const { currentData } = useGetClassroomsAnalyticsQuery({
		classroom_id: Number(id) ?? skipToken,
	})

	return (
		<>
			<Wrapper>
				<CompletedCourse alignItems="center" flexDirection="column">
					<Icon src={courses} alt="completed courses" size={isDesktop ? 12.8 : 6.35} />
					<Count fontSize={isDesktop ? 3.125 : 1.563} color="#6C63FF">
						{currentData?.data?.courses}
					</Count>
					<Text fontSize={isDesktop ? 1.125 : 0.75}>{t('completedCourse')}</Text>
				</CompletedCourse>
				<FlexLayout flex={1} flexDirection="column" gridGap={isDesktop ? '1.094rem' : '0.544rem'}>
					<WatchedUnits alignItems="center" flexDirection="column">
						<Icon src={tests} alt="tests taken" size={isDesktop ? 4.956 : 2.45} />
						<Count fontSize={isDesktop ? 1.5 : 0.813} color="#FF8532">
							{currentData?.data?.tests}
						</Count>
						<Text fontSize={isDesktop ? 0.875 : 0.5}>{t('testsTaken')}</Text>
					</WatchedUnits>
					<WatchedUnits alignItems="center" flexDirection="column">
						<Icon src={videos} alt="video watched" size={isDesktop ? 4.956 : 2.463} />
						<Count fontSize={isDesktop ? 1.5 : 0.813} color="#FF8532">
							{currentData?.data?.videos}
						</Count>
						<Text fontSize={isDesktop ? 0.875 : 0.5}>{t('videosWatched')}</Text>
					</WatchedUnits>
				</FlexLayout>
			</Wrapper>
			<FlexLayout gridGap={isDesktop ? '0.969rem' : '0.544rem'} justifyContent="space-between">
				<WatchedUnits alignItems="center" flexDirection="column" width="100%">
					<Icon src={content} alt="web content" size={isDesktop ? 4.956 : 2.463} />
					<Count fontSize={isDesktop ? 1.5 : 0.813} color="#FF8532">
						{currentData?.data?.webContents}
					</Count>
					<Text fontSize={isDesktop ? 0.875 : 0.5}>{t('webContent')}</Text>
				</WatchedUnits>
				<WatchedUnits alignItems="center" flexDirection="column" width="100%">
					<Icon src={webinar} alt="webinar attended" size={isDesktop ? 4.956 : 2.463} />
					<Count fontSize={isDesktop ? 1.5 : 0.813} color="#FF8532">
						{currentData?.data?.webinars}
					</Count>
					<Text fontSize={isDesktop ? 0.875 : 0.5}>{t('webinarAttended')}</Text>
				</WatchedUnits>
				<WatchedUnits alignItems="center" flexDirection="column" width="100%">
					<Icon src={documents} alt="documents read" size={isDesktop ? 4.956 : 2.463} />
					<Count fontSize={isDesktop ? 1.5 : 0.813} color="#FF8532">
						{currentData?.data?.documents}
					</Count>
					<Text fontSize={isDesktop ? 0.875 : 0.5}>{t('documentsRead')}</Text>
				</WatchedUnits>
			</FlexLayout>
		</>
	)
}
const Wrapper = styled(FlexLayout)`
	gap: 0.481rem;
	margin-bottom: -1rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		gap: 0.969rem;
	}`}
`
const CompletedCourse = styled(FlexLayout)`
	gap: 0.25rem;
	background: #fff;
	padding: 1.063rem 0;
	flex: 2;
	border-radius: 8px;
	${({ theme }) => `${theme.mediaQueries.large}{
		gap: 1.25rem;
		padding: 2.125rem 0;
		border-radius: 15px;
	}`}
`
const WatchedUnits = styled(FlexLayout)`
	gap: 0.5rem;
	background: #fff;
	padding: 0.475rem 0;
	border-radius: 5px;
	${({ theme }) => `${theme.mediaQueries.large}{
    gap: 0.75rem;
	padding: 1.05rem 0;
	border-radius: 15px;
	}`}
`
const Icon = styled.img<{ size: number }>`
	width: ${({ size }) => `${size}rem`};
	height: ${({ size }) => `${size}rem`};
	object-fit: contain;
	${({ theme, size }) => `${theme.mediaQueries.large}{
    width: ${(size: number) => `${size}rem`};
	  height: ${(size: number) => `${size}rem`};
	}`}
`
const Count = styled.p<{ color: string; fontSize: number }>`
	color: ${({ color }) => color};
	font-weight: 600;
	font-size: ${({ fontSize }) => `${fontSize}rem`};
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: ${(fontSize: number) => `${fontSize}rem`};
	}`}
`
const Text = styled.p<{ fontSize: number }>`
	color: #2f2e41;
	font-size: ${({ fontSize }) => `${fontSize}rem`};
	font-weight: 600;
	margin: auto;
	text-align: center;
	line-height: 1.6;
	max-width: 80px;
	white-space: pre-wrap;
	text-overflow: ellipsis;
	${({ theme, fontSize }) => `${theme.mediaQueries.large}{
    font-size: ${(fontSize: number) => `${fontSize}rem`};
    line-height: 1.3;
    max-width: fit-content;
	}`}
`

export default SingleClassroomAnalyticsWidget
