export interface BaseError {
	status: number
	data: {
		data: any
		filters: any
		message: string
		meta: any
		show: boolean
	}
}

export function isBaseError(value: unknown): value is BaseError {
	return typeof value === 'object' && value !== null && 'status' in value && 'data' in value
}
