import React from 'react'
import styled from 'styled-components'
import GuestCard from '@Features/LandingPage/GuestLanding/GuestCards/GuestCard'
import OnlineImage from './Assets/online-classrooms.svg'
import CenterImage from './Assets/center-classrooms.svg'
import RevisionImage from './Assets/revisions.svg'
import ExamImage from './Assets/exams.svg'
import { useTranslation } from 'react-i18next'

const GuestCards: React.FC = () => {
	const [t] = useTranslation('landingPage', {})

	return (
		<SectionWrapper>
			<SectionTitle>{t('courses.offer_variety')}</SectionTitle>
			<StyledGuestCards>
				<GuestCard icon={OnlineImage} title={t('features.online_classroom.title')} body={t('features.online_classroom.body')} />
				<GuestCard icon={CenterImage} title={t('features.center_classroom.title')} body={t('features.center_classroom.body')} />
				<GuestCard icon={RevisionImage} title={t('features.revisions.title')} body={t('features.revisions.body')} />
				<GuestCard icon={ExamImage} title={t('features.exams.title')} body={t('features.exams.body')} />
			</StyledGuestCards>
		</SectionWrapper>
	)
}

const StyledGuestCards = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	gap: 0.75rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		gap: 0.5rem;
		padding: 0 ;
		justify-content: space-between;
	}`}
	${({ theme }) => `${theme.mediaQueries.xlarge}{
		gap: 3.5rem;
		padding: 0 5.188rem;
		justify-content:  space-between;
	}`}
`
const SectionTitle = styled.h1`
	font-weight: bold;
	font-size: 0.75rem;
	margin-bottom: 1.688rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		margin-bottom: 3.813rem;
		font-size: 1.375rem;
	}`}
`
const SectionWrapper = styled.div`
	padding: 0 1.625rem;
	margin-bottom: 5rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding : 8.375rem 5rem;
	}`}
	${({ theme }) => `${theme.mediaQueries.xlarge}{
		padding: 8.375rem;
	}`}
`

export default GuestCards
