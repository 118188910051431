import PaymentModal from '@Components/Modals/PaymentModal/PaymentModal'
import PrerequisitesModal from '@Components/Modals/PrerequisitesModal/PrerequisitesModal'
import RegistrationFeesModal from '@Components/Modals/RegistrationFeesModal/RegistrationFeesModal'
import RaisedButton, { RaisedButtonProps } from '@eduact/ed-components/Buttons/RaisedButton/RaisedButton'
import { useCheckoutSingleCourseMutation } from '@Features/Classroom/ClassroomSlice/ClassroomSlice'
import { useClassroom } from '@Features/Classroom/Providers/ClassroomProvider/ClassroomProvider'
import { useLazyCheckRedirectionQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { useAppSelector } from '@Hooks/ReduxHooks'
import React, { useMemo, useState } from 'react'

type Props = RaisedButtonProps & {
	course: Course
}
const BuyCourseButton: React.FC<Props> = ({ children, course, ...props }) => {
	const { classroom } = useClassroom()
	const { redirect } = useAppSelector(state => state.auth)

	const [showFees, setShowFees] = useState(false)
	const [showPrerequisites, setShowPrerequisites] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleOnPaymentSuccess = () => {
		if (redirect && redirect === 104) {
			setShowFees(true)
		}

		setIsModalOpen(false)
	}

	const hasPrerequisites = useMemo(() => {
		return (course && course.prerequisites && Object.keys(course.prerequisites).length > 0) ?? false
	}, [course])
	const [checkFees] = useLazyCheckRedirectionQuery()
	const [checkout, checkoutData] = useCheckoutSingleCourseMutation()

	const isFree = useMemo(() => {
		return course?.price === 0
	}, [course])
	const handleBuyNow = async () => {
		if (isFree) await handleEnroll()
		else await handlePayment()
	}
	const handlePayment = async () => {
		const { data } = await checkFees({ classroom_id: classroom?.id })
		if (data && data.data.redirect && data.data.redirect === 104) {
			setShowFees(true)
			return
		}
		if (!hasPrerequisites) {
			setIsModalOpen(true)
		} else {
			setShowPrerequisites(true)
		}
	}
	const handleEnroll = async () => {
		const { data } = await checkFees({ classroom_id: classroom?.id })
		if (data && data.data.redirect && data.data.redirect === 104) {
			setShowFees(true)
			return
		}
		if (hasPrerequisites) {
			setShowPrerequisites(true)
		} else {
			checkout({ course_id: course.id, payment_method: 'wallet' })
		}
	}

	return (
		<>
			<RegistrationFeesModal persistent={false} open={showFees} onClose={() => setShowFees(false)} />
			<PrerequisitesModal onClose={() => setShowPrerequisites(false)} open={showPrerequisites} prerequisites={course.prerequisites} />

			<PaymentModal
				onSuccess={handleOnPaymentSuccess}
				paymentMethodsAllowed={course.payment_methods_allowed}
				showPaymentPopup={isModalOpen}
				setShowPaymentPopup={setIsModalOpen}
				courseId={course.id}
				price={course.price}
				classroomName={course.name}
			/>
			<RaisedButton onClick={handleBuyNow} outlined {...props}>
				{children}
			</RaisedButton>
		</>
	)
}

export default BuyCourseButton
