import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import AssignmentAnswer from './AssignmentAnswer'
import AssignmentContent from './AssignmentContent'

type Props = {
	assignment: AssignmentUnit
	submission: AssignmentSubmission
}
const Assignment: React.VoidFunctionComponent<Props> = ({ assignment, submission }) => {
	const [isEdit, setIsEdit] = useState(!submission)
	const canSubmitAnswer = useMemo(() => {
		const currentDate = moment(new Date())
		const deadlineDate = moment(assignment.created_at).add('d', assignment.duration)
		return currentDate.isBefore(deadlineDate)
	}, [assignment])

	useEffect(() => {
		setIsEdit(false)
	}, [assignment.id])

	return (
		<>
			<AssignmentContent
				canSubmit={canSubmitAnswer}
				assignment={assignment}
				submission={submission}
				onEditClick={() => setIsEdit(true)}
				isEdit={isEdit}
			/>
			<AssignmentAnswer
				onChooseType={setIsEdit}
				canSubmit={canSubmitAnswer}
				assignment={assignment}
				submission={submission}
				setIsEdit={setIsEdit}
				isEdit={isEdit}
			/>
		</>
	)
}

export default Assignment
