import { BaseApi } from '@Features/Base/BaseApi/BaseApi'
import { QuestionPayload } from '../Schema/Test.schema'

const TestApi = BaseApi.enhanceEndpoints({
	addTagTypes: ['GO_TO_COURSE', 'TEST'],
}).injectEndpoints({
	endpoints: build => ({
		startTest: build.query<ApiResponse<StartTestResponse>, StartTestPayload>({
			query: payload => ({ url: '/test/start', method: 'POST', body: payload }),
			keepUnusedDataFor: 0,
		}),
		submitQuestion: build.query<any, QuestionPayload>({
			query: payload => ({ url: '/test/question/submit', method: 'POST', body: payload }),
		}),
		submitTest: build.mutation<any, { attempt_id: number; course_id?: number; unit_id?: number; strict?: boolean }>({
			query: payload => ({ url: '/test/submit', method: 'POST', body: payload }),
			invalidatesTags: (result, error, args) => {
				return [
					{ type: 'GO_TO_COURSE', id: args.course_id },
					{ type: 'TEST', id: args.unit_id },
				]
			},
		}),
	}),
})

export const { useStartTestQuery, useLazySubmitQuestionQuery, useSubmitTestMutation } = TestApi
