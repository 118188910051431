import React, { FC } from 'react'
import styled from 'styled-components'

export interface TabListProps {
	children?: React.ReactNode
	label?: string
}

export const TabList: FC<TabListProps> = ({ children, label }) => {
	return (
		<StyledTab role="tablist" tabIndex={0} aria-label={label}>
			{children}
		</StyledTab>
	)
}

const StyledTab = styled.div<TabListProps>`
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	@media screen and (min-width: 769px) {
		margin-top: 2.75rem;
		flex-direction: column;
	}
`
