import React, { FC } from 'react'
import styled from 'styled-components'
import Circle from './Circle'
import Text from './Text'

type PieProps = {
	percentage: number
	color: string
}

const Pie: FC<PieProps> = ({ color, percentage }) => {
	return (
		<Svg>
			<G>
				<Circle colour="#b3b3b3" />
				<Circle colour={color} pct={percentage} />
			</G>
			<Text overallScore={percentage} colour={color} />
		</Svg>
	)
}

const Svg = styled.svg`
	overflow: visible;
	position: relative;
	transform: scale(0.7);
	width: 8rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		margin-top: 0.3rem;
		margin-bottom: 0.3rem;
		transform: scale(1.17);
	}`}
`
const G = styled.g`
	transform: rotate(-90deg);
	transform-origin: 40% 30%;
`

export default Pie
