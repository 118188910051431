import routes from '@Constants/Routes'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
type ICourseProvider = {
	course?: Course
	unit?: Unit
	tab?: Tab
	section?: TabSection
	classroomTabs: Tab[] | undefined
	selectedCourse?: string | null
	selectedUnit?: string | null
	selectedSection?: string | null
	selectedTab?: string | null
	classroomName?: string
	classroomLabel?: string
	currentUnits?: Unit[]
}

type ICourseContext = ICourseProvider & {
	enrolled: boolean
	coursePivot?: CourseEnrollmentPivot
	isExpired: boolean
	expiredAt?: Date
	hasPrerequisites: boolean
	isClosed: boolean
	isFree: boolean
	navigateToCourse: () => void
}
const CourseContext = createContext<ICourseContext | null>(null)

export const useCourseContext = () => {
	const context = useContext(CourseContext)
	if (!context) {
		throw new Error('No Course Provider found')
	}
	return { ...context }
}

export const CourseProvider: React.FC<ICourseProvider> = ({ children, ...props }) => {
	const navigate = useNavigate()
	const { course } = props

	const { isAuth } = useAppSelector(state => state.auth)
	const enrolled = useMemo(() => {
		if (!isAuth || !course?.enrolled || course.enrolled.length === 0 || !!course.enrolled?.[0].pivot.pivot_deleted_at) return false
		return true
	}, [course])
	const coursePivot = useMemo<CourseEnrollmentPivot | undefined>(() => {
		if (!enrolled) return
		return course?.enrolled[0].pivot
	}, [enrolled, course])

	const isExpired = useMemo(() => {
		if (!enrolled) return false
		return (coursePivot && coursePivot?.pivot_expired) ?? false
	}, [coursePivot, enrolled])

	const expiredAt = useMemo(() => {
		return coursePivot && coursePivot.pivot_expire_at
	}, [isExpired])

	const hasPrerequisites = useMemo(() => {
		return (course && course.prerequisites && Object.keys(course.prerequisites).length > 0) ?? false
	}, [course])

	const isClosed = useMemo(() => {
		return (course && course.active && !course.buyable) ?? false
	}, [course])

	const isFree = useMemo(() => {
		return course?.price === 0
	}, [course])

	const navigateToCourse = () => {
		if (props.classroomLabel && course) {
			const path = generatePath(`${routes.classroom}/${routes.course}`, {
				label: props.classroomLabel,
				cId: course.id.toString(),
			})
			navigate(path)
		}
	}

	return (
		<CourseContext.Provider
			value={{ ...props, enrolled, isExpired, coursePivot, expiredAt, hasPrerequisites, isClosed, isFree, navigateToCourse }}
		>
			{children}
		</CourseContext.Provider>
	)
}
