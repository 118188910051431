import {
	ActorRefFrom,
	assign,
	createMachine,
	EventObject,
	Interpreter,
	InterpreterFrom,
	Prop,
	State,
	StateFrom,
	StateMachine,
	interpret,
} from 'xstate'

import routes from '@Constants/Routes'
import { createContext, useContext, useEffect } from 'react'
import { useActor, useInterpret, useMachine } from '@xstate/react'
import { useLocation, useNavigate } from 'react-router'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { useSearchParams } from 'react-router-dom'

export type RedirectCode = 101 | 102 | 103 | 104
export const excludedRedirections = [104]
type Redirections = {
	[key in RedirectCode]?: string
}

export const redirections: Redirections = {
	101: `${routes.signup}/1`,
	103: `${routes.signup}/2`,
	104: `${routes.signup}/3`,
}

type MachineType = { nextRoute: string; pathname: string }
type MachineEventType = { type: string; redirect?: number; pathname?: string }
const checkRedirections = assign<MachineType, MachineEventType>({
	nextRoute: (context, event) => {
		if (event.redirect !== undefined && !excludedRedirections.includes(event.redirect)) {
			const next = redirections[event.redirect as RedirectCode]
			return next ?? event.pathname ?? '/'
		}
		console.log({  pathname: event.pathname })

		return event.pathname ?? '/'
	},
})
const routingMachine = createMachine(
	{
		id: 'navigation',
		initial: '/',
		on: {
			navigate: {
				target: 'CheckRedirections',
				actions: ['checkRedirections'],
			},
		},
		context: {
			nextRoute: '',
			pathname: '',
		},
		states: {
			'/': {
				on: {
					navigate: {
						target: 'CheckRedirections',
						actions: ['checkRedirections'],
					},
				},
			},
			CheckRedirections: {},
		},
	},
	{
		actions: { checkRedirections },
	}
)

type IRoutingContext = { state: StateFrom<typeof routingMachine>; send: Prop<InterpreterFrom<typeof routingMachine>, 'send'> }
const RoutingContext = createContext<IRoutingContext | null>(null)

export const RoutingProvider: React.FC = ({ children }) => {
	const [state, send, service] = useMachine(routingMachine)

	
	const navigate = useNavigate()
	const [params] = useSearchParams()
	const { pathname } = useLocation()
	const { isAuth, redirect } = useAppSelector(state => state.auth)

	useEffect(() => {
		service.start(routingMachine.initialState)
		service.onChange(({ nextRoute }) => {
			navigate({ pathname: nextRoute, search: params.toString() })
		})
	}, [])

	useEffect(() => {
		// if(redirect)
		service.send('navigate', { redirect, pathname })
	}, [redirect])

	return <RoutingContext.Provider value={{ state, send }}>{children}</RoutingContext.Provider>
}

export const useRoutingContext = () => {
	const context = useContext(RoutingContext)
	if (!context) {
		throw new Error('No RoutingProvider found !!!')
	}
	return { ...context }
}
