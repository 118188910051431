import Icon from '@Components/Icons/Icon/Icon'
import { useWidget } from '@eduact/ed-components'
import { Box, FlexLayout } from '@eduact/ed-system'
import { ContentConfig } from '@eduact/utils'
import { useGetLearningTimeQuery } from '@Features/Dashboard/Classrooms/Components/Tabs/SingleClassroomOverview/SingleClassroomSlice/SingleClassroomSlice'
import { useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { ResponsivePie } from '@nivo/pie'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LearningTimeImage from '../../Assets/Images/Learning_Time.png'

type Time = {
	[key: string]: number
}

const LearningTimeWidget = () => {
	const [t] = useTranslation('studentDashboard')
	const { setTitle } = useWidget()

	const { data } = useGetUserProfileQuery()
	const { data: learningData } = useGetLearningTimeQuery({
		user_id: Number(data?.data.student.user_id),
	})

	useEffect(() => {
		setTitle(t("learningTime"))
	}, [])

	const pieChartData = useMemo<PieChartNode[] | undefined>(() => {
		if (!learningData) return
		const data: ReportPieChartData = {
			webinar: {
				label: 'webinar',
				value: learningData?.data.webinar ?? 0,
				color: '#a0a2ff',
			},
			test: {
				label: 'test',
				value: learningData.data.test ?? 0,
				color: '#ffcead',
			},
			document: {
				label: 'document',
				value: learningData.data.document ?? 0,
				color: '#ffe79a',
			},
			video: {
				label: 'video',
				value: learningData.data.video ?? 0,
				color: 'rgba(90, 192, 252, 0.5)',
			},
			webcontent: {
				label: 'webcontent',
				value: learningData.data.webcontent ?? 0,
				color: '#6C63FF',
			},
			assignment: {
				label: 'assignment',
				value: learningData.data.assignment ?? 0,
				color: '#00ce67',
			},
		}
		return Object.values(data)
	}, [learningData])

	const hasData = useMemo(() => {
		return pieChartData?.reduce((p, c) => {
			return c.value + p
		}, 0)
	}, [pieChartData])

	const findTime = (num: number) => {
		if (num < 1) {
			return '0'
		}
		const qualifier = (num: number) => (num > 1 ? '' : '')
		const numToStr = (num: number, unit: string) => (num > 0 ? `${num}${unit} ${qualifier(num)}` : '')
		const oneMinute = 60
		const oneHour = oneMinute * 60
		const oneDay = oneHour * 24
		const oneYear = oneDay * 365
		const times: Time = {
			year: ~~(num / oneYear),
			day: ~~((num % oneYear) / oneDay),
			hour: ~~((num % oneDay) / oneHour),
			minute: ~~((num % oneHour) / oneMinute),
			second: num % oneMinute,
		}
		let str = ''
		for (let [key, value] of Object.entries(times)) {
			str += numToStr(times[key], key[0])
		}
		const arr = str.trim().split(' ')
		const res: Array<any> = []
		arr.forEach((x, i) => {
			res.push(x)
		})
		return res.slice(0, 3).join(' ')
	}

	return (
		<Wrapper flexDirection="row" alignItems="center">
			{!!(pieChartData && hasData && hasData > 0) ? (
				<Box height={'100%'} width="100%">
					<ResponsivePie
						data={pieChartData}
						innerRadius={0.7}
						padAngle={0.7}
						margin={{ left: 20, right: 20 }}
						activeOuterRadiusOffset={8}
						colors={{ datum: 'data.color' }}
						enableArcLinkLabels={false}
						isInteractive
						fit
						animate={false}
						motionConfig="slow"
						valueFormat={() => null as any}
						tooltip={(slice, children) => {
							const slicePoint = slice.datum.data as unknown as ReportPieChartData
							if (slicePoint) {
								return (
									<SliceContainer>
										{Object.entries(slicePoint).map(([key, value]) => {
											return (
												<SliceItemWrapper>
													<FlexLayout>
														{key === 'label' && <Icon>{ContentConfig[value as unknown as UnitType]?.mdIcon}</Icon>}
														{key === 'label' && <span>{value}</span>}

														{key === 'value' && <span> Time: {findTime(Number(value))}</span>}
													</FlexLayout>
												</SliceItemWrapper>
											)
										})}
									</SliceContainer>
								)
							} else {
								return <></>
							}
						}}
					/>
				</Box>
			) : (
				<img alt="learning time" src={LearningTimeImage} />
			)}
			<LegendWrapper>
				{pieChartData?.map(item => (
					<FlexLayout gridGap="0.5rem" alignItems="center" height="1rem	">
						<Dot color={item.color}>•</Dot>
						<LegendText color={item.color}>{item.label}</LegendText>
					</FlexLayout>
				))}
			</LegendWrapper>
		</Wrapper>
	)
}

const Wrapper = styled(FlexLayout)`
	background-color: #fff;
	border-radius: 5px;
	width: 100%;
	height: 250px;
	padding: 0.513rem 0.825rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 0.963rem 0.556rem;
    	border-radius: 15px;
    	min-height: 18.55rem;
		min-width: 18.938rem;
	}`}
`
const LegendWrapper = styled(FlexLayout)`
	flex-direction: column;
	button {
		border: 0;
		background: transparent;
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}
`
const Dot = styled.span<{ color: string | undefined }>`
	font-size: 2.5rem;
	color: ${({ color }) => color}; ;
`
const LegendText = styled.span<{ color: string | undefined }>`
	color: ${({ color }) => color};
	font-size: 0.7rem;
	font-weight: 600;
	margin-top: 5px;
`
const SliceContainer = styled(FlexLayout)`
	border: 2px solid ${props => props.theme.colors.primary};
	background: ${props => props.theme.colors.light};
	padding: 8px;
	border-radius: 10px;
	display: grid;
	row-gap: 10px;
`

const SliceItemWrapper = styled(FlexLayout)`
	font-size: 0.9rem;
	font-weight: 600;
	gap: 0.2rem;
	color: ${props => props.theme.colors.spanishGray};
	text-transform: capitalize;
	display: flex;
	align-items: center;
	column-gap: 5px;
	min-width: 7rem;
`
export default LearningTimeWidget
