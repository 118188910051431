import { BaseApi } from '@Features/Base/BaseApi/BaseApi'

const TestMcqUnitApi = BaseApi.enhanceEndpoints({
	addTagTypes: ['TEST', 'MCQ', 'GO_TO_COURSE', 'Section', 'TestSummary', 'TestAttempts'],
}).injectEndpoints({
	endpoints: build => ({
		getTestMcqUnit: build.query<MCQPayload, string>({
			query: id => `/test/${id}`,
			providesTags: (result, error, arg) => {
				const tags: Array<{ type: 'MCQ'; id: number }> = []
				if (result) {
					result.data.trials.completed?.forEach(_ => tags.push({ type: 'MCQ', id: _.id }))
				}
				return [...tags, { type: 'TEST', id: arg }]
			},
		}),
		getTestAttempts: build.query<TestAttepmtsDropdwonPayload, number>({
			query: test_id => `/test/${test_id}/attempts`,
		}),
		getTestSummary: build.query<AttemptPayload, AttemptId>({
			query: payload => ({
				url: '/test/summary',
				method: 'POST',
				body: {
					attempt_id: payload.attempt_id,
				},
			}),
		}),
	}),
})

export const { useGetTestMcqUnitQuery, useGetTestSummaryQuery, useGetTestAttemptsQuery } = TestMcqUnitApi
export default TestMcqUnitApi
