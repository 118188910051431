import { Icon, Icons, isEssayQuestion, RaisedButton } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout, Typography } from '@eduact/ed-system'
import { Color } from '@eduact/student-theme'
import { NumberFormatter } from '@eduact/utils'
import useSubmitTest from '@Features/Test/Hooks/useSubmitTest'
import { FormQuestion, useTest } from '@Features/Test/Providers/TestProvider'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { rgba } from 'polished'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import styled from 'styled-components'

const DrawerContent = () => {
	const { formQuestions, test, openBottomSheet, setFormQuestions } = useTest()

	const [searchParams, setSearchParams] = useSearchParams()
	const { t } = useTranslation(['test'])
	const getQuestionColor = (question: FormQuestion): Color => {
		if (question.id.toString() === searchParams.get('q')) {
			return 'primary'
		}
		if (isEssayQuestion(question)) {
			if (question.answer && question.answer.answer) return 'primary'
			else if (question.touched) return 'princetonOrange'
		}
		if (question.answer || question.id.toString() === searchParams.get('q')) return 'primary'
		if (!question.answer && !isEssayQuestion(question) && question.touched) return 'princetonOrange'
		return 'spanishGray'
	}
	const handleOnClick = (question: FormQuestion) => {
		if (!test.allow_movement && question.answer === null && !question.touched) return
		setFormQuestions(draft => {
			const questionIndex = draft.findIndex(_ => _.id === question.id)
			if (questionIndex > -1) draft[questionIndex].touched = true
		})
		searchParams.set('q', question.id.toString())
		setSearchParams(searchParams)
		const element = document.querySelector(`#question-${question.id}`)
		openBottomSheet(false)
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' })
		}
	}
	const submitTest = useSubmitTest()
	const [submitLoading, setSubmitLoading] = useState(false)
	const handleSubmitTest = async () => {
		setSubmitLoading(true)
		try {
			await submitTest()
			setSubmitLoading(false)
		} catch (error) {}
	}
	const { isOnline } = useAppSelector(state => state.settings)
	return (
		<>
			<FlexLayout flexDirection={'column'}>
				{formQuestions.map((question, index) => {
					return (
						<QuestionBanner
							onClick={() => handleOnClick(question)}
							px={{ sm: '2rem', lg: '2.5rem' }}
							py={{ sm: '1rem', lg: '0' }}
							alignItems="center"
							selected={question.id.toString() === searchParams.get('q')}
							key={`${question.id}-${question.test_id}-${index}`}
						>
							<QuestionBullet
								filled={
									(!!question.answer && !isEssayQuestion(question)) ||
									!!(isEssayQuestion(question) && question.answer && question.answer.answer)
								}
								color={getQuestionColor(question)}
							>
								{(!question.answer || (isEssayQuestion(question) && question.answer !== null && question.answer.answer === null)) &&
									question.touched &&
									question.id.toString() !== searchParams.get('q') && (
										<Icon size={'0.8rem'} color="princetonOrange">
											<Icons.Warning />
										</Icon>
									)}
							</QuestionBullet>
							<Spacer mx={'0.5rem'} />
							<QuestionTitle fontWeight={{ lg: '500' }}>
								{t('question')} {NumberFormatter(index + 1)}
							</QuestionTitle>
							<Spacer mb={{ lg: '2.5rem' }} />
						</QuestionBanner>
					)
				})}
			</FlexLayout>
			<FlexLayout mt="2rem" alignItems={'center'} justifyContent="center">
				<RaisedButton disabled={!isOnline} btnSize={'medium'} outlined bgFallback onClick={handleSubmitTest}>
					{submitLoading ? <PulseLoader size={5} /> : <>{t('submit')}</>}
				</RaisedButton>
			</FlexLayout>
		</>
	)
}

const QuestionBanner = styled(FlexLayout)<{ selected: boolean }>`
	background: ${props => props.selected && rgba(props.theme.colors.primary, 0.2)};
	cursor: pointer;
`
const QuestionTitle = styled(Typography)`
	color: #6e7577;
`
const QuestionBullet = styled.div<{ color: Color; filled: boolean }>`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 2px solid;
	font-size: 0.5rem;
	border-color: ${props => props.theme.colors[props.color]};
	display: grid;
	background: ${props => props.filled && props.theme.colors[props.color]};
	place-content: center;
	${({ theme, color, filled }) => `${theme.mediaQueries.large}{
    width :20px;
    height : 20px;
}`}
`

export default DrawerContent
