import { MainTableRequestArgs, TableHeader } from '@Components/MainTable/MainTable.types'
import routes from '@Constants/Routes'
import { useWidget } from '@eduact/ed-components'
import { useLazyGetWalletHistoryQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const TableHeaders: Array<TableHeader> = [
	{ displayName: 'Type', fieldName: 'type' },
	{ displayName: 'Before', fieldName: 'before', width: '10%' },
	{ displayName: 'Amount', fieldName: 'amount', width: '15%' },
	{ displayName: 'After', fieldName: 'after' },
	{ displayName: 'Date', fieldName: 'date' },
]

const WalletWidgetElement = () => {
	const { setTitle, setAction } = useWidget()
	const [getWalletHistory, { data }] = useLazyGetWalletHistoryQuery()

	const pageRequest = useCallback((args: MainTableRequestArgs) => {
		getWalletHistory({
			...args,
			perPage: 5,
		})
	}, [])

	useEffect(() => {
		pageRequest({
			filters: [],
			perPage: 5,
			from: '',
			to: '',
		})
	}, [])

	const DropdownList = () => {
		return <Anchor to={`${routes.dashboard}/activity/wallet-history`}>View All</Anchor>
	}
	useEffect(() => {
		setTitle('Wallet history')
		setAction(DropdownList)
	}, [])

	const renderType = (type: string) => {
		switch (type) {
			case 'purchase':
				return '#6C63FF'
			case 'system':
				return '#3F3D56'
			case 'recharge':
				return '#FF8532'
			case 'Refund':
				return '#c7c5f4'
			case 'registration':
				return '#5AC0FC'
			default:
				return
		}
	}
	return (
		<Wrapper>
			<Border />
			<Table>
				<StyledHead>
					<TableHead>
						{TableHeaders?.map((head, index) => {
							return <th key={typeof head === 'object' ? `${head.fieldName}-${index}` : `${head}-${index}`}>{head.displayName}</th>
						})}
					</TableHead>
				</StyledHead>
				<Tbody>
					{data?.data?.map((wall, index) => {
						return (
							<MainTableRow key={index}>
								{wall?.type ? (
									<TableCellStyle>
										<WalletType bg={renderType(wall?.type)}>{wall?.type}</WalletType>
									</TableCellStyle>
								) : wall?.invoice?.type ? (
									<TableCellStyle>
										<WalletType bg={renderType(wall.invoice?.type)}>{wall.invoice?.type}</WalletType>
									</TableCellStyle>
								) : (
									<TableCell>
										<WalletType bg={renderType('system')}>system</WalletType>
									</TableCell>
								)}
								<TableCell>{wall.old_amount}</TableCell>
								<TableCell>{wall.amount}</TableCell>
								<TableCell>{wall.new_amount}</TableCell>
								<TableCell>
									<Date>{moment(wall.created_at).format('DD/MM/yy')}</Date>
									<Time>{moment(wall.created_at).format('hh:mm A')}</Time>
								</TableCell>
							</MainTableRow>
						)
					})}
				</Tbody>
			</Table>
		</Wrapper>
	)
}
const Wrapper = styled.div`
	overflow-y: scroll;
	height: 18.95rem;
	@media screen and (min-width: 769px) {
		height: 19.95rem;
	}
	&::-webkit-scrollbar {
		width: 3px;
		@media screen and (min-width: 769px) {
			width: 6px;
		}
	}
	&::-webkit-scrollbar-track {
		background: #fff;
	}
	&::-webkit-scrollbar-thumb {
		background: #979797;
		border-radius: 3px;
	}
`
const Anchor = styled(Link)`
	font-size: 0.563rem;
	font-weight: 500;
	color: #2f2e41;
	@media screen and (min-width: 769px) {
		font-size: 0.875rem;
	}
`
const TableHead = styled.tr`
	width: 100%;
	font-size: 0.5rem;
	font-weight: normal;
	color: #b6b6b6;
	height: 1rem;
	display: table-row;
	vertical-align: middle;
	outline: 0px;
	@media screen and (min-width: 769px) {
		font-size: 0.75rem;
	}
`
const StyledHead = styled.thead`
	display: table-header-group;
	th {
		text-transform: capitalize;
	}
`
const Tbody = styled.tbody`
	display: table-row-group;
`
const Table = styled.table`
	display: table;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0px;
	margin-top: 1rem;
`
const Border = styled.div`
	height: 1px;
	background: #d3d3d3;
	margin-top: 1rem;
`
export const MainTableRow = styled.tr`
	padding: 1.063rem 1.688rem;
	width: 100%;
	display: table-row;
	vertical-align: middle;
	outline: 0px;
	height: 3.3rem;
`
export const TableCell = styled.td`
	font-size: 0.75rem;
	text-align: center;
	font-weight: 500;
	color: #251b33;
	margin: auto;
	vertical-align: middle;
	@media screen and (min-width: 769px) {
		font-size: 0.875rem;
	}
`
export const TableCellStyle = styled(TableCell)`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 50px;
	font-size: 0.575rem;
	@media screen and (min-width: 769px) {
		font-size: 0.875rem;
	}
`

export const WalletType = styled.div<{ bg: string | undefined }>`
	background: ${({ bg }) => bg};
	width: 3.2rem;
	height: 0.98rem;
	border-radius: 4px;
	padding: 1px 2px;
	margin: auto;
	color: #f9fafc;
	font-weight: 600;
	font-size: 0.6rem;
	line-height: 1.3;
	@media screen and (min-width: 769px) {
		font-size: 1rem;
		width: 6rem;
		height: 1.813rem;
		border-radius: 10px;
		padding: 5px 7px;
		line-height: 1;
	}
`
const Time = styled.p`
	font-size: 0.5rem;
	font-weight: 500;
	@media screen and (min-width: 769px) {
		font-size: 0.75rem;
	}
`
const Date = styled.p`
	font-size: 0.5rem;
	font-weight: normal;
	font-style: italic;
	@media screen and (min-width: 769px) {
		font-size: 0.75rem;
	}
`
export default WalletWidgetElement
