import { Box, FlexLayout, Typography } from '@eduact/ed-system'
import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useElementSize } from 'usehooks-ts'
import { uniqueId } from 'lodash'
import clip from './Assets/clip.png'
import logo from './Assets/calender_eduact_logo.svg'
import backgroundImage from './Assets/calender_background.png'
import { WidgetProps } from '@eduact/ed-components'
import { useTranslation } from 'react-i18next'
const CalenderWidget: React.FC<WidgetProps<any>> = () => {
	const [t] = useTranslation("studentDashboard")
	const [ref, { width }] = useElementSize()
	const clipWidth = 18
	const numberOfClips = useMemo(() => {
		return Math.floor(width / 18)
	}, [width])
	const arrayToN = useMemo(() => {
		return Array.from(Array(numberOfClips).keys())
	}, [numberOfClips])
	return (
		<CalenderContainer minHeight={{ sm: '10rem' }} width={'100%'} height={'100%'} ref={ref}>
			<ClipsContainer>
				{arrayToN.map((c, index) => (
					<Clip key={`${uniqueId('clip-')}-${index}`} />
				))}
			</ClipsContainer>
			<CalenderContent>
				<FlexLayout width={'100%'} justifyContent={'space-between'} alignItems="center">
					<Typography fontSize={{ sm: '1.125rem', lg: '2rem' }} fontWeight={600}>
						{t("calendar")}
					</Typography>
					<Box width={{ sm: 23, lg: 46 }}>
						<img src={logo} alt="eduact calender" />
					</Box>
				</FlexLayout>
				<Box px={{ lg: '1.25rem' }} my={{ sm: '15px', lg: '30px' }} maxWidth={'5ch'}>
					<Typography color={'#9b9b9b'} fontWeight={600} fontSize={{ sm: 12, lg: 24 }}>
						{t("comingSoon")}
					</Typography>
				</Box>
			</CalenderContent>
		</CalenderContainer>
	)
}

export default CalenderWidget

const CalenderContainer = styled(FlexLayout)`
	position: relative;
	padding: 2rem 1.375rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding : 2rem 2.375rem;
	}`}
`
const CalenderContent = styled.div`
	width: 100%;
	background: url(${backgroundImage});
	background-size: contain;
	background-position: right bottom;
	background-repeat: no-repeat;
	${({ theme }) => `${theme.mediaQueries.xlarge}{
		padding-bottom : 9rem;
		background-size: 90%;
	}`}
`
const ClipsContainer = styled.div`
	position: absolute;
	left: 0;
	grid-gap: 1.5rem;
	display: flex;
	overflow: auto;
	width: 90%;
	overflow: hidden;
	left: 50%;
	transform: translateX(-50%);
	top: -2%;
	${({ theme }) => `${theme.mediaQueries.large}{
	padding: 0 2.5rem;
	}`}
`

const Clip = styled.div`
	background: url(${clip});
	height: 18px;
	width: 12.5px;
	background-size: contain;
	background-repeat: no-repeat;
	flex-shrink: 0;
	${({ theme }) => `${theme.mediaQueries.large}{
		height: 26px;
		width: 18px;
	}`}
`
