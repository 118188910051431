import { TextInput, TextInputProps } from '@eduact/ed-components'
import React from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props<T extends FieldValues> = {
	control: Control<T>
	name: Path<T>
	localeName?: string
} & TextInputProps
const CustomTextInput = <T extends {}>({ control, name, placeholder, ref, localeName, ...props }: Props<T>) => {
	const [t] = useTranslation('inputs')

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, ref, ...others }, fieldState }) => {
				return (
					<TextInput
						error={fieldState.error ? true : false}
						helperText={fieldState.error ? t(`validations.${fieldState.error.message}`, { field: t(localeName ?? name) }) : ''}
						placeholder={t(`${placeholder}`)}
						value={value as any}
						{...others}
						{...props}
					/>
				)
			}}
		/>
	)
}

export default CustomTextInput
