import { Color, ResponsiveVal } from '@eduact/student-theme'
import React, { useRef, useState, useCallback } from 'react'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io'
import { GridProps, SpaceProps } from 'styled-system'
import { SwiperWrapper, SwiperSlider, SwiperInner, NavIcon, SwiperOuter, SwiperInnerItem } from './Swiper.styled'
import { useMediaQuery, useScreen } from 'usehooks-ts'
type ItemProps = {
	swipe?: string
}
const SwiperItem: React.FC<ItemProps> = ({ children }) => <div>{children}</div>
interface SwiperComposition {
	Item: React.FunctionComponent<ItemProps>
}
type Props = {
	withProgress?: boolean
	progressHeight?: number | string
	progressColor?: Color
	isIndicator?: boolean
	withArrows?: boolean
	arrowsColor?: Color
	perSlide?: ResponsiveVal<number>
	overlayArrows?: boolean
} & GridProps &
	SpaceProps
const Swiper: React.FC<Props> & SwiperComposition = ({
	children,
	withProgress = false,
	progressHeight,
	isIndicator = false,
	progressColor = 'primary',
	withArrows = true,
	arrowsColor = 'primary',
	perSlide = { sm: 1, lg: 4 },
	overlayArrows = false,
	...props
}) => {
	const SwiperSliderRef = useRef<HTMLDivElement>(null)
	const [progressPosition, setProgressPosition] = useState<number>(0)
	const [isScrollable, setIsScrollable] = useState<boolean>(false)
	const [childWidth, setChildWidth] = useState(0)
	const [gap, setGap] = useState(0)
	const screen = useScreen()
	const _ref = useCallback((node: HTMLDivElement) => {
		const getProgressPercent = () => {
			if (node && node.parentElement)
				return (node.parentElement.scrollLeft / (node.parentElement.scrollWidth - node.parentElement.clientWidth)) * 100
		}
		const handleScroll = (e: Event) => {
			if (node) {
				setProgressPosition(getProgressPercent() ?? 0)
			}
		}

		if (node && node.parentElement) {
			setIsScrollable(node.scrollWidth > node.clientWidth)
			const gap = window.getComputedStyle(node, null).getPropertyValue('grid-column-gap')
			if (gap) {
				setGap(parseFloat(gap.replace('px', '')))
			}
			node.parentElement.addEventListener('scroll', handleScroll)
		}
		return () => {
			if (node && node.parentElement) {
				node.parentElement.removeEventListener('scroll', handleScroll)
			}
		}
	}, [])
	const handleScroll = React.useCallback(
		(dir: 'left' | 'right') => {
			if (!SwiperSliderRef.current) return
			if (typeof perSlide === 'number') {
				const scrollOffset = SwiperSliderRef.current.clientWidth * (1 / perSlide)
				const offsetDir = dir === 'left' ? -1 * scrollOffset : scrollOffset
				SwiperSliderRef.current.scrollLeft += offsetDir
			} else {
				if (perSlide.sm) {
					const scrollOffset = childWidth * perSlide.sm + gap
					const offsetDir = dir === 'left' ? -1 * scrollOffset : scrollOffset
					SwiperSliderRef.current.scrollLeft += offsetDir
				}
			}
		},
		[childWidth, gap, perSlide]
	)

	const getChildWidth = useCallback((node: HTMLDivElement | null, index: number) => {
		if (index > 0) return
		if (node) {
			const width = node.getBoundingClientRect().width
			setChildWidth(width)
		}
	}, [])

	return (
		<SwiperWrapper>
			{isScrollable && withArrows && (
				<NavIcon
					show={!overlayArrows || (overlayArrows && progressPosition > 3)}
					left={'0'}
					position={overlayArrows ? 'absolute' : 'relative'}
					color={arrowsColor}
					onClick={() => handleScroll('left')}
				>
					<IoIosArrowDropleftCircle />
				</NavIcon>
			)}
			<SwiperOuter>
				<SwiperSlider isScrollable={isScrollable} ref={SwiperSliderRef} {...props}>
					<SwiperInner {...props} draggable="false" ref={_ref}>
						{React.Children.map(children, (child, index) => (
							<SwiperInnerItem ref={e => getChildWidth(e, index)} key={`slider-innner-${index}`}>
								{child}
							</SwiperInnerItem>
						))}
					</SwiperInner>
				</SwiperSlider>
			</SwiperOuter>
			{isScrollable && withArrows && (
				<NavIcon
					show={!overlayArrows || (overlayArrows && progressPosition < 98)}
					right={'0'}
					position={overlayArrows ? 'absolute' : 'relative'}
					color={arrowsColor}
					onClick={() => handleScroll('right')}
				>
					<IoIosArrowDroprightCircle />
				</NavIcon>
			)}
		</SwiperWrapper>
	)
}

Swiper.Item = SwiperItem

export default Swiper
