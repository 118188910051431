import React, { useMemo } from 'react'
import GradeIcon from '@Components/Cards/ClassroomCard/Grade.icon'
import { Color } from '@eduact/student-theme'
import { SpaceProps } from 'styled-system'
import {
	StyledClassroomCard,
	StyledClassroomImage,
	Badge,
	CardBody,
	Title,
	Instructor,
	InfoWrapper,
	GradeWrapper,
	Grade,
	SubjectWrapper,
	SubjectImage,
	Subject,
	CardButton,
} from './ClassroomCard.styled'
import { LayoutProps } from 'styled-system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@Hooks/ReduxHooks'

export type ClassroomCardProps = {
	title: string
	instructor: string
	badgeLabel: string
	grade: string
	subject: string
	thumbnail: string | null
	subjectImage: string | null
	buttonBackgroundColor?: Color
	classroom: Classroom
	onClick?: React.MouseEventHandler
} & LayoutProps &
	SpaceProps

const ClassroomCard: React.FC<ClassroomCardProps> = ({
	buttonBackgroundColor,
	instructor,
	title,
	badgeLabel,
	grade,
	subject,
	thumbnail,
	subjectImage,
	classroom,
	onClick,
	...props
}) => {
	const history = useNavigate()
	const { isAuth } = useAppSelector(state => state.auth)
	const [t] = useTranslation('educational_info')

	const label = (label: string) => {
		switch (label) {
			case 'online':
				return <Badge>{t('online')}</Badge>
			default:
				return <Badge>{t('center')}</Badge>
		}
	}

	const enrolled = useMemo(() => {
		if (!isAuth) return false
		return classroom.enrolled && classroom.enrolled.length > 0
	}, [isAuth, classroom])

	const closed = useMemo(() => {
		return classroom.status === 'closed'
	}, [classroom])

	const soon = useMemo(() => {
		return classroom.status === 'soon'
	}, [classroom])

	const accessible = useMemo(() => {
		return classroom.accessible
	}, [classroom])

	const getActionButtonColor = (): Color => {
		if (closed) return 'silver'
		if (soon) return 'purpleNavy'
		if (enrolled) return 'purple'
		else return 'primary'
	}

	const getLabel = (): string => {
		if (closed) return 'closed'
		if (soon) return 'soon'
		return 'view'
	}

	return (
		<StyledClassroomCard
			onClick={e => {
				if (!accessible) return
				if (!onClick) {
					e.stopPropagation()
					history(`/classroom/${classroom.label}`)
				} else {
					onClick(e)
				}
			}}
			{...props}
		>
			<StyledClassroomImage src={thumbnail ?? ''} />
			{label(badgeLabel)}
			<CardBody>
				<Title>{title}</Title>
				<Instructor>
					{classroom.instructor.user.first_name} {classroom.instructor.user.last_name}
				</Instructor>
				<InfoWrapper>
					<GradeWrapper>
						<GradeIcon />
						<Grade>{t(`academic_year.${grade}`)}</Grade>
					</GradeWrapper>
					<SubjectWrapper>
						<SubjectImage src={subjectImage ?? ''} />
						<Subject>{subject}</Subject>
					</SubjectWrapper>
				</InfoWrapper>
			</CardBody>
			<CardButton variant={getActionButtonColor()}>{t(getLabel())}</CardButton>
		</StyledClassroomCard>
	)
}

export default ClassroomCard
