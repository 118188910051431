import { DoubleNextIcon } from '@Features/Progress/Assets/Icons'
import React from 'react'
import styled from 'styled-components'

type Props = {
	opened: boolean
	onClick: () => void
}
const ToggleButton: React.FC<Props> = ({ onClick, opened }) => {
	return (
		<StyledToggleButton opened={opened} onClick={onClick}>
			<DoubleNextIcon />
		</StyledToggleButton>
	)
}

export default ToggleButton

const StyledToggleButton = styled.div.attrs(p => ({ dir: document.documentElement.dir }))<{ opened: boolean }>`
	cursor: pointer;
	width: fit-content;
	transition: all ease-in-out 200ms;
	/* position: absolute; */
	height: fit-content;

	transform: ${props => props.opened && 'rotate(0deg)'};
`
