import styled from 'styled-components'

export const SectionTitle = styled.div`
	display: flex;
	margin-bottom: 1rem;
	align-items: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		margin-bottom: 3.75rem;
	}`}
	label {
		font-weight: 700;
		margin: 0 0.3rem;
		${({ theme }) => `${theme.mediaQueries.large}{
			margin: 0 1rem;
			font-size: 1.5rem;			
		}`}
	}
	svg {
		height: auto;
		max-width: 20px;
		${({ theme }) => `${theme.mediaQueries.large}{
			max-width: 24px;
		}`}
	}
`

export const SectionWrapper = styled.div`
	margin: 5.25rem 0;
`
