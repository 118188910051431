import routes from '@Constants/Routes'
import { useSnackbar } from '@eduact/ed-components'
import { useAppDispatch, useAppSelector } from '@Hooks/ReduxHooks'
import { resetRunningTest } from '@Redux/RunningTestSlice/RunningTestSlice'
import Encryption from '@Utilities/Encryption'
import { SnackbarConfig } from '@Utilities/SnackbarConfig'
import React, { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { useLazySubmitQuestionQuery, useSubmitTestMutation } from '../Api/TestApi'

import { useTest } from '../Providers/TestProvider'

const useSubmitTest = () => {
	const { attempt, formQuestions, test } = useTest()

	const [submitTest] = useSubmitTestMutation()
	const navigate = useNavigate()
	const { openSnackbar } = useSnackbar()
	const { testData } = useAppSelector(state => state.RunningTest)
	const dispatch = useAppDispatch()
	const [submitQuestion] = useLazySubmitQuestionQuery()
	return useCallback(
		async (withValidation: boolean = true, strict: boolean = true) => {
			const _submitTest = async () => {
				await submitTest({ attempt_id: attempt.id, course_id: testData?.course_id, unit_id: testData?.unit_id, strict })
			}
			if (withValidation && formQuestions.find(_ => _.answer === null)) {
				openSnackbar({ ...SnackbarConfig.warning, text: 'Answer all questions to submit' })
				return
			}
			if (test.view_mode === 'single' && formQuestions.find(_ => _.submitted === false && _.answer !== null)) {
				const unsumbittedQuestions = formQuestions.filter(_ => _.submitted === false && _.answer !== null)
				for (let index = 0; index < unsumbittedQuestions.length; index++) {
					const question = unsumbittedQuestions[index]
					await submitQuestion({
						attempt_id: attempt.id,
						id: question.id,
						options: question.answer,
						question_id: question.id,
						type: question.type,
					})
				}
			}
			try {
				await Promise.all(
					formQuestions
						.filter(_ => _.answer !== null)
						.map(async question => {
							await submitQuestion({
								attempt_id: attempt.id,
								id: question.id,
								options: question.answer,
								question_id: question.id,
								type: question.type,
							})
						})
				)
			} catch (error) {}
			await _submitTest()

			if (testData) {
				const path = generatePath(`${routes.classroom}/${routes.course}/${routes.mcqUnit}`, {
					label: testData.classroomLabel,
					cId: testData.course_id.toString(),
					id: testData.unit_id.toString(),
				})
				dispatch(resetRunningTest())
				navigate(path)
			} else {
				navigate(routes.home)
			}
		},
		[formQuestions]
	)
}

export default useSubmitTest
