import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Header, Footer } from '@Components/Layout'
import routes from '@Constants/Routes'
import { matchPath, Outlet, Route, useSearchParams } from 'react-router-dom'
import PublicOnlyRoute from '@Components/CustomRoutes/PublicOnlyRoute'
// import ForgotPassword from '@Features/Authentication/ForgotPassword'
import { useLocation, Routes } from 'react-router-dom'
import { useAppSelector } from '@Hooks/ReduxHooks'
// import Course from '@Features/Course/Pages/Course/Course'
// import Webinar from '@Features/Unit/Webinar/Webinar'
import { LayoutProvider } from '@Providers/LayoutProvider/LayoutProvider'
import ErrorBoundaryFallback from '@Components/ErrorBoundaryFallback/ErrorBoundaryFallback'
import { ErrorBoundary } from 'react-error-boundary'
import NotFoundPage from '@Features/Others/NotFoundPage/NotFoundPage'

import loadable from '@loadable/component'
import LandingPage from '@Features/LandingPage/LandingPage/LandingPage'
import Test from '@Features/Test'
import McqTest from '@Features/Unit/TestMcqUnit/McqTest'
import Whatsapp from '@Components/Whatsapp/Whatsapp'
import Dashboard from '@Features/Dashboard'
import { Invoices } from '@Features/Dashboard/Activity/Invoices'
import Overview from '@Features/Dashboard/Overview'
import ClassroomsOverview from '@Features/Dashboard/Classrooms'
import SingleClassroomOverview from '@Features/Dashboard/Classrooms/Components/Tabs/SingleClassroomOverview'
import SingleClassroomLayout from '@Features/Dashboard/Classrooms/Components/Tabs/SingleClassroomOverview/SingleClassroomLayout/SingleClassroomLayout'
import Activity from '@Features/Dashboard/Activity'
import Profile from '@Features/Dashboard/Profile'
import Tests from '@Features/Dashboard/Classrooms/SingleClassroomOverview/Tests'
import Attendance from '@Features/Dashboard/Classrooms/SingleClassroomOverview/Attendance'

import { setAuthData } from '@Features/Authentication/AuthenticationSlice/Authentication.slice'
import { useDispatch } from 'react-redux'
import Settings from '@Features/Dashboard/Settings'
import AssignmentUnit from '@Features/Unit/AssignmentUnit'
const ForgotPassword = loadable(() => import('@Features/Authentication/ForgotPassword'))
const ResetPassword = loadable(() => import('@Features/Authentication/ResetPassword'))
const WalletHistory = loadable(() => import('@Features/Dashboard/WalletHistory'))
const TimelineEvents = loadable(() => import('@Features/Dashboard/TimelineEvents'))
const SearchResults = loadable(() => import('@Features/LandingPage/SearchResults'))
const Instructor = loadable(() => import('@Features/Instructor'))
const FAQS = loadable(() => import('@Features/Others/FAQs/FAQs'))
const Login = loadable(() => import('@Features/Authentication/Login'))
const About = loadable(() => import('@Features/About'))
const SignUp = loadable(() => import('@Features/Authentication/SignUp'))
const PaymentCallback = loadable(() => import('@Features/Others/PaymentCallback'))
const Classroom = loadable(() => import('@Features/Classroom'))
const ClassroomOverview = loadable(() => import('@Features/Classroom/Pages/ClassroomOverview'))
const Course = loadable(() => import('@Features/Course'))
const Webinar = loadable(() => import('@Features/Unit/Webinar'))
const DocumentUnit = loadable(() => import('@Features/Unit'), { resolveComponent: module => module.DocumentUnit })
const VideoUnit = loadable(() => import('@Features/Unit'), { resolveComponent: module => module.VideoUnit })
const WebContentUnit = loadable(() => import('@Features/Unit'), { resolveComponent: module => module.WebContentUnit })
const Webinars = loadable(() => import('@Features/Dashboard/Classrooms/SingleClassroomOverview/Webinars'))

type LayoutProps = EmbedBaseProps
const Layout: React.FC<EmbedBaseProps> = ({ embed }) => {
	const { pathname } = useLocation()
	const [showWallet, setShowWallet] = useState(true)
	const [showFooter, setShowFooter] = useState(true)
	const [fixedHeader, setFixedHeader] = useState(true)
	const dispatch = useDispatch()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])
	const { isAuth } = useAppSelector(_ => _.auth)

	const [params] = useSearchParams()
	useEffect(() => {
		if (embed) {
			const studentData = params.get('studentData')
			if (studentData) {
				const authData = JSON.parse(studentData) as AuthUserData
				dispatch(setAuthData(authData))
			}
		}
	}, [])
	const { about_us_page } = useAppSelector(s => s.settings)
	return (
		<StyledLayoutContainer>
			<LayoutProvider
				embed={!!embed}
				fixedHeader={fixedHeader}
				setFixedHeader={setFixedHeader}
				setShowFooter={setShowFooter}
				showFooter={showFooter}
				setShowWallet={setShowWallet}
				showWallet={showWallet}
			>
				<ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={() => window.location.reload()}>
					<Header />

					<StyledLayoutContent fixedHeader={fixedHeader}>
						<Routes>
							<Route element={<Outlet />}>
								<Route path={routes.forgotPassword} element={<ForgotPassword />} />
								<Route path={routes.resetPassword} element={<ResetPassword />} />

								<Route path={routes.wallet} element={<WalletHistory />} />
								<Route path={routes.timeline} element={<TimelineEvents />} />
								<Route path={routes.invoices} element={<Invoices />} />
								<Route path={routes.results} element={<SearchResults />} />
								<Route path={routes.paymentCallback} element={<PaymentCallback />} />
								<Route path={routes.instructor} element={<Instructor />} />
								<Route path={routes.faqs} element={<FAQS />} />
								{about_us_page && <Route path={routes.about} element={<About />} />}
								<Route path={routes.login} element={<Login />} />
								{/* <PublicOnlyRoute validator={!isAuth} path={routes.login}>
										</PublicOnlyRoute> */}

								<Route path={`${routes.signup}/*`} element={<SignUp />} />
								<Route path={routes.dashboard} element={<Dashboard />}>
									<Route index element={<Overview />} />
									<Route path={`${routes.dashboard}/profile`} element={<Profile />} />
									<Route path={`${routes.dashboard}/activity`} element={<Outlet />}>
										<Route index element={<Activity />} />
										<Route path={`timeline-events`} element={<TimelineEvents />} />
										<Route path={'invoices'} element={<Invoices />} />
										<Route path={`wallet-history`} element={<WalletHistory />} />
									</Route>
									<Route path={`${routes.dashboard}/classrooms`} element={<Outlet />}>
										<Route index element={<ClassroomsOverview />} />
										<Route path=":id" element={<SingleClassroomLayout />}>
											<Route index element={<SingleClassroomOverview />} />
											<Route path={'attendance'} element={<Attendance />} />
											<Route path={'webinars'} element={<Webinars />} />
											<Route path={'tests'} element={<Tests />} />
										</Route>
									</Route>
									<Route path={`${routes.dashboard}/settings`} element={<Settings />} />
								</Route>
							</Route>

							<Route path={routes.home} element={<LandingPage />} />
							<Route path={routes.classroom} element={<Classroom />}>
								<Route index element={<ClassroomOverview />} />
								<Route path={routes.course} element={<Course />}>
									<Route path={routes.documentUnit} element={<DocumentUnit />} />
									<Route path={routes.videoUnit} element={<VideoUnit />} />
									<Route path={routes.webContentUit} element={<WebContentUnit />} />
									<Route path={routes.webinarUnit} element={<Webinar />} />
									<Route path={routes.mcqUnit} element={<McqTest />} />
									<Route path={routes.assignmentUnit} element={<AssignmentUnit />} />
								</Route>
							</Route>
							<Route path={routes.test} element={<Test />} />
							<Route path="*" element={<NotFoundPage />} />
						</Routes>
					</StyledLayoutContent>
				</ErrorBoundary>

				{showFooter && <Footer />}
			</LayoutProvider>
		</StyledLayoutContainer>
	)
}

const StyledLayoutContainer = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`

const StyledLayoutContent = styled.div<{ fixedHeader: boolean }>`
	margin-top: 4.938rem;
	flex: 1;
	display: flex;
`

export default Layout
