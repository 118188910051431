import React, { useCallback, useEffect } from 'react'
import { useAppSelector } from './ReduxHooks'

type Args = {
	auto?: boolean
}

export const useBrowserTabInit = ({ auto = true }: Args) => {
	const { assets, meta } = useAppSelector(s => s.settings)
	useEffect(() => {
		if (auto) updateFavicon()
	}, [assets.favicon])

	useEffect(() => {
		if (auto) updateDocumentTitle()
	}, [meta?.title])

	const updateFavicon = useCallback(() => {
		let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
		if (!link) {
			link = document.createElement('link')
			link.rel = 'icon'
			document.getElementsByTagName('head')[0].appendChild(link)
		}

		link.href = assets.favicon
		// link.href = assets.favicon ?? '/favicon.ico'
	}, [assets.favicon])

	const updateDocumentTitle = useCallback(() => {
		document.title = meta?.title ?? ''
	}, [meta?.title])

	const resetAppMeta = () => {
		updateFavicon()
		updateDocumentTitle()
	}

	return { resetAppMeta }
}
