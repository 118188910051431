import api from '@Api/Api'
import { AxiosError } from 'axios'
import LocalStorageKeys from '@Constants/LocalStorageKeys'
import { useSnackbar } from '@eduact/ed-components'
import { isApiResponse } from '@Features/Base/BaseApi/BaseApi'
import { isBaseError } from '@Features/Base/Error/BaseError'
import { SnackbarConfig } from '@Utilities/SnackbarConfig'
import React, { useEffect } from 'react'
import { useAppDispatch } from '@Hooks/ReduxHooks'
import { logoutAction, setAuthData } from '@Features/Authentication/AuthenticationSlice/Authentication.slice'
import { useNavigate } from 'react-router'
import routes from '@Constants/Routes'

const ApiProvider: React.FC = ({ children }) => {
	const { openSnackbar } = useSnackbar()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	useEffect(() => {
		api.interceptors.request.use(
			function (config) {
				try {
					const AUTH = localStorage.getItem(LocalStorageKeys.AUTH)
					config.headers = {
						'accept-language': localStorage.getItem('lang') ?? 'en-GB',
					}

					if (!AUTH) return config
					const PARSED_AUTH = JSON.parse(AUTH) as UserAuthKey
					if (AUTH) {
						return {
							...config,
							headers: {
								...config.headers,
								Authorization: `Bearer ${PARSED_AUTH.token}`,
							},
						}
					}

					return config
				} catch (error) {
					return Promise.reject(error)
				}
			},
			function (error) {
				return Promise.reject(error.response)
			}
		)
		api.interceptors.response.use(
			function (response) {
				const { data } = response

				if (isApiResponse(data) && data.show) {
					openSnackbar({ ...SnackbarConfig.success, text: data.message })
				}

				return Promise.resolve(response)
			},
			function (error) {
				const { response } = error

				if (isBaseError(response) && response.data.show) {
					openSnackbar({
						...SnackbarConfig.warning,
						text: response.data.message,
					})
				}
				return Promise.reject(error.response)
			}
		)
	}, [])
	return <>{children}</>
}

export default ApiProvider
