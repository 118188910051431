import ViewAllButton from '@Components/Button/ViewAllButton/ViewAllButton'
import ClassroomCard from '@Components/Cards/ClassroomCard'
import SkeletonCard from '@Components/Utilities/Skeleton/Components/SkeletonCard'
import SkeletonCarousel from '@Components/Utilities/Skeleton/Components/SkeletonCarousel'
import { Swiper } from '@Components/Utilities/Swiper'
import { FlexLayout } from '@eduact/ed-system'
import { useGetUserClassroomsQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { SectionWrapper } from '@Features/LandingPage/Assets/LandingPage.styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SectionTitle } from '../Sections.styled'

const MyClassroomsSection: React.FC = () => {
	const { data, isLoading, isFetching } = useGetUserClassroomsQuery()
	const history = useNavigate()
	const [t] = useTranslation('landingPage', { keyPrefix: 'courses' })
	return (
		<SkeletonCarousel my={'5.25rem'} isLoading={isLoading || isFetching} numberOfItems={4} renderComponent={<SkeletonCard />}>
			<SectionWrapper my={{ sm: '2.8rem', lg: '5.25rem' }}>
				{data && data.data.length > 0 && (
					<div>
						<SectionTitle>
							<label>{t('myclassroom_classrooms')}</label>
						</SectionTitle>
						<Swiper padding={'1rem'} gridGap={'2rem'}>
							{data.data.map((cl, index) => {
								return (
									<ClassroomCard
										classroom={cl}
										badgeLabel={cl.type}
										title={cl.title}
										grade={cl.educationYears.length > 0 ? cl.educationYears[0].name : ''}
										subject={cl.category.name}
										subjectImage={cl.category.icon}
										thumbnail={cl.thumbnail}
										instructor={cl.instructor.user.first_name}
										buttonBackgroundColor="purple"
										onClick={() => {
											history(`/classroom/${cl.label}`)
										}}
										key={`${cl.id}-${index}`}
									/>
								)
							})}
						</Swiper>
					</div>
				)}
			</SectionWrapper>
		</SkeletonCarousel>
	)
}

export default MyClassroomsSection
