import routes from '@Constants/Routes'
import { useStudentScreenSize } from '@eduact/student-theme'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import ResponsiveLayout from './DashboardLayout/ResponsiveLayout'
import SideMenuNavigation from './DashboardLayout/SideMenuNavigation'

const Dashboard = () => {
	const { pathname } = useLocation()

	const dashboardTabValue = useMemo(() => {
		const dashboardPaths = pathname
			.trim()
			.split(routes.dashboard)
			.join('')
			.split('/')
			.filter(_ => _ !== '')
		console.log(dashboardPaths)
		const value = dashboardPaths[0]
		if (dashboardPaths.length === 0) return '/'
		return `/${value}`
	}, [pathname])

	const { isDesktop, isMobile, isTablet } = useStudentScreenSize()
	return (
		<StyledDashboardLayout>
			{isDesktop && <SideMenuNavigation currentTab={dashboardTabValue} />}
			{(isMobile || isTablet) && <ResponsiveLayout currentTab={dashboardTabValue} />}
		</StyledDashboardLayout>
	)
}
export default Dashboard

const StyledDashboardLayout = styled.div`
	width: 100%;
	display: flex;
	height: 100%;
	min-height: 100%;
	flex-direction: column;
	${({ theme }) => `${theme.mediaQueries.large}{
		flex-direction: row;
        height : unset;
		// max-height: 86vh;
		// max-height: 100vh;
		overflow : auto;
    }`}
`
