import { FlexLayout } from '@eduact/ed-system'
import moment from 'moment'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Title } from './StartTest'
import edEn from '../Assets/review_eddie.svg'
import i18next from 'i18next'

type Props = {
	data: Test | undefined
	opened: Opened | undefined
	testInfo: StartTestData | undefined
	completed: Completed[] | undefined
	attempt_id: number | undefined
}

const ReviewEndPage: FC<Props> = ({ data, completed }) => {
	const [t] = useTranslation('test')
	const { dir } = i18next

	return (
		<>
			<CardWrapper>
				<FlexLayout alignItems="center" justifyContent="space-between" width="100%">
					<Title>{data?.unit?.name ?? ''}</Title>
				</FlexLayout>
				<InfoContainer>
					<DateWrapper>
						<Text>{t('testDate')}:</Text>
						<Date>{moment(completed?.[0].created_at).format('DD/MM/YY hh:mm A')}</Date>
					</DateWrapper>
					<MessageWrapper>
						{dir() === 'rtl' && <EddieImg src={edEn} alt="eddie" />}
						<Message maxWidth="23.694rem" maxHeight="16.506rem" minWidth="unset">
							<MsgText>{t('eddieMsg')}</MsgText>
						</Message>
						{dir() === 'ltr' && <EddieImg src={edEn} alt="eddie" />}
					</MessageWrapper>
					{data?.allowed_trials && (
						<AttemptText>
							{t('attemptNum')}: {completed?.length}
						</AttemptText>
					)}
				</InfoContainer>
			</CardWrapper>
		</>
	)
}

const CardWrapper = styled(FlexLayout)`
	scroll-behavior: smooth;
	min-height: 8.563rem;
	border-radius: 15px;
	padding: 46.6px 98.7px 45px 54.8px;
	background-color: #fff;
	margin-bottom: 0.75rem;
	flex-direction: column;
	align-items: start;
	justify-items: flex-start;
	position: relative;
	gap: 1rem;
	overflow: hidden;

	@media (min-width: 610px) and (max-width: 1440px) {
		padding: 15px 50px 15px 15px;
	}
	@media (max-width: 610px) {
		gap: 0.5rem;
		padding: 11.8px 25.4px 17.4px 18.7px;
		min-height: 9rem;
		border-radius: 10px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		margin-bottom: 0.75rem;
		justify-items: flex-start;
		justify-content: space-between;
		overflow: unset;
	}
`
const InfoContainer = styled(FlexLayout)`
	width: 100%;
	flex-direction: column;
	align-items: start;
	justify-items: flex-start;
	z-index: 1;
	@media (max-width: 610px) {
		align-items: center;
		padding: 0;
		z-index: 0;
	}
`
const MessageWrapper = styled(FlexLayout)`
	margin: auto;
	margin-top: 5.313rem;
	@media (max-width: 768px) {
		margin: auto;
		margin-top: 1.5rem;
	}
	@media (max-width: 500px) {
		margin: auto;
		margin-top: 1.5rem;
	}
`
export const EddieImg = styled.img`
	max-width: 214.5px !important;
	object-fit: contain;
	margin-block-start: 2rem;
	margin-inline-start: 2rem;
	@media (max-width: 768px) {
		max-width: 140.6px !important;
		margin-block-start: 1rem;
		margin-inline-start: 1rem;
	}
	@media (max-width: 500px) {
		max-width: 80px !important;
		margin-block-start: 0.5rem;
		margin-inline-start: 0.5rem;
	}
`
const DateWrapper = styled(FlexLayout)`
	gap: 1.5rem;
	justify-content: center;
	width: 100%;
	@media (max-width: 610px) {
		flex-direction: column;
		align-items: center;
		justify-content: start;
		margin-bottom: 0;
		gap: 0;
	}
`
const Text = styled.p`
	font-size: 1.75rem;
	font-weight: 600;
	@media (max-width: 610px) {
		font-size: 1rem;
		line-height: 1.38;
	}
`
const Date = styled.p`
	font-size: 1.75rem;
	font-weight: 500;
	@media (max-width: 610px) {
		font-size: 1rem;
		line-height: 1.38;
	}
`
const AttemptText = styled.p`
	font-size: 1.75rem;
	font-weight: 600;
	margin-bottom: 2rem;
  margin-top: 2.5rem;
	@media (max-width: 610px) {
		font-size: 0.75rem;
		margin-bottom: 0.75rem;
		margin-top: 1.5rem;
	}
	@media (max-width: 768px) {
		font-size: 1.75rem;
		margin-bottom: 0.75rem;
		margin-top: 1.5rem;
	}
`
const MsgText = styled.p`
	font-size: 1.25rem;
	font-weight: normal;
	color: #000;
	@media (max-width: 610px) {
		font-size: 0.65rem;
	}
`
export const Message = styled.div<{
	maxWidth: string
	minWidth: string
	maxHeight: string
}>`
	position: relative;
	word-break: break-word;
	overflow: hidden;
	width: ${({ maxWidth }) => maxWidth};
	min-width: ${({ minWidth }) => minWidth};
	height: ${({ maxHeight }) => maxHeight};
	padding: 1rem;
	line-height: 1.26;
	white-space: pre-wrap;
	background-color: #f8f8fe;
	border-radius: 50%;
	color: #6e7577;
	font-size: 0.799rem;
	font-weight: 500;
	text-align: center;
	z-index: 999;
	margin-top: -20px;
	display: flex;
	align-items: center;
	object-fit: contain;
	justify-content: center;

	@media (max-width: 1024px) {
		margin-top: -20px;
	}
	@media (max-width: 768px) {
		width: 19.813rem;
		height: 16.938rem;
		font-size: 0.6rem;
		line-height: 1.25;
		letter-spacing: 0.24px;
		padding: 12px;
		margin-top: -8px;
		color: #000;
		z-index: 0;
	}
	@media (max-width: 500px) {
		width: 9.813rem;
		height: 8.938rem;
		padding: 14px;
		font-size: 0.5rem;
		font-weight: normal;
		line-height: 1.25;
		letter-spacing: 0.24px;
		margin-top: 0px;
		z-index: 0;
	}
`
export default ReviewEndPage
