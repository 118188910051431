import { TestAnswer } from '@eduact/ed-components'
import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'

type Props = {
	data: Attempt | undefined
}

const Answers: FC<Props> = ({ data }) => {
	const [t] = useTranslation('test')
	const renderType = (type: string) => {
		switch (type) {
			case 'mcq':
				return t('mcq')
			case 'gap':
				return t('gap')
			case 'ordering':
				return t('ordering')
			case 'essay':
				return t('Essay')
			default:
				return
		}
	}

	return (
		<div>
			{data?.attempt.questions.map((question, index) => {
				return (
					<TestAnswer
						type={renderType(question.type)}
						questionNum={t('questionNum')}
						points={t('points')}
						status={data.attempt.status}
						question={question}
						test={data?.attempt.test}
						index={index}
						notAnswered={t('notAnswered')}
					/>
				)
			})}
		</div>
	)
}
export default Answers
