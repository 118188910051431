import { BaseApi } from '@Features/Base/BaseApi/BaseApi'

const UnitApi = BaseApi.enhanceEndpoints({
	addTagTypes: [
		'VideoUnit',
		'DocumentUnit',
		'WebContentUnit',
		'GO_TO_COURSE',
		'Section',
		'CLASSROOM',
		'Unit',
		'LEARNING_TIME',
		'Assignment',
	],
}).injectEndpoints({
	endpoints: build => ({
		getVideoUnit: build.query<VideoUnitResponsePayload, GetVideoUnitRequestPayload>({
			query: id => `/video/play/${id}`,
			providesTags: (result, error, arg) => [{ type: 'VideoUnit', id: arg }],
		}),
		getDocumentUnit: build.query<DocumentUnitResponsePayload, DocumentUnitRequestPayload>({
			query: id => `/document/${id}`,
			providesTags: (result, error, arg) => [{ type: 'DocumentUnit', id: arg }],
		}),
		getWebContentUnit: build.query<WebContentUnitResponsePayload, WebContentUnitRequestPayload>({
			query: id => `/web-content/${id}`,
			providesTags: (result, error, arg) => [{ type: 'WebContentUnit', id: arg }],
		}),
		getAssignmentUnit: build.query<AssignmentUnitResponse, number>({
			query: id => `/assignment/${id}`,
			providesTags: (r, e, arg) => [{ type: 'Assignment', id: r?.data.assignment.id ?? arg }],
		}),
		submitAssignment: build.mutation<any, SubmitAssignmentPayload>({
			query: payload => ({ url: `/assignment/submit`, method: 'POST', body: payload }),
			invalidatesTags: (r, e, arg) => [
				{ type: 'Assignment', id: arg.assignment_id },
				{ type: 'Section', id: Number(arg.section_id) },
				{ type: 'GO_TO_COURSE', id: Number(arg.course_id) },
			],
		}),
		updateUnitProgress: build.mutation<any, UnitProgressPayload>({
			query: payload => ({
				url: '/progress/unit/update',
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: 'GO_TO_COURSE', id: args.course_id }, { type: 'Section', id: args.section_id }, 'CLASSROOM']
			},
		}),
		getUnit: build.query<UnitResponsePayload, UnitRequestPayload>({
			query: id => `/unit/${id}`,
			providesTags: (result, error, arg) => [{ type: 'Unit', id: arg }],
		}),
		updateUnitLearningTime: build.mutation<any, LearningTimePayload>({
			query: payload => ({ url: '/progress/unit/time/update', method: 'POST', body: payload }),
			invalidatesTags: ['LEARNING_TIME'],
		}),
	}),
})

export const {
	useGetVideoUnitQuery,
	useGetDocumentUnitQuery,
	useGetWebContentUnitQuery,
	useUpdateUnitProgressMutation,
	useGetUnitQuery,
	useUpdateUnitLearningTimeMutation,
	useGetAssignmentUnitQuery,
	useSubmitAssignmentMutation,
} = UnitApi
export default UnitApi
