import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { layout, LayoutProps, textAlign, TextAlignProps, variant } from 'styled-system'
import { MainTableProps, TableHeader, TableProps, TableSortBy } from './MainTable.types'
import SortingHeader from './SortingHeader'

const MainTable: React.VoidFunctionComponent<MainTableProps> = ({
	renderCustomHeads,
	customHeads,
	tableHeads,
	title,
	renderItems = () => {},
	hasHeader = true,
	hasSort = true,
	innerRef,
	headerAction,
	striped = true,
	curved = true,
	...props
}) => {
	const [sortBy, setSortBy] = useState<TableSortBy | null>(null)

	const renderTableHeads = () => {
		return (
			<TableHead>
				{tableHeads?.map((head: string | TableHeader, index) => (
					<th key={typeof head === 'object' ? `${head.fieldName}-${index}` : `${head}-${index}`}>
						<SortingHeader
							renderCustomHeads={renderCustomHeads}
							customHead={customHeads?.[index]}
							head={head}
							setSortBy={setSortBy}
							sortBy={sortBy}
							showArrow={hasSort}
							canSort={typeof head === 'object' ? head.canSort : true}
						/>
					</th>
				))}
			</TableHead>
		)
	}

	return (
		<>
			<TableContainer
				curved={curved}
				ref={e => {
					if (innerRef) innerRef.current = e
				}}
			>
				{title && (
					<TableInfoHeader>
						<Title>{title}</Title>
						{headerAction?.()}
					</TableInfoHeader>
				)}
				<Table {...props} aria-label="content table">
					{hasHeader && (
						<colgroup>
							{tableHeads?.map((theader: TableHeader | string, index: number) => {
								return typeof theader === 'object' ? (
									<col width={theader.width ?? ''} key={`${theader.fieldName}-${index}`} />
								) : (
									<col key={`${theader}-${index}`} />
								)
							})}
						</colgroup>
					)}
					{hasHeader && <StyledHead>{renderTableHeads()}</StyledHead>}
					<Tbody>{renderItems()}</Tbody>
				</Table>
				{/* </InfiniteScroll> */}
				{/* </div> */}
			</TableContainer>
		</>
	)
}

const StyledHead = styled.thead`
	display: table-header-group;
	th {
		text-transform: capitalize;
		&:first-child {
			padding-inline-start: 1rem;
		}
		@media screen and (min-width: 769px) {
			&:first-child {
				padding-inline-start: unset;
			}
		}
	}
`
const Tbody = styled.tbody`
	display: table-row-group;
`
const TableContainer = styled.div<{ curved: boolean }>`
	background-color: #fff;
	width: 100%;
	border-radius: ${props => props.curved && '15px'};
	overflow: auto;

	::-webkit-scrollbar {
		appearance: none;
		width: 5px;
	}
	::-webkit-scrollbar-track {
		border-radius: 10px;
		height: auto !important;
		background: ${props => props.theme.colors.silver};
	}
	::-webkit-scrollbar-button {
		border-radius: 10px;
		background: ${props => props.theme.colors.red};
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${props => props.theme.colors.spanishGray};
	}
`
export const MainTableRow = styled.tr.attrs(p => ({ striped: true }))<{ striped?: boolean }>`
	height: 2.875rem;
	padding: 1.063rem 1.688rem;
	width: 100%;
	display: table-row;
	vertical-align: middle;
	outline: 0px;
`
const Table = styled.table<TableProps>`
	display: table;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0px;
	table-layout: ${props => props.tableLayout};
	${MainTableRow} {
		@media screen and (min-width: 769px) {
			&:nth-of-type(2n) {
				background: ${props => (props.striped ? '#f5f5f5' : '')};
				/* height: 3.875rem; */
			}
		}
	}
`
const Title = styled.p`
	font-size: 0.75rem;
	font-weight: 600;
	color: #251b33;
	@media screen and (min-width: 769px) {
		font-size: 1.125rem;
	}
`
const TableInfoHeader = styled.div`
	top: 0;
	z-index: 2;
	background: #fff;
	position: sticky;
	border-bottom: solid 1px #d3d3d3;
	padding: 11px;
	width: 100%;
	padding-bottom: 0.375rem;
	margin-bottom: 0.625rem;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media screen and (min-width: 769px) {
		padding: 17px 27px;
		width: 100%;
		padding-bottom: 0.8rem;
		margin-bottom: 1.156rem;
	}
`
const TableHead = styled.tr`
	width: 100%;
	font-size: 1.125rem;
	font-weight: normal;
	color: #b6b6b6;
	display: table-row;
	vertical-align: middle;
	outline: 0px;
	${({ theme }) => `${theme.mediaQueries.medium}{
		&:first-child {
			padding-inline-start: 1rem;
		}
		font-size : 0.5rem;
		// height: 2.5rem;
		padding: 1.063rem 1.688rem;
	}`}
`

export const TableCell = styled.td<TextAlignProps & LayoutProps>`
	font-size: 0.75rem;
	text-align: center;
	margin: auto;
	font-weight: 500;
	vertical-align: middle;
	${({ theme }) => `${theme.mediaQueries.medium}{
		font-size: 1.125rem;
	}`}
	${textAlign};
	${layout}
`

export default MainTable
