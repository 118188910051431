import { NumberFormatter } from '@eduact/utils'
import { useGetStatisticsQuery } from '@Features/LandingPage/LandingPageApi/LandingPageApi'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import StatImage from './Assets/StatsImage.svg'

type Props = {}

const Stats: React.FC<Props> = () => {
	const { data } = useGetStatisticsQuery()
	const [t] = useTranslation('landingPage', { keyPrefix: 'stats' })
	return (
		<StyledStats>
			<StyledStatImage src={StatImage} alt="stat image" />

			<StatsInfoSection>
				<StatHeader>{t('title')}</StatHeader>
				{data && (
					<StatsWrapper>
						<Stat>
							<StatNumber>{NumberFormatter(data.data.enrollments)}+</StatNumber>
							<StatLabel>{t('enrollments')}</StatLabel>
						</Stat>
						<Stat>
							<StatNumber>{NumberFormatter(data.data.instructors)}+</StatNumber>
							<StatLabel>{t('instructors')}</StatLabel>
						</Stat>
						<Stat>
							<StatNumber>{NumberFormatter(data.data.classrooms)}+</StatNumber>
							<StatLabel>{t('classrooms')}</StatLabel>
						</Stat>
					</StatsWrapper>
				)}
			</StatsInfoSection>
		</StyledStats>
	)
}

const StyledStats = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	${({ theme }) => `${theme.mediaQueries.large}{
		flex-direction : row;
		padding : 0 8.563rem;
	}`}
`

const StyledStatImage = styled.img`
	max-width: 498px;

	@media (max-width: 500px) {
		width: 100%;
	}
`

const StatsInfoSection = styled.div`
	flex-grow: 1;
	text-align: center;
`

const StatHeader = styled.p`
	font-size: 24px;
	line-height: 29px;
	color: #979797;
	margin: 36px 0;
`

const StatsWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	${({ theme }) => `${theme.mediaQueries.large}{
		flex-direction : row;
		gap : 6rem;
	}`}
`

const Stat = styled.div`
	@media (max-width: 1450px) {
		margin: 16px 0;
	}
`

const StatNumber = styled.p`
	font-size: 36px;
	font-weight: bold;
	line-height: 44px;
`

const StatLabel = styled.p`
	font-size: 24px;
	line-height: 29px;
	color: #b5b1ff;
`

export default Stats
