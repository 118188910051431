import React, { createContext, Dispatch, useContext } from 'react'

export type PanelInfo = 'card' | 'reset-password'
type ProfileInfoPanelContextArgs = {
	currentInfo: PanelInfo
	setCurrentInfo: Dispatch<PanelInfo>
	openPasswordModal: boolean
	setPasswordModal: Dispatch<boolean>
}

const ProfileInfoPanelContext = createContext<ProfileInfoPanelContextArgs | null>(null)

export const ProfileInfoPanelProvider: React.FC<ProfileInfoPanelContextArgs> = ({ children, ...props }) => {
	return <ProfileInfoPanelContext.Provider value={props}>{children}</ProfileInfoPanelContext.Provider>
}

export const useProfileInfoPanel = () => {
	const context = useContext(ProfileInfoPanelContext)
	if (!context) throw new Error('No ProfileInfoPanelProvider found !!!')
	return context
}
