import { BackIcon, CloseIcon } from '@Components/Layout/Header/Assets/Icons'
import Spacer from '@eduact/ed-components/Spacer'
import React from 'react'
import styled from 'styled-components'

type Props = {
	walletIndex: number
	onClose?: () => void
	onBackward?: React.Dispatch<number>
	withClose: boolean
}
const WalletBodyHeader: React.FC<Props> = ({ walletIndex, onClose, onBackward, withClose }) => {
	return (
		<WalletBodyHeaderStyled>
			<BackButton visible={walletIndex > 0} onClick={() => onBackward && onBackward(walletIndex - 1)}>
				<BackIcon />
			</BackButton>
			{withClose && (
				<CloseButton onClick={() => onClose && onClose()}>
					<CloseIcon />
				</CloseButton>
			)}
		</WalletBodyHeaderStyled>
	)
}

export default WalletBodyHeader

const WalletBodyHeaderStyled = styled.div`
	padding: 0 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: inherit;
`
const BackButton = styled.div<{ visible: boolean }>`
	visibility: ${props => (props.visible ? 'visible' : 'hidden')};
	color: ${props => props.theme.colors.light};
`

const CloseButton = styled.div`
	cursor: pointer;
`
