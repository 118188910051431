import i18next from 'i18next'
import React, { FC, ReactNode, useContext } from 'react'
import styled from 'styled-components'
import TabsContext from './Provider/TabsProvider'

export interface TabProps {
	id: string
	children: ReactNode
}

export const Tab: FC<TabProps> = ({ children, id }) => {
	const { dir } = i18next
	const { active, setActive } = useContext(TabsContext)
	const isSelected = active === id
	return (
		<Wrapper direction={dir()}>
			<StyledTab
				role="tab"
				aria-selected={isSelected}
				aria-controls={`panel-${id}`}
				id={`tab-${id}`}
				className={isSelected ? 'active' : ''}
				tabIndex={isSelected ? 0 : -1}
				onClick={() => setActive?.(id)}
			>
				{children}
			</StyledTab>
		</Wrapper>
	)
}

const Wrapper = styled.div<{ direction: string }>`
	.active {
		svg {
			color: #5ac0fc;
		}
		@media screen and (min-width: 769px) {
			color: #5ac0fc;
			background-color: RGBA(90, 192, 252, 0.1);
			border-left-color: ${({ direction }) => direction === 'ltr' && '#5ac0fc'};
			border-left-width: ${({ direction }) => direction === 'ltr' && '3.5px'};
			border-left-style: ${({ direction }) => direction === 'ltr' && 'solid'};
			border-right-style: ${({ direction }) => direction === 'rtl' && 'solid'};
			border-right-width: ${({ direction }) => direction === 'rtl' && '3.5px'};
			border-right-color: ${({ direction }) => direction === 'rtl' && '#5ac0fc'};
		}
	}
`

const StyledTab = styled.div<TabProps>`
	display: block;
	cursor: pointer;
	border: none;
	@media screen and (min-width: 769px) {
		color: #251b33;
		font-size: 1.125rem;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1.438rem;
		width: 17.25rem;
		height: 2.5rem;
	}
`
