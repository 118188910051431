import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Breakpoints } from '../Hooks/BreakPoints'
import useWindowResize from '../Hooks/WindowResize'
import Circle from './Circle'
import Text from './Text'

type PieProps = {
	percentage: number
	colour: string
	data: Test | undefined
	overallScore?: number
	score: number | undefined
}

const cleanPercentage = (percentage: number) => {
	const tooLow = !Number.isFinite(+percentage) || percentage < 0
	const tooHigh = percentage > 100
	return tooLow ? 0 : tooHigh ? 100 : +percentage
}

const Pie: FC<PieProps> = ({ percentage, colour, data, score, overallScore }) => {
	const pct = cleanPercentage(percentage)
	const [isMediumSize, setIsMediumSize] = useState<boolean>(false)

	const mediumResizeCallback = () => {
		const md = parseInt(Breakpoints.md.slice(0, -2))
		if (window.innerWidth >= md) {
			setIsMediumSize(true)
		} else {
			setIsMediumSize(false)
		}
	}
	useWindowResize(mediumResizeCallback)

	return (
		<Svg width={!isMediumSize ? 160 : 200} height={!isMediumSize ? 160 : 200}>
			<G>
				<Circle colour="#ecebfc" />
				<Circle colour={colour} pct={percentage} />
			</G>
			<Text overallScore={overallScore} percentage={pct} data={data} score={score} />
		</Svg>
	)
}

const Svg = styled.svg`
	transform: scale(1.25);
	margin: 0 auto;
	margin-top: 0.3rem;
	margin-bottom: 0.3rem;
	@media (min-width: 610px) and (max-width: 768px) {
		transform: scale(1);
	}
	@media (max-width: 610px) {
		transform: unset;
		margin-top: unset;
		margin-bottom: unset;
	}
`
const G = styled.g`
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
`

export default Pie
