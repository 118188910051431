import { useEditUserProfileMutation, useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { cloneDeep } from 'lodash'
import CustomTextInput from '@Components/InputFields/CustomTextInput/CustomTextInput'
import { Box, FlexLayout, GridLayout } from '@eduact/ed-system'
import { ProfileInfoPanel } from '../ProfileInfoPanel'
import { RaisedButton, TextInput } from '@eduact/ed-components'
import styled from 'styled-components'
import { useStudentScreenSize } from '@eduact/student-theme'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { useProfileInfoPanel } from '../ProfileInfoPanelContext'
import { DevTool } from '@hookform/devtools'
import { ProfileTabBaseProps } from '../Profile.types'
import { useProfileTabs } from '../ProfileTabsProvider'
import { FormButton, FormGrid, ProfileForm } from '../Profile.styled'

const schema = yup.object().shape({
	first_name: yup.string().required('field_required'),
	last_name: yup.string().required('field_required'),
	email: yup.string().required('field_required').email('invalid_email'),
	phone_number: yup
		.string()
		.required('field_required')
		.matches(/^01[0,1,2,5]{1}[0-9]{8}/, 'invalid_phone'),
})

const ProfileBasicInfo = () => {
	const { data } = useGetUserProfileQuery()
	const { setIsDirty, blockedNavigationDone } = useProfileTabs()
	const {
		control,
		handleSubmit,
		reset,
		formState: { isDirty, isValid, isSubmitting },
	} = useForm<BasicInfoFormPayload>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: cloneDeep({
			first_name: data?.data.first_name,
			last_name: data?.data.last_name,
			email: data?.data.email,
			phone_number: data?.data.phone_number,
		}),
	})

	useEffect(() => {
		setIsDirty(isDirty)
	}, [isDirty])

	useEffect(() => {
		if (blockedNavigationDone) {
			reset()
		}
	}, [blockedNavigationDone])
	const [update] = useEditUserProfileMutation()
	const handleSave = async (formData: BasicInfoFormPayload) => {
		try {
			const isSuccess = await update({ type: 'educational_info', data: { ...data?.data, ...formData } }).unwrap()
			if (isSuccess) {
				reset(
					{
						...data?.data,
						...formData,
					},
					{ keepDirty: false, keepIsSubmitted: false }
				)
			}
		} catch (error) {}
	}
	const handleCancel = () => reset()

	const { isDesktop } = useStudentScreenSize()
	const { setCurrentInfo, setPasswordModal } = useProfileInfoPanel()
	// console.log({ isDirty, isValid, isSubmitting })
	return (
		<ProfileForm onSubmit={handleSubmit(handleSave)}>
			<DevTool control={control} />
			<FlexLayout height={'100%'} width={'100%'} flexDirection={'column'}>
				<FormGrid width={'100%'} gridTemplateColumns={{ lg: 'repeat(2,1fr)' }}>
					<Box width="100%">
						<CustomTextInput width={'100%'} control={control} name="first_name" />
						<CustomTextInput width={'100%'} control={control} name="last_name" />
						<CustomTextInput readOnly width={'100%'} control={control} name="email" />
						<CustomTextInput readOnly width={'100%'} control={control} name="phone_number" />
						<TextInput
							readOnly
							disabled
							width={'100%'}
							type="password"
							defaultValue={'12345'}
							background="transparent"
							withToggle={false}
							endAdornment={
								<span
									onClick={e => {
										setCurrentInfo('reset-password')
										setPasswordModal(true && !isDesktop)
									}}
								>
									<EdTypography  cursor='pointer' color="princetonOrange" textDecoration={'underline'} fontSize={{ sm: 8, md: 12 }}>
										Change Password
									</EdTypography>
								</span>
							}
						/>
					</Box>
					{!isDesktop && (
						<FlexLayout px={'2.063rem'} width="100%" justifyContent={'space-between'} alignItems="center">
							<FormButton type="button" onClick={handleCancel} disabled={!isDirty} variant="princetonOrange" btnSize={'small'}>
								Cancel
							</FormButton>
							<FormButton type="submit" disabled={!isDirty || !isValid || isSubmitting} btnSize={'small'}>
								Save
							</FormButton>
						</FlexLayout>
					)}
					<Box width="100%">
						<ProfileInfoPanel />
					</Box>
				</FormGrid>
				{isDesktop && (
					<FlexLayout justifyContent={'flex-end'} gridGap={'1rem'}>
						<RaisedButton onClick={handleCancel} type="button" disabled={!isDirty} variant="princetonOrange">
							Cancel
						</RaisedButton>
						<RaisedButton type="submit" disabled={!isDirty || !isValid || isSubmitting}>
							Save
						</RaisedButton>
					</FlexLayout>
				)}
			</FlexLayout>
		</ProfileForm>
	)
}

export default ProfileBasicInfo
