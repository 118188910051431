import { BaseApi } from '@Features/Base/BaseApi/BaseApi'

const TestsApi = BaseApi.injectEndpoints({
	endpoints: builder => ({
		getTestsCount: builder.query<ApiResponse<TestsCountResponse>, TestsQueryPayload>({
			query: payload => ({ url: 'classroom/analytics/tests/count', method: 'POST', body: payload }),
		}),
		getClassroomsTests: builder.query<ApiResponse<ClassroomsTestsResponse>, TestsQueryPayload>({
			query: payload => ({ url: 'classroom/analytics/tests', method: 'POST', body: payload }),
		}),
	}),
})

export const { useGetTestsCountQuery, useGetClassroomsTestsQuery, useLazyGetClassroomsTestsQuery } = TestsApi
