import React, { Suspense } from 'react'
import { BaseWidget, Mosaic, DayNightWidget, WidgetProps } from '@eduact/ed-components'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { useStudentScreenSize } from '@eduact/student-theme'
import styled from 'styled-components'
import { rgba } from 'polished'
import loadable from '@loadable/component'
// import CalenderWidget from './Components/CalenderWidget'
import LeadershipBoardWidget from './Components/LeadershipBoardWidget'
import LearningTimeWidget from './Components/LearningTimeWidget'
import CalenderWidget from './Components/CalenderWidget'
import { useTranslation } from 'react-i18next'
const WalletHistoryWidget = loadable(() => import('./Components/WalletHistoryWidget'))
const CompletedUnitsWidget = loadable(() => import('./Components/CompletedUnitsWidget'))
const MyClassroomsWidget = loadable(() => import('./Components/MyClassroomsWidget'))
const Overview = () => {
	const [t] = useTranslation('studentDashboard')
	const { user } = useAppSelector(state => state.auth)
	const { isDesktop, isMobile } = useStudentScreenSize()

	return (
		<OverviewContainer>
			<Suspense fallback={<div>{t('loading')}</div>}>
				<Mosaic width={'100%'} gridGap={{ sm: '0.625rem', lg: '1.563rem' }}>
					<Mosaic.Col width={'100%'} flex={{ lg: 2 }}>
						<Mosaic.Row width={'100%'} alignItems={'stretch'}>
							<Mosaic.Col flex={{ md: 1.5 }}>
								<BaseWidget
									width={'100%'}
									height={'100%'}
									minHeight={{ sm: 'unset', lg: '13.6rem' }}
									contained
									widget={DayNightWidget}
									widgetProps={{ username: user?.first_name ?? '' }}
								/>
							</Mosaic.Col>
							{!isMobile && (
								<Mosaic.Col flex={{ md: 1 }}>
									<BaseWidget widget={LeadershipBoardWidget} widgetProps={{}} />
								</Mosaic.Col>
							)}
						</Mosaic.Row>
						<Mosaic.Row width={'100%'}>
							<Mosaic.Col flex={{ lg: 1 }} height="100%">
								<BaseWidget widget={LearningTimeWidget} widgetProps={{}} />
							</Mosaic.Col>

							<Mosaic.Col height={{ lg: '100%', md: '17.988rem', sm: '8.869rem' }} flex={{ sm: undefined, md: 2 }}>
								<CompletedUnitsWidget />
							</Mosaic.Col>
						</Mosaic.Row>
						<Mosaic.Row>
							<MyClassroomsWidget />
						</Mosaic.Row>
					</Mosaic.Col>
					<Mosaic.Col flex={{ lg: 1 }}>
						{!isMobile && (
							<Mosaic.Row>
								<BaseWidget overflow={'unset'} contained withShadow widget={CalenderWidget} widgetProps={{}} />
							</Mosaic.Row>
						)}
						<Mosaic.Row flex={undefined}>
							<WalletHistoryWidget />
						</Mosaic.Row>
					</Mosaic.Col>
				</Mosaic>
			</Suspense>
		</OverviewContainer>
	)
}

export default Overview

const OverviewContainer = styled.div`
	background: ${rgba('#FFF', 0.5)};
	width: 100%;
	padding: 11px;
	border-radius: 15px;
	${({ theme }) => `${theme.mediaQueries.large}{
		background : none;
		padding: 0;
		padding-top : 4.813rem;
	}`}
`
