import { MainTableRequestArgs } from '@Components/MainTable/MainTable.types'
import TableFilter from '@Components/MainTable/TableFilter'
import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { SkeletonBox } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { Box, FlexLayout, TableDataFetch } from '@eduact/ed-system'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useLazyGetClassroomsTestsQuery } from '../../Tests.api'
import UnitTestCard from '../UnitTestCard/UnitTestCard'

const TestsTable = () => {
	const { id } = useParams<{ id: string }>()
	const [t] = useTranslation('classroomTests')
	const [getTests, { data, isLoading }] = useLazyGetClassroomsTestsQuery()
	const tableRequest = useCallback(
		(args: MainTableRequestArgs) => {
			getTests({ ...args, classroom_id: Number(id) ?? skipToken })
		},
		[getTests]
	)

	return (
		<SkeletonBox isLoading={isLoading} height={'55vh'}>
			{data?.filters && (
				<TableFilter
					showMobileToggler={false}
					showTitle={false}
					showFilterIcon
					filtersWrapperStyles={{ gridColumnGap: { sm: '0.5rem', lg: '2rem' } }}
					actionsWrapperStyles={{ justifyContent: { sm: 'center' } }}
					dateFilter={false}
					autoApplyFilters
					tableFilters={data?.filters}
				/>
			)}
			<TableDataFetch
				request={tableRequest}
				tableFilters={data?.filters as Filter[]}
				render={() => (
					<Box>
						{data?.data?.map((test, index) => {
							const { units, name } = test
							return (
								<SectionWrapper key={`${test.id}-${index}`} mb={{ sm: '2rem' }}>
									<TableHeaderWrapper px={{ sm: '1.125rem' }}>
										<FlexLayout justifySelf={'flex-start'}>
											<CourseName color="purple" fontSize={{ lg: '1.5rem' }} fontWeight={'bold'} textAlign="center">
												{name}
											</CourseName>
										</FlexLayout>
										<FlexLayout display={{ sm: 'none', lg: 'flex' }}>
											<TableHeader margin="0 auto" px={{ lg: '5.875rem' }}>
												{t('numberOfAttempts')}
											</TableHeader>
										</FlexLayout>
										<FlexLayout display={{ sm: 'none', lg: 'flex' }}>
											<TableHeader margin="0 auto" px={{ lg: '5.875rem' }}>
												{t('status')}
											</TableHeader>
										</FlexLayout>
										<FlexLayout display={{ sm: 'none', lg: 'flex' }}>
											<TableHeader margin="0 auto" px={{ lg: '5.875rem' }}>
												{t('score')}
											</TableHeader>
										</FlexLayout>
										<FlexLayout display={{ sm: 'none', lg: 'flex' }}>
											<TableHeader margin="0 auto" px={{ lg: '5.875rem' }}>
												{t('attemptDate')}
											</TableHeader>
										</FlexLayout>
									</TableHeaderWrapper>
									<Spacer mb={{ sm: '0.625rem' }} />
									{units?.map((unit, index) => (
										<React.Fragment key={index}>
											<UnitTestCard unit={unit} />
										</React.Fragment>
									))}
								</SectionWrapper>
							)
						})}
					</Box>
				)}
			/>
		</SkeletonBox>
	)
}

const TableHeader = styled(Spacer)`
	padding-left: 0rem;
	padding-right: 0rem;
	color: #b6b6b6;
	text-align: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1rem;
	}`}
`
const SectionWrapper = styled(Box)`
	:not(:nth-of-type(1)) {
		${TableHeader} {
			display: none;
		}
	}
`
const CourseName = styled(EdTypography)`
	white-space: pre-line;
	text-overflow: ellipsis;
	text-align: start;
`
const TableHeaderWrapper = styled(Box)`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-items: center;
	${({ theme }) => `${theme.mediaQueries.large}{
		grid-template-columns: repeat(7, 1fr);
	}`}
`

export default TestsTable
