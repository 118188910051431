import { StudentIDProps, StudentQRCode } from '@eduact/ed-components'
import { useStudentScreenSize } from '@eduact/student-theme'
import { useGetUserProfileQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import React, { useMemo } from 'react'

type Props = StudentIDProps
const StudentQRCard: React.VoidFunctionComponent<Props> = ({ scale, ...props }) => {
	const { data, isLoading } = useGetUserProfileQuery()

	const studentCardData = useMemo(() => {
		return {
			userInfo: {
				first_name: data?.data.first_name,
				last_name: data?.data.last_name,
				phone: data?.data.phone_number,
				username: data?.data.username,
				email: data?.data.email,
			},
		}
	}, [data])
	const { isDesktop, isTablet, isMobile } = useStudentScreenSize()

	const getScale = useMemo(() => {
		if (scale) return scale
		return isMobile ? 0.6 : 1
	}, [scale])
	return <StudentQRCode withShadow={false} title="Student ID" scale={getScale} {...studentCardData} {...props} />
}

export default StudentQRCard
