import ImagesSwiper from '@Components/Utilities/ImagesSwiper/ImagesSwiper'
import Skeleton from '@Components/Utilities/Skeleton/Skeleton'
import { SectionWrapper } from '@Features/LandingPage/Assets/LandingPage.styled'
import { useGetNewsFeedQuery } from '@Features/LandingPage/LandingPageApi/LandingPageApi'
import React from 'react'
import styled from 'styled-components'
import { LayoutProps } from 'styled-system'

type Props = {} & LayoutProps
const NewsFeedsSection: React.FC<Props> = ({ ...props }) => {
	const { data } = useGetNewsFeedQuery()

	return (
		<>
			{data && data.data.length > 0 && (
				<SectionWrapper>
					<ImagesSwiper height={props.height ?? '30vh'}>
						{data.data.map((news, index) => {
							return <ImagesSwiper.Item news={news} key={`${news.id}-${index}`} img={news.photo} />
						})}
					</ImagesSwiper>
				</SectionWrapper>
			)}
		</>
	)
}

export default NewsFeedsSection
