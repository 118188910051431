import Modal from '@Components/Utilities/Popup/Modal'
import { RaisedButton, TextButton } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import moment from 'moment'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TrustedDevicesApi, { useLogoutDeviceMutation } from '../Settings.api'
import LocalStorageKeys from '@Constants/LocalStorageKeys'

type Props = {
	device: Device
	key: number
	setDevices: any
}

const DeviceCard: FC<Props> = ({ device, key, setDevices }) => {
	const [t] = useTranslation('settings')
	const [openModel, setOpenModel] = useState<boolean>(false)
	const [deleteDevice] = useLogoutDeviceMutation()

	const getAllDevices = async () => {
		const {
			data: { data },
		} = await TrustedDevicesApi.getAllDevices()
		setDevices(data)
	}
	const deviceId = localStorage.getItem(LocalStorageKeys.DeviceId)

	const logoutSingleDevice = async () => {
		await deleteDevice({
			device_id: device.device_id,
		}).then(() => {
			getAllDevices()
		})
	}

	const isCurrentDevice = useMemo(() => {
		return device.device_id === deviceId
	}, [device.device_id, deviceId])

	return (
		<>
			<ModelStyle modalOpen={openModel}>
				<Modal.Content
					minWidth={'fit-content'}
					minHeight={'fit-content'}
					height="13.994rem"
					onClose={() => {
						setOpenModel(false)
					}}
				>
					<ContentWrapper justifyContent="space-between" alignItems="center" flexDirection="column" height="100%">
						<FlexLayout
							p={{ lg: '3.688rem', sm: '2.688rem' }}
							pb={{ lg: '0', sm: '0' }}
							flexDirection="column"
							alignItems="flex-start"
							gridGap="0.875rem"
						>
							<Note>{t('note')}</Note>
						</FlexLayout>
						<FlexLayout
							justifyContent="space-between"
							alignItems="center"
							width="100%"
							p={{ lg: '0 2.813rem 1.8rem', sm: '0 1.688rem 1.5rem' }}
						>
							<CancelButtonConfirm
								onClick={() => {
									setOpenModel(false)
								}}
							>
								{t('discard')}
							</CancelButtonConfirm>
							<ConfirmButton
								onClick={() => {
									logoutSingleDevice()
								}}
							>
								{t('confirm')}
							</ConfirmButton>
						</FlexLayout>
					</ContentWrapper>
				</Modal.Content>
			</ModelStyle>
			<Wrapper key={key}>
				{isCurrentDevice && (
					<Indicator>
						<Dot />
						<p>{t('currentDevice')}</p>
					</Indicator>
				)}
				<FlexLayout flexDirection="column" gridGap="1.875rem" alignItems="center" justifyContent="center">
					{device.type === 'mobile' ? (
						<PhoneIcon className="material-symbols-outlined">smartphone</PhoneIcon>
					) : (
						<LapIcon className="material-symbols-outlined">laptop_chromebook</LapIcon>
					)}
					<FlexLayout flexDirection="column" gridGap="0.75rem" alignItems="center">
						<Agent>{device.agent}</Agent>
						<Type>{t(`${device.type}`)}</Type>
						<LoggedIn flexDirection="column" gridGap="0.2rem" alignItems="center" justifyContent="center">
							<p>{t('lastLogin')}</p>
							<p>{moment(device.last_login).format('DD/MM hh:mm A')}</p>
						</LoggedIn>
						<LoggedInCount flexDirection="column" gridGap="0.2rem" alignItems="center" justifyContent="center">
							<p>{t('loginCount')}</p>
							<p>{device.login_count}</p>
						</LoggedInCount>
						{!isCurrentDevice && (
							<RemoveButton
								variant="text"
								color="warning"
								onClick={() => {
									setOpenModel(true)
								}}
							>
								{t('logout')}
							</RemoveButton>
						)}
					</FlexLayout>
				</FlexLayout>
			</Wrapper>
		</>
	)
}

const ModelStyle = styled(Modal)`
	display: flex;
	flex-direction: column;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	border-radius: 4px;
	box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
	margin: 32px;
	position: relative;
	overflow-y: auto;
`
const Wrapper = styled.div`
	border-radius: 15px;
	border: solid 1px #cacaca;
	padding: 2.313rem 2.25rem 1rem;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	position: relative;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding: 3.313rem 6.25rem 1rem;
	}`}
`
const Indicator = styled(FlexLayout)`
	position: absolute;
	top: 0;
	font-size: 0.75rem;
	color: #5ac0fc;
	font-weight: normal;
	align-items: center;
	gap: 0.313rem;
	left: 0;
	padding: 1.125rem;
	p {
		${({ theme }) => `${theme.mediaQueries.large}{
			margin-top: 2px;
		}`}
	}
`
const Dot = styled.div`
	width: 10px;
	height: 10px;
	background: #5ac0fc;
	border-radius: 50%;
	${({ theme }) => `${theme.mediaQueries.large}{
		width: 12px;
		height: 12px;
	}`}
`
const PhoneIcon = styled.span`
	font-size: 3.594rem;
	height: 3.594rem;
	color: #cacaca;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 7.594rem;
		height: 7.594rem;
	}`}
`
const LapIcon = styled.span`
	font-size: 5.375rem;
	height: 3.594rem;
	color: #cacaca;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 9.375rem;
		height: 7.594rem;
	}`}
`
const Agent = styled.p`
	color: #707070;
	font-weight: normal;
	font-size: 0.875rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
	}`}
`
const Type = styled.p`
	color: #251b33;
	font-weight: 600;
	font-size: 1.2rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.5rem;
	}`}
`
const LoggedIn = styled(FlexLayout)`
	color: #707070;
	font-weight: normal;
	font-size: 1rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
	}`}
`
const LoggedInCount = styled(FlexLayout)`
	color: #707070;
	font-weight: normal;
	font-size: 1rem;
	margin-top: 0.5rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
	}`}
`
const RemoveButton = styled(TextButton)`
	text-decoration: underline;
	color: #ff8532;
	font-weight: 600;
	font-size: 1rem;
	margin-top: 0.563rem;
	text-transform: none;
	&:focus {
		outline: 0;
	}
	&:hover {
		background: transparent;
		text-decoration: underline;
	}
`
const CancelButtonConfirm = styled(RaisedButton)`
	color: #fff;
	font-size: 1rem;
	font-weight: bold;
	background: #ff8532;
	border-color: #ff8532;
	width: 4.813rem;
	height: 1.438rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		width: 6.813rem;
		height: 2.438rem;
	}`}
`
const ConfirmButton = styled(RaisedButton)`
	color: #fff;
	font-size: 1rem;
	font-weight: bold;
	width: 4.813rem;
	height: 1.438rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		width: 6.813rem;
		height: 2.438rem;
	}`}
`
const ContentWrapper = styled(FlexLayout)`
	font-family: Montserrat;
	gap: 1rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		gap: 1.938rem;
	}`}
`
const Note = styled.p`
	font-size: 1.125rem;
	font-weight: 600;
	color: #251b33;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
	}`}
`
export default DeviceCard
