import { IEssayQuestion, isEssayQuestion, Question, TestQuestion, UploadProgressArgs, useSnackbar } from '@eduact/ed-components'
import { useTest } from '@Features/Test/Providers/TestProvider'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazySubmitQuestionQuery } from '@Features/Test/Api/TestApi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useSubmitTest from '@Features/Test/Hooks/useSubmitTest'
import { SnackbarConfig } from '@Utilities/SnackbarConfig'
import { current } from 'immer'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { useTranslation } from 'react-i18next'
import { NumberFormatter } from '@eduact/utils'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { generatePath } from 'react-router'
import routes from '@Constants/Routes'
import { useDispatch } from 'react-redux'
import { resetRunningTest } from '@Redux/RunningTestSlice/RunningTestSlice'
import styled from 'styled-components'
import { uploadFileWithProgress } from '@Features/Base/BaseApi/BaseApi'
//
const SingleViewMode = () => {
	const { attempt, formQuestions, setFormQuestions, test } = useTest()
	const [currentIndex, setCurrentIndex] = useState(0)
	const [searchParams, setSearchParams] = useSearchParams()
	const { t } = useTranslation(['test'])

	useEffect(() => {
		const firstQuestionIndex = formQuestions.findIndex(_ => _.answer === null)
		if (firstQuestionIndex > -1) {
			setCurrentIndex(firstQuestionIndex)
			searchParams.set('q', formQuestions[firstQuestionIndex].id.toString())
			setSearchParams(searchParams)
		} else {
			setCurrentIndex(formQuestions.length - 1)
		}
	}, [])

	const currentQuestionId = useMemo(() => {
		return Number(searchParams.get('q'))
	}, [searchParams])

	const currentQuestion = useMemo(() => {
		const question = formQuestions.find(_ => _.id === currentQuestionId)
		return question ?? formQuestions[currentIndex]
	}, [currentIndex, currentQuestionId, formQuestions])

	useEffect(() => {
		if (formQuestions.length > 0) {
			const question = formQuestions.findIndex(_ => _.id === currentQuestionId)
			setFormQuestions(draft => {
				if (question > -1) draft[question].touched = true
			})
			if (question > -1) {
				searchParams.set('q', formQuestions[question].id.toString())
				setCurrentIndex(question)
				setSearchParams(searchParams)
			}
		}
	}, [currentQuestion])
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [submitQuestion] = useLazySubmitQuestionQuery()
	const submitTest = useSubmitTest()
	const { openSnackbar } = useSnackbar()

	const handleOnNext = async (order: number, index: number) => {
		if (
			(currentQuestion.answer === null ||
				(isEssayQuestion(currentQuestion) && currentQuestion.answer !== null && currentQuestion.answer.answer === null)) &&
			!test.allow_movement
		) {
			openSnackbar({ ...SnackbarConfig.warning, text: 'You must answer the question' })
			return
		}

		if (index === formQuestions.length - 1) {
			await submitTest()
			return
		}
		if (index < formQuestions.length - 1) {
			setCurrentIndex(index + 1)
			searchParams.set('q', formQuestions[index + 1].id.toString())
			setSearchParams(searchParams)
		}
	}
	const handleOnPrev = async (order: number, index: number) => {
		setCurrentIndex(index - 1)
		searchParams.set('q', formQuestions[index - 1].id.toString())
		setSearchParams(searchParams)
	}
	const { testData } = useAppSelector(state => state.RunningTest)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const handleSubmitQuestion = useCallback(async () => {
		try {
			const { isSuccess } = await submitQuestion({
				attempt_id: attempt.id,
				id: currentQuestion.id,
				options: currentQuestion.answer,
				question_id: currentQuestion.id,
				type: currentQuestion.type,
			})
			setFormQuestions(draft => {
				draft[currentIndex].submitted = true
			})
			return isSuccess
		} catch (error) {
			if (testData) {
				const path = generatePath(`${routes.classroom}/${routes.course}/${routes.mcqUnit}`, {
					label: testData.classroomLabel,
					cId: testData.course_id.toString(),
					id: testData.unit_id.toString(),
				})
				dispatch(resetRunningTest())
				navigate(path)
			}
		}
	}, [currentQuestion])

	const handleOnChange = async (answer: any) => {
		setFormQuestions(draft => {
			draft[currentIndex].answer = answer
			draft[currentIndex].submitted = false
		})
	}

	useEffect(() => {
		if (currentQuestion.answer) {
			handleSubmitQuestion()
		}
	}, [JSON.stringify(currentQuestion.answer)])

	const { isOnline } = useAppSelector(state => state.settings)
	const [uploadProgress, setUploadProgress] = useState<UploadProgressArgs | undefined>()
	const onAttachmentsChange = async (files: FileList | null, question: Question) => {
		if (!files || files.length === 0) return
		const formData = new FormData()
		for (let index = 0; index < files.length; index++) {
			const file = files.item(index)
			if (!file) return
			if (file.size / 1024 / 1024 > 5) {
				openSnackbar({ ...SnackbarConfig.disclaimer, text: `${file.name} exceeds 5MB` })
				return;
			}
			formData.set('file', file as Blob)
			formData.set('folder', '')
			try {
				const {
					data: {
						data: { uri },
					},
				} = await uploadFileWithProgress({
					formData,
					progressCallback(progress) {
						setUploadProgress({
							file,
							progress,
						})
					},
				})
				setUploadProgress(undefined)
				setFormQuestions(questions => {
					if (!isEssayQuestion(question)) return
					const selectedQuestion = questions.find(_ => _.id === currentQuestion.id) as IEssayQuestion
					if (!selectedQuestion) return
					if (!selectedQuestion.answer || !selectedQuestion.answer.answer) {
						selectedQuestion.answer = {
							type: 'attachment',
							answer: JSON.stringify([uri]),
						}
					} else {
						if (!selectedQuestion.answer.answer) return
						const answerFiles = JSON.parse(selectedQuestion.answer.answer) as string[]
						answerFiles.push(uri)
						selectedQuestion.answer = {
							type: 'attachment',
							answer: JSON.stringify(answerFiles),
						}
					}
				})
			} catch (error) {
				setUploadProgress(undefined)
				openSnackbar({ ...SnackbarConfig.disclaimer, text: `File upload failed` })

			}
		}
	}

	return (
		<TestWrapper online={isOnline}>
			<>
				{currentQuestion && (
					<TestQuestion
						key={`${currentQuestion.id}`}
						question={currentQuestion}
						index={currentIndex}
						onChange={val => {
							if (isOnline) {
								handleOnChange(val)
							}
						}}
						onNext={handleOnNext}
						onPrev={handleOnPrev}
						showPrev={currentIndex > 0}
						nextLabel={currentIndex === formQuestions.length - 1 ? t('submit') : t('next')}
						prevLabel={t('prev')}
						withNavigation
						questionLabel={`${t('q')}. ${NumberFormatter(currentIndex + 1)}`}
						scoreLabel={t('score')}
						essayQuestionProps={{
							onAttachmentsChange(files) {
								// console.log({files})
								onAttachmentsChange(files, currentQuestion)
							},
							uploadProgress,
							accept: 'application/pdf, .png, .jpg, .jpeg',
							textChangeDelay: 1000,
						}}
					/>
				)}
			</>
		</TestWrapper>
	)
}

export default SingleViewMode

const TestWrapper = styled.div<{ online: boolean }>`
	pointer-events: ${props => !props.online && 'none'};
	opacity: ${props => !props.online && '0.5'};
`
