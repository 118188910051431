import { AcceptPay } from '@Components/Layout/Header/Assets/js/AcceptPay'
import { DISPLAY_MODE, FawryPay } from '@Components/Layout/Header/Assets/js/FawryPay'
import { useLazySendRechargeCallbackQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { RechargeCallbackPayload, RechargePayloadType, RechargeProcessed } from '@Features/Dashboard/DashboardSlice/Dashboard.slice.types'
import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import queryString from 'query-string'

const usePayment = () => {
	const [sendCallback] = useLazySendRechargeCallbackQuery()
	const history = useNavigate()

	const handlePayment = useCallback(
		async (provider?: RechargeProcessed, paymentResult?: RechargePayloadType) => {
			return await new Promise<void>((resolver, reject) => {
				if (!paymentResult || !provider) {
					reject('Result or Provider not found !!!')
					return
				}

				if (provider.provider === 'fawry' && paymentResult.provider === 'fawry') {
					FawryPay.checkout(paymentResult.payload, {
						returnUrl: `${window.location.href}/invoices`,
						mode: DISPLAY_MODE.SEPARATED,
						onSuccess: (e: RechargeCallbackPayload) => {
							sendCallback(e)
							resolver()
						},
						onFailure: (e: any) => {},
					})
				}
				if (paymentResult.provider === 'opay' && paymentResult.method && paymentResult.method === 'card') {
					window.open(paymentResult.payload.data.cashierUrl, '_self')
					resolver()
				}
				if (paymentResult.provider === 'accept' && paymentResult.method && paymentResult.method === 'card') {
					AcceptPay.checkoutJS(
						paymentResult.payload,
						() => {},
						() => {}
					)
					resolver()
				}
				if (paymentResult.method === 'aman' || paymentResult.method === 'masary') {
					history(
						`/payment/callback?${queryString.stringify(paymentResult.payload)}&bill_reference=${paymentResult.payload.data.bill_reference}`
					)
					resolver()
				}
				if (paymentResult.method === 'ewallet') {
					var win = window.open(paymentResult.payload.redirection_url, '_self')
					win?.focus()
					resolver()
				}
			})
		},
		[history, sendCallback]
	)

	return { handlePayment }
}

export default usePayment
