import EdTypography from '@Components/Utilities/EdTypography/EdTypography'
import { Icon, Icons, SkeletonBox } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import React, { useMemo } from 'react'

import { useParams } from 'react-router'
import { AnalyticInfoCard, AnalyticInfoIcon, AnalyticInfoTitle, AnalyticsCardsWrapper } from '../../../SingleClassroom.styled'
import { useGetAttendanceCountQuery } from '../../Attendance.api'
import styled from 'styled-components'
type Time = {
	[key: string]: number
}

const AttendanceCount = () => {
	const { id } = useParams<{ id: string }>()

	const { isLoading, data } = useGetAttendanceCountQuery({ classroom_id: Number(id) }, { skip: !id })

	const learningTime = useMemo(() => {
		return Number(data?.data.time)
	}, [data])

	const findTime = (num: number) => {
		if (num < 1) {
			return '0'
		}
		const qualifier = (num: number) => (num > 1 ? '' : '')
		const numToStr = (num: number, unit: string) => (num > 0 ? `${num}${unit} ${qualifier(num)}` : '')
		const oneMinute = 60
		const oneHour = oneMinute * 60
		const oneDay = oneHour * 24
		const oneYear = oneDay * 365
		const times: Time = {
			year: ~~(num / oneYear),
			day: ~~((num % oneYear) / oneDay),
			hour: ~~((num % oneDay) / oneHour),
			minute: ~~((num % oneHour) / oneMinute),
		}
		let str = ''
		for (let [key, value] of Object.entries(times)) {
			str += numToStr(times[key], key[0])
		}
		const arr = str.trim().split(' ')
		const res: Array<any> = []
		arr.forEach((x, i) => {
			res.push(x)
		})
		return res.slice(0, 2).join(' ')
	}

	return (
		<SkeletonBox minHeight={{ sm: '120px' }} borderRadius={5} isLoading={isLoading}>
			<AnalyticsCardsWrapper>
				<AnalyticInfoCard>
					<AnalyticInfoIcon>
						<Icon color="light">
							<Icons.UserVerified />
						</Icon>
					</AnalyticInfoIcon>
					<AnalyticInfoTitle color="silver" fontWeight={500} fontSize={{ sm: '0.625rem', lg: '1.25rem' }}>
						Attended
					</AnalyticInfoTitle>
					<EdTypography fontSize={{ sm: '1.125rem', lg: '2.25rem' }} fontWeight={600}>
						{data?.data.attended_courses}
					</EdTypography>
				</AnalyticInfoCard>
				<AnalyticInfoCard>
					<AnalyticInfoIcon>
						<Icon color="light">
							<Icons.UserRejected />
						</Icon>
					</AnalyticInfoIcon>
					<AnalyticInfoTitle color="silver" fontWeight={500} fontSize={{ sm: '0.625rem', lg: '1.25rem' }}>
						Not Attended
					</AnalyticInfoTitle>
					<EdTypography fontSize={{ sm: '1.125rem', lg: '2.25rem' }} fontWeight={600}>
						{data?.data.not_attended_courses}
					</EdTypography>
				</AnalyticInfoCard>
				<AnalyticInfoCard>
					<AnalyticInfoIcon>
						<Icon color="light">
							<Icons.CircleCheck />
						</Icon>
					</AnalyticInfoIcon>
					<AnalyticInfoTitle color="silver" fontWeight={500} fontSize={{ sm: '0.625rem', lg: '1.25rem' }}>
						Completed
					</AnalyticInfoTitle>
					<EdTypography fontSize={{ sm: '1.125rem', lg: '2.25rem' }} fontWeight={600}>
						{data?.data.completed_courses}
					</EdTypography>
				</AnalyticInfoCard>
				<AnalyticInfoCard justifyContent="flex-start !important">
					<AnalyticInfoIcon>
						<Icon color="light">
							<Icons.CircleCheck />
						</Icon>
					</AnalyticInfoIcon>
					<FlexLayout flexDirection="column" alignItems="center" width="100%" gridGap="0.5rem">
						<LearningTimeStyle color="silver" fontWeight={500} fontSize={{ sm: '0.625rem', lg: '1.25rem' }}>
							Learning Time
						</LearningTimeStyle>
						<AllTime fontSize={{ sm: '1rem', lg: '1.5rem' }} fontWeight={600}>
							{findTime(learningTime)}
						</AllTime>
					</FlexLayout>
				</AnalyticInfoCard>
			</AnalyticsCardsWrapper>
		</SkeletonBox>
	)
}
const LearningTimeStyle = styled(AnalyticInfoTitle)`
	max-width: max-content;
`
const AllTime = styled(EdTypography)`
	margin: auto;
	text-align: center;
`
export default AttendanceCount
