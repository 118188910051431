import React from 'react'
import { Swiper } from '@Components/Utilities/Swiper'

import ClassroomCard from '@Components/Cards/ClassroomCard'
import { useGetClassroomsQuery } from '@Features/LandingPage/LandingPageApi/LandingPageApi'
import styled from 'styled-components'
import SkeletonCarousel from '@Components/Utilities/Skeleton/Components/SkeletonCarousel'
import SkeletonCard from '@Components/Utilities/Skeleton/Components/SkeletonCard'
import { useTranslation } from 'react-i18next'
import { space, SpaceProps } from 'styled-system'
import { FlexLayout } from '@eduact/ed-system'
import ViewAllButton from '@Components/Button/ViewAllButton/ViewAllButton'

const GuestLandingClassrooms: React.FC = () => {
	const [t] = useTranslation('landingPage')
	const { data, isLoading, isSuccess } = useGetClassroomsQuery()
	return (
		<SkeletonCarousel padding={0} gridGap={'1rem'} numberOfItems={6} isLoading={isLoading} renderComponent={<SkeletonCard />}>
			<FlexLayout mb={'4.063rem'} alignItems={'center'} justifyContent={'space-between'}>
				<SectionTitle>{t('instructor.classrooms')}</SectionTitle>
				<ViewAllButton mx={{ lg: '8.375rem' }} />
			</FlexLayout>
			<SectionWrapper px={{ lg: '3.875rem' }} mx={{ sm: '-1rem', lg: '' }}>
				<Swiper perSlide={{ sm: 1 }} overlayArrows withArrows withProgress>
					{isSuccess &&
						data &&
						data.data.map((classroom, index) => (
							<Swiper.Item key={`${classroom.id}-${index}-guest-classrooms`} swipe="1">
								<ClassroomCard
									title={classroom.title}
									instructor={`${classroom.instructor.user.first_name} ${classroom.instructor.user.last_name}`}
									badgeLabel={classroom.type}
									grade={(classroom.educationYears.length > 0 && classroom?.educationYears[0]?.name) || ''}
									subject={classroom.category.name}
									thumbnail={classroom.thumbnail}
									subjectImage={classroom.category.icon}
									classroom={classroom}
								/>
							</Swiper.Item>
						))}
				</Swiper>
			</SectionWrapper>
		</SkeletonCarousel>
	)
}

export default GuestLandingClassrooms
const SectionTitle = styled.h1`
	font-weight: bold;
	${({ theme }) => `${theme.mediaQueries.large}{
		padding : 0 8.375rem;
		font-size: 1.5rem;
	}`}
`

const SectionWrapper = styled.div<SpaceProps>`
	${space}
`
