import { BaseApi } from '@Features/Base/BaseApi/BaseApi'

const WebinarUnitApi = BaseApi.enhanceEndpoints({
	addTagTypes: ['WEBINAR', 'SLOT', 'GO_TO_COURSE', 'Section'],
}).injectEndpoints({
	endpoints: build => ({
		getWebinarUnit: build.query<WebinarUnitResponsePayload, string>({
			query: id => `/webinar/${id}`,
			providesTags: (result, error, arg) => {
				let tags: Array<{ type: 'SLOT'; id: number }> = []
				if (result) {
					result.data.webinarSlots?.forEach(_ => tags.push({ type: 'SLOT', id: _.id }))
				}
				return [...tags, { type: 'WEBINAR', id: arg }]
			},
		}),
		bookWebinarSlot: build.mutation<Webinar, Book>({
			query: payload => ({
				url: '/webinar/slot/book',
				method: 'POST',
				body: {
					webinar_id: payload.webinar_id,
					slot_id: payload.slot_id,
				},
			}),
			invalidatesTags: (result, error, args) => {
				return [
					{ type: 'GO_TO_COURSE', id: args.course_id },
					{ type: 'SLOT', id: args.slot_id },
					{ type: 'Section', id: args.section_id },
				]
			},
		}),
		cancelWebinarSlot: build.mutation<Webinar, SlotId>({
			query: payload => ({
				url: '/webinar/slot/cancel',
				method: 'POST',
				body: {
					slot_id: payload.slot_id,
				},
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: 'SLOT', id: arg.slot_id },
				{ type: 'Section', id: arg.section_id },
				{ type: 'GO_TO_COURSE', id: arg.course_id },
			],
		}),
		joinWebinarSlot: build.query<ApiResponse<SlotUrl>, SlotId>({
			query: payload => ({
				url: '/webinar/slot/join',
				method: 'POST',
				body: {
					slot_id: payload.slot_id,
				},
			}),
		}),
	}),
})

export const {
	useGetWebinarUnitQuery,
	useBookWebinarSlotMutation,
	useCancelWebinarSlotMutation,
	useLazyJoinWebinarSlotQuery,
	useJoinWebinarSlotQuery,
} = WebinarUnitApi
export default WebinarUnitApi
