import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { AuthenticationInitialState } from '@Features/Authentication/AuthenticationSlice/Authentication.slice.types'
import {
	LoginRequestPayload,
	VerifyEmailRequestPayload,
	CompleteProfileRequestPayload,
	VerifyPhoneRequestPayload,
	VerifyPhoneResponsePayload,
	LoginResponsePayload,
	AuthUserData,
} from '@Features/Authentication/AuthenticationApi/Authentication.api.types'
import AuthenticationApi from '@Features/Authentication/AuthenticationApi/Authentication.api'
import LocalStorageKeys from '@Constants/LocalStorageKeys'
import landingPageApi from '@Features/LandingPage/LandingPageApi'
import { SignupRequestPayload } from '../AuthenticationApi/Authentication.api.types'
import { BaseApi } from '../../Base/BaseApi/BaseApi'
import { isBaseError } from '@Features/Base/Error/BaseError'
import classroomApi from '@Features/Classroom/ClassroomSlice/ClassroomSlice'
import { dashboardAPI } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'

// export const setAuthData = createAction<AuthUserData, string>('auth/auth-obj')
export const setAuthData = createAsyncThunk('auth/auth-obj', (authObj: AuthUserData, { rejectWithValue }) => {
	try {
		return Promise.resolve(authObj)
	} catch (error) {
		return rejectWithValue(error)
	}
})

// async thunks
export const loginAction = createAsyncThunk('auth/login', async (authObject: LoginRequestPayload, { rejectWithValue }) => {
	try {
		const { data } = await AuthenticationApi.login(authObject)

		return data
	} catch (error) {
		return rejectWithValue(error)
	}
})
export const signupAction = createAsyncThunk('auth/signup', async (form: SignupRequestPayload, { rejectWithValue }) => {
	try {
		const { data } = await AuthenticationApi.signup(form)
		return data
	} catch (error) {
		return rejectWithValue(error)
	}
})

export const verifyCodeAction = createAsyncThunk('auth/verify-phone', async (form: VerifyPhoneRequestPayload) => {
	const { data } = await AuthenticationApi.verifyPhone(form)
	return data
})
export const verifyEmailAction = createAsyncThunk('auth/verify-email', async (verificationPayload: VerifyEmailRequestPayload) => {
	const { data } = await AuthenticationApi.verifyEmail(verificationPayload)
	return data
})

export const completeProfileAction = createAsyncThunk(
	'auth/complete-profile',
	async (completeProfilePayload: CompleteProfileRequestPayload) => {
		const { data } = await AuthenticationApi.completeProfile(completeProfilePayload)
		return data
	}
)

const initialState: AuthenticationInitialState = {
	isAuth: false,
	isProfileCompleted: false,
	user: null,
	redirect: null,
	isAuthSignup: false,
	needRedirect: false,
	settingAuthDataCompleted: null,
	loaders: {
		login: 'idle',
		verifyEmail: 'idle',
		profile: 'idle',
		signup: 'idle',
	},
}

const authenticationSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		checkAuthAction: (state: any) => {
			const authString = localStorage.getItem(LocalStorageKeys.AUTH)
			if (authString) {
				const authObject = JSON.parse(authString) as AuthObject
				// state.isProfileCompleted = authObject.student.profile_complete
				state.redirect = authObject.redirect
				state.needRedirect = authObject.redirect ? true : false
				state.user = authObject.user
				state.isAuth = true
			} else {
				state.isAuth = false
				state.isProfileCompleted = false
			}
		},
		logoutAction: (state: any) => {
			localStorage.removeItem(LocalStorageKeys.AUTH)
			state.isAuth = false
			state.user = null
			state.loaders.login = 'idle'
			state.needRedirect = false
			state.redirect = undefined
		},
	},
	extraReducers: builder => {
		// login
		builder
			.addCase(loginAction.pending, state => {
				state.loaders.login = 'pending'
			})
			.addCase(loginAction.fulfilled, (state, { payload: { data } }) => {
				state.user = data.user
				state.redirect = data.redirect
				localStorage.setItem(LocalStorageKeys.AUTH, JSON.stringify({ user: data.user, token: data.token, redirect: data.redirect }))
				state.isAuth = true
				// state.isProfileCompleted = data?.user?.student.profile_complete
				landingPageApi.util.invalidateTags(['Classrooms', 'Instructors'])
				state.loaders.login = 'fulfilled'
			})
			.addCase(loginAction.rejected, state => {
				state.loaders.login = 'rejected'
			})
		//signup
		builder
			.addCase(signupAction.pending, state => {
				state.loaders.signup = 'pending'
			})
			.addCase(signupAction.fulfilled, (state, { payload: { data } }) => {
				state.user = data.user
				localStorage.setItem(LocalStorageKeys.AUTH, JSON.stringify({ user: data.user, token: data.token }))
				state.isAuth = true
				state.isAuthSignup = true
				landingPageApi.util.invalidateTags(['Classrooms', 'Instructors'])
				state.loaders.login = 'fulfilled'
				state.redirect = data.redirect
			})
			.addCase(signupAction.rejected, state => {
				state.loaders.signup = 'rejected'
			})

		// verify email
		builder
			.addCase(verifyEmailAction.pending, state => {
				state.loaders.verifyEmail = 'pending'
			})
			.addCase(verifyEmailAction.fulfilled, (state, { payload: { data } }) => {
				state.user = data.user
				state.redirect = data.redirect
				localStorage.setItem(LocalStorageKeys.AUTH, JSON.stringify({ user: data.user, token: data.token }))
				state.isAuth = true
				state.loaders.verifyEmail = 'fulfilled'
			})
			.addCase(verifyEmailAction.rejected, state => {
				state.loaders.verifyEmail = 'rejected'
			})

		builder.addCase(setAuthData.pending, state => {
			state.settingAuthDataCompleted = null
		})
		builder.addCase(setAuthData.fulfilled, (state, action) => {
			state.settingAuthDataCompleted = false
			const { token, user, redirect } = action.payload
			// if (redirect) {
			state.redirect = redirect
			// }
			state.needRedirect = redirect ? true : false
			if (user) {
				state.user = user
			}
			debugger
			const existUserData = localStorage.getItem(LocalStorageKeys.AUTH)
			let authData = null
			let _token
			if (existUserData) {
				authData = JSON.parse(existUserData) as AuthUserData
				const { token: __token } = authData
				_token = __token
			}
			localStorage.setItem(
				LocalStorageKeys.AUTH,
				JSON.stringify({ user: state.user, token: token ?? _token, redirect: redirect ?? state.redirect })
			)
			state.settingAuthDataCompleted = true
		})
	},
})

export const { logoutAction, checkAuthAction } = authenticationSlice.actions
export default authenticationSlice.reducer

export const AuthApi = BaseApi.injectEndpoints({
	endpoints: builder => ({
		verifyPhone: builder.query<VerifyPhoneResponsePayload, VerifyPhoneRequestPayload>({
			query: (payload: { token: string }) => {
				return { url: `/auth/phone/confirm`, method: 'POST', body: payload }
			},
		}),
		completeProfile: builder.query<ApiResponse<AuthUserData>, CompleteProfileForm>({
			query: (payload: CompleteProfileForm) => {
				return { url: '/student/profile/complete', method: 'PUT', body: payload }
			},
		}),
		forgetPassword: builder.query<ApiResponse<any>, ForgetPasswordPayload>({
			query: payload => {
				return { url: '/auth/password/forget', method: 'POST', body: payload }
			},
		}),
		resetPassword: builder.query<ApiResponse<any>, ResetPasswordPayload>({
			query: payload => ({ url: '/auth/password/reset', method: 'POST', body: payload }),
		}),
		resendPhone: builder.query<ApiResponse<any>, string>({
			query: payload => ({ url: '/auth/phone/verification/resend', method: 'POST', body: { phone_number: payload } }),
		}),
		resendOTP: builder.query<ApiResponse<any>, string>({
			query: payload => ({ url: '/auth/password/verification/resend', method: 'POST', body: { identifier: payload } }),
		}),
		checkPasswordVerification: builder.query<ApiResponse<any>, string>({
			query: payload => ({ url: '/auth/password/verification/check', method: 'POST', body: { token: payload } }),
		}),
	}),
})

export const {
	useLazyVerifyPhoneQuery,
	useLazyCompleteProfileQuery,
	useLazyForgetPasswordQuery,
	useLazyResetPasswordQuery,
	useLazyResendPhoneQuery,
	useLazyResendOTPQuery,
	useLazyCheckPasswordVerificationQuery,
} = AuthApi
