import { RaisedButton } from '@eduact/ed-components'
import {
	WalletAmanImage,
	WalletCodeImage,
	WalletFawryImage,
	WalletMasaryImage,
	WalletOpayImage,
	WalletPaymobImage,
	WalletVodafoneImage,
	FawryVodafoneImage,
	OpayVodafoneImage
} from '@Components/Layout/Header/Assets/images'
import { useAppSelector } from '@Hooks/ReduxHooks'
import React, { useContext } from 'react'
import styled from 'styled-components'
import ButtonsGrid from '../../../../Layout/Header/Components/Wallet/ButtonsGrid'
import { WalletContext } from '../../../WalletContext'
import { TabBody } from '../Tabs.styled'
import { useTranslation } from 'react-i18next'

type Props = {}
const MethodsTab: React.FC<Props> = ({}) => {
	const { paymentMethods } = useAppSelector(state => state.settings)
	const { provider, setProvider, setWalletIndex } = useContext(WalletContext)
	const [t] = useTranslation(['wallet'])
	return (
		<TabWrapper>
			<MethodTabBody>
				<ButtonsGrid
					value={{
						value: provider?.provider,
						args: {
							method: provider?.method ?? 'aman',
						},
					}}
					onChange={value => {
						if (!value || !value.args) return
						if (value && value.value) {
							setProvider &&
								setProvider({
									method: value?.args?.method,
									provider: value.value,
								})
						}
					}}
				>
					{paymentMethods.fawry_fawry && (
						<ButtonsGrid.Item value={{ value: 'fawry', args: { method: 'fawry' } }}>
							<img alt="Fawry" src={WalletFawryImage} />
						</ButtonsGrid.Item>
					)}
					{paymentMethods.credit_card_accept && (
						<ButtonsGrid.Item value={{ value: 'accept', args: { method: 'card' } }}>
							<img alt="Paymob" src={WalletPaymobImage} />
						</ButtonsGrid.Item>
					)}
					{paymentMethods.aman_accept && (
						<ButtonsGrid.Item value={{ value: 'accept', args: { method: 'aman' } }}>
							<img alt="Aman" src={WalletAmanImage} />
						</ButtonsGrid.Item>
					)}
					{paymentMethods.masary_accept && (
						<ButtonsGrid.Item value={{ value: 'accept', args: { method: 'masary' } }}>
							<img alt="Masary" src={WalletMasaryImage} />
						</ButtonsGrid.Item>
					)}
					{paymentMethods.ewallet_accept && (
						<ButtonsGrid.Item value={{ value: 'accept', args: { method: 'ewallet' } }}>
							<img alt="Vodafone" src={WalletVodafoneImage} />
						</ButtonsGrid.Item>
					)}
					{paymentMethods.ewallet_fawry && (
					<ButtonsGrid.Item value={{ value: 'fawry', args: { method: 'ewallet' } }}>
						<img alt="Vodafone" src={FawryVodafoneImage} />
					</ButtonsGrid.Item>
					 )} 
					{paymentMethods.ewallet_opay && (
					<ButtonsGrid.Item value={{ value: 'opay', args: { method: 'ewallet' } }}>
						<img alt="Vodafone" src={OpayVodafoneImage} />
					</ButtonsGrid.Item>
					)} 
					{paymentMethods.credit_card_opay && (
						<ButtonsGrid.Item value={{ value: 'opay', args: { method: 'card' } }}>
							<img alt="Opay" src={WalletOpayImage} />
						</ButtonsGrid.Item>
					)}
					{paymentMethods.recharge_card && (
						<ButtonsGrid.Item value={{ value: 'eduact', args: { method: 'wallet' } }}>
							<img alt="Wallet Code" src={WalletCodeImage} />
						</ButtonsGrid.Item>
					)}
				</ButtonsGrid>
			</MethodTabBody>
			<RaisedButton
				onClick={() => {
					if (provider?.provider === 'eduact') {
						setWalletIndex?.(4)
						return
					}
					if (provider) {
						setWalletIndex?.(2)
					}
				}}
				disabled={!provider}
				width="100%"
			>
				{t('next')}
			</RaisedButton>
		</TabWrapper>
	)
}

export default MethodsTab

const TabWrapper = styled.div`
	/* padding: 0 1rem; */
`

const MethodTabBody = styled(TabBody)`
	margin: 1rem 0;
	padding: 0;
	font-size: 0.8rem;
`
