import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SnackbarStatus } from '@Utilities/SnackbarConfig'

type openSnackbarArgs = { status?: SnackbarStatus; text: string }
type SnackbarStateArgs = { url?: string }

const initialState: SnackbarStateArgs = {
	url: undefined,
}
const UtilsSlice = createSlice({
	name: 'utils',
	initialState,
	reducers: {
		setCurrentURL: (state: SnackbarStateArgs, action: PayloadAction<string>) => {
			state.url = action.payload
		},
		resetURL: (state: SnackbarStateArgs) => {
			state.url = undefined
		},
	},
})

export const { resetURL, setCurrentURL } = UtilsSlice.actions
export default UtilsSlice
