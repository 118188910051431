import axios from 'axios'
import LocalStorageKeys from '@Constants/LocalStorageKeys'

const api = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
})

export const rtkPrepareHeadersCallback = (headers: Headers, { getState }: any) => {
	const authKey = localStorage.getItem(LocalStorageKeys.AUTH)
	if (authKey != null) {
		const token = JSON.parse(authKey) as UserAuthKey
		if (token) {
			headers.set('authorization', `Bearer ${token.token}`)
		}
	}

	const deviceId = localStorage.getItem(LocalStorageKeys.DeviceId)
	if (deviceId) {
		headers.set('DeviceId', deviceId)
	}
	headers.set('accept-language', localStorage.getItem('lang') ?? 'en-GB')
	return headers
}

api.interceptors.request.use(
	function (config) {
		try {
			const AUTH = localStorage.getItem(LocalStorageKeys.AUTH)
			config.headers = {
				'accept-language': localStorage.getItem('lang') ?? 'en-GB',
			}

			const deviceId = localStorage.getItem(LocalStorageKeys.DeviceId)
			if (deviceId) {
				config.headers = { ...config.headers, DeviceId: deviceId }
			}
			if (!AUTH) return config
			const PARSED_AUTH = JSON.parse(AUTH) as UserAuthKey
			if (AUTH) {
				return {
					...config,
					headers: {
						...config.headers,
						Authorization: `Bearer ${PARSED_AUTH.token}`,
					},
				}
			}

			return config
		} catch (error) {
			return Promise.reject(error)
		}
	},
	function (error) {
		return Promise.reject(error.response)
	}
)
export default api
