import { Icon } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { Typography, FlexLayout } from '@eduact/ed-system'
import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'


type AnswerButtonProps = {
	icon: JSX.Element
	title: string
} & Pick<HTMLAttributes<HTMLButtonElement>, 'onClick'>
const AnswerButton: React.VoidFunctionComponent<AnswerButtonProps> = ({ icon, title, ...props }) => {
	return (
		<StyledAnswerButton as={'button'} {...props} flexDirection={'column'} alignItems="center">
			<AnswerThumb>
				<Icon fontSize={'4rem'} color="darkSilver">
					{icon}
				</Icon>
			</AnswerThumb>
			<Spacer mb={{ lg: 12 }} />
			<Typography fontSize={{ lg: 16 }} color="#707070">
				{title}
			</Typography>
		</StyledAnswerButton>
	)
}

export default AnswerButton

const StyledAnswerButton = styled(FlexLayout)`
	border: 0;
	background: none;
`
const AnswerThumb = styled.div`
	width: 79px;
	height: 79px;
	border-radius: 5px;
	display: grid;
	place-content: center;
	box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.16);
	border: solid 1px #c4c4c4;
	cursor: pointer;
`
