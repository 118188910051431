import { createTheme } from '@eduact/student-theme'

export const secondaryTheme = createTheme({
	colors: {
		primary: '#021e6f',
		purple: '#80b5fd',
		orange: '#fee2c5',
		// maxBluePurple : 'yellow',
		// purpleNavy : 'red',
		// lavender : 'red'
	},
})
