import { ButtonsGrid, RaisedButton } from '@eduact/ed-components'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { WalletContext } from '../../../WalletContext'
import { useGetMethodRatesQuery } from '@Features/Dashboard/DashboardSlice/Dashboard.slice'
import { ProccessRechargeCallback } from '../../WalletTabs'
import { Typography } from '@eduact/ed-system'
import { useTranslation } from 'react-i18next'
import { NumberFormatter } from '@eduact/utils'
import { TabBody } from '../Tabs.styled'
import { layout, LayoutProps } from 'styled-system'

type Props = {
	proccessRecharge: ProccessRechargeCallback
}
const paymentValues: number[] = [50, 75, 80, 100, 140]
const ValuesTab: React.FC<Props> = ({ proccessRecharge }) => {
	const { walletIndex, rechargeAmount, provider, setRechargeAmount, setWalletIndex } = useContext(WalletContext)
	const { data } = useGetMethodRatesQuery(provider?.provider, {
		skip: !provider || walletIndex !== 2,
	})

	const handleConfirm = async () => {
		// if (provider && provider?.method === 'ewallet') {
		// 	setWalletIndex?.(3)
		// 	return
		// }
		if (rechargeAmount && provider) {
			await proccessRecharge(rechargeAmount, provider)
		}
	}
	const [t] = useTranslation(['wallet'])
	const { t: tInputs } = useTranslation(['inputs'])
	const [customValue, setCustomValue] = useState<number>()
	const [customValueError, setCustomValueError] = useState<string>()
	const [customValueValid, setCustomValueValid] = useState<boolean>()
	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// if (!e.target.value) return
		const value = Number(e.target.value)
		if (value % 5 !== 0 || (data && value < data?.data.min)) {
			setCustomValueValid(false)
			setCustomValueError('invalid_multiple')
		} else {
			setCustomValueValid(true)
			setRechargeAmount?.(value)
		}
		setCustomValue(value)
	}

	return (
		<TabWrapper>
			<MethodTabBody>
				<StyledButtonsGrid
					value={{ value: rechargeAmount }}
					onChange={value => {
						if (value && value.value) {
							setRechargeAmount && setRechargeAmount(value?.value)
						}
					}}
				>
					{paymentValues.map((value, index) => {
						return (
							<ButtonsGrid.Item key={`${value}-${index}`} value={{ value: value.toString() }}>
								<ButtonGrid>
									<label>{NumberFormatter(value)}</label>
									{data && (
										<Typography fontSize={{ sm: '0.625rem', lg: '0.8rem' }}>
											{t('taxes_message', {
												amount: NumberFormatter(Math.round((data.data.percentage / 100 + 1) * value + data.data.constant)),
											})}
										</Typography>
									)}
								</ButtonGrid>
							</ButtonsGrid.Item>
						)
					})}
					<ButtonsGrid.Item value={{}}>
						<ButtonGrid maxWidth={'14ch'}>
							<input type="number" value={customValue} onChange={handleOnChange} />
							<Typography fontSize={{ sm: '0.625rem', lg: '0.8rem' }}>
								{data && (
									<>
										{customValueError && !customValueValid && tInputs(customValueError, { min: data.data.min, max: data.data.max })}
										{customValueValid &&
											customValue &&
											t('taxes_message', {
												amount: NumberFormatter(Math.round((data.data.percentage / 100 + 1) * customValue + data.data.constant)),
											})}
									</>
								)}
							</Typography>
						</ButtonGrid>
					</ButtonsGrid.Item>
				</StyledButtonsGrid>
			</MethodTabBody>
			<RaisedButton onClick={handleConfirm} disabled={!rechargeAmount} width="100%">
				{t('next')}
			</RaisedButton>
		</TabWrapper>
	)
}

export default ValuesTab

const TabWrapper = styled.div`
	width: 100%;
`

const MethodTabBody = styled(TabBody)`
	margin: 1rem 0;
	padding: 0;
	font-size: 0.8rem;
`
const ButtonGrid = styled.div<LayoutProps>`
	width: 100%;
	border: none;
	background: inherit;
	display: flex;
	flex-direction: column;
	label {
		font-size: 1.3rem;
	}
	span {
		/* font-size: 0.7rem; */
		color: ${props => props.theme.colors.maxBluePurple};
	}
	${layout}
`

const StyledButtonsGrid = styled(ButtonsGrid)`
	grid-template-columns: 1fr;
	${({ theme }) => `${theme.mediaQueries.large}{
		grid-template-columns: repeat(2,1fr);
	
	}`}
`
