import { useWidget, WidgetProps } from '@eduact/ed-components'
import { GridLayout, Typography } from '@eduact/ed-system'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import EddieImage from '../../Assets/Images/Eddie_Transparent.png'
const LeadershipBoardWidget: React.VoidFunctionComponent<WidgetProps<any>> = () => {
	const [t] = useTranslation('studentDashboard')
	const { setTitle } = useWidget()

	useEffect(() => {
		setTitle(t('leadershipBoard'))
	}, [])
	return (
		<GridLayout px={'2.188rem'} gridTemplateColumns={'repeat(2,1fr)'} alignItems={'center'}>
			<Typography lineHeight={1.22} fontSize={'2rem'} fontWeight={600} color={'#9b9b9b'}>
				{t('comingSoon')}
			</Typography>
			<img alt="Eddie" src={EddieImage} />
		</GridLayout>
	)
}

export default LeadershipBoardWidget
