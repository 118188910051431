import Spacer from '@eduact/ed-components/Spacer'
import { useAppSelector } from '@Hooks/ReduxHooks'
import React from 'react'
import Categories from '../Components/Categories'
import InstructorsCarousel from '../Components/InstructorsCarousel'
import NewsFeedsSection from '../Components/NewsFeedsSection/NewsFeedsSection'
import Stats from '../Components/Stats'
import ExamsSection from './Components/ExamsSection/ExamsSection'
import MyClassroomsSection from './Components/MyClassroomsSection/MyClassroomsSection'
import OnlineClassroomsSection from './Components/OnlineClassroomsSection/OnlineClassroomsSection'
import RevisionsSection from './Components/RevisionsSection/RevisionsSection'
import { StudentLandingPageWrapper } from './StudentLanding.styled'

type Props = {}

const StudentLanding: React.FC<Props> = () => {
	const { instructor_section, subjects_section } = useAppSelector(s => s.settings)
	return (
		<StudentLandingPageWrapper>
			<NewsFeedsSection height={{ sm: '11.25rem', lg: '45vh' }} />
			{instructor_section && (
				<Spacer py={{ lg: '1.625rem' }}>
					<InstructorsCarousel />
				</Spacer>
			)}
			<Spacer px={{ lg: '3.125rem' }}>
				<MyClassroomsSection />
				<OnlineClassroomsSection />
				<ExamsSection />
				<RevisionsSection />
			</Spacer>
			{subjects_section && (
				<Spacer mt={{ lg: '12.5rem' }}>
					<Categories />
				</Spacer>
			)}
			<Spacer px={{ lg: '0.9688rem' }} mt={{ lg: '9.125rem' }} mb={{ lg: '7.625rem' }}>
				<Stats />
			</Spacer>
		</StudentLandingPageWrapper>
	)
}

export default StudentLanding
