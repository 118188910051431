import { RaisedButton } from '@eduact/ed-components'
import { WalletInfoImage } from '@Components/Layout/Header/Assets/images'
import Spacer from '@Components/Utilities/Spacer'
import Tabs from '@Components/Utilities/Tabs/Tabs'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { WalletContext } from '../../../WalletContext'
import { TabBody, TabTitle } from '../Tabs.styled'
import { Typography } from '@eduact/ed-system'
import { useTranslation } from 'react-i18next'
import { NumberFormatter } from '@eduact/utils'

type Props = {}
const MainTab: React.FC<Props> = () => {
	const { walletAmount, setWalletIndex } = useContext(WalletContext)
	const [t] = useTranslation(['wallet', 'generic'])
	return (
		<>
			<MainTabContent>
				<TabBody flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
					<img alt="status" src={WalletInfoImage} />
					<Spacer my={10} />
					<div>
						<Typography fontSize={{ sm: '0.625rem', lg: '1rem' }}>
							{t('balance')} {walletAmount !== undefined && NumberFormatter(walletAmount)} {t('egp', { ns: 'generic' })}
						</Typography>
					</div>
				</TabBody>
				<RaisedButton
					onClick={() => {
						setWalletIndex?.(1)
					}}
					width="100%"
				>
					{t('charge_wallet')}
				</RaisedButton>
			</MainTabContent>
		</>
	)
}

export default MainTab

const MainTabContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: space-between;
`
