import { useEffect } from 'react'

function useWindowResize(callback: () => void) {
	useEffect(() => {
		callback()
		window.addEventListener('resize', callback)
		return () => {
			window.removeEventListener('resize', () => {})
		}
	}, [])
}
export default useWindowResize
