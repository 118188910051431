import { Card, RaisedButton } from '@eduact/ed-components'
import Spacer from '@eduact/ed-components/Spacer'
import { FlexLayout, Typography } from '@eduact/ed-system'
import React from 'react'
import styled from 'styled-components'
import OfferImg from '@Features/LandingPage/GuestLanding/Assets/offer_en.svg'
import OfferArImg from '@Features/LandingPage/GuestLanding/Assets/offer_ar.png'
import { useNavigate } from 'react-router'
import routes from '@Constants/Routes'
import { useTranslation } from 'react-i18next'

const OfferSection = () => {
	const navigate = useNavigate()
	const { t, i18n } = useTranslation(['landingPage', 'login'])
	return (
		<Spacer my={{ lg: '2.875rem' }} px={{ sm: '0.813rem', lg: '8.563rem' }}>
			<OfferCard withShadow variant={'maxBluePurple'}>
				<FlexLayout
					px={{ sm: '2.313rem', lg: '5.125rem' }}
					py={{ sm: '3.063rem', lg: '3.25rem' }}
					justifyContent={{ lg: 'space-between' }}
					alignItems="flex-end"
					flexDirection={{ sm: 'column', lg: 'row' }}
					gridGap={'50px'}
				>
					<FlexLayout px={{ lg: '2.188rem' }} mb={{ lg: '3rem' }} flexDirection="column">
						<Typography
							lineHeight={{ lg: '1.5' }}
							fontFamily={'Roboto'}
							wordSpacing={'0.2rem'}
							fontSize={{ sm: '1.5rem', lg: '2rem' }}
							fontWeight={'bold'}
						>
							{t('stats.we_offer')} {t('stats.features')}
						</Typography>
						<Spacer mb={{ sm: '1rem', lg: '3.625rem' }} />
						<Typography>{t('stats.cool')}</Typography>
						<Spacer mb={{ sm: '1.2rem', lg: '2.75rem' }} />
						<RaisedButton
							onClick={() => navigate(routes.signup)}
							btnSize={{ sm: 'small', lg: 'large' }}
							maxWidth={{ lg: '12rem', xl: '18.863rem' }}
						>
							{t('signup', { ns: 'login' })}
						</RaisedButton>
					</FlexLayout>
					<FlexLayout maxWidth={{ lg: '55%' }}>
						<img src={i18n.dir() === 'ltr' ? OfferImg : OfferArImg} alt="offers" />
					</FlexLayout>
				</FlexLayout>
			</OfferCard>
		</Spacer>
	)
}

export default OfferSection

const OfferCard = styled(Card)`
	background: #f8f8fe;
`
