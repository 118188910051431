import { RechargeProcessed } from '@Features/Dashboard/DashboardSlice/Dashboard.slice.types'
import { createContext } from 'react'

export type WalletTab = 'MAIN' | 'METHODS' | 'VALUES' | 'VODAFONE' | 'RECHARGECODE'
type IWalletContent = {
	walletIndex: number
	closeWallet?: () => void
	walletAmount?: number
	provider?: RechargeProcessed
	rechargeAmount?: number
	setWalletIndex?: React.Dispatch<number>
	setProvider?: React.Dispatch<RechargeProcessed | undefined>
	setRechargeAmount?: React.Dispatch<number>
	code?: string
	setCode?: React.Dispatch<string>
	closed: boolean
}
export const WalletContext = createContext<IWalletContent>({
	walletIndex: 0,
	closed: false,
})
