import routes from '@Constants/Routes'
import { Icon, Icons } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import i18next from 'i18next'
import { rgba } from 'polished'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Outlet } from 'react-router'
import styled from 'styled-components'
import { useGetClassroomBasicInfoQuery } from '../SingleClassroomSlice/SingleClassroomSlice'

const SingleClassroomLayout = () => {
	const navigate = useNavigate()
	const [t] = useTranslation('studentDashboard')
	const { id } = useParams()
	const { dir } = i18next
	const { data } = useGetClassroomBasicInfoQuery(id as string)
	const tabsRoutes = [
		{
			name: t('overviewClass'),
			route: '',
			disabled: false,
		},
		{
			name: t('attendance'),
			route: `attendance`,
			disabled: false,
		},
		{
			name: t('tests'),
			route: `tests`,
			disabled: false,
		},
		{
			name: t('webinars'),
			route: `webinars`,
			disabled: false,
		},
		{
			name: t('q&a'),
			route: `Q&A`,
			disabled: true,
		},
		{
			name: t('notes'),
			route: `notes`,
			disabled: true,
		},
	]
	const goBack = () => {
		navigate(`${routes.dashboard}/classrooms`)
	}

	const { pathname } = useLocation()

	const active = useMemo(() => {
		const index = tabsRoutes.filter(_ => _.route !== '').findIndex(_ => pathname.includes(_.route))

		return index < 0 ? 0 : index + 1
	}, [pathname])

	return (
		<Container>
			<BreadCrumb>
				<Header alignItems="center">
					<BackIcon color="dark" onClick={() => goBack()}>
						{dir() === 'ltr' ? <Icons.ChevronLeft /> : <Icons.ChevronRight />}
					</BackIcon>
					<ClassroomName>{data?.data.title}</ClassroomName>
				</Header>
				<TabsWrapper justifyContent="center" alignItems="center" width="100%">
					{tabsRoutes.map((route, index) => {
						return (
							<Tab
								disabled={route.disabled}
								key={route.name}
								onClick={e => {
									navigate(route.route)
								}}
								className={active === index ? 'active' : ''}
							>
								{route.name}
							</Tab>
						)
					})}
				</TabsWrapper>
			</BreadCrumb>
			<Outlet />
		</Container>
	)
}

const Container = styled.div`
	/* padding-top: 1.719rem; */
	width: 100%;
	background: red;
	padding: 7px;
	background: ${rgba('#FFF', 0.5)};
	border-radius: 15px;
	${({ theme }) => `${theme.mediaQueries.large}{
		background : transparent;
		padding:none;
	}`}
`
const BreadCrumb = styled(FlexLayout)`
	padding: 0 6px;
	padding-bottom: 2.394rem;
	justify-content: space-around;
	gap: 1rem;
	flex-direction: column;
	.active {
		border-radius: 5px;
		background: ${props => rgba(props.theme.colors.primary, 0.2)};
		color: ${props => props.theme.colors.primary};
	}
	${({ theme }) => `${theme.mediaQueries.large}{
    flex-direction: row;
	gap: 0.938rem;
}`}
	${({ theme }) => `${theme.mediaQueries.xlarge}{
		gap: 3.938rem;

	}`}
`
const Header = styled(FlexLayout)`
	width: 50%;
	border-right: unset;
	gap: 0.5rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    border-right: 2px solid #a6a6a6;
    gap: 1rem;
	}`}
`
const ClassroomName = styled.p`
	font-size: 0.75rem;
	font-weight: 600;
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: 1.25rem;
	}`}
`
const TabsWrapper = styled(FlexLayout)`
	justify-content: start;
	flex-wrap: wrap;
	column-gap: 2.438rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 1rem;
	${({ theme }) => `${theme.mediaQueries.medium}{
		display : flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		gap: 1rem;
	}`}
	${({ theme }) => `${theme.mediaQueries.xlarge}{
		gap: 2rem;

	}`}
`
const Tab = styled.p<{ disabled: boolean }>`
	border-radius: 5px;
	font-weight: 600;
	color: #2f2e41;
	font-size: 0.625rem;
	cursor: pointer;
	/* width: 3.481rem; */
	height: 1.131rem;
	flex: 1 0 3.481rem;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: ${props => (props.disabled ? 'none' : '')};
	opacity: ${props => (props.disabled ? '0.5' : '1')};
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: 1rem;
    width: 6.438rem;
	  height: 2.438rem;
	}`}
`
const BackIcon = styled(Icon)`
	font-size: 0.656rem;
	${({ theme }) => `${theme.mediaQueries.large}{
    font-size: 1.55rem;
	}`}
`
export default SingleClassroomLayout
