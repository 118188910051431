import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useState, useMemo } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import EndPage from './Components/EndPage'
import NotActive from './Components/NoActiveTest'
import ReviewEndPage from './Components/ReviewEndPage'
import StartTest from './Components/StartTest'
import { useGetTestMcqUnitQuery } from './TestMcqApi/TestMcqApi'

const McqTest = () => {
	const { id } = useParams()
	const [testInfo] = useState<any>()
	const { currentData, status } = useGetTestMcqUnitQuery(id ?? skipToken)

	const isOpened = useMemo(() => {
		return currentData?.data.trials?.opened
	}, [currentData?.data.trials?.opened])

	const isCompleted = useMemo(() => {
		return currentData?.data.trials?.completed
	}, [currentData?.data.trials?.completed])

	const attempt_id = useMemo(() => {
		return isCompleted?.filter(_ => _.test_id === currentData?.data.test.id).find(data => data.id)
	}, [isCompleted])

	const renderTestPages = () => {
		if (isOpened) return <StartTest isContinue data={currentData?.data.test} />

		if (isCompleted && isCompleted?.length === 0) {
			return <StartTest data={currentData?.data.test} />
		}
		if (isCompleted && isCompleted?.length > 0 && isCompleted?.[0].score === null && isCompleted?.[0].evaluation_status === 'review') {
			return (
				<ReviewEndPage
					data={currentData?.data.test}
					opened={currentData?.data.trials?.opened}
					testInfo={testInfo}
					completed={isCompleted}
					attempt_id={attempt_id?.id}
				/>
			)
		}
		if (isCompleted && isCompleted?.length > 0 && isCompleted?.[0].score !== null) {
			return (
				<EndPage
					data={currentData?.data.test}
					opened={currentData?.data.trials?.opened}
					testInfo={testInfo}
					completed={isCompleted}
					attempt_id={attempt_id?.id}
				/>
			)
		}
	}

	// console.log('isCompleted', isCompleted)
	// console.log('currentData?.data.trials?.opened', currentData?.data.trials?.opened)
	// console.log('currentData?.data.test', currentData?.data.test)
	console.log('isCompleted?.[0]', isCompleted?.[0])

	return (
		<Container>
			{currentData && status === 'fulfilled' && <>{currentData?.data.test.active ? renderTestPages() : <NotActive />}</>}
		</Container>
	)
}

const Container = styled.div`
	@media (max-width: 610px) {
		padding-bottom: 4rem;
	}
`
export default McqTest
